import React, { useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import Cache from '../../../../../../utils/cache'
import withSourceClick from '../../../../../hocs/source-only-click';
import { getSensorsUrl } from '../../../../../utils/sensor'

export const addWordToCache = word => {
	try {
		const recentlyWords = Cache.get('recently-words') || []
		const currentWords = recentlyWords.filter(r => r.value !== word?.value)

		if (currentWords?.length >= 10) {
			currentWords.length = 9
		}
		currentWords.unshift(word)
		Cache.set('recently-words', currentWords, 30 * 24 * 60 * 60)
	} catch (e) {
		console.error(e)
	}
}

const HotWordSource = withSourceClick(props =>{
    const { hotWord, innerRef, sensors } = props;

    const handleClick = useCallback(hot => {
        let href;
        addWordToCache(hot)
		if (hot.href) {
			href = hot.href
		} else {
			href = `/i/search/result?key=${encodeURIComponent(hot.value)}`
		}

        window.location.href = getSensorsUrl(href, {
			resourcepage_title: sensors?.resourcepage_title,
			resource_type: sensors?.resource_type,
            resource_position: sensors?.resource_position,
			resource_content: sensors?.resource_content,
		})
    }, [])

    return <li 
        className={classes.Word} 
        onClick={e => handleClick(hotWord)} 
        ref={innerRef}
        data-title={sensors?.resourcepage_title}
        data-position={sensors?.resource_position}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
    >
        {
            hotWord.hotIcon && <img className={classes.HotIcon} src={`${IMAGE_GEEKO_LTD}${hotWord.hotIcon}`}/>
        }
        {hotWord?.label}
    </li>
})


export default props => {
    useStyles(classes)

    const { hotWords } = props

    return <div className={classes.HotWordsContainer}>
        <div className={classes.Hd}><FormattedMessage id="hot_search" defaultMessage="Hot Search"/></div>
        <ul className={classes.WordList}>
            {
                hotWords?.map?.(hotWord => <React.Fragment>
                    <HotWordSource 
                        key={hotWord?.value} 
                        hotWord={hotWord} 
                        sensors={{
                            resourcepage_title: "search",
                            resource_position: "1",
                            resource_type: "13",
                            resource_content: hotWord?.label
                        }}
                    />
                </React.Fragment>)
            }
        </ul>
    </div>
}