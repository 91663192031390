

const addToCart = data => {
    try {
        if (!window.ttq) return

        const {
            productId,
            amount,
            currency,
            quantity
        } = data


        window.ttq.track('AddToCart', {
            content_type: 'product',
            contents: [
                { id: productId, quantity: quantity, price: amount }
            ],
            currency: currency
        })
    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {

        if (!window.ttq) return

        const {
            productId,
        } = data

        window.ttq.track('AddToWishList', {
            content_type: 'product',
            content_id: productId
        })
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        if (window.ttq){
            tikPageView(window.ttq, data)
        }
    } catch (e) {
        console.warn(e)
    }
}


const tikPageView = (t, data) => {
    const {
        productId
    } = data
    try{
        t.track('ViewContent', {
            content_type: 'product',
            content_id: productId
        })
    }catch(e){
        console.warn(e)
    }
}

const purchase = data => {
    try {
        if (!window.ttq) return

        const {
            orderTotal,
            orderItems,
        } = data

        const contents = orderItems.map(item => {
            return {
                id: item.productId,
                quantity: item.quantity,
                price: item.price?.amount
            }
        })

        window.ttq.track('Purchase', {
            value: orderTotal?.amount,
            currency: orderTotal?.currency,
            contents: contents,
            content_type: 'product',
        })

    } catch (e) {
        console.warn(e)
    }
}

export default {
    addToCart,
    addToWishList,
    pageView,
    purchase,
}