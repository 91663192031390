// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s9XQ-7iH7NqM1IBG7rs7J{height:100%;display:flex;align-items:center;height:100%;position:absolute;top:0;right:50px}.s9XQ-7iH7NqM1IBG7rs7J>*{height:100%;padding-left:12px;padding-right:12px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,WAAY,CAMZ,iBAAkB,CAClB,KAAM,CACN,UAAW,CAZf,yBAMQ,WAAY,CACZ,iBAAkB,CAClB,kBAAmB","sourcesContent":[".Tools{\n    height: 100%;\n    display: flex;\n    align-items: center;\n    height: 100%;\n    & > *{\n        height: 100%;\n        padding-left: 12px;\n        padding-right: 12px;\n    }\n    position: absolute;\n    top: 0;\n    right: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Tools": "s9XQ-7iH7NqM1IBG7rs7J"
};
module.exports = ___CSS_LOADER_EXPORT___;
