import React from 'react'
import _ from 'lodash'
import classes from '../style.module.scss'
import withStyles from 'isomorphic-style-loader/withStyles'


export const withList = Wrapped => {
    
    
    return withStyles(classes)(class extends React.Component{

        constructor(props){
            super(props)
            this.scrollHandle = _.debounce(this.scrollHandle.bind(this), 100);
            this.myRef = React.createRef();
        }

        componentDidMount(){
            this.myRef.current.addEventListener('scroll', this.scrollHandle)
        }

        componentWillUnmount(){
            this.myRef.current.removeEventListener('scroll', this.scrollHandle)
        }

        scrollHandle(event){
            let clientHeight = this.myRef.current.clientHeight;
            let scrollTop = this.myRef.current.scrollTop;
            let scrollHeight = this.myRef.current.scrollHeight;

            // console.log("clientHeight",clientHeight,"scrollTop",scrollTop,"scrollHeight",scrollHeight);
            // console.log("this.props.loading",this.props.loading);
            // console.log("this.props.finished",this.props.finished);
            if(clientHeight + scrollTop + 100 > scrollHeight && !!!this.props.loading && !!!this.props.finished){
               this.props?.request?.()
            }
        }

        render(){
            return <div>
                <Wrapped {...this.props} innerRef={this.myRef}/>
                {/* <div className={classes.LoadingWrapper}>
                    {
                        this.props.loading && <div className={classes.Loading}>loading</div>
                    }
                    {
                        this.props.finished && <div className={classes.Finished}>No more data.</div>
                    }
                </div> */}
            </div>
        }
    })
}