import { fetchCollectionList, fetchCollectionSorters, fetchCollectionFilter, getCountryMessage } from '../../../api'


export const __Default_LIST_COLLECTION_Limit__ = 12
export const __Default_List_Collection_Limit_For_DeskTop__ = 20

export const LIST_COLLECTION_SET = 'LIST_COLLECTION_SET'
export const LIST_COLLECTION_LIST_ADD = 'LIST_COLLECTION_LIST_ADD'
export const LIST_COLLECTION_LIST_SET = 'LIST_COLLECTION_LIST_SET'
export const LIST_COLLECTION_LIST_SKIP = 'LIST_COLLECTION_LIST_SKIP'
export const LIST_COLLECTION_LIST_FINISHED = 'LIST_COLLECTION_LIST_FINISHED'
export const LIST_COLLECTION_LIST_LOADING = 'LIST_COLLECTION_LIST_LOADING'

export const LIST_COLLECTION_FILTER_SET = 'LIST_COLLECTION_FILTER_SET'
export const LIST_COLLECTION_SORTERS_SET = 'LIST_COLLECTION_SORTERS_SET'

export const LIST_COLLECTION_MESSAGE_CONFIG = 'LIST_COLLECTION_MESSAGE_CONFIG'

export const LIST_COLLECTION_FILTER_DATA = 'LIST_COLLECTION_FILTER_DATA'

export const setListCollectionFilterData = payload => ({
	type: LIST_COLLECTION_FILTER_DATA,
	payload
})

export const setMessageConfig = payload => ({
    type: LIST_COLLECTION_MESSAGE_CONFIG,
    payload
})

export const setCollectionFilter = payload => ({
	type: LIST_COLLECTION_FILTER_SET,
	payload
})

export const setCollectionSorters = payload => ({
	type: LIST_COLLECTION_SORTERS_SET,
	payload
})

export const setCollection = payload => ({
	type: LIST_COLLECTION_SET,
	payload
})

export const setCollectionList = payload => ({
	type: LIST_COLLECTION_LIST_SET,
	payload
})

export const addCollectionList = payload => ({
	type: LIST_COLLECTION_LIST_ADD,
	payload
})

export const setCollectionSkip = payload => ({
	type: LIST_COLLECTION_LIST_SKIP,
	payload
})

export const setCollectionFinished = payload => ({
	type: LIST_COLLECTION_LIST_FINISHED,
	payload
})

export const setCollectionListLoading = payload => ({
	type: LIST_COLLECTION_LIST_LOADING,
	payload
})

export const action_add_list_collection_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCollectionListLoading(true))

		const limit = params?.limit || __Default_LIST_COLLECTION_Limit__

		const response = await fetchCollectionList(data, {...params, limit}, headers)
		dispatch(setCollectionListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addCollectionList(products))
			dispatch(setCollectionSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setCollectionFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_list_collection_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCollectionFinished(false))
		dispatch(setCollectionListLoading(true))
		dispatch(setListCollectionFilterData(data))
		const limit = params?.limit || __Default_LIST_COLLECTION_Limit__
		const response = await fetchCollectionList(data, {...params, skip: 0, limit}, headers)
		dispatch(setCollectionListLoading(false))

		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setCollectionList(products))
			dispatch(setCollectionSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setCollectionFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_list_collection_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchCollectionFilter(params, headers)
		if(response?.code === 200){
			dispatch(setCollectionFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_list_collection_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchCollectionSorters(params, headers)
		if(response?.code === 200 && response.result){
			const sorters = response.result
			dispatch(setCollectionSorters(Object.keys(sorters).sort().map(p => ({label: sorters[p], value: p}))))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_message_config = (code, headers) => {
    return async dispatch => {
		const response = await getCountryMessage(code, headers)
		if(response?.code === 200){
			dispatch(setMessageConfig(response?.result))
			return response.result
		}else{
			return null
		}
	}
}