import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { action_fetch_notification, setNotificationEventData } from '../../../../../store/actions/global'
import { TOOGLE_SUCCESS_WINDOW } from '../../../../../store/actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import { useBot } from '../../../../hooks/common'
import { IMAGE_GEEKO_LTD } from '../../../../../constants'
import deeplink from '../../../../../utils/deeplink'
import { Iconfont } from '../../../../components/icon/iconfont'
import NotificationTransition from '../../../components/transitions/notification-transition'
import { getByUrl } from '../../../../../api'
import Cache from '../../../../../utils/cache'
import CountDown from '../../../../components/count-down'
import { BigButton } from '../../../components/button'
import BlackMask from '../../../components/mask'
import { FormattedMessage } from 'react-intl'


const CouponButton = props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const { button, onClose } = props
    const [loading, setLoading] = useState(false)

    const buttonHandle = async e => {
        e.preventDefault()
        setLoading(true)
        const { deepLink, successMsg, couponName } = button
        if (deepLink) {
            if (deepLink.type === 35) {
                const response = await getByUrl(deepLink?.params?.[0])
                if (response?.code === 200) {
                    dispatch({
                        type: TOOGLE_SUCCESS_WINDOW,
                        payload: successMsg
                    })  
                    Cache.set('limit-24-coupon', {couponName, date: new Date().getTime()})
                } else if (response?.result) {
                    alert(response.result)
                }
                onClose()
            } else {
                const url = deeplink(deepLink, "pc")
                window.location.href = url
            }
        }

        setLoading(false)

        if (window.GeekoSensors) {
            window.GeekoSensors.Track('StationMsg', {
                type: '站内信',
                action: 'click',
                message_id: deepLink?.utmTerm
            })
        }
    }


    return <React.Fragment>
        <button className={classes.CouponButton} onClick={buttonHandle}>
            {
                loading && <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
            }
            {button?.text}
        </button>
    </React.Fragment>
}

const LimitCoupon = props => {
    useStyles(classes)
    const { coupon, closeHandle } = props

    return <React.Fragment>
        <BlackMask onClick={closeHandle} style={{zIndex: 10}}/>
        <div className={classes.LimitCouponAlert}>
            <div className={classes.Title}>
                <FormattedMessage id="wait" defaultMessage="Wait"/>!
            </div>
            <div className={classes.Dsc}>
                <FormattedMessage id="limit_coupon_unused" defaultMessage="You still have limit coupon(s) unused" />
            </div>
            <div className={classes.LimitCoupon}>
                <div className={classes.CouponLimit}>
                    <FormattedMessage id="limit_24_time" defaultMessage="24 hours only"/>
                </div>
                <div className={classes.CouponName}>
                    {coupon?.couponName}
                </div>
            </div>
            <div className={classes.Timmer}>
                <CountDown offset={24 * 60 * 60 * 1000 + coupon?.date - new Date().getTime()} showLabel showHour labelClass={classes.Label} numberClass={classes.Number} dotClass={classes.Dotted} />
            </div>
            <div className={classes.ButtonA}>
                <BigButton onClick={closeHandle}>
                    <FormattedMessage id="return_to_site" defaultMessage="Return To Site" />
                </BigButton>
            </div>
        </div>
    </React.Fragment>
}

const CouponSuccess = props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const ui = useSelector(state => state.ui)

    const closeHandle = () => {
        dispatch({
            type: TOOGLE_SUCCESS_WINDOW,
            payload: false
        })
    }

    return <React.Fragment>
        <BlackMask onClick={closeHandle} style={{zIndex: 10}}/>
        <div className={classes.CouponSuccess}>
            <img src={`${IMAGE_GEEKO_LTD}/chicme/20220613/jbbj.svg`} />
            <div className={classes.Title}>
                {ui.showSuccessWindow}
            </div>
            <div>
                <a className={classes.Dsc} href="/me/m/coupons">
                    <FormattedMessage id="check_it_in_coupons" defaultMessage="Check it in [My Coupons]" />
                </a>
            </div>
            <div className={classes.Timmer}>
                <CountDown offset={24 * 60 * 60 * 1000} showLabel showHour labelClass={classes.Label} numberClass={classes.Number} dotClass={classes.Dotted} />
            </div>
            <div className={classes.ButtonA}>
                <BigButton onClick={closeHandle}>
                    <FormattedMessage id="got_it" defaultMessage="Got It" />
                </BigButton>
            </div>
        </div>
    </React.Fragment>
}

const CouponSuccessPortal = props => {
    if (typeof window === 'undefined') return

    return ReactDOM.createPortal(
        <CouponSuccess {...props} />,
        document.getElementById('root')
    )
}


const Type2 = props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { notification } = props

    const handleClick = e => {
        if (notification?.deepLink) {
            const url = deeplink(notification?.deepLink, "pc")
            window.location.href = url
        }

        if (window.GeekoSensors) {

            window.GeekoSensors.Track('StationMsg', {
                type: '站内信',
                action: 'click',
                message_id: notification?.deepLink?.utmTerm
            })

        }

    }

    const getImage = useCallback(image => {
        if (image?.startsWith('/')) {
            return `${IMAGE_GEEKO_LTD}${image}`
        } else if (image?.startsWith('http')) {
            return image
        } else {
            return `${IMAGE_GEEKO_LTD}/${image}`
        }
    }, [])



    return <div className={classes.Notification}>
        <div className={classes.Hd}>
            <div className={classes.LogoContainer}>
                <img className={classes.Logo1} src={notification?.bodycon || global?.config?.icon} />
                {
                    notification?.title ? <span className={classes.Title}>{notification?.title}</span> : <img className={classes.Logo2} src={global?.config?.logo} />
                }
            </div>
            <Iconfont onClick={props?.onClose} className={classes.Close}>&#xe7c9;</Iconfont>
        </div>
        <div className={classes.Bd} onClick={handleClick}>
            <div className={classes.Content}>
                <span dangerouslySetInnerHTML={{ __html: notification?.body }} />
            </div>
            {
                notification?.image && <div className={classes.Icon} style={{ backgroundImage: `url(${getImage(notification?.image)})` }} />
            }
        </div>
    </div>
}


const Type3 = props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { notification } = props

    return <div className={classes.Notification}>
        <div className={classes.Hd}>
            <div className={classes.LogoContainer}>
                <img className={classes.Logo1} src={notification?.bodycon || global?.config?.icon} />
                {
                    notification?.title ? <span className={classes.Title}>{notification?.title}</span> : <img className={classes.Logo2} src={global?.config?.logo} />
                }
            </div>
            <Iconfont onClick={props?.onClose} className={classes.Close}>&#xe7c9;</Iconfont>
        </div>
        <div className={classes.ContentAndButtons}>
            <div className={classes.Coupon}>
                <div className={classes.Content}>
                    <span dangerouslySetInnerHTML={{ __html: notification?.body }} />
                </div>
                {
                    notification?.buttons?.length > 0 && <CouponButton onClose={props?.onClose} button={notification?.buttons?.[0]} />
                }
            </div>
        </div>
    </div>
}

const Notification = props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const { notificationData } = global
    const timmer = useRef()
    const delayAlertTimmer = useRef()

    const isBot = useBot()

    const handleCouponNotification = useCallback(async () => {
        const shouldShow = !!!Cache.get('notification-coupon-show')
        // const shouldShow = true
        if (shouldShow) {

            const data = await dispatch(action_fetch_notification('coupon'))
            timmer.current = setTimeout(() => {
                dispatch(setNotificationEventData(null))
            }, 10000)

            if (data?.body) {
                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('StationMsg', {
                        type: '站内信',
                        action: 'expose',
                        message_id: data?.buttons?.[0]?.deepLink?.utmTerm
                    })
                }



                Cache.set('notification-coupon-show', 1)
            }
        }

    }, [])

    useEffect(async () => {

        if (!isBot && global?.user) {
            const data = await dispatch(action_fetch_notification('notification'))

            timmer.current = setTimeout(() => {
                dispatch(setNotificationEventData(null))
            }, 10000)


            if (data?.body) {
                if (window.GeekoSensors) {
                    window.GeekoSensors.Track('StationMsg', {
                        type: '站内信',
                        action: 'expose',
                        message_id: data?.deepLink?.utmTerm
                    })

                }
            }

        }

        if (!isBot) {
            delayAlertTimmer.current = setTimeout(() => {
                handleCouponNotification()
            }, 10000)
        }


        return () => {
            clearTimeout(timmer.current)
            clearTimeout(delayAlertTimmer.current)
        }
    }, [])

    const closeHandle = useCallback(e => {
        dispatch(setNotificationEventData(null))
    }, [])

    const overHandle = useCallback(e => {
        clearTimeout(timmer.current)
    }, [])

    const leaveHandle = useCallback(e => {
        timmer.current = setTimeout(() => {
            dispatch(setNotificationEventData(null))
        }, 8000)
    }, [])

    let NotificationDOM

    switch (notificationData?.type) {
        case 2:
            NotificationDOM = Type2
            break
        case 3:
            NotificationDOM = Type3
            break
        default:
            NotificationDOM = Type2
            break
    }

    return <NotificationTransition in={!!notificationData?.body} className={classes.NotificationContainer}>
        <div onMouseLeave={leaveHandle} onMouseOver={overHandle}>
            <NotificationDOM onClose={closeHandle} notification={notificationData} />
        </div>
    </NotificationTransition>
}


export default props => {

    const ui = useSelector(state => state.ui)
    const dispatch = useDispatch()
    const [coupon, setCoupon] = useState(null)

    const leaveHandle = useCallback(e => {
        const c = Cache.get('limit-24-coupon')
        if(c){
            setCoupon(c)
            Cache.remove('limit-24-coupon')
        }
    }, [])

    const closeHandle = useCallback(e => {
        setCoupon(null)
        if (window.GeekoSensors) {
            window.GeekoSensors.Track('ELClick', {
                clicks: 'coupon挽留'
            })
        }
    }, [])

    useEffect(() => {
        document.addEventListener('mouseleave', leaveHandle)
        return () => {
            document.removeEventListener('mouseleave', leaveHandle)
        }
    }, [])

    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <React.Fragment>

            {coupon && !ui.showSuccessWindow && <LimitCoupon coupon={coupon} closeHandle={closeHandle}/>}
            <Notification {...props} />
            {
                ui.showSuccessWindow && <CouponSuccessPortal onClose={() => {
                    dispatch({
                        type: TOOGLE_SUCCESS_WINDOW,
                        payload: false
                    })
                }}/>
            }
        </React.Fragment>,
        document.getElementById('root')
    )
}