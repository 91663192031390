import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import PaypalMessage from '../../../../../../components/paypal-message'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { getCountryMessage } from '../../../../../../../api'
import withSource from '../../../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../../../utils/sensor'
import { useLocation } from 'react-router-dom'

const Tip = withSource(props => {
    useStyles(classes)
    const { tip, innerRef, position } = props

    const clickHandle = useCallback(e => {
		e.preventDefault()
		const href = e.currentTarget?.href
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: 'nav-banner',
			resource_type: '1',
			resource_content: tip?.refId,
			resource_position: `1-${position+1}`,
		})
	}, [])

    return tip?.href ? <a
     onClick={clickHandle}
     className={classes.TipLink}
     ref={innerRef}
     data-type='1'
     data-content={tip?.refId}
     data-position={`1-${position+1}`}
     data-title={'nav-banner'}
     href={tip?.href} dangerouslySetInnerHTML={{__html: tip?.content}}/>: <span ref={innerRef} dangerouslySetInnerHTML={{__html: tip?.content}}/>
})

const PaypalTip = withSource(props => {
    const { innerRef, position, currency, country, clientId, messageFailure } = props
    return <PaypalMessage sensors={{
        ref: innerRef,
        'data-type': '1',
        'data-content': 'PayPal Message',
        'data-title': 'nav-banner',
        position: `1-${position+1}`
    }} logoType="inline" styleTextSize={12} placement={'home'} money={{ currency }} country={country} clientId={clientId} messageFailure={messageFailure}/>
})

export default props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { currency, country, config } = global

    const [tips, setTips] = useState(null)
    const location = useLocation()

    const [failure, setFailure] = useState(false)

    useEffect(async () => {
        const response = await getCountryMessage('M1712')
        if (response?.code === 200) {
            setTips(response?.result?.tips)
        }
    }, [])

    const showMessage = config?.paypal?.showMessage && global.isFirstOrder && country === 'US' && currency === 'USD' && !(location.pathname?.indexOf('/product/')>=0)


    const failureHandle = () => {
        setFailure(true)
    }

    return tips?.length > 0 ? <Swiper initialSlide={showMessage ? tips?.length - 1: 0} className={classes.Swiper} direction='vertical' loop autoplay>

        {
            tips?.map((tip, index) => <SwiperSlide key={index} data-swiper-autoplay="3000" className={classes.SwiperSlide}>
                <Tip tip={tip} position={index}/>
            </SwiperSlide>)
        }

        {
           showMessage && !failure && <SwiperSlide className={classes.SwiperSlide} style={{ paddingTop: 4 }} data-swiper-autoplay="1000">
                <PaypalTip position={tips?.length} currency={currency} country={country} clientId={config?.paypal?.clientId} messageFailure={failureHandle}/>
            </SwiperSlide>
        }

    </Swiper> : <></>
}