// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".a0u6YFh7Rw3jqHzHqtrxp{padding:15px;width:350px}.a0u6YFh7Rw3jqHzHqtrxp>div{margin-top:15px}.a0u6YFh7Rw3jqHzHqtrxp ._3piCge0VdTSkEGl884zuea>.bQ9bJ6_JmUjwp12_vGgGi{font-family:Roboto-Medium}.a0u6YFh7Rw3jqHzHqtrxp ._3piCge0VdTSkEGl884zuea>._15hgnJReLfskdo0qHQiaG9{line-height:18px;margin-top:10px}.a0u6YFh7Rw3jqHzHqtrxp ._3piCge0VdTSkEGl884zuea>._1Rn1JIy0YjPDzrgWiuGX3I{margin-top:12px}._2I0gS2SMsYNEg8jAnmYIT5{font-size:16px !important;color:#fff !important;margin-right:4px !important}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/components/support/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAa,CACb,WAAY,CAFhB,2BAKQ,eAAgB,CALxB,uEAUY,yBAA0B,CAVtC,yEAcY,gBAAiB,CACjB,eAAgB,CAf5B,yEAmBY,eAAgB,CACnB,yBAKL,yBAA0B,CAC1B,qBAAsB,CACtB,2BAA4B","sourcesContent":[".Support{\n    padding: 15px;\n    width: 350px;\n\n    & > div{\n        margin-top: 15px;\n    }\n\n    .SupportMethod{\n        & > .Title{\n            font-family: Roboto-Medium;\n        }\n\n        & > .Content{\n            line-height: 18px;\n            margin-top: 10px;\n        }\n\n        & > .Button{\n            margin-top: 12px;\n        }\n    }\n}\n\n.SupportIcon{\n    font-size: 16px !important;\n    color: #fff !important;\n    margin-right: 4px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Support": "a0u6YFh7Rw3jqHzHqtrxp",
	"SupportMethod": "_3piCge0VdTSkEGl884zuea",
	"Title": "bQ9bJ6_JmUjwp12_vGgGi",
	"Content": "_15hgnJReLfskdo0qHQiaG9",
	"Button": "_1Rn1JIy0YjPDzrgWiuGX3I",
	"SupportIcon": "_2I0gS2SMsYNEg8jAnmYIT5"
};
module.exports = ___CSS_LOADER_EXPORT___;
