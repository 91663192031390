import React from 'react'
import { debounce } from 'lodash'
import classes from './style.module.scss'
import withStyles from 'isomorphic-style-loader/withStyles'
import { FormattedMessage } from 'react-intl'


export const withList = Wrapped => {
    
    
    return withStyles(classes)(class extends React.Component{

        constructor(props){
            super(props)
            this.scrollHandle = debounce(this.scrollHandle.bind(this), 100)
        }

        componentDidMount(){
            window.addEventListener('scroll', this.scrollHandle)
        }

        componentWillUnmount(){
            window.removeEventListener('scroll', this.scrollHandle)
        }

        scrollHandle(event){
            const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
            const documentHeight = document.body.clientHeight
            const windowHeight = window.innerHeight
            if(windowHeight + scrollTop + 300 > documentHeight && !!!this.props.loading && !!!this.props.finished){
               this.props?.request?.()
            }
        }

        render(){
            return <div>
                <Wrapped {...this.props}/>
                {
                    (this.props.loading || (!this.props.hideFinishedTxt && this.props.finished)) &&
                    <div className={classes.LoadingWrapper}>
                        {
                            this.props.loading && <div className={classes.Loading}><FormattedMessage id="loading" defaultMessage="Loading" /></div>
                        }
                        {
                            !this.props.hideFinishedTxt && this.props.finished && <div className={classes.Finished}><FormattedMessage id="no_more_data" defaultMessage="No more data" />.</div>
                        }
                    </div>
                }
            </div>
        }
    })
}

export const withContainerList = Wrapped => {
    
    
    return withStyles(classes)(class extends React.Component{

        constructor(props){
            super(props)
            this.scrollHandle = debounce(this.scrollHandle.bind(this), 100);
            this.myRef = React.createRef();
        }

        componentDidMount(){
            this.myRef.current.addEventListener('scroll', this.scrollHandle)
        }

        componentWillUnmount(){
            this.myRef.current.removeEventListener('scroll', this.scrollHandle)
        }

        scrollHandle(event){
            let clientHeight = this.myRef.current.clientHeight;
            let scrollTop = this.myRef.current.scrollTop;
            let scrollHeight = this.myRef.current.scrollHeight;

            // console.log("clientHeight",clientHeight,"scrollTop",scrollTop,"scrollHeight",scrollHeight);
            // console.log("this.props.loading",this.props.loading);
            // console.log("this.props.finished",this.props.finished);
            if(clientHeight + scrollTop + 100 > scrollHeight && !!!this.props.loading && !!!this.props.finished){
               this.props?.request?.();
            }
        }

        render(){
            return <div ref={this.myRef} className={`${this.props?.className?this.props.className:''}`}>
                <Wrapped {...this.props} />

                {
                    this.props.loading && <div className={classes.ContainerLoading}>loading</div>
                }
                {
                    this.props.finished && <div className={classes.ContainerFinished}>No more data.</div>
                }
            </div>
        }
    })
}