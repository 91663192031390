import {
	SIMPLE_LIST_ADD,
	SIMPLE_LIST_SET,
	SIMPLE_LIST_FINISHED,
	SIMPLE_LIST_SKIP,
	SIMPLE_LIST_LOADING,
} from '../../actions/pages/simple-list'

const initialState = {
	list: [],
	skip: 0,
	finished: false,
	loading: false,
}

export default (state = initialState, action) => {
	switch (action.type){
		case SIMPLE_LIST_SET:
			return {...state, list: action.payload}
		case SIMPLE_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case SIMPLE_LIST_SKIP:
			return {...state, skip: action.payload}
		case SIMPLE_LIST_FINISHED:
			return {...state, finished: action.payload}
		case SIMPLE_LIST_LOADING:
			return {...state, loading: action.payload}
		default:
			return state
	}
}