import { getCountryMessage } from '../../../api'

export const SIMPLE_LANDING_INIT = 'SIMPLE_LANDING_INIT'
export const setSimpleLanding = payload => ({
    type: SIMPLE_LANDING_INIT,
    payload
})

export const action_fetch_init_data = (code, headers) => {
    return async dispatch => {
        const response = await getCountryMessage(code, headers)
        if(response?.code === 200){
            const { result } = response
            dispatch(setSimpleLanding(result))
            return result
        }
        return null
    }
}
