import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default props => {
    const { money, country, clientId, placement, styleLayout,  styleColor, styleTextSize, styleAlign, logoType, sensors, messageFailure} = props
    const global = useSelector(state => state.global)
    const { config } = global
    useEffect(() => {
        let paypalScript = document.getElementById('paypal-message-script')
        const head = document.getElementsByTagName('head')[0]
        if (!paypalScript) {
            const script = document.createElement('script')
            script.id = 'paypal-message-script'
            script.type = 'text/javascript'
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${money?.currency}${config.env==='sandbox'?(`&buyer-country=${country}`):''}&components=messages`
            script.async = true
            head.appendChild(script)
        }

        setTimeout(() => {
            const child = document.querySelectorAll('[data-pp-message]')
            child?.forEach($e => {
                if($e?.offsetHeight <= 16){
                    messageFailure?.()
                }
            })
        }, 3000)
    }, [])

    return <div
        data-pp-message
        data-pp-placement={placement || 'product'}
        data-pp-style-layout={styleLayout}
        data-pp-style-color={styleColor}
        data-pp-amount={ money?.amount }
        data-pp-buyercountry={country}
        data-pp-style-text-size={styleTextSize}
        data-pp-style-text-align={styleAlign}
        data-pp-style-logo-type={logoType}
        {...(sensors || {})}
    ></div>
}