import { fetchGetMessage } from '../../../api'

export const LIVE_INIT_DATA = 'LIVE_INIT_DATA'

export const setLiveInitData = payload => ({
    type: LIVE_INIT_DATA,
    payload
})

export const action_fetch_data = headers => {
    return async dispatch => {
        const response = await fetchGetMessage('M1650', headers)
        if(response.code === 200){
            dispatch(setLiveInitData(response.result))
            return response.result
        }
    }
}