import da from './da'
import de from './de'
import en from './en'
import es from './es'
import fi from './fi'
import fr from './fr'
import is from './is'
import nb from './nb'
import pt from './pt'
// import ptBr from './pt-BR'
import sv from './sv'
import ar from './ar'

const supportLangs = [
    'da',
    'de',
    'en',
    'es',
    'fi',
    'fr',
    'is',
    'nb',
    'pt',
    // 'pt-BR',
    'sv',
    'ar'
]

export const getLocale = (language, country) => {
    return supportLangs.find(s => s === language) || 'en'
}

export const getMessage = (lang, country) => {
    switch(lang){
        case 'da':
            return da
        case 'de':
            return de
       case 'es':
           return es
        case 'fi':
            return fi
        case 'fr':
            return fr
        case 'is':
            return is
        case 'nb':
            return nb
        case 'pt':
            // if(country === 'BR')
            //     return ptBr
            return pt
        case 'sv':
            return sv
        case 'ar':
            return ar    
        default:
            return en
    }
}