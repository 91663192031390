import {
	TOGGLE_UI_SEARCH,
	TOGGLE_UI_MENU,
	TOGGLE_UI_LOTTERY,
	TOGGLE_UI_LOGIN,
	TOOGLE_UI_DIRECTION,
	TOOGLE_UI_REQUEST_PRIVACY,
	TOOGLE_SUCCESS_WINDOW,
	TOOGLE_UI_CONFIRM,
	TOOGLE_UI_ALERT, 
	TOOGLE_UI_POPUP_SLIDE_IMAGES, 
	TOOGLE_UI_OPLOADING
} from '../actions/ui'

const initialState = {
	showMenu: false,
	showSearch: false,
	showLottery: false,
	login:{
		showLogin:false,
		alertForgetPassword:false,
		eventType:"其他",
		callBack:null,
		redirectUrl:null
	},
	direction: null,
	requestPrivacy: false,
	showSuccessWindow: false,
	alert: {
		show: false,
		options: null
	},
	confirm: {
		show: false,
		options: null
	},
	popupImages: {
		show: false,
		options: {
			images: [],
			selctedIndex: 0
		}
	},
	opLoading: false
}

export default (state = initialState, action) => {
	switch (action.type){
		case TOGGLE_UI_SEARCH:
			return {...state, showSearch: action.payload}
		case TOGGLE_UI_MENU:
			return {...state, showMenu: action.payload}
		case TOGGLE_UI_LOTTERY:
			return {...state, showLottery: action.payload}	
		case TOGGLE_UI_LOGIN:
			return {...state,login:{...state.login,...action.payload}}
		case TOOGLE_UI_DIRECTION:
			return {...state, direction: action.payload}	
		case TOOGLE_UI_REQUEST_PRIVACY:
			return {...state, requestPrivacy: action.payload}
		case TOOGLE_SUCCESS_WINDOW:
			return {...state, showSuccessWindow: action.payload}
		case TOOGLE_UI_ALERT:
			return {...state, alert: action.payload}	
		case TOOGLE_UI_CONFIRM:
			return {...state, confirm: action.payload}	
		case TOOGLE_UI_POPUP_SLIDE_IMAGES:
			return { ...state, popupImages: action.payload }
		case TOOGLE_UI_OPLOADING:
			return { ...state, opLoading: action.payload }
		default:
			return state
	}
}