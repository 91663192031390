import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Iconfont } from '../../../components/icon/iconfont'

export const BigButton = props => {
	useStyles(classes)
	return <button  {...props} disabled={props.loading || props.disabled} className={`${classes.BigButton} ${(props.loading||props.disabled)?classes.Loading:''} ${props.className}`}>
		{
			props.loading && <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
		}
		{
			props.children
		}
	</button>
}