import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import { useState } from "react";
import classes from './style.module.scss';

export const SetSelect = props => {
    useStyles(classes)
    const {setSelectList, setSelected, setSelectChange, intl} = props || {}

    const formateIntl = (key) => {
        if(!key)return ''
        let keyI = key?.toLocaleLowerCase()
        return intl.formatMessage({id: keyI, defaultMessage: key})
    }

    return (
        <div className={classes.SetSelectBox}>
            {
                setSelectList?.map((data, index) => {
                    return <div key={index+'-'+data} className={`${classes.SelectItem} ${data == setSelected ? classes.Selected: ''}`} onClick={() => setSelectChange(data)}>
                        {formateIntl(data) || ''}
                    </div>
                })
            }
        </div>
    )
}