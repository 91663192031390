import {
    CLOG_SET_HOME_TITLES,
    CLOG_SET_HOME_BANNER,
    CLOG_SET_HOME_LIST_ADD,
    CLOG_SET_HOME_LIST_SET,
    CLOG_SET_HOME_LIST_SKIP,
    CLOG_SET_HOME_LIST_FINISHED,
    CLOG_SET_HOME_LIST_LOADING,

    CLOG_SET_IMAGE_LIST_ADD,
    CLOG_SET_IMAGE_LIST_SET,
    CLOG_SET_IMAGE_LIST_SKIP,
    CLOG_SET_IMAGE_LIST_FINISHED,
    CLOG_SET_IMAGE_LIST_LOADING,

    CLOG_SET_VIDEO_LIST_ADD,
    CLOG_SET_VIDEO_LIST_SET,
    CLOG_SET_VIDEO_LIST_SKIP,
    CLOG_SET_VIDEO_LIST_FINISHED,
    CLOG_SET_VIDEO_LIST_LOADING,

    CLOG_TOGGLE_LIKED,
    CLOG_TOOGLE_COMMENT,

    CLOG_SET_TAG_LIST_ADD,
    CLOG_SET_TAG_LIST_SET,
    CLOG_SET_TAG_LIST_SKIP,
    CLOG_SET_TAG_LIST_FINISHED,
    CLOG_SET_TAG_LIST_LOADING,
} from '../../actions/pages/c-log'

const initialState = {
    homeTitles: [],
    homeBanner: null,
    homeClogs: null,
    homeSkip: 0,
    homeFinished: false,
    homeLoading: false,
    imageClogs: null,
    imageSkip: 0,
    imageFinished: false,
    imageLoading: false,

    videoClogs: null,
    videoSkip: 0,
    videoFinished: false,
    videoLoading: false,


    tagClogs: null,
    tagSkip: 0,
    tagFinished: false,
    tagLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CLOG_SET_HOME_TITLES:
            return { ...state, homeTitles: action.payload }
        case CLOG_SET_HOME_LIST_SET:
            return { ...state, homeClogs: action.payload }
        case CLOG_SET_HOME_LIST_ADD:
            return { ...state, homeClogs: [...(state.homeClogs || []), ...(action.payload || [])] }
        case CLOG_SET_HOME_LIST_SKIP:
            return { ...state, homeSkip: action.payload }
        case CLOG_SET_HOME_LIST_FINISHED:
            return { ...state, homeFinished: action.payload }
        case CLOG_SET_HOME_LIST_LOADING:
            return { ...state, homeLoading: action.payload }
        case CLOG_SET_HOME_BANNER:
            return { ...state, homeBanner: action.payload }


        case CLOG_SET_IMAGE_LIST_SET:
            return { ...state, imageClogs: action.payload }
        case CLOG_SET_IMAGE_LIST_ADD:
            return { ...state, imageClogs: [...(state.imageClogs || []), ...(action.payload || [])] }
        case CLOG_SET_IMAGE_LIST_SKIP:
            return { ...state, imageSkip: action.payload }
        case CLOG_SET_IMAGE_LIST_FINISHED:
            return { ...state, imageFinished: action.payload }
        case CLOG_SET_IMAGE_LIST_LOADING:
            return { ...state, imageLoading: action.payload }



        case CLOG_SET_VIDEO_LIST_SET:
            return { ...state, videoClogs: action.payload }
        case CLOG_SET_VIDEO_LIST_ADD:
            return { ...state, videoClogs: [...(state.videoClogs || []), ...(action.payload || [])] }
        case CLOG_SET_VIDEO_LIST_SKIP:
            return { ...state, videoSkip: action.payload }
        case CLOG_SET_VIDEO_LIST_FINISHED:
            return { ...state, videoFinished: action.payload }
        case CLOG_SET_VIDEO_LIST_LOADING:
            return { ...state, videoLoading: action.payload }

        case CLOG_TOGGLE_LIKED:
            return {
                ...state,
                homeClogs: state.homeClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, liked: !s.liked, numberOfLike: action.payload.count }) : s),
                imageClogs: state.imageClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, liked: !s.liked, numberOfLike: action.payload.count }) : s),
                videoClogs: state.videoClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, liked: !s.liked, numberOfLike: action.payload.count }) : s),
                tagClogs: state.tagClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, liked: !s.liked, numberOfLike: action.payload.count }) : s),
            }
        case CLOG_TOOGLE_COMMENT:
            return {
                ...state,
                homeClogs: state.homeClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, numberOfComment: s.numberOfComment + 1 }) : s),
                imageClogs: state.imageClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, numberOfComment: s.numberOfComment + 1 }) : s),
                videoClogs: state.videoClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, numberOfComment: s.numberOfComment + 1 }) : s),
                tagClogs: state.tagClogs?.map?.(s => s.id === action.payload.id ? ({ ...s, numberOfComment: s.numberOfComment + 1 }) : s),
            }


        case CLOG_SET_TAG_LIST_SET:
            return { ...state, tagClogs: action.payload }
        case CLOG_SET_TAG_LIST_ADD:
            return { ...state, tagClogs: [...(state.tagClogs || []), ...(action.payload || [])] }
        case CLOG_SET_TAG_LIST_SKIP:
            return { ...state, tagSkip: action.payload }
        case CLOG_SET_TAG_LIST_FINISHED:
            return { ...state, tagFinished: action.payload }
        case CLOG_SET_TAG_LIST_LOADING:
            return { ...state, tagLoading: action.payload }

        default:
            return state
    }
}