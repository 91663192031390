// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jEINn5zFiyhsMIRTCacc_{max-width:1900px;padding-left:50px;padding-right:50px;margin-left:auto;margin-right:auto}._1hOQJe1UcI9KWVy1ALavVN{margin-top:194px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/base-layout/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,gBAAiB,CACjB,iBAAkB,CAClB,kBAAmB,CACnB,gBAAiB,CACjB,iBAAkB,CACrB,yBAGG,gBAAiB","sourcesContent":[".BaseBox{\n    max-width: 1900px;\n    padding-left: 50px;\n    padding-right: 50px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.LayOutMain{\n    margin-top: 194px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BaseBox": "jEINn5zFiyhsMIRTCacc_",
	"LayOutMain": "_1hOQJe1UcI9KWVy1ALavVN"
};
module.exports = ___CSS_LOADER_EXPORT___;
