export default class {
    constructor(fn) {
        this.fn = fn
        this.next = null
    }

    setNext(next) {
        return this.next = next
    }

    excute() {
        const res = this.fn.apply(this, arguments)
        if (res === 'next') {
            return this.next && this.next.excute.apply(this.next, arguments)
        }
        return res
    }
}