// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e-QSBK0El4JH53uHhyyXO{display:flex;justify-content:flex-end;margin-top:20px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/listing/hocs/pagination-list/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAa,CACb,wBAAyB,CACzB,eAAgB","sourcesContent":[".Pagination{\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pagination": "e-QSBK0El4JH53uHhyyXO"
};
module.exports = ___CSS_LOADER_EXPORT___;
