// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3-CwNB76Hn7zyonrV-d6Eh{z-index:11;position:fixed;top:50%;left:50%;transform:translate(-50%, -60%)}._37YB9T84ItAjrWaURka9iI{transition:unset !important}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/raffle/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,UAAW,CACX,cAAe,CACf,OAAQ,CACR,QAAS,CACT,+BAAgC,CACnC,yBAGG,2BAA4B","sourcesContent":[".RaffleContainer{\n    z-index: 11;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -60%);\n}\n\n.noOp{\n    transition: unset !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RaffleContainer": "_3-CwNB76Hn7zyonrV-d6Eh",
	"noOp": "_37YB9T84ItAjrWaURka9iI"
};
module.exports = ___CSS_LOADER_EXPORT___;
