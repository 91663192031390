import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import classes from './style.module.scss';

const LikeFitType = (props) => {
    useStyles(classes)
    const {title, list, selected} = props

    return <div className={classes.FitType}>
        <div className={classes.Title}>{title}</div>
        <div className={classes.FitTypeBox}>
            {
                list.map((f,index) => (
                    <div className={`${classes.FitTypeItem} ${selected==f.id && classes.FitTypeItemSelect}`} key={index}>{f.label}</div>
                ))
            }
        </div>
    </div>
}


const SizeChartType = props => {
    useStyles(classes)
    const {intl, fitTypeValue, fitTypeList, stretchValue, stretchList} = props || {}

    return <React.Fragment>
        {
            fitTypeValue && fitTypeList && fitTypeList.length > 0 &&
            <LikeFitType title={intl.formatMessage({id:"fit_type"})} list={fitTypeList} selected={fitTypeValue}></LikeFitType>
        }

        {
            stretchValue && stretchList && stretchList.length > 0 &&
            <LikeFitType title={intl.formatMessage({id:"stretch"})} list={stretchList} selected={stretchValue}></LikeFitType>
        }  

    </React.Fragment>
}

export default SizeChartType