import { fetchTrendingList, fetchTrendingSorters, fetchTrendingFilter } from '../../../api'


export const __Default_Trending_Limit__ = 12
export const __Default_Trending_Limit_For_DeskTop__ = 20

export const TRENDING_LIST_ADD = 'TRENDING_LIST_ADD'
export const TRENDING_LIST_SET = 'TRENDING_LIST_SET'
export const TRENDING_LIST_SKIP = 'TRENDING_LIST_SKIP'
export const TRENDING_LIST_FINISHED = 'TRENDING_LIST_FINISHED'
export const TRENDING_LIST_LOADING = 'TRENDING_LIST_LOADING'

export const TRENDING_FILTER_SET = 'TRENDING_FILTER_SET'
export const TRENDING_SORTERS_SET = 'TRENDING_SORTERS_SET'
export const TRENDING_TOTAL = 'TRENDING_TOTAL'

export const TRENDING_FILTER_DATA = 'TRENDING_FILTER_DATA'

export const setTrendingFilterData = payload => ({
	type: TRENDING_FILTER_DATA,
	payload
})

export const setTrendingTotal = payload => ({
	type: TRENDING_TOTAL, 
	payload
})

export const setTrendingFilter = payload => ({
	type: TRENDING_FILTER_SET,
	payload
})

export const setTrendingSorters = payload => ({
	type: TRENDING_SORTERS_SET,
	payload
})


export const setTrendingList = payload => ({
	type: TRENDING_LIST_SET,
	payload
})

export const addTrendingList = payload => ({
	type: TRENDING_LIST_ADD,
	payload
})

export const setTrendingSkip = payload => ({
	type: TRENDING_LIST_SKIP,
	payload
})

export const setTrendingFinished = payload => ({
	type: TRENDING_LIST_FINISHED,
	payload
})

export const setTrendingListLoading = payload => ({
	type: TRENDING_LIST_LOADING,
	payload
})


export const action_add_trending_list = (data, params, headers) => {
	const fetchData = fetchTrendingList
	return async dispatch => {
		dispatch(setTrendingListLoading(true))
		const limit = params?.limit || __Default_Trending_Limit__
		const response = await fetchData(data, {...params, limit}, headers)
		dispatch(setTrendingListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addTrendingList(products))
			dispatch(setTrendingSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setTrendingFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_trending_list = (data, params, headers) => {

	const fetchData = fetchTrendingList

	return async dispatch => {
		dispatch(setTrendingFinished(false))
		dispatch(setTrendingListLoading(true))
		dispatch(setTrendingFilterData(data))
		const limit = params?.limit || __Default_Trending_Limit__
		const response = await fetchData(data, {...params, skip: 0, limit}, headers)
		dispatch(setTrendingListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setTrendingList(products))
			dispatch(setTrendingTotal(response.total))
			dispatch(setTrendingSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setTrendingFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_trending_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchTrendingFilter(params, headers)
		if(response?.code === 200){
			dispatch(setTrendingFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_trending_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchTrendingSorters(params, headers)
		if(response?.code === 200 && response.result){
			const sorters = response.result
			dispatch(setTrendingSorters(Object.keys(sorters).sort().map(p => ({label: sorters[p], value: p}))))
			return response.result
		}else{
			return null
		}
	}
}