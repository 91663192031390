import React from 'react'
import VisualSearch from './pages/index'
import Transition from '../../../../components/transitions/visual-search-transition'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import ReactDOM from 'react-dom'
import Mask from '../../../../components/mask'
import { useSelector } from 'react-redux'


const WrappedEditor = props => {
    useStyles(classes)
    return <React.Fragment>
        <Transition in={props.showEditor} className={classes.EditorContainer}>
            <div>
                <VisualSearch {...props} />
            </div>
        </Transition>
        {props.showEditor && <Mask style={{
            zIndex: 10
        }} onClick={props.onClose} />}
    </React.Fragment>
}


export default props => {

    if (typeof window === 'undefined')
        return null

    return ReactDOM.createPortal(
        <WrappedEditor {...props} />,
        document.getElementById('root')
    )
}