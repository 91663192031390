import { fetchExclusiveList, fetchExclusiveSorters, fetchExclusiveFilter } from '../../../api'


export const __Default_Exclusive_Limit__ = 12
export const __Default_Exclusive_Limit_For_DeskTop__ = 20

export const EXCLUSIVE_LIST_ADD = 'EXCLUSIVE_LIST_ADD'
export const EXCLUSIVE_LIST_SET = 'EXCLUSIVE_LIST_SET'
export const EXCLUSIVE_LIST_SKIP = 'EXCLUSIVE_LIST_SKIP'
export const EXCLUSIVE_LIST_FINISHED = 'EXCLUSIVE_LIST_FINISHED'
export const EXCLUSIVE_LIST_LOADING = 'EXCLUSIVE_LIST_LOADING'

export const EXCLUSIVE_FILTER_SET = 'EXCLUSIVE_FILTER_SET'
export const EXCLUSIVE_SORTERS_SET = 'EXCLUSIVE_SORTERS_SET'
export const EXCLUSIVE_TOTAL = 'EXCLUSIVE_TOTAL'

export const EXCLUSIVE_FILTER_DATA = 'EXCLUSIVE_FILTER_DATA'

export const setExclusiveFilterData = payload => ({
	type: EXCLUSIVE_FILTER_DATA,
	payload
})

export const setExclusiveTotal = payload => ({
	type: EXCLUSIVE_TOTAL, 
	payload
})

export const setExclusiveFilter = payload => ({
	type: EXCLUSIVE_FILTER_SET,
	payload
})

export const setExclusiveSorters = payload => ({
	type: EXCLUSIVE_SORTERS_SET,
	payload
})


export const setExclusiveList = payload => ({
	type: EXCLUSIVE_LIST_SET,
	payload
})

export const addExclusiveList = payload => ({
	type: EXCLUSIVE_LIST_ADD,
	payload
})

export const setExclusiveSkip = payload => ({
	type: EXCLUSIVE_LIST_SKIP,
	payload
})

export const setExclusiveFinished = payload => ({
	type: EXCLUSIVE_LIST_FINISHED,
	payload
})

export const setExclusiveListLoading = payload => ({
	type: EXCLUSIVE_LIST_LOADING,
	payload
})


export const action_add_exclusive_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setExclusiveListLoading(true))
		const limit = params?.limit || __Default_Exclusive_Limit__
		const response = await fetchExclusiveList(data, {...params, limit}, headers)
		dispatch(setExclusiveListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addExclusiveList(products))
			dispatch(setExclusiveSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setExclusiveFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_exclusive_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setExclusiveFinished(false))
		dispatch(setExclusiveListLoading(true))
		dispatch(setExclusiveFilterData(data))
		const limit = params?.limit || __Default_Exclusive_Limit__
		const response = await fetchExclusiveList(data, {...params, skip: 0, limit}, headers)
		dispatch(setExclusiveListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setExclusiveList(products))
			dispatch(setExclusiveTotal(response.total))
			dispatch(setExclusiveSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setExclusiveFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_exclusive_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchExclusiveFilter(params, headers)
		if(response?.code === 200){
			dispatch(setExclusiveFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_exclusive_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchExclusiveSorters(params, headers)
		if(response?.code === 200){
			dispatch(setExclusiveSorters(response.result))
			return response.result
		}else{
			return null
		}
	}
}