import React from 'react'
import { FacebookShareButton } from 'react-share'

export default props => {
    return <FacebookShareButton
        url={props?.shareUrl}
        quote={props?.title}
        className={props.className}
    >
        {props.children}
    </FacebookShareButton>
}