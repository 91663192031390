export default data => {
    if(data.type === 'notification'){
        const payload = data.payload
        return {
            origin: payload,
            type: 2,
            bodycon: payload?.icon,
            title: payload?.title,
            body: payload?.model?.content || payload?.content,
            image: payload?.image || payload?.model?.image,
            sendTime: payload?.sendTime,
            deepLink: payload?.deepLink || payload?.model?.deepLink,
        }
    }
    return 'next'
}