import React, { useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage } from 'react-intl'
import { Iconfont } from '../../../../../../../components/icon/iconfont'

export default props => {
    useStyles(classes)
    const { uploading } = props

    return <div className={classes.Container}>
        <div className={classes.Hd}>
            <span className={classes.Title}>
                <FormattedMessage id="visual_search" defaultMessage="Visual Search" />
            </span>
            <Iconfont onClick={evt => props?.onClose()} className={classes.Close}>&#xe7c9;</Iconfont>
        </div>
        <div className={classes.Bd}>
            <div className={classes.Content}>
                <div className={classes.VisualTitle}>
                    <FormattedMessage id="visual_search" defaultMessage="Visual Search" />
                </div>
                <div className={classes.VisualDsc}>
                    <FormattedMessage id="uploading_photo" defaultMessage="Search by uploading a photo" />
                </div>

                {
                    uploading ? <div className={`${classes.UploadButton} ${classes.Uploading}`}>
                        <Iconfont className={classes.LoadIcon}>&#xe62d;</Iconfont>
                        <FormattedMessage id="uploading" defaultMessage="Uploading" />
                    </div> : <div className={classes.UploadButton}>
                        <FormattedMessage id="upload_photo" defaultMessage="Upload Photo" />
                        <input type="file" onChange={props.onUpload} />
                    </div>
                }

            </div>
        </div>
    </div>
}