import {
    BrowserRouter
  } from 'react-router-dom'
  
  import Index from './client/desktop/index'
  import {Provider} from 'react-redux'
  import { createStore, applyMiddleware } from 'redux'
  import thunk from 'redux-thunk'
  import reducers from './store/reducers'
  import {action_fetch_init_data, action_fetch_wish_list, action_fetch_cart_count, action_fetch_notification_count, action_fetch_currency} from "./store/actions/global";
  import {useEffect} from "react";

  
  // Grab the state from a global variable injected into the server-generated HTML
  const initialState = window.__INITIAL_STATE__
  
  
  
  // Create Redux store with initial state
  const store = createStore(reducers, initialState, applyMiddleware(thunk))
  
  
  
  function App(){
  
    useEffect(() => {
      if(!initialState){
        store.dispatch(action_fetch_init_data())
      }
      // store.dispatch(action_fetch_wish_list())
      store.dispatch(action_fetch_cart_count())
      store.dispatch(action_fetch_notification_count())
      // store.dispatch(action_fetch_currency())
    }, [])
  
  
  
    return <BrowserRouter>
      <Provider store={store}>
          <Index/>
      </Provider>
    </BrowserRouter>
  
  }
  
  export default App
  