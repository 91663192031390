import { LuckyWheel } from '@lucky-canvas/react/dist/index.cjs'
import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { IMAGE_GEEKO_LTD } from '../../../../../../../../constants';
import classes from './style.module.scss'


export default (props) => {
    useStyles(classes)
    // 奖品列表, 获取用户剩余次数, 抽奖次数, 获取最近抽奖历史记录(所有用户), 开始抽奖事件, 抽奖结束事件, 转盘初始化数据, 抽奖结果,
    const { prizes, onStart, onEnd, lotteryInit, lotteryResult } = props

    const global = useSelector(state => state.global);
    const myLucky = useRef()
    const [allPrizes, setAllPrizzes] = useState()

    const concatImageDomain = (url) => {
        if (url?.indexOf("http") != -1) {
            return url
        } else {
            return IMAGE_GEEKO_LTD + url
        }
    }
    const wrapTxt = (txt) => {
        if (!txt) return
        if (txt.trim().length > 13 && txt.trim().length < 24) {
            return txt.slice(0, 13) + '\n' + txt.slice(13)
        } else if(txt.trim().length > 24) {
            return txt.slice(0, 13) + '\n' + txt.slice(13,22)+'...'
        }
        return txt
    }

    useEffect(() => {
        if (prizes && prizes.length > 0) {
            const newArr = lotteryInit.prizes.map((item, index) => {
                let newItem = prizes?.[index];
                let name = wrapTxt(newItem?.name?.replace(/\s+/g, ' '))
                item.fonts[0].text = name;
                item.data = newItem;
                if (item.imgs) {
                    if (item.imgs.length > 1) {
                        item.imgs[1]['src'] = concatImageDomain(newItem?.pcImageUrl)
                    } else {
                        item.imgs[0]['src'] = concatImageDomain(newItem?.pcImageUrl)
                    }
                }
                return item;
            });
            setAllPrizzes(newArr, (e) => {
                // console.log('setAllPrizzes: ', e)
                // console.log(allPrizes)
            })
        }
    }, [prizes])

    useEffect(() => {
        if (lotteryResult || lotteryResult === 0) {
            myLucky.current.play()
            // 模拟调用接口异步抽奖
            setTimeout(() => {
                myLucky.current.stop(lotteryResult)
            }, 2500)
        }
    }, [lotteryResult])


    return <div className={classes.container}>
        {
            <LuckyWheel
                key={global?.user?.id}
                ref={myLucky}
                width={lotteryInit.width}
                height={lotteryInit.height}
                blocks={lotteryInit.blocks || {}}
                prizes={allPrizes}
                buttons={lotteryInit.buttons || {}}
                defaultConfig={lotteryInit.defaultConfig || {}}
                defaultStyle={lotteryInit.defaultStyle || {}}
                activeStyle={lotteryInit.activeStyle || {}}
                onStart={onStart}
                onEnd={prize => onEnd(prize)}
            />
        }
    </div>
}