import { fetchGetMessage, fetchProductDetails, getProductTaglia } from "../../../api"

export const SET_SIZE_CHART = 'SET_SIZE_CHART'
export const SET_SHOW_SIZE_CHART = 'SET_SHOW_SIZE_CHART'
export const SET_APP_PRODUCT_DETAIL = 'SET_APP_PRODUCT_DETAIL'
export const SET_APP_PRODUCT_TAGLIA = 'SET_APP_PRODUCT_TAGLIA'

export const setSizeChart = payload => ({
	type: SET_SIZE_CHART,
	payload
})

export const setShowSizeChart = payload => ({
	type: SET_SHOW_SIZE_CHART,
	payload
})

export const setAppProductDetail = payload => ({
    type: SET_APP_PRODUCT_DETAIL,
    payload
})

export const setAppProductTaglia = payload => ({
    type: SET_APP_PRODUCT_TAGLIA,
    payload
})

export const action_fetch_size_data = headers => {
	return async dispatch => {
		const response = await fetchGetMessage('M1708',headers )
		if(response?.code === 200 && response?.result){
			const res = JSON.parse(response?.result?.message)
			res['shoesMeasurement'] = []
			res['shoesMeasurement'] = [{"size":"5.5","length":"22.4"},{"size":"6","length":"22.9"},{"size":"6.5","length":"23.4"},{"size":"7","length":"23.9"},{"size":"8","length":"24.4"},{"size":"8.5","length":"24.9"},{"size":"9","length":"25.4"},{"size":"9.5","length":"25.9"},{"size":"10","length":"26.4"}]
			dispatch(setSizeChart(res))
			return res
		}else{
			return null
		}
	}
}

export const action_fetch_app_product_detail = (id, countryCode, headers) => {
    return async dispatch => {
		const response = await fetchProductDetails(id,null,headers )
		if(response?.code === 200 && response?.result){
			dispatch(setAppProductDetail({data: response?.result?.products, countryCode: countryCode}))
			return {data: response?.result?.products, countryCode: countryCode}
		}else{
			return null
		}
	}
}

export const action_fetch_app_product_taglia = (id, headers) => {
    return async dispatch => {
        const response = await getProductTaglia(id, headers)
        if(response?.code === 200 && response?.result){
			dispatch(setAppProductTaglia(response?.result))
			return response?.result
		}else{
			return null
		}
    }
}