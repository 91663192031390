import { fetchNewArrivalsList, fetchNewArrivalsSorters, fetchNewArrivalsFilter, fetchNewRangeArrivalsList } from '../../../api'


export const __Default_NewArrivals_Limit__ = 12
export const __Default_NewArrivals_Limit_For_DeskTop__ = 20

export const NEWARRIVALS_LIST_ADD = 'NEWARRIVALS_LIST_ADD'
export const NEWARRIVALS_LIST_SET = 'NEWARRIVALS_LIST_SET'
export const NEWARRIVALS_LIST_SKIP = 'NEWARRIVALS_LIST_SKIP'
export const NEWARRIVALS_LIST_FINISHED = 'NEWARRIVALS_LIST_FINISHED'
export const NEWARRIVALS_LIST_LOADING = 'NEWARRIVALS_LIST_LOADING'

export const NEWARRIVALS_FILTER_SET = 'NEWARRIVALS_FILTER_SET'
export const NEWARRIVALS_SORTERS_SET = 'NEWARRIVALS_SORTERS_SET'
export const NEWARRIVALS_TOTAL = 'NEWARRIVALS_TOTAL'

export const NEWARRIVALS_FILTER_DATA = 'NEWARRIVALS_FILTER_DATA'

export const setNewArrivalsFilterData = payload => ({
	type: NEWARRIVALS_FILTER_DATA,
	payload
})

export const setNewArrivalsTotal = payload => ({
	type: NEWARRIVALS_TOTAL, 
	payload
})

export const setNewArrivalsFilter = payload => ({
	type: NEWARRIVALS_FILTER_SET,
	payload
})

export const setNewArrivalsSorters = payload => ({
	type: NEWARRIVALS_SORTERS_SET,
	payload
})


export const setNewArrivalsList = payload => ({
	type: NEWARRIVALS_LIST_SET,
	payload
})

export const addNewArrivalsList = payload => ({
	type: NEWARRIVALS_LIST_ADD,
	payload
})

export const setNewArrivalsSkip = payload => ({
	type: NEWARRIVALS_LIST_SKIP,
	payload
})

export const setNewArrivalsFinished = payload => ({
	type: NEWARRIVALS_LIST_FINISHED,
	payload
})

export const setNewArrivalsListLoading = payload => ({
	type: NEWARRIVALS_LIST_LOADING,
	payload
})


export const action_add_newarrivals_list = (data, params, headers) => {
	const fetchData = params?.rangeId ? fetchNewRangeArrivalsList: fetchNewArrivalsList
	return async dispatch => {
		dispatch(setNewArrivalsListLoading(true))
		const limit = params?.limit || __Default_NewArrivals_Limit__
		const response = await fetchData(data, {...params, limit}, headers)
		dispatch(setNewArrivalsListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addNewArrivalsList(products))
			dispatch(setNewArrivalsSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setNewArrivalsFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_newarrivals_list = (data, params, headers) => {

	const fetchData = params?.rangeId ? fetchNewRangeArrivalsList: fetchNewArrivalsList

	return async dispatch => {
		dispatch(setNewArrivalsFinished(false))
		dispatch(setNewArrivalsListLoading(true))
		dispatch(setNewArrivalsFilterData(data))
		const limit = params?.limit || __Default_NewArrivals_Limit__
		const response = await fetchData(data, {...params, skip: 0, limit}, headers)
		dispatch(setNewArrivalsListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setNewArrivalsList(products))
			dispatch(setNewArrivalsTotal(response.total))
			dispatch(setNewArrivalsSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setNewArrivalsFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_newarrivals_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchNewArrivalsFilter(params, headers)
		if(response?.code === 200){
			dispatch(setNewArrivalsFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_newarrivals_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchNewArrivalsSorters(params, headers)
		if(response?.code === 200){
			dispatch(setNewArrivalsSorters(response.result))
			return response.result
		}else{
			return null
		}
	}
}