import React, { useCallback, useEffect } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { FormattedMessage } from 'react-intl'
import { BigButton } from '../../../../../components/button'
import { useSelector } from 'react-redux'
import { Iconfont } from '../../../../../../components/icon/iconfont'

const initZenDesk = () => {
    window.zESettings = {
        webWidget: {
            offset: {
                horizontal: '50px',
                vertical: '0'
            }
        }
    }

    if(window.zE){
        window.zE('webWidget', 'hide')
        window.zE('webWidget:on', 'open', function(){
            window.zE('webWidget', 'show')
        })
        window.zE('webWidget:on', 'close', function(){
            //	 			window.zE('webWidget', 'hide');
        })
        window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'FAQ' })
    }
}

const loadZenDesk = apiKey => {
    const zenDeskDom = document.getElementById('ze-snippet')
	if (!zenDeskDom) {

        window.initZen = initZenDesk

		const head = document.getElementsByTagName('head')[0]
		const script = document.createElement('script')
		script.type = 'text/javascript'
		script.id = 'ze-snippet'
		script.defer = true
		script.async = true
		script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`
        script.onload = initZenDesk()
		head.appendChild(script)
	}
}


export default props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { config } = global

    useEffect(() => {
        if(config?.support?.zendesk){
            loadZenDesk(config?.support?.zendesk)
        }
    }, [])

    const handleZendesk = useCallback(e => {
        if(window.zE){
            window.zE('webWidget', 'show')
            window.zE('webWidget', 'open')
        }
    }, [])

    const handleTicket = useCallback(e => {
        window.location.href = '/me/m/faq/support-ticket'
    })


    return <div className={classes.Support}>
        <div className={classes.SupportMethod}>
            <div className={classes.Title}>
                <FormattedMessage id="support_pre_sale_title" />
            </div>
            <div className={classes.Content}>
                <FormattedMessage id="support_pre_sale" />
            </div>
            <div className={classes.Button}>
                <BigButton onClick={handleZendesk} style={{width: 180, height: 35, fontSize: 14}}>
                    <Iconfont className={classes.SupportIcon}>&#xe770;</Iconfont>
                    <FormattedMessage id="online_help" defaultMessage="Online Help"/>
                </BigButton>
            </div>
        </div>
        <div className={classes.SupportMethod}>
            <div className={classes.Title}>
                <FormattedMessage id="support_after_sale_title" />
            </div>
            <div className={classes.Content}>
                <FormattedMessage id="support_after_sale" />
            </div>
            <div className={classes.Button}>
                <BigButton onClick={handleTicket} style={{width: 180, height: 35, fontSize: 14}}>
                    <FormattedMessage id="submit_ticket" defaultMessage="Submit a Ticket"/>
                </BigButton>
            </div>
        </div>
    </div>
}