import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {
    useStyles(classes)
    return <span {...props} className={`${classes.Badge} ${props.className}`}>
        {props.children}
        {
            props.count > 0 && <span className={classes.Count}>{props.count}</span>
        }
        {
            props.dotted && <span className={classes.New}/>
        }
    </span>
}