// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DVKIYsru8UjMNpXqxY9Oq{cursor:pointer;width:100%;height:45px;background-color:#222;color:#fff;border-radius:2px;text-transform:uppercase;font-size:18px;font-family:Roboto-Medium;-webkit-appearance:none;border:none;display:flex;align-items:center;justify-content:center}.DVKIYsru8UjMNpXqxY9Oq ._2x598PjF_UJumSdPDKfLXT{margin-right:6px;animation:_1zQGAJzcLuvv2vdlUJvInH 1600ms infinite linear;text-align:center;display:inline-block}.DVKIYsru8UjMNpXqxY9Oq._3bJTtPOvk-PMtectJXNqaN{background-color:#999 !important}@keyframes _1zQGAJzcLuvv2vdlUJvInH{from{transform:rotate(0)}to{transform:rotate(360deg)}}\n", "",{"version":3,"sources":["webpack://src/client/desktop/components/button/style.module.scss"],"names":[],"mappings":"AAAA,uBACE,cAAe,CACf,UAAW,CACX,WAAY,CACZ,qBAAsB,CACtB,UAAW,CACX,iBAAkB,CAClB,wBAAyB,CACzB,cAAe,CACf,yBAA0B,CAC1B,uBAAwB,CACxB,WAAY,CAOZ,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CApBzB,gDAaI,gBAAiB,CACjB,wDAAyC,CACzC,iBAAkB,CAClB,oBAAqB,CAhBzB,+CAuBI,gCAAiC,CAClC,mCAID,KACE,mBAAoB,CAGtB,GACE,wBAAyB,CAAA","sourcesContent":[".BigButton{\n  cursor: pointer;\n  width: 100%;\n  height: 45px;\n  background-color: #222;\n  color: #fff;\n  border-radius: 2px;\n  text-transform: uppercase;\n  font-size: 18px;\n  font-family: Roboto-Medium;\n  -webkit-appearance: none;\n  border: none;\n  .LoadIcon{\n    margin-right: 6px;\n    animation: loading 1600ms infinite linear;\n    text-align: center;\n    display: inline-block;\n  }\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &.Loading{\n    background-color: #999 !important;\n  }\n}\n\n@keyframes loading {\n  from{\n    transform: rotate(0);\n  }\n\n  to{\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BigButton": "DVKIYsru8UjMNpXqxY9Oq",
	"LoadIcon": "_2x598PjF_UJumSdPDKfLXT",
	"loading": "_1zQGAJzcLuvv2vdlUJvInH",
	"Loading": "_3bJTtPOvk-PMtectJXNqaN"
};
module.exports = ___CSS_LOADER_EXPORT___;
