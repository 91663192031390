import _ from 'lodash'

export const getFilterItemsFromQuery = query => {
	if(!query) return
	const filterItems = []
	Object.keys(query).forEach(key => {
		let value = query[key]
		if(value instanceof Array){
			value = value.join(',')
		}
		filterItems.push({
			fieldName: key,
			selections: value?.split(',')?.map(s => s.trim()).map(s => ({value: s}))
		})
	})
	return filterItems
}


export const filterItemsToString = (filterItems, sort, price) => {
	if(!filterItems) return

	const searches =  _.reduce(filterItems?.filter?.(f => f.selections?.length > 0)?.map(f => ({fieldName: f.fieldName, selections: f.selections.map(s => encodeURIComponent(s.value)).join(',')})), (result, value, key)=> {
		result.push(`${value.fieldName}=${value.selections}`)
		return result
	}, [])

	if(price?.minPrice){
		searches.push(`lowerPrice=${price.minPrice}`)
	}
	if(price?.maxPrice){
		searches.push(`higherPrice=${price.maxPrice}`)
	}

	if(sort){
		searches.push(`sort=${sort || 0}`)
	}
	
	return searches?.join?.('&')
}

export const getUrl = (url, suffex) => {
	if(!suffex) return url
	if(url?.indexOf('?') > 0){
		return `${url}&${suffex}`
	}
	return `${url}?${suffex}`
}

export const getTopBanner = (cfg, relatedId) => {
	if(!cfg) return null
	const { excludes } =  cfg
	if(excludes?.indexOf?.(relatedId) >= 0) return null
	const key = Object.keys(cfg)?.find?.(key => key.indexOf(relatedId) >= 0)
	return cfg?.[key] || cfg?.['__Default_Banner__']
}

export const getPageUrl = (url, page) => {
	if(!page) return url
	if(url?.indexOf('page=') > 0){
		return url.replace(/page=\d+/, `page=${page}`)
	}
	if(url?.indexOf('?') > 0){
		return `${url}&page=${page}`
	}
	return `${url}?page=${page}`
}