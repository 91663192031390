import React, { useCallback } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { getProductUrl } from '../../../../../../utils/product'
import { FormattedMessage } from 'react-intl'
import { getSensorsUrl } from '../../../../../utils/sensor'

const ProductList = props => {
    useStyles(classes)
    const { products, count } = props

    const clickHandle = useCallback((product, event) => {
        let href = getProductUrl(product?.id, product?.name);

        if(window.GeekoSensors){
			window.GeekoSensors.Track("PitPositionClick", {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '16',
				resource_content: product?.id
			})

			// window.GeekoSensors.Track("PitPositionExposure", {
			// 	resourcepage_title: 'search',
			// 	resource_position: '1',
			// 	resource_type: '16',
			// 	resource_content: product?.id
			// })
		}

        window.location.href = getSensorsUrl(href, {
			resourcepage_title: 'search',
            resource_position: '1',
            resource_type: '16',
            resource_content: product?.id
        })
    }, [])


    return <div className={classes.ProductsContainer}>
        <div className={classes.Hd}>
            <FormattedMessage id="products" defaultMessage="Products"/>
        </div>
        <ul className={classes.Products}>
            {
                products.map(product => <li className={classes.Product} key={product?.id} onClick={event => clickHandle(product, event)}>
                    <img src={`${IMAGE_GEEKO_LTD}/small/${product.image || product.id}`} />
                    <div className={classes.Title}>
                        {product?.name}
                    </div>
                </li>)
            }
        </ul>
    </div>
}

const WordList = props => {
    useStyles(classes)
    const { keyWord, words, count } = props
    const reg = new RegExp(keyWord, 'ig')

    const getHtmlWord = w => {
        return w?.replace(reg, matched => {
            return `<b>${matched}</b>`
        })
    }

    const clickHandle = useCallback((word, e) => {
        e.preventDefault();
        let href = `/i/search/result?key=${encodeURIComponent(word)}`;

        if(window.GeekoSensors){
			window.GeekoSensors.Track("PitPositionClick", {
				resourcepage_title: 'search',
				resource_position: '1',
				resource_type: '15',
				resource_content: word
			})

			// window.GeekoSensors.Track("PitPositionExposure", {
			// 	resourcepage_title: 'search',
			// 	resource_position: '1',
			// 	resource_type: '15',
			// 	resource_content: word
			// })
		}

        window.location.href = getSensorsUrl(href, {
			resourcepage_title: 'search',
            resource_position: '1',
            resource_type: '15',
            resource_content: word
        })
    }, [])


    return <ul className={classes.WordList}>
        {
            words.map(word => <li key={word} onClick={event => clickHandle(word, event)}>
                <span className={classes.Word} dangerouslySetInnerHTML={{ __html: getHtmlWord(word) }} />
            </li>)
        }
    </ul>
}

export default props => {
    const { product, words, keyWord } = props
    return <div>
        {
            words?.count > 0 && <WordList keyWord={keyWord} words={words.result} count={words.count} />
        }

        {
            product?.count > 0 && <ProductList products={product.result} count={product.count} />
        }
    </div>
}