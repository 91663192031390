import React, { useEffect } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { useState } from 'react'
import {Iconfont} from '../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl'
import { getPageUrl } from '../../../pages/listing/utils'

const Element = props => {
    useStyles(classes)
    const { page, currentPage, onClick, href } = props
    const [active, setActive] = useState(page === currentPage)
    useEffect(() => {
        setActive(page === currentPage)
    }, [currentPage])
    return <a href={href} onClick={e => onClick(e, page)} className={`${classes.Element} ${active ? classes.Active : ''}`}>{page}</a>
}


export default props => {
    useStyles(classes)

    const { pagination } = props
    const { total, pageSize, currentPage, onPage, baseUrl, displayPageCount= 10 } = pagination

    const totalPages = Math.ceil(total / pageSize)

    let displayPages = []

    if (totalPages <= displayPageCount) {
        displayPages = Array.from(new Array(totalPages)).map((_, index) => index + 1)
    } else {    
        const half = Math.floor(displayPageCount / 2)
        const start = currentPage - half
        const end = currentPage + half
        if (start <= 1) {
            displayPages = Array.from(new Array(displayPageCount)).map((_, index) => index + 1)
        } else if (end >= totalPages) {
            displayPages = Array.from(new Array(displayPageCount)).map((_, index) => totalPages - displayPageCount + index + 1)
        } else {
            displayPages = Array.from(new Array(displayPageCount)).map((_, index) => start + index)
        }
    }
    

    const clickHandler = (e, page, disabled) => {
        e.preventDefault()
        if (page === currentPage || disabled) return
        onPage(page)
    }

    const getPaginationUrl = p => getPageUrl(baseUrl, p)


    return <div className={classes.Pagination}>
            <span className={classes.TotalCount}>
                <FormattedMessage id="total_count_pages" defaultMessage="Total {count} Pages" values={{count: totalPages}}/>
            </span>
            <a href={getPaginationUrl(currentPage - 1)} onClick={e => clickHandler(e, currentPage - 1, currentPage === 1)} className={`${classes.PrevNextButton} ${currentPage === 1 ? classes.Disabled : ''}`}>
                <Iconfont className={classes.IconPrev}>&#xe7c7;</Iconfont>
            </a>
            <Element page={1} currentPage={currentPage} onClick={clickHandler} />
            {
                displayPages?.[0] > 2 && <span>...</span>
            }

            {
                displayPages?.filter(v => v!==1)?.map((value, index) => {
                    return <Element href={getPaginationUrl(value)} key={value} page={value} currentPage={currentPage} onClick={clickHandler} />
                })
            }
            <a href={getPaginationUrl(currentPage + 1)} onClick={e => clickHandler(e, currentPage + 1, currentPage === totalPages)} className={`${classes.PrevNextButton} ${currentPage === totalPages ? classes.Disabled : ''}`}>
                <Iconfont className={classes.IconNext}>&#xe7c7;</Iconfont>
            </a>
    </div>
}