import { 
	GET_GENERAL_MESSAGE, 
	GET_GENERAL_COUNTRY_MESSAGE,
	PRODUCT_SET_STAGING_INFO,
} from "../../actions/pages/policy"

const initialState = {
    aboutUs:null,
	affiliatePolicy:null,
	bloggerProgram:null,
	couponCredits:null,
	returnPolicy:null,
	outOfStock:null,
	wholesaleProgram:null,
	dropShipping:null,
	copyRightNotice:null,
	privacySecurity:null,
	termsConditions:null,
	howToPay:null,
	afterPay:null,
	klanraPolicy:null,
	zipPolicy:null,
	vipPolicy:null,
	shippingPolicy:null,
	shippingMorePolicy:null,
	contactUs:null,
	orderTracking:null,
	bonusPoint:null,
	stagingInfo: null,
}

export default (state = initialState, action) => {
	switch (action.type){
		case GET_GENERAL_MESSAGE:
			return {...state, ...action.payload}
		case GET_GENERAL_COUNTRY_MESSAGE:
			return {...state, ...action.payload }	
		case PRODUCT_SET_STAGING_INFO:
			return {...state, stagingInfo: action.payload}
		default:
			return state
	}
}