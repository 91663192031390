import React, { useCallback, useEffect, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { action_fetch_cart, action_fetch_cart_count, SET_CART_INITED, setCart, setCountCart } from '../../../../../../../store/actions/global'
import _ from 'lodash'
import { unitPrice } from '../../../../../../../utils/product'
import { Iconfont } from '../../../../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl'
import { BigButton } from '../../../../../components/button/index'
import { changeItem, removeItem } from '../../../../../../../api'
import toast from '../../../../../../components/toast'

const Quantity = props => {
    useStyles(classes)
    const { value, onChange } = props
    const [quantity, setQuantity] = useState(value)
    const debouncedHandle = useCallback(_.debounce(onChange, 400), [])

    useEffect(() => {
        setQuantity(quantity)
    }, [value])

    const changeHandle = qty => {
        if(qty < 0) return
        setQuantity(qty)
        debouncedHandle(qty)
    }

    return <span className={classes.Quantity}>
        <Iconfont className={`${classes.Minus} ${quantity <=1 ? classes.Disabled:''}`} onClick={e => changeHandle(quantity - 1)}>&#xe77f;</Iconfont>
        <span className={classes.Qty}>{quantity}</span>
        <Iconfont className={classes.Add} onClick={e => changeHandle(quantity + 1)}>&#xe7ca;</Iconfont>
    </span>
}

const MinItem = props => {
    useStyles(classes)

    const dispatch = useDispatch()

    const global = useSelector(state => state.global)

    const { item } = props

    const { realPrice, itemPrice } = item

    const off = itemPrice?.amount - realPrice?.amount > 0
    const percent = Math.round((itemPrice?.amount - realPrice?.amount)*100/itemPrice?.amount)

    // const countCart = useCallback((overseasDelivery, domesticDeliveryCases) => {
	// 	let count = 0
	// 	const deliveryItems = overseasDelivery ? overseasDelivery.deliveryItems : null
	// 	if (deliveryItems && deliveryItems.length) {
	// 		deliveryItems.forEach(deliveryItem => {
	// 			let items = deliveryItem.shoppingCartProducts
	// 			if (items && items.length) {
	// 				items.forEach(item => {
	// 					if (item.selected) count += item.quantity
	// 				})
	// 			}
	// 		})
	// 	}


	// 	if (domesticDeliveryCases && domesticDeliveryCases.length) {
	// 		domesticDeliveryCases.forEach(domestic => {
	// 			let items = domestic.shoppingCartProducts
	// 			if (items && items.length) {
	// 				items.forEach(item => {
	// 					if (item.selected) count += item.quantity
	// 				})
	// 			}
	// 		})
	// 	}
	// 	return count
	// }, [])

    const changeQuantity = async q => {
        let response
        if(q > 0){
           response = await changeItem(item.variantId, item.variantId, q)
        }else{
           response = await removeItem(item.variantId)
        }
        if(response?.code === 200){
            dispatch(setCart(response.result))
            // dispatch(setCountCart(countCart(response.result?.overseasDelivery, response.result?.domesticDeliveryCases)))
            dispatch(action_fetch_cart_count())
        }else{
            toast(response?.result, {isDesktop: global?.isDesktop})
        }
    }


    return <div className={classes.Item}>
        <div className={classes.Image}>
            <LazyLoadImage className="__image" src={item.imageUrl} />
        </div>
        <div className={classes.Bd}>
            <div className={classes.Name}>{item.productName}</div>
            <div className={classes.Prices}>
                <span className={classes.RealPrice}>{unitPrice(realPrice)}</span>
                {
                    off > 0 && <del className={classes.Del}>{unitPrice(itemPrice)}</del>
                }
                {
                    off> 0 && <span className={classes.Off}>-{percent}%</span>
                }
            </div>
            <div className={classes.SizeColor}>
                <span>{item.color}</span>
                {
                    item.size && <span>, {item.size}</span>
                }
            </div>
            <div style={{marginTop: 20}}>
                <Quantity value={item.quantity} onChange={changeQuantity}/>
            </div>
        </div>
    </div>
}

export default props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const global = useSelector(state => state.global)
    const { cart, cartInited } = global
    const [fetched, setFecthed] = useState(false)
    useEffect(async () => {
        if (!cartInited) {
            dispatch({
                type: SET_CART_INITED,
                payload: true
            })
            setLoading(true)
            await dispatch(action_fetch_cart())
            setLoading(false)
        }
        setFecthed(true)
    }, [])

    const isOutOfStock = useCallback(item => {
        return (item.inventory <= 0 && !item.isDomesticDelivery) && !item.isAutoInventory || item.status !== '1'
    }, [])



    let [overseasDelivery = {}, domesticDeliveryCases = [], orderSummary] = [cart?.overseasDelivery, cart?.domesticDeliveryCases, cart?.orderSummary]

    const overSeasitems = _.reduce(overseasDelivery?.deliveryItems, function (result, value, key) {
        const validItems = value?.shoppingCartProducts?.filter?.(item => !isOutOfStock(item)) || []
        result.push(...validItems)
        return result
    }, []);

    const localItems = _.reduce(domesticDeliveryCases, function (result, value, key) {
        const validItems = value?.shoppingCartProducts?.filter?.(item => !isOutOfStock(item)) || []
        result.push(...validItems)
        return result
    }, []);

    // const items = [...overSeasitems, ...localItems]

    const items = []

    cart?.regionalCases?.forEach(v1 => {
        v1?.caseItems?.forEach(v2 => {
            const validItems = v2?.shoppingCartProducts?.filter?.(item => !isOutOfStock(item)) || [] 
            items.push(...validItems)
        })
    })

    // const items = _.reduce(cart?.regionalCases, (result, value, key) => {
    //     const validItems = value?.caseItems?.filter?.(item => !isOutOfStock(item)) || []
    //     result.push(...validItems)
    //     return result
    // }, [])

    const isEmpty = fetched && items?.length < 1

    return <div className={classes.MinCart}>

        {
            isEmpty ? <div className={classes.EmptyCart}>
                <div>
                    <Iconfont className={classes.EmptyIcon}>&#xe6c0;</Iconfont>
                </div>
                <div className={classes.Description}>
                    <FormattedMessage id="empty_cart_description" defaultMessage="Your bag is currently empty"/>
                </div>
            </div> : <React.Fragment>
                <div className={classes.Bd}>
                    <ul className={classes.CartItems}>
                        {
                            items?.map((item, index) => <MinItem item={item} key={item.variantId} />)
                        }
                    </ul>
                </div>
                <div className={classes.Fd}>
                    <div className={classes.Total}>
                        <span className={classes.Label}><FormattedMessage id="total" defaultMessage="Total"/></span>
                        <span className={classes.Value}>{unitPrice(orderSummary?.orderTotal)}</span>
                    </div>
                    {
                        cart?.messages?.orderSummaryMsg && <div className={classes.Message}>
                            <span dangerouslySetInnerHTML={{ __html: cart?.messages?.orderSummaryMsg }}></span>
                        </div>
                    }
                    <div className={classes.Button}>
                        <BigButton onClick={e => {
                            window.location.href = '/cart'
                        }}>
                            <FormattedMessage id="view_bag" defaultMessage="View Bag"/>
                        </BigButton>
                    </div>
                </div>
            </React.Fragment>
        }

    </div>
}