import React from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setEditingProduct } from '../../../../../../../store/actions/global'


export default props => {
	const dispatch = useDispatch()
	const { productId, color, sensors, giftInfo, isLuckyDraw  } = props

	// 有isLuckyDraw的时候加购文案不要
	const clickHandle = useCallback(e => {
		e.preventDefault()
		e.stopPropagation()

		dispatch(setEditingProduct({
			sensors,
			productId,
			color,
			giftInfo,
			isLuckyDraw
		}))
	}, [giftInfo])

	return <React.Fragment>
		<span onClick={clickHandle}>
			{
				props.render()
			}
		</span>
	</React.Fragment>
}