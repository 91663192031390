import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'


export const ContainerMask = props => {
	useStyles(classes)
	return <div {...props} className={classes.ContainerMask}>
		{props.children}
	</div>
}


export function BlackMask(props){
	useStyles(classes)
	return <div {...props} className={classes.BlackMask}/>
}