import _ from 'lodash'

const __Sort_Map__ = {
	0: "Recommendation",
	1: "Best Sellers",
	2: "New Arrivals",
	3: "Price Low To High",
	4: "Price High To Low"
}

export const getSensorsUrl = (url, sensors) => {
	if (!sensors) return url
	const suffex = encodeURIComponent(`${sensors.resourcepage_title || ''}~${sensors.resource_type || ''}~${sensors.resource_content || ''}~${sensors.resource_position || ''}~${sensors.product_position || ''}~${sensors.filter || ''}`)
	if (url?.indexOf('?') > 0) {
		return `${url}&gf=${suffex}`
	}
	return `${url}?gf=${suffex}`
}

const getTerm = (field, value) => {
	if (!value) return ''
	return `${field}:${value};`
}

const getPriceRange = (start, end) => {
	if (!start && !end) return ''
	return `[${start || ''},${end || ''}]`
}

const getSorter = (sort, sorters) => {
	if (!sorters) return sort
	if (sorters instanceof Array) {
		const sorter = sorters?.find(s => s.value === sort)
		return sorter?.enLabel || sorter?.label
	} else {
		return __Sort_Map__[sort] || sort
	}
}

export const generateFilter = (filterObj, sorters) => {
	if (!filterObj) return ''
	try {
		const { filterItems, sorter, endPrice, startPrice } = filterObj
		const priceRange = getPriceRange(startPrice, endPrice)
		const filterItemsExcludeTags = filterItems?.filter(f => f.fieldName !== 'tags') || []
		const tags = filterItems?.filter(f => f.fieldName === 'tags') || []

		const filterStr = _.reduce(filterItemsExcludeTags, function (result, value, index) {
			const values = value?.selections?.map(s => s.value)
			if (values?.length > 0) {
				result.push(values.join(','))
			}
			return result
		}, [])?.join(',')

		const tagStr = _.reduce(tags, function (result, value, index) {
			const values = value?.selections?.map(s => s.value)
			if (values?.length > 0) {
				result.push(values.join(','))
			}
			return result
		}, [])?.join(',')

		return `${getTerm('sort', getSorter(sorter, sorters))}${getTerm('filter', filterStr)}${getTerm('tag', tagStr)}${getTerm('price', priceRange)}`
	} catch (error) {
		console.log(error)
		return ''
	}
}

export const SensorsTrack = (event, opts) => {
	try {
		if (window.GeekoSensors) {
			window.GeekoSensors.Track(event, opts)
		}
	} catch (error) {
		console.error(error)
	}
}

export const getOriginalSize = size =>{
	try {
		if(!size) return '';
		return size.replace(/\(.*/, '');
	} catch (error) {
		console.error(error)
	}
}