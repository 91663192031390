import ads from '../../../../configs/ads.json'




const addToCart = data => {
    try {
        if (!window.gtag) return

        const accounts = ads?.google?.gtag?.accounts
        const { amount, currency, name, productId } = data

        accounts.forEach(account => {
            if(account.addToCart){
                window.gtag('event', 'conversion', {
                    'send_to': account.id + '/' + account.addToCart,
                    'value': amount,
                    'currency': currency
                })
            }
        })

        window.gtag('event', 'add_to_cart', {
            currency: currency,
            value: amount,
            items: [
              {
                item_id: productId,
                item_name: name
              }
            ]
        })

    } catch (e) {
        console.warn(e)
    }

}


const addToWishList = data => {
    try {
        if (!window.gtag) return
        const { productId } = data
        window.gtag("event", "add_to_wishlist", {
            items: [
              {
                item_id: productId,
              }
            ]
          });
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        if (!window.gtag) return
        const { productId } = data
        window.gtag("event", "view_item", {
            items: [
              {
                item_id: productId
              }
            ]
        });
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        if (!window.gtag) return
        // 本来放这个地方的  不知为何purchease事件不触发
        // 所以将代码移到了 Route thanks.js 中
        // const accounts = ads?.google?.gtag?.accounts
        // const { orderTotal, orderItems } = data
        // accounts.forEach(account => {
        //     window.gtag('event', 'conversion', {
        //         'send_to': account.id + '/' + account.label,
        //         'value': orderTotal?.amount,
        //         'currency': orderTotal?.currency,
        //         'transaction_id': data?.transactionId,
        //     })
        // })

        // 本来放这个地方的  不知为何purchease事件不触发
        // 所以将代码移到了 Route thanks.js 中
        // const items = orderItems?.map(item => {
        //     return {
        //         item_id: item.productId,
        //         price: item.price?.amount,
        //         quantity: item.quantity,
        //     }
        // })

        // window.gtag('event', 'purchase', {
        //     transaction_id: data?.transactionId,
        //     currency: orderTotal?.currency,
        //     value: orderTotal?.amount,
        //     items: items
        // })

    } catch (e) {
        console.warn(e)
    }
}


export default {
    addToCart,
    addToWishList,
    pageView,
    purchase,
}