import React, { useEffect, useMemo, useState } from 'react'
import { getCountryMessage } from '../../../../../api'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { TOGGLE_UI_LOTTERY } from '../../../../../store/actions/ui'
import { useDispatch, useSelector } from 'react-redux'
import Cache from '../../../../../utils/cache'
import { Iconfont } from '../../../../components/icon/iconfont'
import withSourceClick from '../../../../hocs/source-only-click'
import CountDown from '../../../../components/count-down'
import { action_fetch_floating_mark_data } from '../../../../../store/actions/global'
import { FormattedMessage } from 'react-intl'
import deeplink from '../../../../../utils/deeplink'
import { useBot } from '../../../../hooks/common'

const IndexAlert = withSourceClick(props => {
    useStyles(classes)

    const { src, href, innerRef, isLottery, show, onClose } = props

    const dispatch = useDispatch()

    const fixedEvent = (evt) => {
        if (isLottery) {
            evt.preventDefault()
            dispatch({
                type: TOGGLE_UI_LOTTERY,
                payload: true
            })
        }
    }

    const touchEvent = evt => {
        evt.stopPropagation()
    }

    const onCloseEvent = () => {
        onClose();
        Cache.set('fixed-icon', 1)
    }

    return <div className={`${classes.IndexAlert} ${show ? classes.Show : ''}`}>
        <span className={classes.CloseIcon} onClick={onCloseEvent}>
            <Iconfont>&#xe69a;</Iconfont>
        </span>

        <a
            onClick={fixedEvent}
            onTouchStart={touchEvent}
            ref={innerRef}
            data-title="icon"
            data-position="1"
            data-type="12"
            data-content={href}
            href={href}
        ><img src={src} /></a>
    </div>
})

const LotteryIndexAlert = withSourceClick(props => {
    useStyles(classes)

    const { src, href, innerRef, isLottery, show, onClose, lotteryResult, shouldMini } = props

    const showCountDown = useMemo(() => {
        // console.log(lotteryResult)
        if (lotteryResult?.expiredDate && lotteryResult?.serverTime) {
            return lotteryResult?.expiredDate - lotteryResult?.serverTime> 0 ?
                lotteryResult?.expiredDate - lotteryResult?.serverTime : false
        }
        return false
    }, [lotteryResult])

    const dispatch = useDispatch()

    const fixedEvent = (evt) => {
        if (isLottery) {
            evt.preventDefault()
            dispatch({
                type: TOGGLE_UI_LOTTERY,
                payload: lotteryResult?.prize ? lotteryResult : true
            })
        }
    }

    const touchEvent = evt => {
        evt.stopPropagation()
    }

    const onCloseEvent = () => {
        onClose();
        Cache.set('fixed-icon', 1)
    }

    const formateName = useMemo(() => {
        if(lotteryResult?.prize?.name){
            let nameList = lotteryResult?.prize?.name?.split(" ")
            let name = nameList?.[0] ?
                `<span style="font-size:${nameList?.[0]?.length > 4 ? 20:32}px;line-height: 34px;">${nameList?.[0]}</span>`: ''

            return name + '<br>' + nameList?.slice(1)?.join(" ")
        }
        return ''
    },[ lotteryResult?.prize])

    return <div className={`${classes.IndexAlert} ${show ? classes.Show : ''}`}>
        <span className={classes.CloseIcon} onClick={onCloseEvent}>
            <Iconfont>&#xe69a;</Iconfont>
        </span>

        <a
            onClick={fixedEvent}
            onTouchStart={touchEvent}
            ref={innerRef}
            data-title="icon"
            data-position="1"
            data-type="12"
            data-content={lotteryResult?.ref || href}
            href={href}
        >
            <span
                className={classes.prizeName}
                dangerouslySetInnerHTML={{__html:formateName}}>
            </span>
            <img src={src} />
        </a>

        {
            formateName && <React.Fragment>
                {
                    showCountDown?
                    <div
                        className={classes.CountDownBox}
                        style={{
                            backgroundColor:lotteryResult?.backgroundColor || '#222',
                            border:`1px solid ${lotteryResult?.borderColor || '#222'}`
                        }}
                        >
                        <CountDown
                            showHour
                            offset={showCountDown}
                            callback={async () => {
                                await dispatch(action_fetch_floating_mark_data())
                            }}/>
                    </div>:
                    <div
                        className={classes.CountDownBox}
                        onClick={fixedEvent}
                        style={{
                            backgroundColor:lotteryResult?.backgroundColor || '#222',
                            border:`1px solid ${lotteryResult?.borderColor || '#222'}`
                        }}
                        >
                        <FormattedMessage id="view" defaultMessage={"View"}/>
                    </div>
                }
            </React.Fragment>
        }
    </div>
})


export default props => {

    const [message, setMessage] = useState();
    const ui = useSelector(state => state.ui);
    const global = useSelector(state => state.global)
    const { floatingMarkData, country } = global
    const [showIcon, setShowIcon] = useState(false);
    const dispatch = useDispatch()
    const isBot = useBot()

    useEffect(async () => {
        const fixedIcon = Cache.get('fixed-icon');
        if(!fixedIcon){
            const res = await dispatch(action_fetch_floating_mark_data())
            if(res){
                setShowIcon(true)
            }
        }
    }, [])


    return <React.Fragment>
        {
            showIcon && !isBot &&
            <React.Fragment>
                {
                    floatingMarkData?.isLottery ?
                        <LotteryIndexAlert
                            show={(ui.direction === 'down' || !ui.direction) && !ui.showLottery}
                            isLottery={true}
                            src={floatingMarkData?.icon || ''}
                            lotteryResult={floatingMarkData}
                            href={''}
                            onClose={() => setShowIcon(false)}
                            shouldMini={country == 'MX'}
                        /> :
                        <IndexAlert
                            show={(ui.direction === 'down' || !ui.direction) && !ui.showLottery}
                            isLottery={false}
                            src={floatingMarkData?.icon || ''}
                            href={deeplink(floatingMarkData?.deepLink, 'pc') || ''}
                            onClose={() => setShowIcon(false)}
                        />
                }
            </React.Fragment>
        }
    </React.Fragment>
}