import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './style.module.scss';

const ModalTable = (props) => {
    useStyles(classes)
    const {data, unitSelect} = props
    const unit = unitSelect ? 'cm': 'inch'
    return <div className={classes.ModalTables}>
        {
            data?.[unit]?.map((item,index) => {
                return <table className={classes.modalTale} key={index+item?.name}>
                    {
                        Object.keys(item?.detail)?.map((key, index2) => {
                            return <tr key={index2+item?.name}>
                                {index2 ==0 && <td rowSpan={Object.keys(item?.detail)?.length}>{item?.name}</td>}
                                <td className={classes.modalTitle}>{item?.detail?.[key]?.title}</td>
                                <td className={classes.modalContent}>{item?.detail?.[key]?.content}</td>
                            </tr>
                        })
                    }
                </table>
            })
        }
        <table >

        </table>
    </div>
}

const SizeChartModalStature = props => {
    useStyles(classes)
    const {modalStature, unitSelect} = props || {}

    return <React.Fragment>
        <div className={`${classes.ProductMeasurementsBox} ${classes.ModalStature}`}>
            <div className={classes.Title}><FormattedMessage id="size_fit"/></div>
            <ModalTable data={modalStature} unitSelect={unitSelect}></ModalTable>
        </div>
    </React.Fragment>
}


export default SizeChartModalStature