import {
	SIMILAR_LIST_ADD,
	SIMILAR_LIST_SET,
	SIMILAR_LIST_FINISHED,
	SIMILAR_LIST_SKIP,
	SIMILAR_LIST_LOADING,
	SIMILAR_FILTER_SET,
	SIMILAR_SORTERS_SET
} from '../../actions/pages/similar'

const initialState = {
	list: [],
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false
}

export default (state = initialState, action) => {
	switch (action.type){
		case SIMILAR_LIST_SET:
			return {...state, list: action.payload}
		case SIMILAR_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case SIMILAR_LIST_SKIP:
			return {...state, skip: action.payload}
		case SIMILAR_LIST_FINISHED:
			return {...state, finished: action.payload}
		case SIMILAR_LIST_LOADING:
			return {...state, loading: action.payload}
		case SIMILAR_FILTER_SET:
			return {...state, filterVo: action.payload}
		case SIMILAR_SORTERS_SET:
			return {...state, sorters: action.payload}
		default:
			return state
	}
}