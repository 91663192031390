import React from 'react'
import { Switch, Route } from 'react-router-dom'
import '../theme.scss'
import '../app.scss'
import '../swiper.bundle.scss'
import BaseLayout from './layout/base-layout'
// import ListingCollection from './pages/listing/collection'
// import ListingCategory from './pages/listing/category'
import listCollectionPaths from '../pages/listing/list-collection/paths.json'
import Loadable from 'react-loadable'




import { IntlProvider } from 'react-intl'
import { getMessage, getLocale } from '../../i18n'

import { useSelector } from 'react-redux'

import SwiperCore, {
	Pagination,
	Autoplay,
	Navigation,
	Thumbs
} from 'swiper'

SwiperCore.use([Pagination, Autoplay, Navigation, Thumbs])

const Home = Loadable({
	loader: () => import('./pages/moduleable/home'),
	loading: () => <span></span>,
})


const ListingCollection = Loadable({
	loader: () => import('./pages/listing/collection'),
	loading: () => <span></span>,
})

const ListingCategory = Loadable({
	loader: () => import('./pages/listing/category'),
	loading: () => <span></span>,
})

const ListingSearch = Loadable({
	loader: () => import('./pages/listing/search'),
	loading: () => <span></span>,
})

const ListCollections = Loadable({
	loader: () => import('./pages/listing/list-collection'),
	loading: <span></span>,
})


const ListingSimple = Loadable({
	loader: () => import('./pages/listing/simple-list'),
	loading: <span></span>,
})


const Exclusive = Loadable({
	loader: () => import('./pages/listing/exclusive'),
	loading: <span></span>,
})




const Details = Loadable({
	loader: () => import('./pages/details'),
	loading: <span></span>,
})


const SimpleLanding = Loadable({
	loader: () => import('./pages/moduleable/simple-landing'),
	loading: <span></span>,
})


const OrderConfirmAlert = Loadable({
	loader: () => import('./pages/order-confirm-alert'),
	loading: <span></span>,
})

const NewArrivals = Loadable({
	loader: () => import('./pages/listing/new-arrivals'),
	loading: <span></span>,
})


const LoginPage = Loadable({
	loader: () => import('./pages/login'),
	loading: <span></span>,
})

const Trending = Loadable({
	loader: () => import('./pages/listing/trending'),
	loading: <span></span>,
})


const Index = props => {

	const global = useSelector((state) => state.global)

	return <IntlProvider country={global.country} messages={getMessage(global.language, global.country)} locale={getLocale(global.language, global.country)} defaultLocale="en">


		<Switch>
			<Route path={'/order-confirm-alert'} exact component={OrderConfirmAlert} />
			<BaseLayout>

				<Route path={'/'} exact component={Home} />

				{
					['en', 'es', 'de', 'fr', 'pt', 'da', 'sv', 'nb', 'fi', 'is'].map(lang => <Route path={`/${lang}/`} exact component={Home} />)
				}

				<Route path={'/*/i/index'} component={Home} />
				<Route path={'/index'} component={Home} />
				<Route path={'/*/index'} component={Home} />


				<Route path={'/*/collection/*/:id.html'} component={ListingCollection} />
				<Route path={'/collection/*/:id.html'} component={ListingCollection} />
				<Route path={'/**/i/collection/products'} component={ListingCollection} />
				<Route path={'/*/category/*/:id.html'} component={ListingCategory} />
				<Route path={'/category/*/:id.html'} component={ListingCategory} />
				<Route path={'/**/i/category/products'} component={ListingCategory} />
				<Route path={'/*/w-site/anon/search'} component={ListingSearch} />
				<Route path={'/w-site/anon/search'} component={ListingSearch} />
				<Route path={'/*/i/search/result'} component={ListingSearch} />
				<Route path={'/i/search/result'} component={ListingSearch} />
				<Route path={'/simple-list/*'} component={ListingSimple} />
				<Route path={'/*/simple-list/*'} component={ListingSimple} />
				<Route path={'/landing-page/*'} component={SimpleLanding} />
				<Route path={'/*/landing-page/*'} component={SimpleLanding} />
				<Route path={'/i/black-friday'} component={SimpleLanding} />
				<Route path={'/*/i/black-friday'} component={SimpleLanding} />
				<Route path={'/i/sales'} component={SimpleLanding} />
				<Route path={'/*/i/sales'} component={SimpleLanding} />

				{
					['/i/exclusive/products', '/*/i/exclusive/products'].map(path => <Route key={path} path={path} component={Exclusive} />)
				}

				{
					['/i/new-arrivals', '/*/i/new-arrivals'].map(path => <Route key={path} path={path} component={NewArrivals} />)
				}

				{
					listCollectionPaths.map(c => <Route key={c.path} path={c.path} component={ListCollections} />)
				}

				{
					listCollectionPaths.map(c => <Route key={c.path} path={`/*${c.path}`} component={ListCollections} />)
				}


				<Route path={'/product/:name/:id.html'} component={Details} />
				<Route path={'/*/product/:name/:id.html'} component={Details} />
				<Route path={'/pc/product_detail'} component={Details} />
				<Route path={'/*/pc/product_detail'} component={Details} />

				{
					['/i/login', '/*/i/login', '/i/register', '/*/i/register'].map(c => <Route key={c} path={c} component={LoginPage}/>)
				}
				{
					['/i/trending-now', '/*/i/trending-now'].map(path => <Route key={path} path={path} component={Trending}/>)
				}
			</BaseLayout>
		</Switch>


		<div id="alert-container"></div>
		<div id="confirm-container"></div>
		<div id="toast-container"></div>

	</IntlProvider>

}


export default Index