import { get, post, body } from './axios'
import website from '../configs/website.json'

export const V = '/v9'

export const fetchMenu = headers => {
	return get('/menu/anon/show', null, headers)
}

export const fetchHeaderTip = headers => {
	return get('/message/anon/header-tip', null, headers)
}

export const getCountryMessage = (code, headers) => {
	if(code === 'M1316'){
		console.log('M1316 访问接口' ,headers)
	}
	return get(`${V}/message/anon/country-message/${code}`, null, headers)
}

export const getCountryAppMessage = (code, headers) => {
	return get(`${V}/message/anon/app-message/${code}`, null, headers)
}

export const getHotWords = headers => {
	return get(`${V}/search/anon/get-hot-words2`, null, headers)
}

export const searchSimple = (searchValue, headers) => {
	return post(`${V}/search/anon/simple`, { searchValue }, headers)
}

export const getBaseConfig = headers => {
	return Promise.resolve({
		result: website
	})
}

export const getTopReminder = headers => {
	return get(`/context/anon/get-top-reminder`, {}, headers)
}

export const searchCollection = (searchValue, headers) => {
	// return Promise.resolve({
	// 	result: {
	// 		id: '1b5q6F4z5t5u7n2p3L9T8E85c',
	// 		name: 'minimini'
	// 	}
	// }) 
	return get(`${V}/search/anon/search-collection`, { searchValue }, headers)
}

export const searchCategory = (searchValue, headers) => {
	return get(`${V}/search/anon/search-category`, { searchValue }, headers)
}

export const fetchSearchList = (data, params, headers) => {
	return body(`${V}/search/anon/${params.skip}/${params.limit}/products2`, data, params, headers)
}
export const fetchSearchSorters = (params, headers) => {
	return get(`/context/anon/get-search-sorters`, params, headers)
}

export const fetchSearchFilter = (params, headers) => {
	return get(`${V}/search/anon/get-product-search-filter`, params, headers)
}

export const fetchCategory = (id, headers) => {
	return get(`${V}/category/anon/get`, { id }, headers)
}
export const fetchCategoryList = (data, params, headers) => {
	return body(`${V}/product/anon/${params.skip}/${params.limit}/w-filter`, data, params, headers)
}
export const fetchCategorySorters = (params, headers) => {
	return get(`/context/anon/get-sorters`, params, headers)
}
export const fetchCategoryFilter = (params, headers) => {
	return get(`${V}/filter/anon/${params.categoryId}/get-by-category-id`, params, headers)
}


export const fetchExclusiveList = (data, params, headers) => {
	return body(`${V}/product/anon/${params.skip}/${params.limit}/get-exclusive`, data, params, headers)
}
export const fetchExclusiveSorters = (params, headers) => {
	return get(`/context/anon/get-sorters`, params, headers)
}
export const fetchExclusiveFilter = (params, headers) => {
	return get(`${V}/filter/anon/get-exclusive-filter`, params, headers)
}


export const fetchCollection = (id, headers) => {
	return get(`${V}/collection/anon/${id}/get`, null, headers)
}

export const fetchCollectionList = (data, params, headers) => {
	return body(`${V}/collection/anon/${params.skip}/${params.limit}/filter`, data, params, headers)
}

export const fetchCollectionFilter = (params, headers) => {
	return get(`${V}/filter/anon/${params.collectionId}/get-by-collection-id`, params, headers)
}

export const fetchCollectionSorters = (params, headers) => {
	return get(`/context/anon/get-collection-sorters`, params, headers)
}

export const fetchNewCategoryList = (data, params, headers) => {
	return body(`${V}/product/anon/${params.skip}/${params.limit}/newin`, data, params, headers)
}


export const fetchSimilarList = (data, params, headers) => {
	return body(`${V}/search/anon/${params.skip}/${params.limit}/similar-products`, data, params, headers)
}

export const fetchSimilarSorters = (params, headers) => {
	return get(`/context/anon/get-sorters`, params, headers)
}

export const fetchSimilarFilter = (params, headers) => {
	return get(`${V}/search/anon/get-similar-product-search-filter`, params, headers)
}


export const fetchProductDetails = (id, params, headers) => {
	return get(`${V}/product/anon/${id}/show2`, params, headers)
}

export const fetchProductDetailsReview = (id,headers) =>{
	return get(`${V}/product/anon/${id}/get-product-comment`,null,headers);
}

export const fetchMessageGetList = (code,headers) =>{
	return get(`/message/anon/get-list/${code}`,null,headers);
}

export const fetchTranslateReview = (data,headers) =>{
	return post("/product-comment/anon/translate",data,headers);
}

export const fetchReviewComments = (productId,skip,limit=12,headers) =>{
	return get(`/comment/${productId}/${skip}/${limit}/show`,headers)
}

export const fetchStockOutSubscribe = (variantId,headers) =>{
	return post(`/notify-product-back/add`,{variantId},headers).then((data) =>{
		if(data?.code === 300){
			window.location.href = `/i/login?redirectUrl=${window.location.href}`
			return Promise.reject("Error");
		}else{
			return data;
		}
	});
}

export const fetchStockOutUnSubscribe = (variantId,headers) =>{
	return post(`/notify-product-back/remove`,{variantId},headers).then(data =>{
		if(data?.code === 300){
			window.location.href = `/i/login?redirectUrl=${window.location.href}`
			return Promise.reject("Error");
		}else{
			return data;
		}
	});
}

export const fetchWishList = headers => {
	return get(`${V}/wanna-list/show`, null, headers)
}

export const fetchProductCouponMessage = headers =>{
	return get(`${V}/coupon/anon/get-coupon-msg`,null,headers);
}

export const addToWishList = (productId, headers) => {
	return get(`${V}/wanna-list/${productId}/add-product`, { _: new Date().getTime() }, headers)
}

export const removeFromWishList = (productIds, headers) => {
	return get(`${V}/wanna-list/remove-products`, { productIds, _: new Date().getTime() }, headers)
}

export const removeExpiredProducts = (headers) =>{
	return post(`${V}/wanna-list/clear-products`, {}, headers)
}

export const addToCart = (data, headers) => {
	return body(`/shopping-cart/add-products`, [data], headers)
}

export const addToCarts = (data, headers) => {
	return body(`/shopping-cart/add-products`, data, headers)
}

export const selectGift = (data, headers) => post(`${V}/shopping-cart/update-gift`, data, headers)

export const fetchCountCart = headers => {
	return get(`/shopping-cart/count-products`, { _: new Date().getTime() }, headers)
}

export const fetchCountNotification = headers => {
	return get(`/notification/no-read-notifications`, { _: new Date().getTime() }, headers)
}

export const fetchCurrencies = headers => {
	return get(`/dictionary/anon/get`, { typeCode: 'currency' }, headers)
}


export const sendToFaceBook = (data, headers) => {
	return body('/fb-server-side/anon/event', data, null, headers)
}

export const sendCurrency = (currency, headers) => {
	return post('/context/anon/update-currency', { currency: currency }, headers)
}

export const fetchCurrency = headers => {
	return get('/context/anon/get-currency', { _: new Date().getTime() }, headers)
}

export const fetchCountry = headers => {
	return get('/context/anon/get-country', { _: new Date().getTime() }, headers)
}

export const fetchCurrentUser = headers => {
	return get('/context/anon/get-current-user', { _: new Date().getTime() }, headers)
}

export const fetchBaseInfo = (headers, shouldResponse) => {
	return get('/context/get-base-info', { _: new Date().getTime() }, headers, shouldResponse)
}

export const fetchSavedRedirect = headers => {
	return get('/context/anon/get-saved-redirect', { _: new Date().getTime() }, headers)
}


//modules
export const fetchBanner = (website, headers) => {
	return get(`${V}/collection/anon/theme`, { wbs: website }, headers)
}

export const fetchByMenu = (params, headers) => {
	return get(`${V}/product/anon/${params.skip}/${params.limit}/1/${params.code}/list-by-menu`, { ...params }, headers)
}

export const fetchMenus = headers => {
	return get(`${V}/product-menu/anon/get`, { _: new Date().getTime() }, headers)
}

export const subscribe = (email, headers) => {
	return post(`${V}/subscription/anon`, { email }, headers)
}


export const fetchLuckDrawTasks = headers => {
	// return Promise.resolve({
	// 	success: true,
	// 	code: 200,
	// 	result: [{
	// 		"id": "02",
	// 		"name": "en_US",
	// 		"completedTimes": 0,
	// 		"maxCompletedTimes": 1,
	// 		"rule": {
	// 			"addToCartTimes": 5
	// 		}
	// 	}]
	// })
	return get(`/luck-draw-task/anon/list`, {_: new Date().getTime()}, headers)
}

export const doLuckDrawTask = (id, headers) => {
	return post(`/luck-draw-task/do-task`, { id }, headers)
}

export const fetchABTesting = (code, headers) => {
	return get(`/context/anon/ab-testing/${code}`, {_: new Date().getTime()}, headers)
}

export const fetchCollections = (params, headers) => {
	return get(`${V}/collection/anon/${params.skip}/${params.limit}/get`, params, headers)
}

export const fetchCart = headers => {
	return get(`${V}/shopping-cart/show`, {_: new Date().getTime()}, headers)
}
export const fetchProductYouLikes = (params,headers) =>{
	return get(`/product/${params.skip}/${params.limit}/${params.productId}/scp/show`,null,headers);
}

export const fetchProductOftenWith = (data,headers) =>{
	return get(`${V}/collection/anon/get-products-by-main-product-category-id`,data,headers);
}

export const fetchShareUrl = (params,headers) =>{
	return get(`/context/anon/get-share-urls`,params,headers);
}

export const fetchProductBestSellers = (productCategoryId,headers) =>{
	return get(`${V}/product/anon/0/20/best-sellers-on-product-category`,{productCategoryId},headers);
}

export const fetchProductShopTheLook = (productId,headers) =>{
	return get(`${V}/collection/anon/get-match-clothes-by-product-id`,{productId},headers);
}

export const fetchProductShopTheLookNew = (params, headers) => {
	return body(`${V}/product/get-shop-the-look`, params, headers)
}

export const fetchBeautyLookItem = (id,headers) =>{
	return get(`/beauty-look/anon/get`,{id},headers);
}

export const fetchCommentIds = (ids,headers) =>{
	return post(`/product-comment/get-by-ids`,{ids},headers);
}

export const fetchUpdateGift = (variantId,headers) =>{
	return post(`${V}/shopping-cart/update-gift`,{variantId},headers);
}

export const fetchProductSummary = (productId, headers) => {
	return get(`/comment/${productId}/get/commentSummary`, {}, headers)
}

export const fetchProductMenus = (productId, headers) => {
	return get(`/productCategory/anon/get-product-categorys-by-product-id`, {productId}, headers)
}

export const fetchHomePagePopup = (headers) =>{
	return get(`/context/get-homepage-popup`, {_: new Date().getTime()}, headers);
}

export const fetchTimeRanges = headers => {
	return get(`${V}/product/anon/get-new-product-time-ranges`, {}, headers)
}

export const getProductsByTimeRange = (params, headers) => {
	return get(`${V}/product/anon/${params.index}/${params.skip}/${params.limit}/get-new-products-by-time-range`, {index: params.index}, headers)
}

export const fetchSearchImage = (data,headers) =>{
	return body(`${V}/product-vision/search`,data,headers);
}

export const fetchSearchImageSort = (data,headers) =>{
	return post(`${V}/product-vision/search-sort`,data,headers);
}

export const fetchRegisterAreaCode = (headers) =>{
	return get(`${V}/customer/anon/use-customer-register-validate-code`,{},headers);
}

export const fetchLoginAreaCode = (headers) =>{
	return get(`${V}/login-customer/anon/use-validate-code`,{},headers);
}

export const fetchRegister2 = (data,headers) =>{
	return post(`${V}/customer/anon/register2`,data,headers);
}

export const fetchLogin = (data,headers) =>{
	return post(`${V}/login-customer/anon/login`,data,headers);
}

export const fetchGoogleLogin = (token, headers) =>{
	return post(`${V}/login-customer/anon/login-by-google`, { token }, headers);
}

export const fetchAutoLoginByKey = (key, headers) =>{
	return post(`${V}/login-customer/anon/login-by-key`, { key }, headers);
}

export const fetchLoginByPaypal = (code, headers) =>{
	return get(`${V}/login-customer/login-by-paypal`, {  code }, headers);
}

export const fetchSetWhatsApp = (data, headers) =>{
	return post(`${V}/customer/set-whatsapp`, data, headers);
}

export const fetchRestPassword = (email,headers) =>{
	return post(`/customer/get/change/password/url`,{email},headers);
}

export const fetchFacebookLoginHandler = (params,headers) =>{
	return get(`/login/customer/login/facebook/${params.fbId}/${params.accessToken}`,{},headers);
}

export const fetchUserDistinguish = (data, headers) =>{
	return post(`${V}/login-customer/get-login-distinguish`, data, headers);
}

export const fetchLoginBonusMessage = (headers) =>{
	return get(`${V}/login-customer/get-login-page-bonus-banner`, { _:new Date().getTime() }, headers);
}

export const fetchGetMessage = (code,headers) =>{
	return get(`/message/get/${code}`,{_:new Date().getTime()},headers);
}

export const fetchGetLotteryPrizes = (headers) =>{
	return get(`${V}/luck-draw/get-all-prizes`,{},headers)
}

export const fetchLuckDraw = (token,headers) =>{
	return get(`${V}/luck-draw/luck-draw`,{token,_:new Date().getTime()},headers);
}

export const fetchGetUserLastTimes = (headers) =>{
	return get(`${V}/luck-draw/times`,{_: new Date().getTime()},headers);
}

export const fetchLuckLastesthistory = (headers) =>{
	return get(`${V}/luck-draw/lastest-history`,{},headers);
}

export const fetchTemporaryRegister = (email,headers) =>{
	return post(`${V}/customer/temporary-register`,{email},headers);
}

export const fetchGetMyPrizes = (headers) =>{
	return get(`${V}/luck-draw/0/50/history`,{date:new Date().getTime()},headers);
}

export const subscribeWebPush = (data, params, headers) => {
	return body(`/web-push/anon/add`, data, params, headers)
}

export const getLatestNotification = headers => {
	return get(`/notification/get-pop-up-notification`, {_: new Date().getTime()}, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: {
	// 		image: '/menu/3c9a63b2-4e97-4b97-bdbf-76fcd5c177f6',
	// 		content: 'If you accidentally passed it from a parent component',
	// 		sendTime: 1649761559011,
	// 		model: {
	// 			content: '111',
	// 			deepLink: {
	// 				type: 1,
	// 				params: ['1Z6b4v6k9A0f6G8E0g1k6v3k8E']
	// 			}
	// 		}
	// 	}
	// })
}

export const getProductSearchImageResult = (data,headers) =>{
	return post(`${V}/product-vision/search-by-product-id`,data,headers);
}

export const fetchCountries = headers => {
	return get(`/dictionary/anon/get`, { typeCode: 'country' }, headers)
}

export const fetchValidCode = (email, headers) => {
	return post(`/privacy-reqeust-center-record/send`, { email }, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: 'success'
	// })
}

export const addRequestPrivacy = (data, headers) => {
	return post(`/privacy-reqeust-center-record/add`, data, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: 'success'
	// })
}

export const changeItem = (oldId, newId, quantity, headers) => get(`${V}/shopping-cart/${oldId}/${newId}/${quantity}/change-product`, {}, headers)
export const removeItem = (itemId, headers) => get(`${V}/shopping-cart/${itemId}/remove`, {}, headers)

export const logout = headers => get(`${V}/login-customer/logout`, {}, headers)

export const getCouponNotification = headers => {
	return get(`/notification/get-announcement`, {_: new Date().getTime()}, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: {
	// 		"icon": "http://iconURL",
	// 		"title": "Coupon",
	// 		"content": "Congratulations! <br> You can use it to.",
	// 		"buttons": [
	// 			{
	// 				"text": "Get",
	// 				"deepLink": {
	// 					"type": 35,
	// 					"params": [
	// 						"/v9/coupon/add?couponCode=cm20"
	// 					]
	// 				},
	// 				"successMsg": "Get it!Don't forget to use the coupon at checkout."
	// 			}
	// 		]
	// 	}
	// })
}

export const getByUrl = (url, params, headers) => {
	return get(url, params, headers)
}

export const fetchProductShareSuccess = (productId,ShareTo,headers) =>{
	return get(`${V}/product/share/success`,{productId,ShareTo},headers);
}

export const getShippingInfo = (params,headers) =>{
	return get(`/context/anon/get-shipping-policy-info`, params, headers);
}

export const getFirstOrder = headers => {
	return get(`/context/anon/is-first-order`,{},headers)
}

export const getProductTaglia = (productId,headers) =>{
	return get(`/productCategory/anon/get-taglia`,{ productId },headers)
}

export const getProductClog = (params, headers) => {
	return get(`/c-log/${params.skip}/${params.limit}/${params.productId}/list-by-product`, {}, headers)
}

export const getProductOffInfo = (productId, headers) => {
	// return get(`${V}/product/${productId}/get-product-promotions`, {}, headers)
	return get(`/promotional-activity/${productId}/get-product-promotions`, {}, headers)
}

export const getActivityPromotional = (activityId, productId, headers) =>{
	return get(`/promotional-activity/get?productId=${productId}&activityId=${activityId}&_=${new Date().getTime()}`, {}, headers);
}

export const getGiftsInfo = (headers) =>{
	// 新接口不用加/v9
	return get(`/shopping-cart/get-gift-info`, {_:new Date().getTime()}, headers)
}

export const getRaffleCoupon = (params,headers) => {
	return get(`${V}/order/get-cash-back`, {...(params||{}), _: new Date().getTime()}, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: "https://image.geeko.ltd/chicme/20220801/coupons.png"
	// })
}

export const fetchConfig = (code, headers) =>{
	return get(`/context/get-config`,{code},headers);
}

// 落地页签到数据
export const fetchSignInData = (headers) => {
	return get(`/sign-in/activity/get-sign-in`,{_: new Date().getTime()},headers);
}

// 落地页签到
export const fetchSignIn = (headers) => {
	return post(`/sign-in/activity/check-in`,null,headers);
}

// 落地页开启通知
export const fetchSignInRemind = (open,headers) => {
	return post(`/sign-in/activity/open-reminder`,{open:open},headers)
}

export const getLuckyStar = headers => {
	return get(`/luck-draw/lucky-star`, {_: new Date().getTime()}, headers)
	// return Promise.resolve({
	// 	code: 200,
	// 	result: {
	// 		"amount": "1",
	// 		"currency": "USD",
	// 		"unit": "$"
	// 	}
	// })
}

export const getLuckStarShow = headers => {
	return get(`/luck-draw/lucky-star-show`, {_: new Date().getTime()}, headers)
}
	
export const getClogTitles = headers => {
	return get(`/c-log/get-tab-titles`,{},headers)
}

export const getClogsByTitle = (params, headers) => {
	return get(`/c-log/${params.skip}/${params.limit}/list`,params,headers)
}

export const getSimilarClogs = (params, headers) => {
	return get(`/c-log/${params.skip}/${params.limit}/get-similar-list`,params,headers)
}

export const addCLogWish = (id, headers) =>{
	return get(`/c-log/${id}/like`, {}, headers);
}

export const removeCLogWish = (id, headers) =>{
	return get(`/c-log/${id}/unlike`, {}, headers);
}

export const getCLogComments = ({commentId, skip, limit}, headers) =>{
	return get(`/c-log/${commentId}/${skip}/${limit}/get-comments`, {}, headers)
}

export const addCLogComment = (params,headers) =>{
	return post(`/c-log/add-comment`, params, headers);
}

export const addCLogCommentWish = (id, headers) =>{
	return post(`/c-log/like-on-comment`, {commentId: id}, headers);
}

export const removeCLogCommentWish = (id, headers) =>{
	return post(`/c-log/unlike-on-comment`, {commentId: id}, headers);
}

export const getCLogBanner = (headers) =>{
	return get(`c-log/get-banner`, {}, headers)
}

export const getShowingList = (headers) =>{
	return get(`${V}/showing/anon/0/10/list`, {}, headers)
}

export const getStatedCollections = (params,headers) =>{
	return get(`${V}/collection/anon/${params.skip}/${params.limit}/get`, {}, headers)
}

export const getShowGiftPickMask = (headers) => {
	return get(`/luck-draw/lucky-star-show`,{},headers)
}

export const fetchNewArrivalsList = (data, params, headers) => {
	return body(`${V}/collection/anon/${params.skip}/${params.limit}/get-new-product-selling`, data, params, headers)
}

export const fetchNewArrivalsSorters = (params, headers) => {
	return get(`/context/anon/get-sorters`, params, headers)
}
export const fetchNewArrivalsFilter = (params, headers) => {
	return get(`${V}/filter/anon/APP0010/get-by-menu-id`, params, headers)
}

export const fetchNewRangeArrivalsList = (data, params, headers) => {
	return body(`${V}/product/anon/${params?.rangeId}/${params.skip}/${params.limit}/get-new-products-by-time-range`, data, params, headers)
}

export const fetchByOrderId =(orderId, headers) =>{
	return get(`/${V}/tracking/anon/get-by-order-id`, {orderId}, headers)
}

export const fetchStates = (country,headers) => {
	return get(`/dictionary/anon/get`, { typeCode: `state-${country}` }, headers)
}

export const fetchCites = (countryCode, stateCode, headers) => get('/context/anon/get-cities', { countryCode, stateCode }, headers)

//autocomplete
export const fetch_address_list = (data, headers) => post(`${V}/place-auto-complete/get`, data, headers)
export const fetch_address_details = (placeId, headers) => post(`${V}/place-auto-complete/get-by-place-id`, {placeId}, headers)


//credit pay


//pay get params
export const checkout_getparams = (params, headers) => get(`${V}/pay/get-pay-params-by-order`, {...params, _:new Date().getTime()}, headers)

//ebanx
export const openEbanxOrder = (params, headers) => post(`${V}/ebanx/open-order`, params, headers)
export const errorEbanx = (data, headers) => post(`${V}/ebanx/set-pay-error-msg`, data, headers)
export const ebanxPay = (data, headers) => body(`${V}/ebanx/direct`, data, {payMethod: data?.payMethod}, headers)
export const pay_ws_Ebanx = (data, headers) => post(`${V}/ebanx/ws-direct`, data, headers)
export const pay_request_EBanx = (data, headers) => post(`${V}/ebanx/request`, data, headers)


//stripe
export const openStripeOrder = (params, headers) => post(`${V}/stripe/open-order`, params, headers)
export const stripePay = (data, headers) => body(`${V}/stripe/bind-card2`, data, {payMethod: data.payMethod}, headers)
export const stripeCallBack = (data, headers) => body(`${V}/stripe/pay-result`, data, {payMethod: data.payMethod}, headers)
export const getStripeClient = (params, headers) => post(`${V}/stripe/get-client-secret`, params, headers)

export const openStripeAppleOrder = (data, headers) => post(`${V}/stripe-apple-pay/open-order`, data, headers)
export const stripeAppleCallBack = (data, headers) => body(`${V}/stripe-apple-pay/pay-result`, data, {payMethod: data?.payMethod}, headers)


//safecharge
export const getSafeCharge = (payMethod, headers) => get(`${V}/safe-charge/get-pay-params`, { payMethod, _: new Date().getTime() }, headers)
export const openSafeChargeOrder = ({orderId, merchantSiteId}, headers) => get(`${V}/safe-charge/open-order`, { orderId, merchantSiteId, _: new Date().getTime() }, headers)
export const setSafeChargeStatus = (sessionToken, headers) => get(`${V}/safe-charge/set-payment-status`, { sessionToken, _: new Date().getTime() }, headers)
export const setSafeCharge = (params, headers) => post(`${V}/safe-charge/set-payment-status`, params, headers)
export const initSafeChargeOrder = (params, headers) => get(`${V}/safe-charge/init-order`, { ...params, _: new Date().getTime() }, headers)

//billingaddress
export const updatebillingaddress = (address, headers) => post(`${V}/order/anon/update-billing-address`, address, headers)

//checkout
export const openCheckOutOrder = (params, headers) => post(`${V}/checkout/open-order`, params, headers)

//card list
export const getCardList = (payMethods,headers) => get(`/quickpay-record/history-by-pay-methods`, {payMethods, _: new Date().getTime()}, headers)
export const selectCard = (cardId, headers) => get(`/quickpay-record/${cardId}/use`, {_: new Date().getTime()}, headers)

//pay
export const checkout_credit = (params, headers) => post(`${V}/pay/quickpay-by-order`, params, headers)
export const canOrderPay = (orderId, headers) => get(`${V}/pay/check-order/${orderId}`,{ _: new Date().getTime() }, headers)

//cart rec list
export const getRecProducts = (limit, headers) => get(`${V}/shopping-cart/get-shopping-cart-recommendeds`,{ limit }, headers)

//new version shopping cart
export const fetchShoppingCart = headers => get(`/shopping-cart/get`, {_: new Date().getTime()}, headers)
export const shoppingcart_select_all = (select,headers) => post(`/shopping-cart/select-all`, {select}, headers)
export const shoppingcart_select = (data,headers) => post(`/shopping-cart/select`, data, headers)
export const shoppingcart_add_to_wish = (data, headers) => post(`/shopping-cart/move-to-wish-list`, data, headers) 
export const shoppingcart_remove_items = (data, headers) => post(`/shopping-cart/remove`, data, headers)
export const shoppingcart_change_qty = (data, headers) => post(`/shopping-cart/update-qty`, data, headers)
export const shoppingcart_change_item = (data, headers) => post(`/shopping-cart/change-item`, data, headers)
export const shoppingcart_change_address = (data, headers) => post(`/shopping-cart/update-shipping-detail`, data, headers)
export const shoppingcart_set_coupon = (couponId, headers) => post(`/shopping-cart/set-coupon`, {couponId}, headers)
export const shoppingcart_unset_coupon = headers => post(`/shopping-cart/unset-coupon`, {}, headers)
export const shoppingcart_apply_coupon_code = (code, headers) => post(`/shopping-cart/use-coupon-by-code`, {code}, headers)
export const shoppingcart_change_paymethod = (payMethod, headers) => post(`/shopping-cart/set-pay-method`, {payMethod}, headers)

export const fetchAddresses = headers => get(`${V}/customer/get-shipping-details`, {_: new Date().getTime()}, headers)
export const addCustomerAddress = (data, headers) => post('/customer/add/address2', data, headers)
export const editCustomerAddress = (data, headers) => post('/customer/update-address', data, headers)
export const setCustomerDefaultAddress = (id, headers) => get(`${V}/customer/${id}/set-default-shipping-detail`, {_: new Date().getTime()}, headers)
//checkout address
export const saveBillingAddress = (data, headers) => post(`/billing/set-shipping-detail`, data, headers)
export const setDefaultBillingAddress = (data, headers) => post(`/billing/set-default-shipping-detail`, data, headers)


//checkout
export const billingCheckout = headers => get(`/billing/checkout`, {_: new Date().getTime()}, headers)
export const billingGet = headers => get(`/billing/get`, {_: new Date().getTime()}, headers)
export const billingPlaceOrder = (isFastPayment, headers) => get(`/billing/place-order`, {isFastPayment, _: new Date().getTime()}, headers)
export const billingSetPayMethod = (payMethod, headers) => post(`/billing/set-pay-method`, { payMethod }, headers)
export const billingSetShippingMethod = (data, headers) => post(`/billing/set-shipping-method`, data, headers)
export const billingSetShippingInsurance = (data, headers) => post(`/billing/use-shipping-insurance`, data, headers)
export const billingSetCoupon = (couponId, headers) => post(`/billing/set-coupon`, {couponId}, headers)
export const billingSetPoints = (points, headers) => post(`/billing/update-points`, {points}, headers)
export const billingSetCash = (amount, headers) => post(`/billing/update-cash`, {amount}, headers)
export const billingUnSetCoupon = headers => post(`/billing/unset-coupon`, {}, headers)
export const billingUseCouponCode = (code, headers) => post(`/billing/use-coupon-by-code`, {code}, headers)


//place
//klarna
export const klarna_order_create_session = (params, headers) => get(`${V}/klarna/create-session-by-order`, params, headers)
export const klarna_order_get_params = (params, headers) => get(`${V}/klarna/get-order-details-by-order`, params, headers)
export const klarna_order_place_order = (params, headers) => post(`${V}/klarna/authorizations-by-order`, params, headers)

export const klarna_create_session = (params, headers) => get(`${V}/klarna/create-session-by-billing`, params, headers)
export const klarna_get_params = (params, headers) => get(`${V}/klarna/get-order-details-by-billing`, params, headers)

export const klarna_place_order = (params, headers) => post(`${V}/klarna/authorizations-order`, params, headers)

//paypal
export const paypalSetExpressCheckout = (params, headers) => post(`${V}/paypal2/ba/pay`, params, headers)
export const paypalDoExpressCheckout = (params, headers) => post(`${V}/paypal2/ba/do-express-checkout`, params, headers)
export const paypalSetBillingAgreement = (params, headers) => post(`${V}/paypal2/update-billing-agreement-id`, params, headers)
export const paypalOrderSetExpressCheckout = (params, headers) => post(`${V}/paypal2/ba/pay-by-order`, params, headers)

//zip
export const zip_pay_order = (data, headers) => post(`${V}/zip/checkout-by-order`, data, headers)

//deco
export const deco_opt_in = (id, headers) => post(`${V}/riskified/opt-in`, {id}, headers)

//pay by order
export const pay_by_order = (params, headers) => get(`${V}/pay/pay-by-order`, {...params, _: new Date().getTime()}, headers)

//凑单
export const filterCollectOrderProducts = (data, params, headers) => body(`${V}/product/${params.skip}/${params.limit}/make-up-order-list-filter`, data, params, headers)
export const filterProductsSorts = (headers) => get(`${V}/product/get-make-up-order-list-filter`,{_: new Date().getTime()}, headers)
export const filterOrderPromptMsg = (type, progressCouponId, headers) => get(`/shopping-cart/get-make-up-order-prompt-msg`,{_: new Date().getTime(), type, progressCouponId: type == 1 ? progressCouponId: ''}, headers)

export const deleteItemNew = (itemIds, headers) => post(`/shopping-cart/remove`,{itemIds}, headers)

//coupons
export const fetchCoupons = headers => get(`${V}/coupon/anon/get-coupon-selections`, {_: new Date().getTime()}, headers)

export const fetchCodeImage = (headers) =>{
	return get(`${V}/login-customer/anon/get-rand-code?timestamp=${new Date().getTime()}`, {}, {...headers, responseType:"blob"})
}

export const fetchPhoneRandcode = (data, headers) =>{
	return post(`${V}/customer/send-randcode`, data, headers)
}

export const fetchPhoneNumberLogin = (data, headers) =>{
	return post(`${V}/login-customer/login-by-phone-number`, data, headers)
}

export const fetchValidateSms = (data, headers) =>{
	return post(`${V}/customer/validate-sms-randcode`, data, headers)
}

export const fetchPhoneNumberChangePassword = (data, headers) =>{
	return post(`${V}/customer/change-pwd-by-phone-number`, data, headers);
}

export const fetchPhoneLoginByPassword = (data, headers) =>{
	return post(`${V}/login-customer/login-with-password-by-phone-number`, data, headers);
}

export const fetchChangePassword = (data, headers) =>{
	return post(`${V}/customer/anon/change-pwd`, data, headers)
}

export const fetchRegisterCountryData = (headers) =>{
	return get(`/context/get-register-country-data`, {}, headers)
}

export const fetchChangeCurrentCountry = (country, headers) =>{
	return post(`/context/set-country`, {country}, headers)
}

// 1、黑五首页弹窗获取优惠券接口
export const fetchBlackIndexCoupons = (headers) =>{
	return get(`/wallet/get-public-coupons-from-wallet`, { _: new Date().getTime() }, headers)
}

export const fetchOrderConfirmCoupons = (orderId, headers) =>{
	return post(`${V}/order/get-cash-back`, {orderId}, headers)
}

export const fetchStagingPrice = (params, headers) => {
	return get(`${V}/ebanx//get-installment`, params, headers)
}

export const fetchVerifyPhoneNumberCode = (params, headers) =>{
	return post(`${V}/customer/exists-customer-by-phone-number`, params, headers)
}

export const serverTick = headers => {
	return get(`/context/anon/tick`, {}, headers)
}

export const fetchUnpaidOrders = (params, headers) =>{
	return get(`${V}/order/${params.skip}/${params.limit}/get-unpaid-orders2`, params, headers);
}

/**
 * 
 * @param { orderId } params 
 * @param {*} headers 
 * @returns 
 */
export const fetch_unpaid_checkout = (params, headers) => get(`${V}/order/checkout?_=${new Date().getTime()}`, params, headers)

/**
 * 
 * @param { orderId, payMethod} params 
 * @param {*} headers 
 * @returns 
 */
export const set_unpaid_checout_method = (params, headers) => post(`${V}/order/update-pay-method`, params, headers)



export const fetchCustomer = headers => get(`${V}/customer/get`, {}, headers)

export const fetchCustomerFeed = (customerId, headers) => {
    return get(`${V}/customer/anon/${customerId}/feed-summary`, {}, headers);
}

export const fetchConfirmEmail = (email, headers) =>{
	return post(`${V}/customer/send-confirm-email`, { email }, headers);
}

export const fetchUnpaidOrderCount = (headers) =>{
	return get(`${V}/order/unpaid-orders-count2`, { _: new Date().getTime() }, headers)
}

export const fetchWishListProducts = (customerId, skip, headers) =>{
	return get(`${V}/wanna-list/anon/${customerId}/${skip}/20/all-products`, { _:new Date().getTime() }, headers)
}

export const fetchMePageYouLikes = (skip, headers) =>{
	return get(`/product/1/${skip}/20/show`, { _:new Date().getTime() }, headers);
}

export const fetchMeAddressList = (headers) =>{
	return get(`${V}/customer/get-shipping-details`, { _:new Date().getTime() }, headers);
}

export const setDefaultAddress = (id, headers) => {
	return get(`${V}/customer/${id}/set-default-shipping-detail`, { _:new Date().getTime() }, headers);
}

export const deleteAddress = (id, headers) => {
	return get(`/customer/delete-address`, { shippingDetailId: id }, headers);
}

export const updateAddress = (data, headers) => {
	return post(`/customer/update-address`, data, headers);
}

export const addAddress = (address, headers) => {
	return post(`${V}/customer/add-address`, address, headers);
}

export const getCreditCards = (headers) => {
	return get(`/quickpay-record/history`, {}, headers);
}

export const getMercadoCreditCards = (headers) => {
	return get(`/mercadopago/get-cards`, {}, headers);
}

export const deleteCreditCards = (cardId, headers) => {
	return get(`/quickpay-record/${cardId}/remove`, {}, headers);
}

export const deleteMercadoCreditCards = (cardId, headers) => {
	return get(`/mercadopago/remove-card?token=${cardId}`, {}, headers);
}

export const changePassword = (data, headers) => {
	// {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}
	return post(`${V}/customer/update-password`, data, headers)
}
export const fetchMessageToObject = (code, headers) =>{
	return get(`${V}/message/anon/get-object/${code}`, { _:new Date().getTime() }, headers)
}

export const fetchShopVip = (headers) =>{
	return get(`/context/show-vip`, { _:new Date().getTime() }, headers)
}

export const fetchVipData = (headers) =>{
	return get(`${V}/customer/vip-show`, { _:new Date().getTime() }, headers)
}

export const changeAccountEmail = (data, headers) => {
	return post(`${V}/customer/send-change-account-email`, data, headers)
}

export const changeEmail = (data, headers) => {
	return post(`${V}/customer/change-email`, data, headers)
}

export const postHeaderImage = (files, headers) => {
	return body(`${V}/customer/upload-icon`, files, {}, headers)
}

export const updateCustomerSave = (customer, headers) => {
    return body(`${V}/customer/save`,customer,{}, headers);
}

export const getActivityNotificationNoRead = (headers) => {
    return get('/notification/no-read-activity-notifications', {}, headers)
}

export const getPromoNotificationNoRead = (headers) => {
    return get('/notification/no-read-promotion2-notifications', {}, headers)
}

export const getOthersNotificationNoRead = (headers) => {
    return get('/notification/no-read-other2-notifications', {}, headers)
}

export const getTicketNotificationNoRead = (headers) => {
    return get('/notification/no-read-other2-ticket-notifications', {}, headers)
}

export const getNewsNotificationNoRead = (headers) => {
    return get('/notification/no-read-other2-news-notifications', {}, headers)
}

export const fetchGetPointsMessage = (headers) =>{
	return get(`/points-history-record/get-points`, { _:new Date().getTime() }, headers)
}

export const getOrdersNotificationNoRead = (headers) => {
    return get('/notification/no-read-other2-orders-notifications', {}, headers)
}

export const getActivityNotification = (skip = 0, headers) => {
    return get('/notification/' + skip + '/20/get-activity-notifications', {}, headers)
}

export const getPromoNotification = (skip = 0, headers) => {
    return get('/notification/' + skip + '/20/get-promotion2-notifications', {}, headers)
}

export const getOthersNotification = (skip = 0, headers) => {
    return get('/notification/' + skip + '/20/get-other2-notifications', {}, headers)
}

export const getTicketNotification = (skip = 0, headers) => {
    return get('/notification/' + skip + '/20/get-other2-ticket-notifications', {}, headers)
}

export const getNewsNotification = (skip = 0, headers) => {
    return get('/notification/'+skip+'/20/get-other2-news-notifications', {}, headers)
}

export const getOrdersNewNotification = (skip = 0, headers) => {
    return get('/notification/'+skip+'/20/get-other2-orders-notifications', {}, headers)
}

export const getCoupons = (headers) => {
    return get(`${V}/coupon/get-coupon-selections`, {}, headers)
}

export const getCouponByCouponMouldId = (params, headers) => {
    return get(`${V}/coupon/get`, params, headers)
}

export const getWalletCoupons = (headers) => {
    return get(`/wallet/get-coupons`, {}, headers)
}

export const getShowRedeemCoupon = (headers) => {
	return get(`/context/show-coupon-points-rule-list`, {}, headers)
}

// 参数：status（1：已使用，2：已过期）
export const getExpiredCoupons = (skip, status, headers) => {
    return get(`${V}/coupon/${skip}/20/history`,{status}, headers)
}

export const getCashWallet = (headers) =>{
    return get(`/wallet/get-cash`, {}, headers)
}

export const getCashHistoryRecord = (skip, limit, path, headers) =>{
    return get(`/cash-history-record/${skip}/${limit}/${path}`, {}, headers)
}

// 获取积分商城优惠券列表
export const getPointsCouponList = (headers) => {
    return get(`/points-exchange-coupon/get-list?_=${new Date().getTime()}`,{},headers)
}

// 积分商城优惠券兑换
export const pointsCouponExchange = (id, headers) => {
    return post('/points-exchange-coupon/exchange',id,headers)
}

export const fetchCancelReturnOrder = (id, headers) =>{
	return post(`/return-order/cancel`, { id }, headers);
}



























export const fetchRedeemFreeShipping = (path, headers) =>{
	return get(path, { _:new Date().getTime() }, headers)
}

export const fetchShowRedeemCoupons = (headers) =>{
	return get(`/context/show-coupon-points-rule-list`, {}, headers);
}

// 获取积分页面产品列表的接口
// groupNo  0
// menuId   APP0010
// product/anon/{skip}/{limit}/{groupNo}/{menuId}/list-by-menu
export const fetchPointsProductList = (params, headers) => {
    return get(`${V}/product/anon/${params.skip}/${params.limit}/${params.type}/${params.code}/list-by-menu`, params, headers);
}

export const fetchPointsHistoryAll = (skip, headers) =>{
	return get(`/points-history-record/${skip}/20/get-all`,{ _:new Date().getTime() }, headers)
}

export const fetchPointsHistoryRecived = (skip, headers) =>{
	return get(`/points-history-record/${skip}/20/get-recived`,{ _:new Date().getTime() }, headers)
}

export const fetchPointsHistoryUsed = (skip, headers) =>{
	return get(`/points-history-record/${skip}/20/get-used`,{ _:new Date().getTime() }, headers)
}

export const fetchPointsHistoryExpired = (skip, headers) =>{
	return get(`/points-history-record/${skip}/20/get-expired`,{ _:new Date().getTime() }, headers)
}

export const surveySave = (params, headers) => {
    return post('/questionnaire-answer/anon/save',params,headers);
}

export const surveyGet = (headers) => {
    return get('/questionnaire-answer/anon/get',{},headers)
}

export const makeSuggestion = (files, headers) => {
    return body('/ticket/add', files, headers);
}

export const fetchSendShareEmails = (params, headers) =>{
	return post(`${V}/customer/send-share-via-emails`, params, headers);
}

export const fetchOrderListAll = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-orders2`, {}, headers);
}

export const fetchOrderListUnpaid = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-unpaid-orders2`, {}, headers);
}

export const fetchOrderListProcessing = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-proccessing-orders`, {}, headers);
}

export const fetchOrderListShipped = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-shipped-orders`, {}, headers);
}

export const fetchOrderListConfirmed = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-receipt-orders`, {}, headers);
}

export const fetchOrderListCanceled = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-canceled-orders`, {}, headers);
}

export const fetchOrderListPaid = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-paid-orders`, {}, headers);
}

export const fetchReturnOrders = (skip, headers) =>{
	return get(`/return-order/${skip}/20/get-return-orders`, {skip, _:new Date().getTime()}, headers);
}

export const fetchGetReturnOrderDetail = (id, headers) =>{
	return get(`/return-order/get`, { id, _:new Date().getTime() }, headers);
}

export const fetchOrderDetail = (params, headers) =>{
	return post(`${V}/order/get-order-details`, params, headers);
}

export const fetchOrderRecommend = (params, headers) =>{
	return get(`${V}/order/anon/${params.skip}/20/get-same-category-products`, {orderId: params.orderId}, headers);
}

export const fetchMssageByTypeCode = (type, headers) =>{
	return get(`/dictionary/anon/get`, { typeCode: type }, headers);
}

export const fetchCancelOrder = (params, headers) =>{
	return post(`${V}/order/cancel`, params, headers);
}

export const fetchNoCommentOrder = (headers) =>{
	return post(`${V}/order/exists-order-without-comment`, {}, headers);
};

export const fetchOrderReviewByOrderId = (params, headers) =>{
	return post(`/product-comment/get-product-comments-by-order-id`, params, headers);
}

export const fetchOrderReviewPointsRule = (headers) =>{
	return get(`/context/anon/get-product-comment-config`, { _:new Date().getTime() }, headers);
}

export const fetchUploadImage = (imageFile, headers) =>{
	return body(`/context/upload`, imageFile, {}, headers);
}

export const fetchSaveComments = (comments, headers) =>{
	return body(`/product-comment/save`, comments, {}, headers);
}

export const fetchConfirmOrder = (orderId, headers) =>{
	return post(`${V}/order/${orderId}/receipt`, { orderId }, headers);
}

export const fetchNotCommentOrders = (limit, headers) =>{
	return post(`${V}/order/${limit}/get-not-comment-orders`, { limit }, headers);
}

export const fetchGetOrderTrack = (orderId, headers) =>{
	return get(`${V}/tracking/anon/get-by-order-id`, { orderId, _:new Date().getTime() }, headers);
}

export const fetchGetTrackById = ({ type, id }, headers) =>{
	return get(`${V}/tracking/get-by-id`, { type, id,_:new Date().getTime() }, headers);
}

export const fetchLogisticsCompanies = (headers) =>{
	return get(`/context/anon/get-logistics-companies`, { _:new Date().getTime() }, headers);
}

export const fetchAddReturnLogistics = (logistics, headers) =>{
	return body(`/return-order/add-return-logistics`, logistics, {}, headers);
}

export const fetchEditReturnLogistics = (logistics, headers) =>{
	return body(`/return-order/edit-return-logistics`, logistics, {}, headers);
}

export const fetchHistoryOrder = (skip, headers) =>{
	return get(`${V}/order/${skip}/20/get-order-history`, { skip }, headers);
}

export const fetchTicketList = (skip, limit, headers) => {
	return get(`/ticket/${skip}/${limit}/show`, {state: 9}, headers);
}

export const fetchTicketDetailByTicketId = (id, headers) => {
	return get(`/ticket/${id}/get`, {}, headers);
}

export const fetchTicketDetailByOrderId = (id, headers) => {
	return get(`/ticket/${id}/order/get`, {}, headers);
}

export const fetchTicketDetailByUrlCode = (code, headers) => {
	return get(`/ticket/${code}/get-by-order`, {}, headers);
}

export const fetchTicketQuestionTypeChange = (type, headers) => {
	return post(`/ticket/order/add`,type, headers)
}

export const fetchTicketRateSend = (rate, headers) => {
	return post(`/ticket/rate-service`, rate, headers)
}

export const fetchTicketImageSend = (ticket, headers) => {
	return body(`/ticket/order/add`, ticket, '',headers)
}

export const fetchTicketSend = (ticket, headers) => {
	return post(`/ticket/order/add`, ticket, headers)
}

// 获取support所有父节点数据
export const fetchTicketQuestions = (headers) => {
	return get(`/shop/support/get-parent-tree`, { _:new Date().getTime() }, headers);
}

// 获取support所有数据
export const fetchTicketQuestionsTree = (headers) => {
	return get(`/shop/support/get-tree`, { _:new Date().getTime() }, headers);
}

// 根据id查support问题详情 没用
export const fetchTicketQuestionDetail = (id, headers) => {
	return get(`/shop/support/get/${id}`, { _:new Date().getTime() }, headers);
}

// 暂时没有support 问题点赞这个接口
export const fetchTicketQuestionSubscribe = (type, headers) => {
	return get(`/context/anon/get-product-comment-config`, { _:new Date().getTime() }, headers);
}

export const fetchUpdateShippingAddress = (address, headers) =>{
	return post(`${V}/order/anon/shipping-detail-update`, address, headers);
}

export const fetchUpdateBilingAddress = (address, headers) =>{
	return post(`${V}/order/anon/update-billing-address`, address, headers);
}
export const update_order_address = (address, headers) => post(`${V}/order/anon/shipping-detail-update`, address, headers)

export const getByProductIds = (data, headers) =>{
	return post(`${V}/product/anon/get-by-ids`, data, headers);
}

export const fetch_transaction = (transactionId, headers) => get(`${V}/order/anon/order-confim`, { transactionId }, headers)

export const changeCommunicationEmail = (params, headers) =>{
	return post(`/order/anon/change-communication-email`, params, headers);
}

export const fetchOrderConfirmIsNew = (buyerId, headers) =>{
	return get(`${V}/customer/anon/${buyerId}/is-new`, {}, headers);
}
export const get_paypal_user_token = headers => get(`${V}/paypal2/anon/get-user-id-token`, {}, headers)

export const fetchSharedCouponById = (params, headers) => get(`/wallet/get-coupon`, params,headers)
export const fetchReviewAlert = ({email, name, transactionId }, headers) => {
	return get(`${V}/order/review-alert`,{email, name, transactionId, _:new Date().getTime()}, headers)
}

export const clientcall = (transationId, headers) => get(`${V}/pay/client-call/${transationId}/true`, {_: new Date().getTime()}, headers)
export const fetchAddOnItemsFilter = (type = 2,headers) => {
	return get(`${V}/product/get-make-up-order-list-filter`, {type}, headers)
}

export const updateAddOnItem = (variantId, headers) => {
	return post(`${V}/shopping-cart/update-redeem-product`, variantId, headers)
}

export const removeAddOnItem = (productId, headers) => {
	return post(`${V}/shopping-cart/remove-redeem-product`, {productId}, headers)
}

export const fetchFixedLuckDraw = (headers) =>{
	return post(`/context/get-floating-mark`, {}, headers);
}

export const fetchLastestPrize = (headers) =>{
	return post(`/luck-draw/get-lastest-win-prize`, {}, headers);
}
export const fetchTrendingList = (data, params, headers) => {
	return body(`${V}/collection/anon/${params.skip}/${params.limit}/filter-trending-selling`, data, params, headers)
}
export const fetchTrendingSorters = (params, headers) => {
	return get(`/context/anon/get-collection-sorters`, params, headers)
}
export const fetchTrendingFilter = (params, headers) => {
	return get(`${V}/filter/anon/APP0010/get-by-menu-id`, params, headers)
}

export const fetchAddCustomerSize = (params, headers) =>{
	return post(`${V}/customer-product-size/add`, params, headers);
}

export const fetchSearchSizeSimilar = (params, headers) =>{
	return post(`${V}/product-vision/find-similar`, params, headers);
}

export const fetchProductExcludeSize = (productId, headers) =>{
	return get(`${V}/product/size-exclude-by-product-with-measurement/${productId}`, {_: new Date().getTime()}, headers);
}
export const openStripeGoogleOrder = (data, headers) => post(`${V}/stripe-google-pay/open-order`, data, headers)
export const stripeGoogleCallBack = (data, headers) => body(`${V}/stripe-google-pay/pay-result`, data, {payMethod: data?.payMethod}, headers)

export const fetchSelectRecommend = (skip, limit, productId, headers) => {
	return post(`${V}/product/${skip}/${limit}/${productId}/get-lastest-unselected-shopping-cart-product-recommendation`,{onlyProducts:true},headers)
}

export const fetchHasLuckDraw = (headers) => {
	return get(`/luck-draw/has-luck-draw`, {}, headers)
}
export const init_safecharge_apple_pay = (params, headers) => get(`${V}/safe-charge-apple-pay/init-order`, params, headers)

export const open_safecharge_apple_pay = (params, headers) => get(`${V}/safe-charge-apple-pay/open-order`, params, headers)

export const set_safecharge_apple_result = (data, headers) => post(`${V}/safe-charge-apple-pay/set-payment-status`, data, headers)

export const init_safecharge_google_pay = (params, headers) => get(`${V}/safe-charge-google-pay/init-order`, params, headers)

export const open_safecharge_google_pay = (params, headers) => get(`${V}/safe-charge-google-pay/open-order`, params, headers)

export const set_safecharge_google_result = (data, headers) => post(`${V}/safe-charge-google-pay/set-payment-status`, data, headers)


export const fetchCartIconInfo = (headers) => {
	return get(`/shopping-cart/get-overview`, {_:new Date().getTime()}, headers)
}

export const afterpay_cash_app_checkout = (params, headers) => get(`${V}/afterpay/checkout`, {...params, _:new Date().getTime()}, headers)
export const afterpay_cash_app_callback = (data, headers) => post(`${V}/afterpay/capture-callback`, data, headers)

export const fetch_parent_categories_by_id = (id, headers) => get(`${V}/category/anon/get-all-parent-categorys`, {id}, headers)

export const fetchWishListCount = (filter, headers) => {
	return body('/wishlist/count', filter, null, headers)
}

export const fetchWishListFilterResult = (filter, headers) => {
	return body(`/wishlist/filter`, filter, null, headers)
}

export const getWishListFilter = (headers) => {
	return get(`/wishlist/get-filter`, null, headers)
}

export const getWishListTotalCount = (headers) => {
	return get(`/wishlist/get-total-count`, {_:new Date().getTime()}, headers)
}

export const removeWishListProduct = (productIds, headers) => {
	return post(`/wishlist/remove`, {productIds, _:new Date().getTime()}, headers)
}

export const saveWishListProduct = (productId, headers) => {
	return post(`/wishlist/save`, {productId, _:new Date().getTime()}, headers)
}

export const fetchAdvertisingDiscount = (headers) =>{
	return get(`/context/get-advertising-discount-info`, { _:new Date().getTime() }, headers);
}

export const fetchLoginMessageImg = (headers) => {
	return get(`${V}/login-customer/get-login-page-bonus-banner`, { _:new Date().getTime() }, headers)
}
export const sendToSensors = (data, headers) => body('/sensors-analytics/track', data, null, headers)

export const fetchWep2AppApi = (headers) => get(`${V}/download-app-gifs/add`, { _:new Date().getTime() }, headers)

export const sendWep2AppType = (type, headers) => get(`${V}/download-app-gifs/mark`, { type, _: new Date().getTime() }, headers)

export const fetchPinterestAlert = (headers) => get(`${V}/coupon/get-pinterest-coupon`, {_: new Date().getTime()}, headers)