import React from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import ToolIcon from './components/tool-icon'
import Badge from '../../../components/badge'
import { Iconfont } from '../../../../components/icon/iconfont'

import MinCart from './components/min-cart'
import Languages from './components/language'
import Currencies from './components/currency'
import Support from './components/support'
import Account from './components/account'
import TipSwiper from './components/tip-swiper'
import { useSelector } from 'react-redux'


export default props => {
    useStyles(classes)

    const global = useSelector(state => state.global)
    const { countNotification, countCart, cartTrigger } = global

    return <div className={ classes.Tools }>

        <TipSwiper/>


        <ToolIcon menuStyle={{left: -70}} downMenu={<Account/>}>
            <Badge count={countNotification}>
                <Iconfont onClick={e => {window.location.href = '/me/m'}} style={{fontSize: 22}}>&#xe657;</Iconfont>
            </Badge>
        </ToolIcon>

        <ToolIcon menuStyle={
            {
                left: -200
            }
        } trigger={cartTrigger} downMenu={
            <MinCart/>
        }>
            <Badge count={countCart}>
                <Iconfont onClick={e => {window.location.href = '/cart'}}>&#xe65c;</Iconfont>
            </Badge>
        </ToolIcon>

        <ToolIcon menuStyle={{left: -200}} downMenu={<Support/>}>
            <Iconfont>&#xe770;</Iconfont>
        </ToolIcon>

        <ToolIcon menuStyle={{left: -350}} downMenu={<Currencies/>}>
            <Iconfont>&#xe76e;</Iconfont>
        </ToolIcon>

        <ToolIcon menuStyle={{left: -50}} downMenu={
            <Languages/>
        }>
            <Iconfont>&#xe76f;</Iconfont>
        </ToolIcon>
    </div>
}