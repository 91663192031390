export default data => {
    if(data.type === 'coupon'){
        const payload = data.payload
        return {
            origin: payload,
            type: 3,
            bodycon: payload?.icon,
            title: payload?.title,
            body: payload?.content,
            buttons: payload?.buttons,
            deepLink: payload?.buttons?.[0]?.deepLink,
        }
    }
    return 'next'
}