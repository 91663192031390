import React, { useCallback, useEffect, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Iconfont } from '../../../../components/icon/iconfont'
import { action_fetch_download_message } from '../../../../../store/actions/global'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'

const AsideButton = props => {
    useStyles(classes)
    const { render, children, clickHandle } = props
    const [show, setShow] = useState(false)

    const handleOver = e => {
        setShow(true)
    }

    const handleLeave = e => {
        setShow(false)
    }

    return <div className={classes.AsideIcon} onMouseOver={handleOver} onMouseLeave={handleLeave}>
        <span className={classes.Icon} onClick={clickHandle}>
            {children}
        </span>
        {
            render && show && <div className={classes.Block}>
                <div className={classes.Bd}>
                    {render()}
                </div>
            </div>
        }
    </div>
}

const Download = props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const {config, downloadMessage} = global

    useEffect(async () => {

        if(!downloadMessage){
            dispatch(action_fetch_download_message())
        }

    }, [])

    return <div className={classes.Download}>
        <div className={classes.Hd}>
            APP
        </div>
        <div className={classes.Bd}>
            <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{__html: downloadMessage?.title}}></span>
            </div>

            {
                downloadMessage?.discount && <div className={classes.Discount}>
                    <span><FormattedMessage id="code" defaultMessage="code" />: { downloadMessage?.discount }</span>
                </div>
            }
            
            <div className={classes.DownLoadArea}>
                <div className={classes.QRCode}>
                    <img src={config?.app?.qrcode}/>
                </div>
                <div className={classes.DownLoadBtns}>
                    <a className={classes.DownLoadApp} href={`https://play.google.com/store/apps/details?id=${config?.app.android.id}`}>
                        <Iconfont className={classes.App}>&#xe64d;</Iconfont>
                        <span className={classes.Label}>Android</span>
                    </a>

                    <a className={classes.DownLoadApp} href={`https://itunes.apple.com/us/app/chic-me-shopping-sharing-saving/id${config?.app.ios.id}?l=zh&ls=1&mt=8`}>
                        <Iconfont className={classes.App}>&#xe64e;</Iconfont>
                        <span className={classes.Label}>Apple</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
}

const WhatsApp = props =>{
    const { data } = props;

    return <React.Fragment>
        {
            (data?.whatsAppId1 || data?.whatsAppId3 || data?.whatsAppId4) && <div className={classes.WhatsApp}>
                {
                    data?.whatsAppId1 && <a href={`https://api.whatsapp.com/send?phone=${data.whatsAppId1}`} className={classes.WhatsItem}>
                        <Iconfont className={classes.WhatsIcon}>&#xe7c4;</Iconfont>
            
                        <div className={classes.WhatsFont}>
                            <p><FormattedMessage id="pre_wholesale" defaultMessage="PRE-Wholesale" /></p>
                            <p>+{data.whatsAppId1}</p>
                        </div>
                    </a>
                }
                
                {
                    data?.whatsAppId3 && <a href={`https://api.whatsapp.com/send?phone=${data.whatsAppId3}`} className={classes.WhatsItem}>
                        <Iconfont className={classes.WhatsIcon}>&#xe7c4;</Iconfont>
            
                        <div className={classes.WhatsFont}>
                            <p><FormattedMessage id="after_wholesale" defaultMessage="AFTER-Wholesale" /></p>
                            <p>+{data.whatsAppId3}</p>
                        </div>
                    </a>
                }

                {
                    data?.whatsAppId4 && <a href={`https://api.whatsapp.com/send?phone=${data.whatsAppId4}`} className={classes.WhatsItem}>
                        <Iconfont className={classes.WhatsIcon}>&#xe7c4;</Iconfont>
            
                        <div className={classes.WhatsFont}>
                            <p><FormattedMessage id="drop_shipping" defaultMessage="Drop Shipping" /></p>
                            <p>+{data.whatsAppId4}</p>
                        </div>
                    </a>
                }
            </div>
        }
    </React.Fragment>
}

export default props => {
    useStyles(classes)

    const [show, setShow] = useState(false);
    const global = useSelector(state=>state.global);
    const { config } = global;

    useEffect(() => {

        window.addEventListener('scroll', scrollHandle)

        return () => {
            window.removeEventListener('scroll', scrollHandle)
        }
    }, [])

    const scrollHandle = useCallback(event => {
        const scrollTop = window.scrollY
        if(scrollTop > 400){
            setShow(true)
        }else{
            setShow(false)
        }
    }, [])




    const scrollToTopHandle = useCallback(e => {
        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
         })
    }, [])

    const homeHandle = useCallback(e => {
        window.location.href = '/'
    }, [])

    const downloadHandle = useCallback(e => {
        e.preventDefault()
        // window.location.href = '/i/download'
    }, [])

    const surveyHandle = useCallback(e => {
        window.location.href = '/me/m/survey'
    }, []);

    const messengerHandle = useCallback((pageId) =>{
        window.location.href = `https://www.messenger.com/t/${pageId}`;
    }, []);

    const whatsAppHandle = useCallback((e) =>{
        e.preventDefault();
    }, []);

    return <div className={`${classes.AsideTools} ${show ? classes.Active: ''}`}>
        {
            config?.indexContactService ? <React.Fragment>
                <AsideButton clickHandle={() =>messengerHandle(config?.facebook?.pageId)}>
                    <Iconfont className={classes.IconFont}>&#xe66a;</Iconfont>
                </AsideButton>

                <AsideButton clickHandle={whatsAppHandle} render={() => <WhatsApp data={config?.policy} />}>
                    <Iconfont className={classes.IconFont}>&#xe7c4;</Iconfont>
                </AsideButton>
            </React.Fragment> : <React.Fragment>
                <AsideButton clickHandle={surveyHandle} render={() => <div style={{padding: '5px 10px'}}><FormattedMessage defaultMessage="survey" id="survey"/></div>}>
                    <Iconfont className={classes.IconFont}>&#xe7ce;</Iconfont>
                </AsideButton>
            </React.Fragment>
        }
        
        <AsideButton clickHandle={downloadHandle} render={() => <Download/>}>
            <Iconfont className={classes.IconFont}>&#xe7d0;</Iconfont>
        </AsideButton>
        <AsideButton clickHandle={homeHandle}>
            <Iconfont className={classes.IconFont}>&#xe7d1;</Iconfont>
        </AsideButton>
        <AsideButton clickHandle={() => scrollToTopHandle()}>
            <Iconfont style={{
                fontSize: 12,
                transform: 'scale(0.7)',
                display: 'inline-block'
            }} className={classes.IconFont}>&#xe75c;</Iconfont>
        </AsideButton>
    </div>
}