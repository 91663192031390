import React, { useCallback, useState } from 'react'
import Result from './result'
import Welcome from './welcome'
import ErrorAlert from './error'
import { fetchSearchImage } from '../../../../../../../api'

export default props => {
    const [page, setPage] = useState(props.page || 0)
    const [result, setResult] = useState(props.visualResult)
    const [image, setImage] = useState(props.image)
    const [uploading, setUploading] = useState(false)
    const [showError, setShowError] = useState(false)

    const handleUpload = useCallback(async e => {
        setUploading(true)

        const { files } = e.target
        const file = files?.[0]
        const imageUrl = file ? (window.navigator.userAgent.indexOf("Chrome") >= 1 || window.navigator.userAgent.indexOf("Safari") >= 1 ? window.webkitURL.createObjectURL(file) : window.URL.createObjectURL(file)): null

        const data = new FormData()
        data.append("image", file)
        const response = await fetchSearchImage(data)

        if(response.code === 200){
            if(response.result?.length > 0){
                setResult(response.result)
                setPage(1)
                setImage(imageUrl)
            }else{
                setShowError(true)
            }
        }else{
            response?.result && alert(response.result);
        }

        setUploading(false)
    }, [])



    return <React.Fragment>
        {
            page === 0 && <Welcome {...props} onUpload={handleUpload} uploading={uploading}/>
        }
        {
            page === 1 && <Result {...props} image={image} result={result} onUpload={handleUpload}/>
        }
        {
            showError && <ErrorAlert handleNo={() => props?.onClose()} handleYes={() => {setShowError(false)}}/>
        }
    </React.Fragment>
}