import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import useStyles from 'isomorphic-style-loader/useStyles'
// api 及 redux
import { fetchGetLotteryPrizes } from '../../../../../api';
// 样式 及 组件
import classes from './style.module.scss'
import DisplayTransition from '../../../components/transitions/display-transition'
import Mask from '../../../components/mask'
// import TurnTable from './components/turntable'
// import LotteryResult from './components/lottery-result';
// import Jiugongge from './components/jiugongge';
import Fourgongge from './components/fourgongge-summer-23'
import Lottery from './components/lottery-black-friday-23';
import { useSelector } from 'react-redux';



const Raffle = props => {
    useStyles(classes)
    // const [result, setResult] = useState(null)
    // const [showResultMask, setShowResultMask] = useState(false)
    // const [prizes, setPrizes] = useState(null)
    const { show } = props
    const global = useSelector(state => state.global)
    const { config } = global || {}
    const { showLuckyWheel } = config || {}

    useEffect(() => {
        // 请求奖品列表
        // const message = await fetchGetLotteryPrizes().then(data => data.result).catch(e => {
        //     console.error(e)
        //     return null
        // })
        // setPrizes(message)
    }, [show])

    // useEffect(() => {
    //     // 监听转盘返回的结果
    //     if(result){
    //         setShowResultMask(true)
    //     } else {
    //         setShowResultMask(false)
    //     }
    // }, [result])

    // const closeModal = () => {
    //     setResult(null)
    // }

    return <div>
        {/* 转盘抽奖 */}
        {/* <TurnTable 
            prizes={prizes} 
            onResult={(res) => setResult(res)}
            onClose={props.onClose}
            /> */}
        {
            showLuckyWheel ?
                <Lottery onClose={props.onClose} showResult={show && show !== true && show} /> :
                <Fourgongge onClose={props.onClose} showResult={show && show !== true && show} />
        }

        {/* 抽中奖品弹窗 */}
        {/* {
            showResultMask &&
            <LotteryResult data={result} onClose={() => closeModal()}/>
        } */}
    </div>
}



const Wrapped = props => {
    useStyles(classes)

    return <React.Fragment>
        <DisplayTransition in={props.show} className={`${classes.RaffleContainer} ${props?.show?.prize && classes.noOp}`}>
            <div>
                <Raffle {...props} />
            </div>
        </DisplayTransition>
        {props.show && <Mask style={{ backgroundColor: 'rgba(0,0,0,0.7)' }} onClick={() => {
            if (window.GeekoSensors) {
                window.GeekoSensors.Track("ClickLottery", {
                    button: 'close'
                })
            }
            props.onClose()
        }} />}
    </React.Fragment>
}


export default props => {

    if (typeof window === 'undefined') {
        return null
    }

    return ReactDOM.createPortal(
        <Wrapped {...props} />,
        document.getElementById('root')
    )
}