import React, { useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { FormattedMessage } from "react-intl";
import ReactDOM from 'react-dom'

import Mask from '../../../../../components/mask'
import CountDown from "../../../../../../components/count-down";
import { Iconfont } from "../../../../../../components/icon/iconfont";
import { IMAGE_GEEKO_LTD } from '../../../../../../../constants'



const AfterPlayMask = (props) => {
    useStyles(classes)

    const {data, onClose} = props
    const [hideCountDown, setHideCountDown] = useState(false)

    const cashCoupon = data?.cashCoupon

    // console.log(data)
    return <>
        <div className={classes.LotteryAlertModal}>
            <Iconfont onClick={onClose} className={classes.CloseModal}>&#xe69a;</Iconfont>

            {
                cashCoupon ? 
                <React.Fragment>
                    {
                        data?.font1 && <div className={classes.Font1} style={{ ...data?.font1?.style }}>
                            {data?.font1?.label}
                        </div>
                    }
                     {
                        data?.imageUrl && <div className={classes.LotteryAlertModalImage}>
                            <img 
                                src={`${IMAGE_GEEKO_LTD}${data?.imageUrl}`} 
                                alt="luckImage" 
                                // style={{width:`${data?.imageWidth?data.imageWidth:'60%'}`}} 
                            />
                        </div>
                    }
                </React.Fragment>:
                <React.Fragment>
                    {
                        data?.imageUrl && <div className={classes.LotteryAlertModalImage}>
                            <img 
                                src={`${IMAGE_GEEKO_LTD}${data?.imageUrl}`} 
                                alt="luckImage" 
                                // style={{width:`${data?.imageWidth?data.imageWidth:'60%'}`}} 
                            />
                        </div>
                    }
                </React.Fragment>
            }   

            <div style={{...data?.customerMadeStyle, display:'flex', alignItems:'center', flexDirection:'column', padding: '0 10px'}} >
                {
                    !cashCoupon && data?.font1 && data?.font1?.label && <div className={classes.Font1} style={{ ...data?.font1?.style }}>
                        {data?.font1?.label}
                    </div>
                }

                {
                    data?.font2 && data?.font2?.label && <div className={classes.Font1} style={{ ...data?.font2?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.font2?.label}}></span>
                    </div>
                }

                {
                    data?.font3 && data?.font3?.label && <div className={classes.Font1} style={{ ...data?.font3?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.font3?.label}}></span>
                    </div>
                }

                {
                    data?.font4 && data?.font4?.label && <div className={classes.Font1} style={{ ...data?.font4?.style }}>
                        <span dangerouslySetInnerHTML={{__html:data?.font4?.label}}></span>
                    </div>
                }

                {
                    (data?.showTimes > 0 && !hideCountDown) && <div className={classes.ExpireIn}>
                        <p><FormattedMessage id="expire_in" defaultMessage="Expire In" />:</p>
                        <CountDown 
                            offset={data?.showTimes} 
                            showLabel
                            showHour 
                            numberStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 2px 2px 4px',fontSize: '1vw',fontFamily: 'Roboto-Medium'}} 
                            labelStyle={{backgroundColor: '#222',color:'#ffffff',padding:'2px 4px 2px 0px',textTransform: 'uppercase',fontSize: '1vw',fontFamily: 'Roboto-Medium'}} 
                            dotStyle={{color:'#222',margin:'0 3px'}} 
                            callback={() => setHideCountDown(true)}
                        />
                    </div>
                }

                <div className={classes.ButtonContainer}>
                    {
                        data?.btn1 && <button onClick={data?.btn1?.callback} className={classes.Btn1}>{data?.btn1?.label}</button>
                    }

                    {
                        data?.btn2 && <button onClick={data?.btn2?.callback} className={classes.Btn2}>{data?.btn2?.label}</button>
                    }
                </div>
            </div>

        </div>
    </>
}

export default props => {
    
    return <>
    {
        ReactDOM.createPortal(<React.Fragment>
            <AfterPlayMask {...props}/>
            <Mask onClick={props.onClose} style={{zIndex:12}}/>
        </React.Fragment>,
        typeof document !== 'undefined' ? document.getElementById('root') : null)
    }
    
    </>
}