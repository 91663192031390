import Facebook from './facebook'
import Ga from './ga'
import GTag from './gtag'
import Sensors from './sensors'
import Tiktok from './tiktok'
// import Aws from './aws'
import RtbHouse from './rtbhouse'
import Mkq from './mkq'
import Pin from './pintrest'
import Twitter from './twitter'
import Bing from './bing'
import Yahoo from './yahoo'
import Paypal from './paypal'

export default {
    addToCart: data => {
        try{
            Facebook.addToCart(data)
            Ga.addToCart(data)
            GTag.addToCart(data)
            Sensors.addToCart(data)
            Tiktok.addToCart(data)
            // Aws.addToCart(data)
            RtbHouse.addToCart(data)
            Mkq.addToCart(data)
            Pin.addToCart(data)
            Twitter.addToCart(data)
            Bing.addToCart(data)
            Yahoo.addToCart(data)
        }catch(e){
            console.warn(e)
        }
    },
    addToWishList: data => {
        try{
            Facebook.addToWishList(data)
            Ga.addToWishList(data)
            GTag.addToWishList(data)
            Sensors.addToWishList(data)
            Tiktok.addToWishList(data)
            // Aws.addToWishList(data)
            RtbHouse.addToWishList(data)
            Mkq.addToWishList(data)
            Pin.addToWishList(data)
            Twitter.addToWishList(data)
            Bing.addToWishList(data)
            Yahoo.addToWishList(data)
        }catch(e){
            console.warn(e)
        }
    },
    pageView: data => {
        try{
            Facebook.pageView(data)
            Ga.pageView(data)
            GTag.pageView(data)
            Sensors.pageView(data)
            Tiktok.pageView(data)
            // Aws.pageView(data)
            RtbHouse.pageView(data)
            Mkq.pageView(data)
            Pin.pageView(data)
            Twitter.pageView(data)
            Bing.pageView(data)
            Yahoo.pageView(data)
        }catch(e){
            alert(e)
            console.warn(e)
        }
    },
    purchase: (data, mul) => {
        //facebook 购买事件不用去重
        try{
            Facebook.purchase(data)
            if(!mul){
                Ga.purchase(data)
                GTag.purchase(data)
                Sensors.purchase(data)
                Tiktok.purchase(data)
                // Aws.purchase(data)
                RtbHouse.purchase(data)
                Mkq.purchase(data)
                Pin.purchase(data)
                Twitter.purchase(data)
                Bing.purchase(data)
                Yahoo.purchase(data)
                Paypal.purchase(data)
            }
        }catch(e){
            console.warn(e)
        }
    }
}