const addToCart = data => {
    try {

        if (!window.GeekoSensors) return

        const {
            productId,
            quantity,
            product_type,
            is_success,
            variantSize,
            usdPrice,
            sensors,
            isGift,
            size
        } = data


        window.GeekoSensors?.Track('AddToCartDetail', {
            product_id: productId,
            product_qty: quantity,
            product_type: product_type,
            product_position: sensors?.product_position,
            ali_request_id: sensors?.aliRequestId,
            geeko_request_id: sensors?.geekoRequsestId,
            geeko_experiment_id: sensors?.geekoExperimentId,
            ali_experiment_id: sensors?.aliExperimentId,
            data_source: sensors?.dataSource,
            data_layer_name: sensors?.layerName,
            data_tracking_records: sensors?.trackingRecords,
            resourcepage_title: sensors?.resourcepage_title,
            resource_position: sensors?.resource_position,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            filter: sensors?.filter
        })


        window.GeekoSensors?.Track('Addtocart', {
            product_id: productId,
            product_qty: quantity,
            product_type: product_type,
            is_success,
            size: variantSize,
            price: usdPrice,
            product_position: sensors?.product_position,
            ali_request_id: sensors?.aliRequestId,
            geeko_request_id: sensors?.geekoRequsestId,
            geeko_experiment_id: sensors?.geekoExperimentId,
            ali_experiment_id: sensors?.aliExperimentId,
            data_source: sensors?.dataSource,
            data_layer_name: sensors?.layerName,
            data_tracking_records: sensors?.trackingRecords,
            is_gift:isGift,
            clog_id: sensors?.clogId,
            resourcepage_title: sensors?.resourcepage_title,
            resource_position: sensors?.resource_position,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            filter: sensors?.filter,
            price_tag: sensors?.price_tag,
            page_title: sensors?.page_title,
        })



    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {

        if (!window.GeekoSensors) return

        const {
            productId,
            product_type,
            sensors
        } = data

        window.GeekoSensors?.Track('AddToWishList', {
            product_id: productId,
            page_type: window.sourceTitle || window.currentPage,
            is_success: true,
            product_type: product_type,
            product_position: sensors?.product_position,
            ali_request_id: sensors?.aliRequestId,
            geeko_request_id: sensors?.geekoRequsestId,
            geeko_experiment_id: sensors?.geekoExperimentId,
            ali_experiment_id: sensors?.aliExperimentId,
            data_source: sensors?.dataSource,
            data_layer_name: sensors?.layerName,
            data_tracking_records: sensors?.trackingRecords,
            resourcepage_title: sensors?.resourcepage_title,
            resource_position: sensors?.resource_position,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            filter: sensors?.filter,
        })

        window.GeekoSensors?.Track('AddToWishListDetail', {
            product_id: productId,
            product_type: product_type,
            product_position: sensors?.product_position,
            ali_request_id: sensors?.aliRequestId,
            geeko_request_id: sensors?.geekoRequsestId,
            geeko_experiment_id: sensors?.geekoExperimentId,
            ali_experiment_id: sensors?.aliExperimentId,
            data_source: sensors?.dataSource,
            data_layer_name: sensors?.layerName,
            data_tracking_records: sensors?.trackingRecords,
            resourcepage_title: sensors?.resourcepage_title,
            resource_position: sensors?.resource_position,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            filter: sensors?.filter,
        })
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        if (!window.GeekoSensors) return

        const {
            productId,
            product_type,
            sensors
        } = data

        window.GeekoSensors.Track('ProductDetaiPageView', {
            product_id: productId,
            product_type: product_type,
            product_position: sensors?.product_position,
            ali_request_id: sensors?.aliRequestId,
            geeko_request_id: sensors?.geekoRequsestId,
            geeko_experiment_id: sensors?.geekoExperimentId,
            ali_experiment_id: sensors?.aliExperimentId,
            data_source: sensors?.dataSource,
            data_layer_name: sensors?.layerName,
            data_tracking_records: sensors?.trackingRecords,
            resourcepage_title: sensors?.resourcepage_title,
            resource_position: sensors?.resource_position,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            filter: sensors?.filter,
        })
    } catch (e) {
        console.warn(e)
    }

}

const purchase = data => {}


export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}