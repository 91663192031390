import {useCallback, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useSelector } from 'react-redux'


export function useApp(){
    const location = useLocation()
    const isApp = useSelector(state => state.global)?.isApp
    return !!qs.parse(location.search)?.isApp ||  isApp
}

export function useAppVersion(){
    return useSelector(state => state.global)?.appVersion
}

export function useDeviceType(){
    return useSelector(state => state.global)?.deviceType
}

export function useQuery(){
    const location = useLocation()
    return qs.parse(location.search)
}

export function useCurrency(){
    const currency = useSelector(state => state.global)?.currencyObject
    return currency
}

export function useOnlyList(){
    const location = useLocation()
    return !!qs.parse(location.search)?.isOnlyList
}

export function useBot(){
    return !!useSelector(state => state.global)?.isBot
}


export function useLanguage(){
    return useSelector(state => state.global)?.language
}

export function useWebp(){
    return !!useSelector(state => state.global)?.canUseWebp
}

export function useTouched(){
    const [touched, setTouched] = useState(false)

    const onTouchStart = useCallback(e => {
        setTouched(true)
    }, [])

    useEffect(() => {
        window.addEventListener('touchstart', onTouchStart)
        return () => {
            window.removeEventListener('touchstart', onTouchStart)
        }
    }, [])

    return touched
}

export function useDesktop(){
    return !!useSelector(state => state.global)?.isDesktop
}

export function useHeader(){
    const location = useLocation()
    return !location?.pathname?.startsWith('/c-log/vi')
}

export function useLang() {
    const global = useSelector(state => state.global)
    const { country } = global
    if(country && (country ==='DE' || country === 'ES')){
        return 'EU'
    }else if(country && country ==='GB'){
        return 'UK'
    }else if(country && country ==='BR'){
        return 'BR'
    }else if(country && country ==='CA'){
        return 'CA'
    }else if(country && country ==='FR'){
        return 'FR'
    }else {
        return 'US'
    }
}

export function useLogin() {
    const global = useSelector(state => state.global)
    return !!global?.user?.id
}