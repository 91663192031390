import { fetchCollectionList, fetchByMenu } from '../../../api'

export const __Default_Collection_Limit__ = 12

export const GIFTS_SET_INIT_DATA = 'GIFTS_SET_INIT_DATA'
export const GFITS_ADD_COLLECTION_LIST = 'GFITS_ADD_COLLECTION_LIST'
export const GIFTS_SET_COLLECTION_SKIP = 'GIFTS_SET_COLLECTION_SKIP'
export const GIFTS_SET_COLLECTION_LOADING = 'GIFTS_SET_COLLECTION_LOADING'
export const GIFTS_SET_COLLECTION_FINISHED = 'GIFTS_SET_COLLECTION_FINISHED'

export const GFITS_ADD_GIFT_LIST = 'GFITS_ADD_GIFT_LIST'
export const GIFTS_SET_GIFT_SKIP = 'GIFTS_SET_GIFT_SKIP'
export const GIFTS_SET_GIFT_LOADING = 'GIFTS_SET_GIFT_LOADING'
export const GIFTS_SET_GIFT_FINISHED = 'GIFTS_SET_GIFT_FINISHED'





export const setInitData = payload => ({
    type: GIFTS_SET_INIT_DATA,
    payload
})

export const addCollectionList = payload => ({
    type: GFITS_ADD_COLLECTION_LIST,
    payload
})

export const setCollectionSkip = payload => ({
    type: GIFTS_SET_COLLECTION_SKIP,
    payload
})

export const setCollectionLoading = payload => ({
    type: GIFTS_SET_COLLECTION_LOADING,
    payload
})

export const setCollectionFinished = payload => ({
    type: GIFTS_SET_COLLECTION_FINISHED,
    payload
})

export const addGiftList = payload => ({
    type: GFITS_ADD_GIFT_LIST,
    payload
})

export const setGiftSkip = payload => ({
    type: GIFTS_SET_GIFT_SKIP,
    payload
})

export const setGiftLoading = payload => ({
    type: GIFTS_SET_GIFT_LOADING,
    payload
})

export const setGiftFinished = payload => ({
    type: GIFTS_SET_GIFT_FINISHED,
    payload
})

export const action_add_collection_list = (params, headers) => {
	return async dispatch => {
		dispatch(setCollectionLoading(true))
		const response = await fetchByMenu({...params, code: 'APP0010', limit: __Default_Collection_Limit__}, headers)
		dispatch(setCollectionLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addCollectionList(products))
			dispatch(setCollectionSkip(params.skip + __Default_Collection_Limit__))

			if(!products || products?.length < 1){
				dispatch(setCollectionFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}



export const action_add_gift_list = (data, params, headers) => {
    return async dispatch => {
        dispatch(setGiftLoading(true))
        const response = await fetchCollectionList(data, {...params, limit: __Default_Collection_Limit__}, headers)
        dispatch(setGiftLoading(false))
        if(response?.code === 200){

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))

            if(!products || products?.length < 1){
                dispatch(setGiftFinished(true))
            } else {
                dispatch(addGiftList(products))
                dispatch(setGiftSkip(params.skip + __Default_Collection_Limit__))
            }
            return products
        }else{
            return null
        }
    }
}


export const action_fetch_init_data = (giftId, headers) => {
    return async dispatch => {
        const fetchGiftProducts = fetchCollectionList({collectionId: giftId}, {skip: 0, limit: __Default_Collection_Limit__}, headers).then(response => getProducts(response)).catch(e => [])
        const fetchCollectionProducts = await fetchByMenu({skip: 0, code: 'APP0010', limit: __Default_Collection_Limit__}, headers).then(response => getProducts(response)).catch(e => [])
        const [giftProducts, collectionProducts] = await Promise.all([fetchGiftProducts, fetchCollectionProducts])
        const initData = {
            gift: {
                id: giftId,
                skip: __Default_Collection_Limit__,
                finished: giftProducts?.length < 1,
                products: giftProducts,
            },
            collection: {
                skip: __Default_Collection_Limit__,
                finished: collectionProducts?.length < 1,
                products: collectionProducts,
            }
        }
        dispatch(setInitData(initData))
        return initData
    }
}



const getProducts = response => {
    if(response?.code === 200){
        return response.result?.map(p => ({
            ...p,
            geekoRequsestId: response.requestId,
            geekoExperimentId: response.experimentId,
            layerName: response.layerName,
            trackingRecords: response.trackingRecords
        }))
    }else{
        return []
    }
}