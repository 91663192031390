// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uwtBsejBeJsntNbVLRBZs{background-color:#fff;height:58px;width:100%}._3pmUVXdr7NIRVHZGeDr_KP{text-align:center;font-weight:bold;font-size:20px;display:block;line-height:58px;text-transform:uppercase;font-family:'ROBOTO-BOLD'}._1Ufd9J7tHXqYfafqAqFBwX{position:absolute;top:10px;right:10px;cursor:pointer;font-size:18px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/size-chart2/components/main/size-chart-head/style.module.scss"],"names":[],"mappings":"AAAA,uBAII,qBAAsB,CACtB,WAAY,CACZ,UAAW,CACd,yBAGG,iBAAkB,CAClB,gBAAiB,CACjB,cAAe,CACf,aAAc,CACd,gBAAiB,CACjB,wBAAyB,CACzB,yBAA0B,CAC7B,yBAGG,iBAAkB,CAClB,QAAS,CACT,UAAW,CACX,cAAe,CACf,cAAe","sourcesContent":[".SizeChartHead{\n    // position: fixed;\n    // top: 0;\n    // left: 0;\n    background-color: #fff;\n    height: 58px;\n    width: 100%;\n}\n\n.SizeChartTitle{\n    text-align: center;\n    font-weight: bold;\n    font-size: 20px;\n    display: block;\n    line-height: 58px;\n    text-transform: uppercase;\n    font-family: 'ROBOTO-BOLD';\n}\n\n.SizeChartClose{\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    cursor: pointer;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SizeChartHead": "uwtBsejBeJsntNbVLRBZs",
	"SizeChartTitle": "_3pmUVXdr7NIRVHZGeDr_KP",
	"SizeChartClose": "_1Ufd9J7tHXqYfafqAqFBwX"
};
module.exports = ___CSS_LOADER_EXPORT___;
