const getUrlName = name => {
    return name.replace(new RegExp(/[\s|\%|\?|\&|#]/g),'-').replace(new RegExp(/"/g),'').replace(new RegExp(/'/g),'').replace(/\//g, '').toLowerCase()
}

export const getCollectionUrl = (id, name, lang) => {
    const urlName = getUrlName(name || '-')
    return `${(lang && lang != 'en')?`/${lang}`:''}/collection/${urlName}/${id}.html`;
}

export const getCategoryUrl = (id, name, lang) => {
    const urlName = getUrlName(name || '-')
    return `${(lang && lang != 'en')?`/${lang}`:''}/category/${urlName}/${id}.html`;
}