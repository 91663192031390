import website from '../configs/website.json'
import Cookie from 'js-cookie'

// "pageId":"804997446264798",
//         "messengerAppId":"2087214101500691",
//         "ctmApi": {
//             "baseUrl": "https://graph.facebook.com/v15.0",
//             "accessToken": "EAAdqT2cXExMBABhdOupSattYVhegdnRUfqFgsic9p3BwAXj5eSZAgMZCSdLx5wbyq2tOxE4bPzYsbkJYUcDFG9ZCeonHcoZCgNuR3HPYqqZAZA4TRNcx9BEFjZACUFjNZBkmxWt75HVV4VvUGZByNp8IskuwC6Ex4yeeo9GFoDKJrIorvDuO3OgGa"
//         }

const { pageId, messengerAppId, ctmApi } = website.facebook

const __send = async body => {
    if (ctmApi) {
        const url = `${ctmApi?.baseUrl}/${messengerAppId}/page_activities?access_token=${ctmApi?.accessToken}`
        const response = await fetch(url, {
            method: 'post',
            body,
            headers: { 'Content-Type': 'application/json' }
        })
        const data = await response.json()
        console.log(data)
        return data
    } else {
        return Promise.resolve(null)
    }
}

export const fbContentView = async (contentId, price, currency) => {
    try {
        const psid = Cookie.get('messagerid')

        if(psid){
            const body = JSON.stringify({
                "custom_events": [
                    {
                        "_eventName": "fb_mobile_content_view",
                        "fb_content_id": contentId, // "product Id. For multiple product ids: e.g. "[\"1234\",\"5678\"]"
                        "fb_content_type": "product",
                        "fb_currency": currency, // "ISO 4217 code, e.g., "EUR", "USD", "JPY", "CNY", "HKD"
                        "_valueToSum": price //"e.g 57.23
                    }
                ],
                "advertiser_tracking_enabled": 1,
                "application_tracking_enabled": 1,
                "page_id": pageId,
                "page_scoped_user_id": psid,
                "logging_source": "messenger_bot",
                "logging_target": "app_and_page"
            })
    
            __send(body)
        }
        
    } catch (error) {
        console.warn('Facebook 事件发送失败')
    }
}

export const fbAddToCart = (contentId, price, currency) => {
    try {
        const psid = Cookie.get('messagerid')
        if(psid){
            const body = JSON.stringify({
                "custom_events": [
                    {
                        "_eventName": "fb_mobile_add_to_cart",
                        "fb_content_id": contentId, //"product Id. For multiple product ids: e.g. "[\"1234\",\"5678\"]"
                        "fb_content_type": "product", //" product / product_group
                        "fb_currency": currency, //"ISO 4217 code, e.g., "EUR", "USD", "JPY", "CNY", "HKD"
                        "_valueToSum": price //"e.g 57.23
                    }
                ],
                "advertiser_tracking_enabled": 1,
                "application_tracking_enabled": 1,
                "page_id": pageId,
                "page_scoped_user_id": psid,
                "logging_source": "messenger_bot",
                "logging_target": "app_and_page"
            })
            __send(body)
        }
    } catch (error) {
        console.warn('Facebook 事件发送失败', error)
    }
}

export const fbInitialCheckout = (contentId, price, currency, numberOfItem) => {
    try {
        const psid = Cookie.get('messagerid')
        if(psid){
            const body = JSON.stringify({
                "custom_events": [
                    {
                        "_eventName": "fb_mobile_initiated_checkout",
                        "fb_content_id": contentId, //"product Id. For multiple product ids: e.g. "[\"1234\",\"5678\"]"
                        "fb_content_type": "product", //" product / product_group
                        "fb_currency": currency, //"ISO 4217 code, e.g., "EUR", "USD","JPY", "CNY", "HKD"
                        "_valueToSum": price, //"e.g 57.23
                        "fb_num_items": numberOfItem
                    }
                ],
                "advertiser_tracking_enabled": 1,
                "application_tracking_enabled": 1,
                "page_id": pageId,
                "page_scoped_user_id": psid,
                "logging_source": "messenger_bot",
                "logging_target": "app_and_page"
            })
            __send(body)
        }
    } catch (error) {
        console.warn('Facebook 事件发送失败', error)
    }
}

export const fbPurchase = (contentId, price, currency, numberOfItem) => {
    try {
        const psid = Cookie.get('messagerid')
        if(psid){
            const body = JSON.stringify({
                "custom_events": [
                    {
                        "_eventName": "fb_mobile_purchase",
                        "fb_content_id": contentId, //"product Id. For multiple product ids: e.g. "[\"1234\",\"5678\"]"
                        "fb_content_type": "product", //" product / product_group
                        "fb_currency": currency, //"ISO 4217 code, e.g., "EUR", "USD", "JPY", "CNY", "HKD"
                        "_valueToSum": price, //"e.g 57.23
                        "fb_num_items": numberOfItem
                    }
                ],
                "advertiser_tracking_enabled": 1,
                "application_tracking_enabled": 1,
                "page_id": pageId,
                "page_scoped_user_id": psid,
                "logging_source": "messenger_bot",
                "logging_target": "app_and_page"
            })
            __send(body)
        }
       
    } catch (error) {
        console.warn('Facebook 事件发送失败', error)
    }
}