import { fetchCategory, fetchCategoryList, fetchCategorySorters, fetchCategoryFilter, fetch_parent_categories_by_id } from '../../../api'


export const __Default_Category_Limit__ = 12
export const __Default_Category_Limit_For_DeskTop__ = 100

export const CATEGORY_SET = 'CATEGORY_SET'
export const CATEGORY_LIST_ADD = 'CATEGORY_LIST_ADD'
export const CATEGORY_LIST_SET = 'CATEGORY_LIST_SET'
export const CATEGORY_LIST_SKIP = 'CATEGORY_LIST_SKIP'
export const CATEGORY_LIST_FINISHED = 'CATEGORY_LIST_FINISHED'
export const CATEGORY_LIST_LOADING = 'CATEGORY_LIST_LOADING'

export const CATEGORY_FILTER_SET = 'CATEGORY_FILTER_SET'
export const CATEGORY_SORTERS_SET = 'CATEGORY_SORTERS_SET'
export const CATEGORY_TOTAL = 'CATEGORY_TOTAL'

export const CATEGORY_FILTER_DATA = 'CATEGORY_FILTER_DATA'
export const CATEGORY_SET_PARENT_NODES = 'CATEGORY_SET_PARENT_NODES'

export const setCategoryFilterData = payload => ({
	type: CATEGORY_FILTER_DATA,
	payload
})

export const setCategoryTotal = payload => ({
	type: CATEGORY_TOTAL,
	payload
})

export const setCategoryFilter = payload => ({
	type: CATEGORY_FILTER_SET,
	payload
})

export const setCategorySorters = payload => ({
	type: CATEGORY_SORTERS_SET,
	payload
})

export const setCategory = payload => ({
	type: CATEGORY_SET,
	payload
})

export const setCategoryList = payload => ({
	type: CATEGORY_LIST_SET,
	payload
})

export const addCategoryList = payload => ({
	type: CATEGORY_LIST_ADD,
	payload
})

export const setCategorySkip = payload => ({
	type: CATEGORY_LIST_SKIP,
	payload
})

export const setCategoryFinished = payload => ({
	type: CATEGORY_LIST_FINISHED,
	payload
})

export const setCategoryListLoading = payload => ({
	type: CATEGORY_LIST_LOADING,
	payload
})

export const setCategoryParentNodes = payload => ({
	type: CATEGORY_SET_PARENT_NODES,
	payload
})

export const action_fetch_category_parent_nodes = (id, headers) => {
	return async dispatch => {
		const response = await fetch_parent_categories_by_id(id, headers).catch(data => null)
		if(response?.code === 200){
			dispatch(setCategoryParentNodes(response.result))
			return response.result
		}else{
			return null
		}
	}
}


export const action_fetch_category = (id, headers) => {
	return async dispatch => {
		const response = await fetchCategory(id, headers).catch(data => null)
		if(response?.code === 200){
			dispatch(setCategory(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_add_category_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCategoryListLoading(true))

		const limit = params?.limit || __Default_Category_Limit__


		const response = await fetchCategoryList(data, {...params, limit}, headers)
		dispatch(setCategoryListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addCategoryList(products))
			dispatch(setCategorySkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setCategoryFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_category_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCategoryFinished(false))
		dispatch(setCategoryListLoading(true))
		dispatch(setCategoryFilterData(data))
		const limit = params?.limit || __Default_Category_Limit__
		const response = await fetchCategoryList(data, {...params, skip: params.skip || 0, limit}, headers)
		dispatch(setCategoryListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setCategoryList(products))
			dispatch(setCategoryTotal(response.total))
			dispatch(setCategorySkip(limit))
			if(!products || products?.length < 1){
				dispatch(setCategoryFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_category_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchCategoryFilter(params, headers)
		if(response?.code === 200){
			dispatch(setCategoryFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_category_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchCategorySorters(params, headers)
		if(response?.code === 200){
			dispatch(setCategorySorters(response.result))
			return response.result
		}else{
			return null
		}
	}
}