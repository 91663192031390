// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1GlxKQYXjooMdudROmAlER{display:flex;align-items:center;justify-content:center}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/raffle/components/components/fourgongge-container/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,kBAAmB,CACnB,sBAAuB","sourcesContent":[".container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_1GlxKQYXjooMdudROmAlER"
};
module.exports = ___CSS_LOADER_EXPORT___;
