import React from 'react'
import enhanceWithClickOutside from 'react-click-outside'

export default Wrapped => {
    return enhanceWithClickOutside(class extends React.Component{
        constructor(props){
            super(props)
            this.state = {
                open: 0
            }
        }
    
        handleClickOutside(){
           this.setState({open:this.state.open+1})
        }
    
        
        render(){
            return <Wrapped {...this.props} open={this.state.open}/>
        }
    })
}