
const addToCart = data => {
    try {

        if (!window.ga)
            return

        const {
            variantSku,
            variantId,
            name,
            amount,
            quantity
        } = data


        window.ga("ec:addProduct", {
            "id": variantSku || variantId,
            "name": name,
            "price": amount,
            "quantity": quantity
        });

        window.ga("ec:setAction", "add");
        window.ga("send", "event", "UX", "click", "add to cart")
    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        if (!window.ga)
            return

        const {
            productId
        } = data

        window.ga('send', 'product', 'like', 'product like', productId);
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        if (window.ga){
            gaPageView(window.ga, data)
        }
    } catch (e) {
        console.warn(e)
    }
}


const gaPageView = (g, data) => {
    const {
        variantSku,
        name
    } = data

    g('require', 'ec')
    g('ec:addImpression', {
        'id': variantSku,
        'name': name
    })
    g('ec:addProduct', {
        'id': variantSku,
        'name': name
    })
    g('ec:setAction', 'detail')
    g('send', 'pageview')
}

const purchase = data => {}


export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}