import { LIVE_INIT_DATA } from '../../actions/pages/live'

const initialState = {
	data: null
}

export default (state = initialState, action) => {
	switch (action.type){
		case LIVE_INIT_DATA:
			return {...state, data: action.payload}
		default:
			return state
	}
}