import React, { useCallback } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { setLanguage } from '../../../../../../../store/actions/global'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'

export default props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const { languages, language } = global

    const handleLanguage = useCallback(lang => {
		dispatch(setLanguage(lang.value))
		Cookies.set('lang', lang.value, { path: '/', expires: 30 })
		const originUrl = window.location.href
		const directUrl = originUrl.replace(/\/(en|es|de|fr|pt|is|fi|sv|nb|da)\//, `/${lang.value}/`)
		window.location.replace(directUrl)
	}, [])


    return <ul className={classes.Languages}>
        {
            languages?.map(lang => <li className={`${classes.Language} ${lang.value === language?classes.Selected:''}`} key={lang.value} onClick={evt => handleLanguage(lang)}>
               {lang.label}
            </li>)
        }
    </ul>
}