import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import _ from 'lodash'
import BaseBox from '../../base-layout/base-box'
import SearchInput from '../search/search-input'
import { Iconfont } from '../../../../components/icon/iconfont'
import moment from 'moment'

import withSource from '../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../utils/sensor'

const handleAClick = (e, sensors) => {
    e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}

const ImageNode = withSource(props => {
    useStyles(classes)
    const { image, innerRef, position, sensors } = props
    const { width, height, url, href, styledTitle, title, enTitle } = image
    return <a
        ref={innerRef}
        data-title={sensors?.resourcepage_title}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        data-position={position}
        onClick={e => handleAClick(e, {...sensors, resource_position: position})}
        className={classes.ImageNode} href={href} title={title}>
        <div className={classes.Image}>
            <LazyLoadImage alt={title} src={url} />
        </div>
        <div className={classes.Title}>
            <span dangerouslySetInnerHTML={{ __html: styledTitle }} />
        </div>
    </a>
})

const ImageNodes = props => {
    useStyles(classes)
    const { images, position, sensors } = props

    return <div className={classes.ImageNodes}>
        {
            images?.map((image, index) => <ImageNode position={`${position}-${index + 1}`} sensors={{
                ...sensors,
                resource_content: `${sensors?.resource_content} - ${image?.enTitle || image?.title}`
            }} key={index} image={image} />)
        }
    </div>
}

const Leaf = withSource(props => {
    useStyles(classes)
    const { leaf, innerRef, position, sensors } = props
    return <a ref={innerRef}
        data-title={sensors?.resourcepage_title}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        data-position={position}
        onClick={e => handleAClick(e, {...sensors, resource_position: position})}
        href={leaf.href} className={classes.SubTitle}>
        <span dangerouslySetInnerHTML={{ __html: leaf.styledTitle }} />
    </a>
})

const Leaives = props => {
    useStyles(classes)

    const { menu, sensors, position } = props
    let leavies = menu?.children || []
    return <ul className={classes.Leaives}>
        {
            leavies.map((leaf, index) => <li key={index}>
                <Leaf leaf={leaf} sensors={{
                    ...sensors,
                    resource_content: `${sensors?.resource_content} - ${leaf?.enTitle || leaf?.title}`
                }} position={`${position}-${index + 1}`} />
            </li>)
        }
    </ul>
}

const Node = withSource(props => {
    useStyles(classes)
    const { menu, position, sensors, innerRef } = props
    const global = useSelector(state => state.global)
    const isNewIn = menu?.href?.indexOf("#newid") >= 0
    let leavies = menu?.children || []
    if (isNewIn) {
        try {
            leavies = [...leavies, ...(global?.timeRanges?.map(tr => {
                const dateStr = moment(tr.startDate).format('YYYY/MM/DD')
                return {
                    styledTitle: dateStr,
                    href: `/i/new-arrivals?rangeId=${tr.id}&date=${dateStr}`,
                }
            }))]
        } catch (error) {
            console.error(error)
        }
    }

    return <div className={classes.Node}>
        <div>
            <a ref={innerRef}
                data-title={sensors?.resourcepage_title}
                data-type={sensors?.resource_type}
                data-content={sensors?.resource_content}
                data-position={position}
                onClick={e => handleAClick(e, {...sensors, resource_position: position})}
                href={menu.href} className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: menu.styledTitle }} />
            </a>
        </div>
        {
            leavies?.length > 0 && <Leaives position={position} sensors={sensors} menu={{ ...menu, children: leavies }} />
        }
    </div>
})

const NavNode = withSource(props => {
    useStyles(classes)
    const { mouseOvwerHandle, menu, position, innerRef, sensors } = props
    return <a
        ref={innerRef}
        data-position={position}
        data-title={sensors?.resourcepage_title}
        data-type={sensors?.resource_type}
        data-content={sensors?.resource_content}
        onClick={e => handleAClick(e, {...sensors, resource_position: position})}
        href={menu.href} className={classes.NavNode} onMouseOver={evt => mouseOvwerHandle(menu, position, evt)}>
        <span dangerouslySetInnerHTML={{ __html: menu.styledTitle }} />
        {
            menu.hotIcon && <span className={classes.HotIcon}>
                <img src={menu.hotIcon} />
            </span>
        }
    </a>
})


const GroupLeaf = props => {
    const { menus, position, sensors } = props
    return <div className={classes.GroupLeaf}>
        {
            menus.map((menu, index) => {
                return <Node position={position} sensors={{
                    ...sensors,
                    resource_content: `${sensors?.resource_content} - ${menu?.enTitle || menu?.title}`
                }} key={index} menu={menu} />
            })
        }
    </div>
}

const DownMenu = props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { menu, position, sensors } = props
    const children = menu?.children
    const images = menu?.images


    const caculateLeafLength = useCallback((menus, inMenu) => {
        let length = 0
        menus.forEach(menu => {
            if (menu?.href === '#newid' && global?.timeRanges?.length > 0) {
                length += global?.timeRanges?.length
            } else {
                length += (menu.children?.length || 1)
            }

        })
        return length + (inMenu?.children?.length || 0)
    }, [])



    const groupMenus = _.reduce(children, function (result, value, index) {
        if (caculateLeafLength(result[result.length - 1], value) >= 12) {
            result.push([value])
        } else {
            result[result.length - 1].push(value)
        }
        return result
    }, [[]])


    return <div className={classes.DownMenu}>

        {
            groupMenus?.length > 0 && <div className={classes.GroupMenus}>
                {
                    groupMenus.map((menus, index) => {
                        return <GroupLeaf position={`${position}-${index + 1}`} sensors={sensors} key={index} menus={menus} />
                    })
                }
            </div>
        }


        {
            images?.length > 0 && <ImageNodes position={position} sensors={sensors} images={images} />
        }
    </div>
}

export default props => {
    useStyles(classes)
    const global = useSelector(state => state.global)
    const { menus } = global
    const [selectedMenu, setSelectedMenu] = React.useState(null)
    const [position, setPosition] = useState(1)
    const [swiper, setSwiper] = useState()
    const [status, setStatus] = useState(null)

    const sensors = {
        resourcepage_title: `cat-${global?.websiteInfo?.refId}`,
        resource_type: '10'
    }

    const mouseOvwerHandle = (menu, p) => {
        setSelectedMenu(menu)
        setPosition(p)
    }

    const mouseOutHandle = () => {
        setSelectedMenu(null)
        setPosition(1)
    }

    const swiperInit = useCallback(s => {
        setSwiper(s)
        setStatus(s.isEnd ? 'end' : null)
    }, [])


    return <div onMouseLeave={mouseOutHandle}>
        <BaseBox>
            <div className={classes.NavContainer}>
                <nav className={classes.SwiperNav}>
                    <Swiper
                        className={classes.Swiper}
                        slidesPerView="auto"
                        spaceBetween={30}
                        onInit={swiperInit}
                        onResize={swiperInit}
                        navigation={
                            {
                                nextEl: '#swiper-button-next',
                                prevEl: '#swiper-button-prev',
                                disabledClass: classes.Disable,
                                lockClass: 'lockClass',
                                hiddenClass: 'hiddenClass',
                                navigationDisabledClass: 'navigationDisabledClass',

                            }
                        }
                    >
                        {
                            menus?.map((menu, index) => <SwiperSlide className={classes.SwiperSlide} key={index}>
                                <NavNode sensors={{...sensors, resource_content: menu?.enTitle || menu?.title}} position={index + 1} menu={menu} mouseOvwerHandle={mouseOvwerHandle} />
                            </SwiperSlide>)
                        }

                    </Swiper>

                    <div className={classes.NavButtons}>
                        {
                            status !== 'end' && <React.Fragment>
                                <Iconfont id="swiper-button-prev" className={`${classes.Prev}`} onClick={e => { swiper?.slidePrev() }}>&#xe676;</Iconfont>
                                <Iconfont id="swiper-button-next" className={`${classes.Next}`} onClick={e => { swiper?.slideNext() }}>&#xe676;</Iconfont>
                            </React.Fragment>
                        }

                    </div>
                </nav>
                <div className={classes.SearchInput}>
                    <SearchInput />
                </div>
            </div>
        </BaseBox>

        {
            selectedMenu && <DownMenu position={position} sensors={{
                ...sensors,
                resource_content: selectedMenu?.enTitle || selectedMenu?.title
            }} menu={selectedMenu} />
        }
    </div>
}