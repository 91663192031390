// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3vvl97sdv2vHAaOuIYNFUo,._2T4FOw7e1r1MLJwgRGBJsD{position:fixed;z-index:10;background-color:rgba(0,0,0,0.5);top:0;left:0;width:100%;height:100%;cursor:pointer}._2T4FOw7e1r1MLJwgRGBJsD{display:flex;align-items:center;justify-content:center;overflow:auto;-webkit-overflow-scrolling:touch}\n", "",{"version":3,"sources":["webpack://src/client/components/mask/style.module.scss"],"names":[],"mappings":"AAAA,kDACE,cAAe,CACf,UAAW,CACX,gCAAmC,CACnC,KAAM,CACN,MAAO,CACP,UAAW,CACX,WAAY,CACZ,cAAe,CAChB,yBAIC,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CACvB,aAAc,CACd,gCAAiC","sourcesContent":[".BlackMask{\n  position: fixed;\n  z-index: 10;\n  background-color: rgba(0, 0, 0, .5);\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  cursor: pointer;\n}\n\n.ContainerMask{\n  @extend .BlackMask;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: auto;\n  -webkit-overflow-scrolling: touch;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BlackMask": "_3vvl97sdv2vHAaOuIYNFUo",
	"ContainerMask": "_2T4FOw7e1r1MLJwgRGBJsD"
};
module.exports = ___CSS_LOADER_EXPORT___;
