import Cache from '../../../../utils/cache'
import Cookies from 'js-cookie'

const addToCart = data => {
    try {
        if (window.twq) {
            const { amount, currency, name, productId, quantity } = data
            const eventId = 'Addtocart_' + new Date().getTime() + '_' + Cookies.get('wid')
            window.twq('event', 'tw-nz4i9-od5zx', {
                value: (Number(amount) * (quantity || 1)).toFixed(2),
                currency: currency,
                contents: [{
                    content_id: productId,
                    content_name: name,
                    content_price: amount,
                    num_items: quantity || 1,
                }],
                conversion_id: eventId, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
                email_address: window.email || Cache.get('shippingDetail')?.email || Cache.get('customerEmail'), // use this to pass a user’s email address
            })
        }
    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        if(window.twq){
            const {
                productId
            } = data
            const eventId = 'AddToWishList_' + new Date().getTime() + '_' + Cookies.get('wid')
            window.twq('event', 'tw-nz4i9-od5zu', {
                contents: [{content_id: productId}],
                conversion_id: eventId,
                email_address: window.email || Cache.get('shippingDetail')?.email || Cache.get('customerEmail'),
            })
        }
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {

        if(window.twq){
            const {
                productId,
                amount,
                currency,
                name
            } = data
    
            const eventId = 'ViewCentent_' + new Date().getTime() + '_' + Cookies.get('wid')
    
            window.twq('event', 'tw-nz4i9-od605', {
                value: amount,
                currency: currency, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
                contents: [
                    {
                        content_id: productId,
                        content_name: name,
                        content_price: amount,
                    }],
                conversion_id: eventId,
                email_address: window.email || Cache.get('shippingDetail')?.email || Cache.get('customerEmail'),
            })
        }

        

    } catch (e) {
        console.warn(e)
    }
}

const viewList = data => {
    try {
        if(window.twq){
            const {
                products
            } = data
            const eventId = 'ViewList_' + new Date().getTime() + '_' + Cookies.get('wid')
            window.twq('event', 'tw-nz4i9-od6hs', {
                contents: products?.map(l => ({
					content_id: l.id
				})),
                conversion_id: eventId,
                email_address: window.email || Cache.get('shippingDetail')?.email || Cache.get('customerEmail'),
            })
        }
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        if(window.twq){
            const {
                orderItems,
                orderTotal,
                transactionId,
                shippingDetail
            } = data
            const eventId = 'Purchase_' + new Date().getTime() + '_' + Cookies.get('wid')
            window.twq('event', 'tw-nz4i9-od602', {
                value: orderTotal?.amount,
                currency: orderTotal?.currency,
                contents: orderItems?.map(l => ({
                    content_id: l.productId,
                    content_price: l.price?.amount,
                    num_items: l.quantity,
                })),
                conversion_id: transactionId,
                email_address: window.email || shippingDetail?.email || Cache.get('customerEmail'),
            })
        }
    } catch (e) {
        console.warn(e)
    }
}


export default {
    addToCart,
    addToWishList,
    pageView,
    viewList,
    purchase
}