// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3Zxy3Yz5fOHRgfwAvqRH16{z-index:11;position:fixed;top:0;right:0;width:1100px;height:100%;background-color:#fff}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/search/visual-search/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,UAAW,CACX,cAAe,CACf,KAAM,CACN,OAAQ,CACR,YAAa,CACb,WAAY,CACZ,qBAAsB","sourcesContent":[".EditorContainer{\n    z-index: 11;\n    position: fixed;\n    top: 0;\n    right: 0;\n    width: 1100px;\n    height: 100%;\n    background-color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"EditorContainer": "_3Zxy3Yz5fOHRgfwAvqRH16"
};
module.exports = ___CSS_LOADER_EXPORT___;
