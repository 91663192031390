

const addToCart = data => {
    try {
        const { amount, currency, name, productId } = data
        if (window.mkq) {
            window?.mkq('track', 'AddToCart', {
                value: amount,
                currency: currency,
                content_name: name,
                content_type: 'product',
                content_ids: [productId]
            })
        }

    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        const {
            variantId,
            productId
        } = data

    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {
        const {
            productId,
            amount,
            currency,
            name
        } = data

        if (window.mkq) {
            window?.mkq('track', 'ViewContent', {
                value: amount,
                currency: currency,
                content_name: name,
                content_type: 'product',
                content_ids: [productId]
            });
        }

    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        const {
            orderTotal,
            orderItems
        } = data

        const item_ids = orderItems?.map(item => item.productId)
        const item_quantity = orderItems?.reduce((acc, item) => acc + item.quantity, 0)

        if (window.mkq) {
            window.mkq('track', 'Purchase', {
                value: orderTotal?.amount,
                currency: orderTotal?.currency,
                content_type: 'product',
                content_ids: item_ids,
                num_items:item_quantity
            })
        }

    } catch (e) {
        console.warn(e)
    }
}



export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}