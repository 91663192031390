import {
	LANDING_INIT,
	LANDING_CURRENT_PAGE,
	LANDING_AB_TEST,
	LANDING_WEB2APP_LOTTERY_MESSAGE,
	LANDING_WEB2APP_LIST_MESSAGE,
	LANDING_WEB2APP_LIST_PRODUCTS,
	LANDING_WEB2APP_APPEND_LIST_PRODUCTS,
	LANDING_WEB2APP_LIST_PRODUCTS_SKIP,
	LANDING_WEB2APP_LIST_PRODUCTS_LIMIT,
	LANDING_WEB2APP_LIST_PRODUCTS_FINISHED,
	LANDING_WEB2APP_LIST_PRODUCTS_LOADING,
	LANDING_WEB2APP_IMAGE_PAGE_MESSAGE
} from '../../actions/pages/landing'

const initialState = {
	pages: [],
	currentPage: '1',
	abTest: 'A',
	web2App: {
		lotteryMessage: '',
		listMessage: '',
		listProducts: null,
		listSkip: 0,
		listLimit: 20,
		listFinished: false,
		listLoading: false,
		imagePageMessage: ''
	}
}

export default (state = initialState, action) => {
	switch(action.type){
		case LANDING_INIT:
			return {...state, pages: action.payload}
		case LANDING_CURRENT_PAGE:
			return {...state, currentPage: action.payload}
		case LANDING_AB_TEST:
			return {...state, abTest: action.payload}
		case LANDING_WEB2APP_LOTTERY_MESSAGE:
			return {...state, web2App: {...state.web2App, lotteryMessage: action.payload}}
		case LANDING_WEB2APP_LIST_MESSAGE:
			return {...state, web2App: {...state.web2App, listMessage: action.payload}}
		case LANDING_WEB2APP_LIST_PRODUCTS:
			return {...state, web2App: {...state.web2App, listProducts: action.payload}}
		case LANDING_WEB2APP_APPEND_LIST_PRODUCTS:
			return {...state, web2App: {...state.web2App, listProducts: [...state.web2App.listProducts, ...action.payload]}}
		case LANDING_WEB2APP_LIST_PRODUCTS_SKIP:
			return {...state, web2App: {...state.web2App, listSkip: action.payload}}
		case LANDING_WEB2APP_LIST_PRODUCTS_LIMIT:
			return {...state, web2App: {...state.web2App, listLimit: action.payload}}
		case LANDING_WEB2APP_LIST_PRODUCTS_FINISHED:
			return {...state, web2App: {...state.web2App, listFinished: action.payload}}
		case LANDING_WEB2APP_LIST_PRODUCTS_LOADING:
			return {...state, web2App: {...state.web2App, listLoading: action.payload}}
		case LANDING_WEB2APP_IMAGE_PAGE_MESSAGE:
			return {...state,  web2App: {...state.web2App, imagePageMessage: action.payload}}
		default:
			return state
	}
}