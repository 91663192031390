import React from "react"

const DropDownContent = props => {
    const {show, content} = props

    return <React.Fragment>
        {
            show ?
            props.children:
            <React.Fragment></React.Fragment>
        }
    </React.Fragment>

}

export default DropDownContent