import React from 'react'

export default class extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hasError: false, error: null }
    }
  
    static getDerivedStateFromError(error) {
      console.log(error)
      return { hasError: true,  error}
    }
  
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }
  
    render() {
      if (this.state.hasError) {
        return <span>{this.state.error?.toString?.() || this.state.error || 'something went wrong'}</span>
      }
      return this.props.children
    }
  }
  