import {
	NEWARRIVALS_LIST_ADD,
	NEWARRIVALS_LIST_SET,
	NEWARRIVALS_LIST_FINISHED,
	NEWARRIVALS_LIST_SKIP,
	NEWARRIVALS_LIST_LOADING,
	NEWARRIVALS_FILTER_SET,
	NEWARRIVALS_SORTERS_SET,
	NEWARRIVALS_TOTAL,
	NEWARRIVALS_FILTER_DATA,
} from '../../actions/pages/new-arrivals'

const initialState = {
	list: [],
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	total: 0,
	filterData: null
}

export default (state = initialState, action) => {
	switch (action.type){
		case NEWARRIVALS_LIST_SET:
			return {...state, list: action.payload}
		case NEWARRIVALS_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case NEWARRIVALS_LIST_SKIP:
			return {...state, skip: action.payload}
		case NEWARRIVALS_LIST_FINISHED:
			return {...state, finished: action.payload}
		case NEWARRIVALS_LIST_LOADING:
			return {...state, loading: action.payload}
		case NEWARRIVALS_FILTER_SET:
			return {...state, filterVo: action.payload}
		case NEWARRIVALS_SORTERS_SET:
			return {...state, sorters: action.payload}
		case NEWARRIVALS_TOTAL:
			return {...state, total: action.payload}
		case NEWARRIVALS_FILTER_DATA:
			return {...state, filterData: action.payload}	
		default:
			return state
	}
}