import { 
	fetchCollection, 
	fetchCollectionList, 
	fetchCollectionSorters, 
	fetchCollectionFilter, 
	getGiftsInfo
} from '../../../api'


export const __Default_Collection_Limit__ = 12
export const __Default_Collection_Limit_For_DeskTop__ = 100

export const COLLECTION_SET = 'COLLECTION_SET'
export const COLLECTION_LIST_ADD = 'COLLECTION_LIST_ADD'
export const COLLECTION_LIST_SET = 'COLLECTION_LIST_SET'
export const COLLECTION_LIST_SKIP = 'COLLECTION_LIST_SKIP'
export const COLLECTION_LIST_FINISHED = 'COLLECTION_LIST_FINISHED'
export const COLLECTION_LIST_LOADING = 'COLLECTION_LIST_LOADING'

export const COLLECTION_FILTER_SET = 'COLLECTION_FILTER_SET'
export const COLLECTION_SORTERS_SET = 'COLLECTION_SORTERS_SET'
export const COLLECTION_TOTAL = 'COLLECTION_TOTAL'

export const MODAL_GIFTS_MODAL_DATA = 'MODAL_GIFTS_MODAL_DATA';
export const MODAL_GFITS_ADD_GIFT_LIST = 'MODAL_GFITS_ADD_GIFT_LIST'
export const MODAL_GIFTS_SET_GIFT_SKIP = 'MODAL_GIFTS_SET_GIFT_SKIP'
export const MODAL_GIFTS_SET_GIFT_LOADING = 'MODAL_GIFTS_SET_GIFT_LOADING'
export const MODAL_GIFTS_SET_GIFT_FINISHED = 'MODAL_GIFTS_SET_GIFT_FINISHED'
export const SET_GIFTS_INFO_MESSAGE = "SET_GIFTS_INFO_MESSAGE";

export const COLLECTION_FILTER_DATA = 'COLLECTION_FILTER_DATA'


export const setCollectionFilterData = payload => ({
	type: COLLECTION_FILTER_DATA,
	payload
})

export const setCollectionTotal = payload => ({
	type: COLLECTION_TOTAL,
	payload
})

export const setCollectionFilter = payload => ({
	type: COLLECTION_FILTER_SET,
	payload
})

export const setCollectionSorters = payload => ({
	type: COLLECTION_SORTERS_SET,
	payload
})

export const setCollection = payload => ({
	type: COLLECTION_SET,
	payload
})

export const setCollectionList = payload => ({
	type: COLLECTION_LIST_SET,
	payload
})

export const addCollectionList = payload => ({
	type: COLLECTION_LIST_ADD,
	payload
})

export const setCollectionSkip = payload => ({
	type: COLLECTION_LIST_SKIP,
	payload
})

export const setCollectionFinished = payload => ({
	type: COLLECTION_LIST_FINISHED,
	payload
})

export const setCollectionListLoading = payload => ({
	type: COLLECTION_LIST_LOADING,
	payload
})

export const addModalGiftList = payload => ({
    type: MODAL_GFITS_ADD_GIFT_LIST,
    payload
})

export const setModalGiftSkip = payload => ({
    type: MODAL_GIFTS_SET_GIFT_SKIP,
    payload
})

export const setModalGiftLoading = payload => ({
    type: MODAL_GIFTS_SET_GIFT_LOADING,
    payload
})

export const setModalGiftFinished = payload => ({
    type: MODAL_GIFTS_SET_GIFT_FINISHED,
    payload
})

export const setGiftsInfo = payload =>({
	type:SET_GIFTS_INFO_MESSAGE,
	payload
})

export const setGiftsModalData = payload =>({
	type: MODAL_GIFTS_MODAL_DATA,
	payload
})

export const action_fetch_collection = (id, headers) => {
	return async dispatch => {
		const response = await fetchCollection(id, headers)
		if(response?.code === 200){
			dispatch(setCollection(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_add_collection_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCollectionListLoading(true))
		
		const limit = params?.limit || __Default_Collection_Limit__

		const response = await fetchCollectionList(data, {...params, limit}, headers)
		dispatch(setCollectionListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addCollectionList(products))
			dispatch(setCollectionSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setCollectionFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_collection_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setCollectionFinished(false))
		dispatch(setCollectionListLoading(true))
		dispatch(setCollectionFilterData(data))
		const limit = params?.limit || __Default_Collection_Limit__

		const response = await fetchCollectionList(data, {...params, skip: params?.skip || 0, limit}, headers)
		dispatch(setCollectionListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords,



				// promotion: {
				// 	...(p.promotion || {}),
				// 	type: '9',
				// 	salesQty: Math.ceil(Math.random()*10),
				// 	totalQty: 12
				// }

			}))


			dispatch(setCollectionList(products))
			dispatch(setCollectionTotal(response.total))
			dispatch(setCollectionSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setCollectionFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_collection_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchCollectionFilter(params, headers)
		if(response?.code === 200){
			dispatch(setCollectionFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_collection_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchCollectionSorters(params, headers)
		if(response?.code === 200 && response.result){
			const sorters = response.result
			dispatch(setCollectionSorters(Object.keys(sorters).sort().map(p => ({label: sorters[p], value: p}))))
			return response.result
		}else{
			return null
		}
	}
}

export const action_add_modal_gift_list = (giftId, data, headers) => {
    return async dispatch => {
        dispatch(setModalGiftLoading(true))
        const response = await fetchCollectionList({collectionId: giftId}, {skip: data?.skip, limit: __Default_Collection_Limit__}, headers).catch(e => [])
        dispatch(setModalGiftLoading(false))
        if(response?.code === 200){

            const products = response.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))

            if(!products || products?.length < 1){
                dispatch(setModalGiftFinished(true))
            } else {
				dispatch(addModalGiftList(products))
            	dispatch(setModalGiftSkip(data.skip + __Default_Collection_Limit__))
			}
            return products
        }else{
            return null
        }
    }
}

export const action_fetch_gifts_info_message = (headers) =>{
	return async dispatch =>{
		let response = await getGiftsInfo(headers);
		if(response?.code === 200){
			dispatch(setGiftsInfo(response?.result));
			return response?.result;
		}else{
			return null;
		}
	}
}