export const __Pay_Method_Adyen__ = '89'
export const __Pay_Method_Stripe__ = '88'
export const __Pay_Method_Stripe_EU__ = '91'
export const __Pay_Method_Stripe_GB__ = '101'
export const __Pay_Method_Stripe_IR__ = '109'
export const __Pay_Method_SafeCharge__ = '18'
export const __Pay_Checkout__ = '87'
export const __Pay_Checkout_EU__ = '92'
export const __Pay_Ebanx__ = '127'
export const __Pay_Ebanx_Debet__ = '140'



export const isStripeCredit = paymethod => paymethod === __Pay_Method_Stripe__ || paymethod === __Pay_Method_Stripe_EU__ || paymethod === __Pay_Method_Stripe_GB__ || paymethod === __Pay_Method_Stripe_IR__

export const isCheckoutCredit = paymethod => paymethod === __Pay_Checkout__ || paymethod === __Pay_Checkout_EU__

export const isEbanxCredit = paymethod => paymethod === __Pay_Ebanx__ || paymethod === __Pay_Ebanx_Debet__


export const getRelatedPayMethods = payMethod => {

    const ps = [payMethod, __Pay_Method_SafeCharge__]

    if (payMethod === __Pay_Method_SafeCharge__) {
        ps.push(__Pay_Method_Stripe__)
        ps.push(__Pay_Method_Stripe_EU__)
    }

    if (payMethod === __Pay_Checkout__) {
        ps.push(__Pay_Method_Stripe__)
        ps.push(__Pay_Method_Stripe_EU__)
        ps.push(__Pay_Checkout_EU__)
    }

    if (payMethod === __Pay_Method_Stripe__) {
        ps.push(__Pay_Checkout__)
        ps.push(__Pay_Method_Stripe_EU__)
        ps.push(__Pay_Checkout_EU__)
    }



    if (payMethod === __Pay_Method_Stripe_EU__) {
        ps.push(__Pay_Checkout__)
        ps.push(__Pay_Method_Stripe__)
        ps.push(__Pay_Method_Adyen__)
        ps.push(__Pay_Checkout_EU__)
    }

    if (payMethod === __Pay_Checkout_EU__) {
        ps.push(__Pay_Checkout__)
        ps.push(__Pay_Method_Stripe__)
        ps.push(__Pay_Method_Stripe_EU__)
    }

    if (payMethod === __Pay_Method_Stripe_GB__ || payMethod === __Pay_Method_Stripe_IR__) {
        ps.push(__Pay_Method_Stripe_EU__)
        ps.push(__Pay_Checkout__)
        ps.push(__Pay_Method_Stripe__)
        ps.push(__Pay_Checkout_EU__)
    }

    return ps
}