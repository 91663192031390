// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1PkdvHVkBojImAqAs5CrIu{display:inline-block;position:relative;cursor:pointer}._1PkdvHVkBojImAqAs5CrIu ._2K7YNM2p-c7PcNKBlvFZ1c{display:inline-block;min-width:18px;height:18px;border-radius:50%;background-color:var(--highlight-color--, #e64545);color:#fff;font-size:12px;text-align:center;line-height:18px;position:absolute;right:-10px;top:-6px}._1PkdvHVkBojImAqAs5CrIu ._1dwWGHcV07mzi-u7xCe-sK{display:inline-block;background-color:var(--highlight-color--, #e64545);width:8px;height:8px;border-radius:50%;position:absolute;right:-3px;top:-3px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/components/badge/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,oBAAqB,CACrB,iBAAkB,CAClB,cAAe,CAHnB,kDAKQ,oBAAqB,CACrB,cAAe,CACf,WAAY,CACZ,iBAAkB,CAClB,kDAAmD,CACnD,UAAW,CACX,cAAe,CACf,iBAAkB,CAClB,gBAAiB,CACjB,iBAAkB,CAClB,WAAY,CACZ,QAAS,CAhBjB,kDAoBQ,oBAAqB,CACrB,kDAAmD,CACnD,SAAU,CACV,UAAW,CACX,iBAAkB,CAClB,iBAAkB,CAClB,UAAW,CACX,QAAS","sourcesContent":[".Badge{\n    display: inline-block;\n    position: relative;\n    cursor: pointer;\n    .Count{\n        display: inline-block;\n        min-width: 18px;\n        height: 18px;\n        border-radius: 50%;\n        background-color: var(--highlight-color--, #e64545);\n        color: #fff;\n        font-size: 12px;\n        text-align: center;\n        line-height: 18px;\n        position: absolute;\n        right: -10px;\n        top: -6px;\n    }\n\n    .New{\n        display: inline-block;\n        background-color: var(--highlight-color--, #e64545);\n        width: 8px;\n        height: 8px;\n        border-radius: 50%;\n        position: absolute;\n        right: -3px;\n        top: -3px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": "_1PkdvHVkBojImAqAs5CrIu",
	"Count": "_2K7YNM2p-c7PcNKBlvFZ1c",
	"New": "_1dwWGHcV07mzi-u7xCe-sK"
};
module.exports = ___CSS_LOADER_EXPORT___;
