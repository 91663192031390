// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3gXA_pGzvbYgDVfROXC_uf{display:block;text-decoration:none;color:#222}._3gXA_pGzvbYgDVfROXC_uf .WR35kbfTD2Bo4eh2jyg6V{position:relative;overflow:hidden}._3gXA_pGzvbYgDVfROXC_uf .WR35kbfTD2Bo4eh2jyg6V::after{content:'';margin-top:125%;display:block}._3gXA_pGzvbYgDVfROXC_uf .WR35kbfTD2Bo4eh2jyg6V img{position:absolute;width:100%;top:0;left:0;display:block}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/listing/components/collection-card/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,aAAc,CACd,oBAAqB,CACrB,UAAW,CAHf,gDAKQ,iBAAkB,CAClB,eAAgB,CANxB,uDAQY,UAAW,CACX,eAAgB,CAChB,aAAc,CAV1B,oDAaY,iBAAkB,CAClB,UAAW,CACX,KAAM,CACN,MAAO,CACP,aAAc","sourcesContent":[".CollectionCard{\n    display: block;\n    text-decoration: none;\n    color: #222;\n    .Image{\n        position: relative;\n        overflow: hidden;\n        &::after{\n            content: '';\n            margin-top: 125%;\n            display: block;\n        }\n        img{\n            position: absolute;\n            width: 100%;\n            top: 0;\n            left: 0;\n            display: block;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CollectionCard": "_3gXA_pGzvbYgDVfROXC_uf",
	"Image": "WR35kbfTD2Bo4eh2jyg6V"
};
module.exports = ___CSS_LOADER_EXPORT___;
