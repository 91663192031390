import _ from 'lodash'
import {
	deleteCreditCards,
	deleteMercadoCreditCards,
	fetchCustomer,
	fetchMeAddressList,
	fetchMessageToObject,
	getActivityNotification,
	getActivityNotificationNoRead,
	getCashHistoryRecord,
	getCashWallet,
	getCoupons,
	getCreditCards,
	getExpiredCoupons,
	getMercadoCreditCards,
	getNewsNotification,
	getNewsNotificationNoRead,
	getOrdersNewNotification,
	getOrdersNotificationNoRead,
	getOthersNotificationNoRead,
	getPromoNotification,
	getPromoNotificationNoRead,
	getTicketNotification,
	getTicketNotificationNoRead,
	setDefaultAddress,
	fetchPointsHistoryAll,
	fetchPointsHistoryRecived,
	fetchPointsHistoryUsed,
	fetchPointsHistoryExpired,
	getShowRedeemCoupon,
	getPointsCouponList,
	fetchGetMessage,
	fetchWishListProducts,
	fetchMessageGetList,
	fetchOrderListAll,
	fetchOrderDetail,
	fetchOrderListUnpaid,
	fetchOrderListProcessing,
	fetchOrderListShipped,
	fetchOrderListConfirmed, 
	fetchOrderReviewByOrderId,
	fetchOrderRecommend,
	fetchReturnOrders,
	fetchGetReturnOrderDetail,
	fetchTicketList,
	fetchOrderListPaid,
	fetchOrderListCanceled,
	fetchTicketQuestions,
	fetchTicketQuestionsTree,
	getWalletCoupons,
	getWishListFilter,
	getWishListTotalCount,
	fetchWishList,
	fetchWishListFilterResult,
	fetchWishListCount
} from '../../../api'


export const CUSTOMER_SET = 'CUSTOMER_SET'
export const CUSTOMER_ADDRESS_LIST = 'CUSTOMER_ADDRESS_LIST'
export const CUSTOMER_CREDIT_CARD = 'CUSTOMER_CREDIT_CARD'
export const CUSTOMER_MESSAGE = 'CUSTOMER_MESSAGE'
export const CUSTOMER_NOTIFICATION_NO_READ = 'CUSTOMER_NOTIFICATION_NO_READ'
export const CUSTOMER_NOTIFICATION_SET = 'CUSTOMER_NOTIFICATION_SET'
export const CUSTOMER_NOTIFICATION_SET_LOADING = 'CUSTOMER_NOTIFICATION_SET_LOADING'
export const CUSTOMER_NOTIFICATION_SET_FINISHED = 'CUSTOMER_NOTIFICATION_SET_FINISHED'
export const CUSTOMER_NOTIFICATION_SET_SKIP = 'CUSTOMER_NOTIFICATION_SET_SKIP'
export const CUSTOMER_SET_COUPON_LIST = 'CUSTOMER_SET_COUPON_LIST'
export const CUSTOMER_SET_EXPIRED_COUPON = 'CUSTOMER_SET_EXPIRED_COUPON'
export const CUSTOMER_SET_WALLET = 'CUSTOMER_SET_WALLET'
export const CUSTOMER_SET_WALLET_HISTORY = 'CUSTOMER_SET_WALLET_HISTORY'
export const CUSTOMER_SET_WALLET_HISTORY_SKIP = 'CUSTOMER_SET_WALLET_HISTORY_SKIP'
export const CUSTOMER_SET_WALLET_HISTORY_LOADING = 'CUSTOMER_SET_WALLET_HISTORY_LOADING'
export const CUSTOMER_SET_WALLET_HISTORY_FINISHED = 'CUSTOMER_SET_WALLET_HISTORY_FINISHED'
export const CUSTOMER_POINTS_HISTORY_ALL_DATA = 'CUSTOMER_POINTS_HISTORY_ALL_DATA';
export const CUSTOMER_POINTS_HISTORY_RECIVED_DATA = 'CUSTOMER_POINTS_HISTORY_RECIVED_DATA';
export const CUSTOMER_POINTS_HISTORY_USED_DATA = 'CUSTOMER_POINTS_HISTORY_USED_DATA';
export const CUSTOMER_POINTS_HISTORY_EXPIRED_DATA = 'CUSTOMER_POINTS_HISTORY_EXPIRED_DATA';
export const CUSTOMER_SET_SHOW_REDEEM_COUPON = 'CUSTOMER_SET_SHOW_REDEEM_COUPON';
export const CUSTOMER_SET_REDEEM_COUPON_LIST = 'CUSTOMER_SET_REDEEM_COUPON_LIST';
export const CUSTOMER_WISHLIST_DATA = "CUSTOMER_WISHLIST_DATA";
export const CUSTOMER_POINTS_GUIDE = "CUSTOMER_POINTS_GUIDE";
export const CUSTOMER_ORDER_LIST_ALL = "CUSTOMER_ORDER_LIST_ALL";
export const CUSTOMER_ORDER_LIST_UNPAID = "CUSTOMER_ORDER_LIST_UNPAID";
export const CUSTOMER_ORDER_LIST_PROCESSING = "CUSTOMER_ORDER_LIST_PROCESSING";
export const CUSTOMER_ORDER_LIST_SHIPPED = "CUSTOMER_ORDER_LIST_SHIPPED";
export const CUSTOMER_ORDER_LIST_CONFIRMED = "CUSTOMER_ORDER_LIST_CONFIRMED";
export const CUSTOMER_ORDER_LIST_RETURN = "CUSTOMER_ORDER_LIST_RETURN";
export const CUSTOMER_ORDER_DETAIL = "CUSTOMER_ORDER_DETAIL";
export const CUSTOMER_ORDER_RETURN_DETAIL_EMPTY = 'CUSTOMER_ORDER_RETURN_DETAIL_EMPTY';
export const CUSTOMER_ORDER_RETURN_DETAIL = "CUSTOMER_ORDER_RETURN_DETAIL";
export const CUSTOMER_ORDER_REVIEW = 'CUSTOMER_ORDER_REVIEW';
export const CUSTOMER_ORDER_OFTEN_BOUGHT_WITH = 'CUSTOMER_ORDER_OFTEN_BOUGHT_WITH';
export const CUSTOMER_SUCCESS_REMINDER = 'CUSTOMER_SUCCESS_REMINDER';
export const CUSTOMER_SET_LOADING = 'CUSTOMER_SET_LOADING';
export const CUSTOMER_GET_TICKET_LIST = 'CUSTOMER_GET_TICKET_LIST';
export const CUSTOMER_ADD_TICKET_LIST = 'CUSTOMER_ADD_TICKET_LIST';
export const CUSTOMER_TICKET_LIST_LOADING = 'CUSTOMER_TICKET_LIST_LOADING';
export const CUSTOMER_TICKET_LIST_FINISHED = 'CUSTOMER_TICKET_LIST_FINISHED';
export const CUSTOMER_TICKET_LIST_SKIP = 'CUSTOMER_TICKET_LIST_SKIP';
export const CUSTOMER_TICKET_SET_QUESTION_TYPE = 'CUSTOMER_TICKET_SET_QUESTION_TYPE';
export const CUSTOMER_GET_TICKET_ORDER = 'CUSTOMER_GET_TICKET_ORDER';
export const CUSTOMER_ADD_TICKET_ORDER = 'CUSTOMER_ADD_TICKET_ORDER';
export const CUSTOMER_TICKET_ORDER_LOADING = 'CUSTOMER_TICKET_ORDER_LOADING';
export const CUSTOMER_TICKET_ORDER_FINISHED = 'CUSTOMER_TICKET_ORDER_FINISHED';
export const CUSTOMER_TICKET_ORDER_SKIP = "CUSTOMER_TICKET_ORDER_SKIP";
export const CUSTOMER_TICKET_ORDER_SELECT_ID = "CUSTOMER_TICKET_ORDER_SELECT_ID";
export const CUSTOMER_TICKET_QUESTIONS = 'CUSTOMER_TICKET_QUESTIONS';
export const CUSTOMER_TICKET_QUESTIONS_TREE = 'CUSTOMER_TICKET_QUESTIONS_TREE';

export const CUSTOMER_WISH_LIST_FILTER = "CUSTOMER_WISH_LIST_FILTER";
export const CUSTOMER_WISH_LIST_INIT = "CUSTOMER_WISH_LIST_INIT";
export const CUSTOMER_WISH_LIST_APPEND = "CUSTOMER_WISH_LIST_APPEND";
export const CUSTOMER_WISH_LIST_UNSHIFT = "CUSTOMER_WISH_LIST_UNSHIFT";
export const CUSTOMER_WISH_LIST_FILTER_COUNT = "CUSTOMER_WISH_LIST_FILTER_COUNT";
export const CUSTOMER_WISH_LIST_COUNT = "CUSTOMER_WISH_LIST_COUNT";
export const CUSTOMER_WISH_LIST_LOADING = "CUSTOMER_WISH_LIST_LOADING";
export const CUSTOMER_WISH_LIST_FINISHED = "CUSTOMER_WISH_LIST_FINISHED";
export const CUSTOMER_WISH_LIST_LAST_TOKEN = "CUSTOMER_WISH_LIST_LAST_TOKEN";

export const setCustomer = payload => ({
	type: CUSTOMER_SET,
	payload
})

export const setAddressList = payload => ({
	type: CUSTOMER_ADDRESS_LIST,
	payload
})

export const setCreditCards = payload => ({
	type: CUSTOMER_CREDIT_CARD,
	payload
})

export const setMessage = payload => ({
	type: CUSTOMER_MESSAGE,
	payload
})

export const setNotificationNoRead = payload => ({
	type: CUSTOMER_NOTIFICATION_NO_READ,
	payload
})

export const setNotifications = payload => ({
	type: CUSTOMER_NOTIFICATION_SET,
	payload
})

export const setNotificationLoading = payload => ({
	type: CUSTOMER_NOTIFICATION_SET_LOADING,
	payload
})

export const setNotificationFinished = payload => ({
	type: CUSTOMER_NOTIFICATION_SET_FINISHED,
	payload
})

export const setNotificationSkip = payload => ({
	type: CUSTOMER_NOTIFICATION_SET_SKIP,
	payload
})

export const setCouponList = payload => ({
	type: CUSTOMER_SET_COUPON_LIST,
	payload
})

export const setExpiredCoupon = payload => ({
	type: CUSTOMER_SET_EXPIRED_COUPON,
	payload
})

export const setWallet = payload => ({
	type: CUSTOMER_SET_WALLET,
	payload
})

export const setWalletHistory = payload => ({
	type: CUSTOMER_SET_WALLET_HISTORY,
	payload
})

export const setWalletHistorySkip = payload => ({
	type: CUSTOMER_SET_WALLET_HISTORY_SKIP,
	payload
})

export const setWalletHistoryLoading = payload => ({
	type: CUSTOMER_SET_WALLET_HISTORY_LOADING,
	payload
})

export const setWalletHistoryFinished = payload => ({
	type: CUSTOMER_SET_WALLET_HISTORY_FINISHED,
	payload
})

export const setPointsHistoryAllData = payload =>({
	type: CUSTOMER_POINTS_HISTORY_ALL_DATA, 
	payload
})

export const setPointsHistoryRecivedData = payload =>({
	type: CUSTOMER_POINTS_HISTORY_RECIVED_DATA, 
	payload
})

export const setPointsHistoryUsedData = payload =>({
	type: CUSTOMER_POINTS_HISTORY_USED_DATA, 
	payload
})

export const setPointsHistoryExpiredData = payload =>({
	type: CUSTOMER_POINTS_HISTORY_EXPIRED_DATA, 
	payload
})

export const setShowRedeemCoupon = payload => ({
	type: CUSTOMER_SET_SHOW_REDEEM_COUPON,
	payload
})

export const setOrderListAll = payload =>({
	type: CUSTOMER_ORDER_LIST_ALL,
	payload
})

export const setOrderListUnpaid = payload =>({
	type: CUSTOMER_ORDER_LIST_UNPAID,
	payload
})

export const setOrderListProcessing = payload =>({
	type: CUSTOMER_ORDER_LIST_PROCESSING,
	payload
})

export const setOrderListShipped = payload =>({
	type: CUSTOMER_ORDER_LIST_SHIPPED,
	payload
})

export const setOrderListConfirmed = payload =>({
	type: CUSTOMER_ORDER_LIST_CONFIRMED,
	payload
})

export const setOrderListReturn = payload =>({
	type: CUSTOMER_ORDER_LIST_RETURN,
	payload
})

export const setRedeemCouponList = payload => ({
	type: CUSTOMER_SET_REDEEM_COUPON_LIST,
	payload
})

export const setWishListData = payload =>({
	type: CUSTOMER_WISHLIST_DATA, 
	payload
})

export const setPointsGuide = payload =>({
	type: CUSTOMER_POINTS_GUIDE, 
	payload
});

export const setOrderDetail = payload =>({
	type: CUSTOMER_ORDER_DETAIL,
	payload
});

export const setOrderDetailEmpty = payload =>({
	type: CUSTOMER_ORDER_RETURN_DETAIL_EMPTY,
	payload
});

export const setOrderReturnDetail = payload =>({
	type: CUSTOMER_ORDER_RETURN_DETAIL,
	payload
});

export const setOrderReview = payload =>({
	type: CUSTOMER_ORDER_REVIEW,
	payload
})

export const setOrderOftenWith = payload =>({
	type: CUSTOMER_ORDER_OFTEN_BOUGHT_WITH,
	payload
})

export const setSuccessReminder = payload =>({
	type: CUSTOMER_SUCCESS_REMINDER,
	payload
})

export const setCustomerLoading = payload =>({
	type: CUSTOMER_SET_LOADING,
	payload
})


export const setTicketList = payload => ({
	type: CUSTOMER_GET_TICKET_LIST,
	payload
})

export const addTicketList = payload => ({
	type: CUSTOMER_ADD_TICKET_LIST,
	payload
})

export const setTicketListLoading = payload => ({
	type: CUSTOMER_TICKET_LIST_LOADING,
	payload
})

export const setTicketListFinished = payload => ({
	type: CUSTOMER_TICKET_LIST_FINISHED,
	payload
})

export const setTicketListSkip = payload => ({
	type: CUSTOMER_TICKET_LIST_SKIP,
	payload
})

export const setTicketQuestionType = payload => ({
	type: CUSTOMER_TICKET_SET_QUESTION_TYPE,
	payload
})

export const setTicketOrder = payload => ({
	type: CUSTOMER_GET_TICKET_ORDER,
	payload
})

export const addTicketOrder = payload => ({
	type: CUSTOMER_ADD_TICKET_ORDER,
	payload
})

export const setTicketOrderLoading = payload => ({
	type: CUSTOMER_TICKET_ORDER_LOADING,
	payload
})

export const setTicketOrderFinished = payload => ({
	type: CUSTOMER_TICKET_ORDER_FINISHED,
	payload
})

export const setTicketOrderSkip = payload => ({
	type: CUSTOMER_TICKET_ORDER_SKIP,
	payload
})

export const setTicketSelectId = payload => ({
	type: CUSTOMER_TICKET_ORDER_SELECT_ID,
	payload
})

export const setTicketQuestions = payload => ({
	type: CUSTOMER_TICKET_QUESTIONS,
	payload
})

export const setTicketQuestionsTree = payload => ({
	type: CUSTOMER_TICKET_QUESTIONS_TREE,
	payload
})

export const setWishListFilter = payload => ({
	type: CUSTOMER_WISH_LIST_FILTER,
	payload
})

export const setWishListFilterCount = payload => ({
	type: CUSTOMER_WISH_LIST_FILTER_COUNT,
	payload
})

export const initWishList = payload => ({
	type: CUSTOMER_WISH_LIST_INIT,
	payload
})

export const appendWishList = payload => ({
	type: CUSTOMER_WISH_LIST_APPEND,
	payload
})

export const unshiftWishList = payload => ({
	type: CUSTOMER_WISH_LIST_UNSHIFT,
	payload
})

export const setWishListCount = payload => ({
	type: CUSTOMER_WISH_LIST_COUNT,
	payload
})

export const setWishListLoading = payload => ({
	type: CUSTOMER_WISH_LIST_LOADING,
	payload
})

export const setWishListFinished = payload => ({
	type: CUSTOMER_WISH_LIST_FINISHED,
	payload
})

export const setWishListLastToken = payload => ({
	type: CUSTOMER_WISH_LIST_LAST_TOKEN,
	payload
})

export const action_fetch_customer = headers => {
	return async dispatch => {
		let response = await fetchCustomer(headers);
		if (response?.code === 200) {
			dispatch(setCustomer(response?.result));
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_address_list = headers => {
	return async dispatch => {
		let response = await fetchMeAddressList(headers)
		if (response?.code === 200) {
			dispatch(setAddressList(response?.result))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_set_default_address = (id, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { addressList } = pageCustomer || {}
		let response = await setDefaultAddress(id, headers)
		if (response?.code === 200) {
			let addressListNew = _.cloneDeep(addressList)
			_.each(addressListNew, (address) => {
				address.isDefaultAddress = false
				if (address?.id == id) {
					address.isDefaultAddress = true
				}
				return address
			})
			dispatch(setAddressList(addressListNew))
		} else {
			return null
		}
	}
}

export const action_get_credit_cards = (headers) => {
	return async dispatch => {
		const response = await Promise.all([getCreditCards(headers), getMercadoCreditCards(headers)])
		let cardsList = []
		if (response[0]?.code == 200 && Array.isArray(response[0]?.result)) {
			cardsList = cardsList.concat(response[0]?.result)
		}
		if (response[1]?.code == 200 && Array.isArray(response[1]?.result)) {
			cardsList = cardsList.concat(response[1]?.result)
		}
		dispatch(setCreditCards([...cardsList]))
	}
}

export const action_delete_credit_card = (cardId, payMethod, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { creditCards } = pageCustomer || {}
		let cardsList = _.cloneDeep(creditCards)
		let response
		if (payMethod !== '19') {
			response = await deleteCreditCards(cardId, headers)
		} else {
			response = await deleteMercadoCreditCards(cardId, headers)
		}
		if (response?.code == 200) {
			if(response?.result?.length > 0){
				dispatch(setCreditCards(response?.result))
			} else {
				dispatch(setCreditCards([]))
			}
			return cardsList
		} else {
			return null
		}

	}
}

export const action_fetch_obj_message = (code, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { message } = pageCustomer || {}
		const response = await fetchMessageToObject(code, headers)
		if (response?.code == 200) {
			let obj = {}
			obj[code] = response?.result
			dispatch(setMessage({ ...message, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_message = (code, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { message } = pageCustomer || {}
		const response = await fetchGetMessage(code, headers)
		if (response?.code == 200) {
			let obj = {}
			obj[code] = response?.result?.message
			dispatch(setMessage({ ...message, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_notification_no_read = (headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notificationNoRead } = pageCustomer || {}

		let fetchActivity = getActivityNotificationNoRead(headers)
		let fetchPromo = getPromoNotificationNoRead(headers)
		let fetchOthers = getOthersNotificationNoRead(headers)
		const response = await Promise.all([fetchActivity, fetchPromo, fetchOthers])
		let noReadObj = {
			activity: 0,
			promo: 0,
			others: 0,
		}
		if (response[0]?.code == 200) {
			noReadObj.activity = response?.[0]?.result || 0
		}
		if (response[1]?.code == 200) {
			noReadObj.promo = response?.[1]?.result || 0
		}
		if (response[2]?.code == 200) {
			noReadObj.others = response?.[2]?.result || 0
		}
		dispatch(setNotificationNoRead({ ...notificationNoRead, ...noReadObj }))
	}
}

export const action_fetch_activity_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notifications, notificationLoading, notificationSkip, notificationFinished } = pageCustomer || {}
		if (notificationLoading?.activity || notificationFinished?.activity) {
			return
		}
		dispatch(setNotificationLoading({ activity: true }))
		let skipNum = skip ? skip : (Number(notificationSkip?.activity) || 0)
		const response = await getActivityNotification(skipNum, headers)
		dispatch(setNotificationLoading({ activity: false }))
		if (response?.code == 200) {
			let obj = {}
			obj['activity'] = notifications?.activity ? [...notifications?.activity, ...response?.result] : response?.result
			if (Array.isArray(response?.result) && response?.result?.length < 20) {
				dispatch(setNotificationFinished({ activity: true }))
			} else {
				dispatch(setNotificationSkip({ activity: skipNum + 20 }))
			}
			dispatch(setNotifications({ ...notifications, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_promo_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notifications, notificationLoading, notificationSkip, notificationFinished } = pageCustomer || {}
		if (notificationLoading?.promo || notificationFinished?.promo) {
			return
		}
		dispatch(setNotificationLoading({ promo: true }))
		let skipNum = (skip || skip === 0) ? skip : (Number(notificationSkip?.promo) || 0)
		const response = await getPromoNotification(skipNum, headers)
		dispatch(setNotificationLoading({ promo: false }))
		if (response?.code == 200) {
			let obj = {}
			obj['promo'] = notifications?.promo ? [...notifications?.promo, ...response?.result] : response?.result
			if (Array.isArray(response?.result) && response?.result?.length < 20) {
				dispatch(setNotificationFinished({ promo: true }))
			} else {
				dispatch(setNotificationSkip({ promo: skipNum + 20 }))
			}
			dispatch(setNotifications({ ...notifications, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_others_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notificationNoRead } = pageCustomer

		let fetchTicket = getTicketNotificationNoRead(headers)
		let fetchNews = getNewsNotificationNoRead(headers)
		let fetchOrders = getOrdersNotificationNoRead(headers)
		const response = await Promise.all([fetchTicket, fetchNews, fetchOrders])
		let noReadObj = {
			ticket: 0,
			news: 0,
			orders: 0
		}
		if (response[0]?.code == 200) {
			noReadObj.ticket = response?.[0]?.result || 0
		}
		if (response[1]?.code == 200) {
			noReadObj.news = response?.[1]?.result || 0
		}
		if (response[2]?.code == 200) {
			noReadObj.orders = response?.[2]?.result || 0
		}
		dispatch(setNotificationNoRead({ ...notificationNoRead, ...noReadObj }))
	}
}

export const action_fetch_ticket_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notifications, notificationLoading, notificationSkip, notificationFinished } = pageCustomer || {}
		if (notificationLoading?.ticket || notificationFinished?.ticket) {
			return
		}
		dispatch(setNotificationLoading({ ticket: true }))
		let skipNum = skip ? skip : (Number(notificationSkip?.ticket) || 0)
		const response = await getTicketNotification(skipNum, headers)
		dispatch(setNotificationLoading({ ticket: false }))
		if (response?.code == 200) {
			let obj = {}
			obj['ticket'] = notifications?.ticket ? [...notifications?.ticket, ...response?.result] : response?.result
			if (Array.isArray(response?.result) && response?.result?.length < 20) {
				dispatch(setNotificationFinished({ ticket: true }))
			} else {
				dispatch(setNotificationSkip({ ticket: skipNum + 20 }))
			}
			dispatch(setNotifications({ ...notifications, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_orders_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState() 
		const { notifications, notificationLoading, notificationSkip, notificationFinished } = pageCustomer || {}
		if (notificationLoading?.orders || notificationFinished?.orders) {
			return
		}
		dispatch(setNotificationLoading({ orders: true }))
		let skipNum = skip ? skip : (Number(notificationSkip?.orders) || 0)
		const response = await getOrdersNewNotification(skipNum, headers)
		dispatch(setNotificationLoading({ orders: false }))
		if (response?.code == 200) {
			let obj = {}
			obj['orders'] = notifications?.orders ? [...notifications?.orders, ...response?.result] : response?.result
			if (Array.isArray(response?.result) && response?.result?.length < 20) {
				dispatch(setNotificationFinished({ orders: true }))
			} else {
				dispatch(setNotificationSkip({ orders: skipNum + 20 }))
			}
			dispatch(setNotifications({ ...notifications, ...obj }))
			return obj
		} else {
			return null
		}
	}
}

export const action_fetch_news_notification = (skip, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState()
		const { notifications, notificationLoading, notificationSkip, notificationFinished } = pageCustomer || {}
		if (notificationLoading?.news || notificationFinished?.news) {
			return
		}
		dispatch(setNotificationLoading({ news: true }))
		let skipNum = skip ? skip : (Number(notificationSkip?.news) || 0)
		const response = await getNewsNotification(skipNum, headers)
		dispatch(setNotificationLoading({ news: false }))
		if (response?.code == 200) {
			let obj = {}
			obj['news'] = notifications?.news ? [...notifications?.news, ...response?.result] : response?.result
			if (Array.isArray(response?.result) && response?.result?.length < 20) {
				dispatch(setNotificationFinished({ news: true }))
			} else {
				dispatch(setNotificationSkip({ news: skipNum + 20 }))
			}
			dispatch(setNotifications(obj))
			return obj
		} else {
			return null
		}
	}
}


export const action_fetch_coupons = (type, skip, headers) => {
	return async (dispatch, getState) => {
		let response, skipNum;
		const {pageCustomer} = getState()
		const {expiredCoupon} = pageCustomer || {}
		if(type == 'unused'){
			// 没用过的优惠券接口没有分页
			response = await getWalletCoupons(headers)
		} else {
			if(expiredCoupon?.loading || expiredCoupon?.finished){
				return
			}
			dispatch(setExpiredCoupon({...expiredCoupon, loading:true}))
			skipNum = skip || expiredCoupon?.skip || 0
			response = await getExpiredCoupons(skipNum, 2,headers)
		}
		if(response?.code == 200 && Array.isArray(response?.result) && response?.result?.length > 0){
			let obj = {}
			obj[type] = response?.result

			dispatch(setExpiredCoupon({...expiredCoupon, loading:false, skip: skipNum+20}))
			dispatch(setCouponList(obj))
			return obj
		} else {
			dispatch(setExpiredCoupon({...expiredCoupon, loading:false, finished: true}))
			return null
		}
	}
}

export const action_fetch_show_redeem = (headers) => {
	return async (dispatch) => {
		const response = await getShowRedeemCoupon(headers)
		if(response?.code == 200 && response?.result){
			dispatch(setShowRedeemCoupon(true))
			return true
		} else {
			return null
		}
	}
}

export const action_fetch_redeem_coupon_list = headers => {
	return async dispatch => {
		const response = await getPointsCouponList(headers)
		if(response?.code == 200 && response?.result && Array.isArray(response?.result) && response?.result?.length > 0){
			dispatch(setRedeemCouponList(response?.result))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_wallet = (headers) => {
	return async dispatch => {
		const response = await getCashWallet(headers)
		if(response?.code == 200){
			dispatch(setWallet(response?.result))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_wallet_history_all = (skip, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {walletHistory, walletHistorySkip, walletHistoryLoading, walletHistoryFinished} = pageCustomer || {}
		if(walletHistoryLoading?.all || walletHistoryFinished?.all){
			return
		}
		let limit = 20, url = 'get-all';
		dispatch(setWalletHistoryLoading({all: true}))
		let skipNum = skip || walletHistorySkip?.all || 0
		const response = await getCashHistoryRecord(skipNum, limit, url, headers)
		dispatch(setWalletHistoryLoading({all: false}))
		if(response?.code == 200){
			if(response?.result?.length < 20){
				dispatch(setWalletHistoryFinished({all: true}))
			} else {
				dispatch(setWalletHistorySkip({all: walletHistorySkip?.all + 20}))
			}
			let list = walletHistory?.all ? [...walletHistory?.all, ...response?.result]: [...response?.result]
			dispatch(setWalletHistory({all: list}))
			return {all: response?.result}
		} else {
			return null
		}
	}
}

export const action_fetch_wallet_history_earned = (skip, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {walletHistory, walletHistorySkip, walletHistoryLoading, walletHistoryFinished} = pageCustomer || {}
		if(walletHistoryLoading?.earned || walletHistoryFinished?.earned){
			return
		}
		let limit = 20, url = 'get-recived';
		dispatch(setWalletHistoryLoading({earned: true}))
		let skipNum = skip || walletHistorySkip?.earned || 0
		const response = await getCashHistoryRecord(skipNum, limit, url, headers)
		dispatch(setWalletHistoryLoading({earned: false}))
		if(response?.code == 200){
			if(response?.result?.length < 20){
				dispatch(setWalletHistoryFinished({earned: true}))
			} else {
				dispatch(setWalletHistorySkip({earned: walletHistorySkip?.earned + 20}))
			}
			let list = walletHistory?.earned ? [...walletHistory?.earned, ...response?.result]: [...response?.result]
			dispatch(setWalletHistory({earned: list}))
			return {earned: response?.result}
		} else {
			return null
		}
	}
}

export const action_fetch_wallet_history_expired = (skip, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {walletHistory, walletHistorySkip, walletHistoryLoading, walletHistoryFinished} = pageCustomer || {}
		if(walletHistoryLoading?.expired || walletHistoryFinished?.expired){
			return
		}
		let limit = 20, url = 'get-expired';
		dispatch(setWalletHistoryLoading({expired: true}))
		let skipNum = skip || walletHistorySkip?.expired || 0
		const response = await getCashHistoryRecord(skipNum, limit, url, headers)
		dispatch(setWalletHistoryLoading({expired: false}))
		if(response?.code == 200){
			if(response?.result?.length < 20){
				dispatch(setWalletHistoryFinished({expired: true}))
			} else {
				dispatch(setWalletHistorySkip({expired: walletHistorySkip?.expired + 20}))
			}
			let list = walletHistory?.expired ? [...walletHistory?.expired, ...response?.result]: [...response?.result]
			dispatch(setWalletHistory({expired: list}))
			return {expired: response?.result}
		} else {
			return null
		}
	}
}

export const action_fetch_wallet_history_used = (skip, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {walletHistory, walletHistorySkip, walletHistoryLoading, walletHistoryFinished} = pageCustomer || {}
		if(walletHistoryLoading?.used || walletHistoryFinished?.used){
			return
		}
		let url = 'get-used',limit = 20;
		dispatch(setWalletHistoryLoading({used: true}))
		let skipNum = skip || walletHistorySkip?.used || 0
		const response = await getCashHistoryRecord(skipNum, limit, url, headers)
		dispatch(setWalletHistoryLoading({used: false}))
		if(response?.code == 200){
			if(response?.result?.length < 20){
				dispatch(setWalletHistoryFinished({used: true}))
			} else {
				dispatch(setWalletHistorySkip({used: walletHistorySkip?.used + 20}))
			}
			let list = walletHistory?.used ? [...walletHistory?.used, ...response?.result]: [...response?.result]
			dispatch(setWalletHistory({used: list}))
			return {used: response?.result}
		} else {
			return null
		}
	}
}

export const action_fetch_points_history_all = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { pointsHistoryAll } = pageCustomer;
		const { data, skip, finished } = pointsHistoryAll;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setPointsHistoryAllData({loading:true}));
		let response = await fetchPointsHistoryAll(skip, headers);
		dispatch(setPointsHistoryAllData({loading:false}));
		if(response?.code === 200 && response?.result){
			let actionData = {
				data:[...data, ...response.result],
				finished: response?.result?.length < 20,
				skip:skip+20
			}; 
			dispatch(setPointsHistoryAllData(actionData));
		}

		return response;
	}
}

export const action_fetch_points_history_recived = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { pointsHistoryRecived } = pageCustomer;
		const { data, skip, finished } = pointsHistoryRecived;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setPointsHistoryRecivedData({loading:true}));
		let response = await fetchPointsHistoryRecived(skip, headers);
		dispatch(setPointsHistoryRecivedData({loading:false}));
		if(response?.code === 200 && response?.result){
			let actionData = {
				data:[...data, ...response.result],
				finished: response?.result?.length < 20,
				skip:skip+20
			}; 
			dispatch(setPointsHistoryRecivedData(actionData));
		}

		return response;
	}
}

export const action_fetch_points_history_used = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { pointsHistoryUsed } = pageCustomer;
		const { data, skip, finished } = pointsHistoryUsed;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setPointsHistoryUsedData({loading:true}));
		let response = await fetchPointsHistoryUsed(skip, headers);
		dispatch(setPointsHistoryUsedData({loading:false}));
		if(response?.code === 200 && response?.result){
			let actionData = {
				data:[...data, ...response.result],
				finished: response?.result?.length < 20,
				skip:skip+20
			}; 
			dispatch(setPointsHistoryUsedData(actionData));
		}

		return response;
	}
}

export const action_fetch_points_history_expired = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { pointsHistoryExpired } = pageCustomer;
		const { data, skip, finished } = pointsHistoryExpired;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setPointsHistoryExpiredData({loading:true}));
		let response = await fetchPointsHistoryExpired(skip, headers);
		dispatch(setPointsHistoryExpiredData({loading:false}));
		if(response?.code === 200 && response?.result){
			let actionData = {
				data:[...data, ...response.result],
				finished: response?.result?.length < 20,
				skip:skip+20
			}; 
			dispatch(setPointsHistoryExpiredData(actionData));
		}

		return response;
	}
}

export const action_fetch_wishlist_filter = (headers) => {
	return async (dispatch) => {
		let response = await getWishListFilter(headers)
		if(response?.code == 200 && response?.result){
			dispatch(setWishListFilter(response?.result))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_wishlist_count = (headers) => {
	return async (dispatch) => {
		let response = await getWishListTotalCount(headers)
		if(response?.code == 200){
			dispatch(setWishListCount(response?.result))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_wishlist_filter_count = (filter, headers) => {
	return async (dispatch) => {
		let response = await fetchWishListCount(filter, headers)
		if(response?.code == 200){
			dispatch(setWishListFilterCount(response?.result || 0))
			return response?.result
		} else {
			return null
		}
	}
}

export const action_init_wishlist = (filter, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {wishListLoading} = pageCustomer || {}
		dispatch(setWishListFinished(false))
		dispatch(setWishListLastToken(null))
		dispatch(initWishList(null))
		if(!wishListLoading){
			dispatch(setWishListLoading(true))
			let params = filter
			let response = await fetchWishListFilterResult(params, headers)
			dispatch(setWishListLoading(false))
			if(response?.code == 200 && response?.result){
				dispatch(initWishList(response?.result?.products))
				if(response?.result?.products?.length <= 1 || !response?.result?.lastToken){
					dispatch(setWishListFinished(true))
				}
				dispatch(setWishListLastToken(response?.result?.lastToken || null))
				return response?.result?.products
			} else {
				return null
			}
		}
	}
}

export const action_append_wishlist = (filter, headers) => {
	return async (dispatch, getState) => {
		const {pageCustomer} = getState()
		const {wishListLoading, wishListFinished, wishListLastToken} = pageCustomer || {}
		if(!wishListLoading && !wishListFinished){
			dispatch(setWishListLoading(true))
			let params = wishListLastToken ? {...filter, lastToken: wishListLastToken}: filter
			let response = await fetchWishListFilterResult(params, headers)
			dispatch(setWishListLoading(false))
			if(response?.code == 200 && response?.result){
				dispatch(appendWishList(response?.result?.products))
				if(response?.result?.products?.length <= 1 || !response?.result?.lastToken){
					dispatch(setWishListFinished(true))
				}
				dispatch(setWishListLastToken(response?.result?.lastToken || null))
				return response?.result?.products
			} else {
				return null
			}
		}
	}
}

export const action_fetch_points_guide = (headers) =>{
	return async (dispatch ) =>{
		let response = await fetchMessageGetList("M1538", headers);
		if(response?.code === 200 && response?.result){
			dispatch(setPointsGuide(response.result));
			return response.result;
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_all = (refreshList = false, headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListAll } = pageCustomer;
		const { data, skip, finished } = orderListAll;
		if(!refreshList && finished){
			return new Promise((res, rej)=>{});
		}
		let paramSkip = refreshList ? 0 : skip;
		dispatch(setOrderListAll({loading:true}));
		let response = await fetchOrderListAll(skip, headers);
		dispatch(setOrderListAll({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data: refreshList ? [...response.result] : [...data, ...response.result],
					finished: response?.result?.length < 20,
					skip:paramSkip+20
				}; 
				dispatch(setOrderListAll(actionData));
	
				return response.result;
			}else{
				if(paramSkip === 0){
					dispatch(setOrderListAll({finished: true}));
				}
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_unpaid = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListUnpaid } = pageCustomer;
		const { data, skip, finished } = orderListUnpaid;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setOrderListUnpaid({loading:true}));
		let response = await fetchOrderListUnpaid(skip, headers);
		dispatch(setOrderListUnpaid({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data:[...data, ...response.result],
					finished: response.result.length < 20,
					skip:skip+20
				}; 
				dispatch(setOrderListUnpaid(actionData));

				return response.result;
			}else{
				if(skip === 0){
					dispatch(setOrderListUnpaid({finished: true}));
				}
				
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_processing = (headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListProcessing } = pageCustomer;
		const { data, skip, finished } = orderListProcessing;
		if(finished){
			return new Promise((res, rej)=>{});
		}
		dispatch(setOrderListProcessing({loading:true}));
		let response = await fetchOrderListProcessing(skip, headers);
		dispatch(setOrderListProcessing({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data:[...data, ...response.result],
					finished: response.result.length < 20,
					skip:skip+20
				}; 
				dispatch(setOrderListProcessing(actionData));

				return response.result;
			}else{
				if(skip === 0){
					dispatch(setOrderListProcessing({finished: true}));
				}
				
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_shipped = (refreshList = false, headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListShipped } = pageCustomer;
		const { data, skip, finished } = orderListShipped;
		if(!refreshList && finished){
			return new Promise((res, rej)=>{});
		}
		let paramSkip = refreshList ? 0 : skip;
		dispatch(setOrderListShipped({loading:true}));
		let response = await fetchOrderListShipped(paramSkip, headers);
		dispatch(setOrderListShipped({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data: refreshList ? [...response.result] : [...data, ...response.result],
					finished: response.result.length < 20,
					skip:paramSkip+20
				}; 
				dispatch(setOrderListShipped(actionData));

				return response.result;
			}else{
				if(paramSkip === 0){
					dispatch(setOrderListShipped({finished: true}));
				}
				
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_confirmed = (refreshList = false, headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListConfirmed } = pageCustomer;
		const { data, skip, finished } = orderListConfirmed;
		if(!refreshList && finished){
			return new Promise((res, rej)=>{});
		}
		let paramSkip = refreshList ? 0 : skip;
		dispatch(setOrderListConfirmed({loading:true}));
		let response = await fetchOrderListConfirmed(paramSkip, headers);
		dispatch(setOrderListConfirmed({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data: refreshList ? [...response.result] : [...data, ...response.result],
					finished: response.result.length < 20,
					skip:paramSkip+20
				}; 
				dispatch(setOrderListConfirmed(actionData));

				return response.result;
			}else{
				if(paramSkip === 0){
					dispatch(setOrderListConfirmed({finished: true}));
				}
				
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_list_return = (refreshList = false, headers) =>{
	return async (dispatch, getState) =>{
		const { pageCustomer } = getState();
		const { orderListReturn } = pageCustomer;
		const { data, skip, finished } = orderListReturn;
		if(!refreshList && finished){
			return new Promise((res, rej)=>{});
		}
		let paramSkip = refreshList ? 0 : skip;
		dispatch(setOrderListReturn({loading:true}));
		let response = await fetchReturnOrders(paramSkip, headers);
		dispatch(setOrderListReturn({loading:false}));
		if(response?.code === 200){
			if(response?.result?.length > 0){
				let actionData = {
					data: refreshList ? [...response.result] : [...data, ...response.result],
					finished: response.result.length < 20,
					skip:paramSkip+20
				}; 
				dispatch(setOrderListReturn(actionData));

				return response.result;
			}else{
				if(paramSkip === 0){
					dispatch(setOrderListReturn({finished: true}));
				}
				
				return null;
			}
		}else{
			return null;
		}
	}
}

export const action_fetch_order_detail = (orderId, headers) =>{
	return async (dispatch) =>{
		let response = await fetchOrderDetail({orderId}, headers);
		if(response?.code === 200){
			if(response?.result){
				dispatch(setOrderDetail(response.result));
				dispatch(setOrderDetailEmpty(false));
			} else {
				dispatch(setOrderDetailEmpty(true));
			}
			return response.result;
		}else{
			return null;
		}
	}
}

export const action_fetch_order_return_detail = (returnId, headers) =>{
	return async (dispatch) =>{
		let response = await fetchGetReturnOrderDetail(returnId, headers);
		if(response?.code === 200 && response?.result){
			dispatch(setOrderReturnDetail(response.result));
			return response.result;
		}else{
			return null;
		}
	}
}

export const action_fetch_order_review = (orderId, headers) =>{
	return async (dispatch) =>{
		let response = await fetchOrderReviewByOrderId({orderId}, headers);
		if(response?.code === 200 && response?.result){
			dispatch(setOrderReview(response.result));
			return response.result;
		}else{
			return null;
		}
	}
}

export const action_fetch_order_ofteh_bought_with = (orderId, headers) =>{
	return async (dispatch) =>{
		let products = await fetchOrderRecommend({skip:0, orderId}).then(response=>{
            const productItems = response?.result?.map(p => ({
                ...p,
                geekoRequsestId: response.requestId,
                geekoExperimentId: response.experimentId,
                layerName: response.layerName,
                trackingRecords: response.trackingRecords
            }))
            return productItems || [];
        }).catch(error=>[]);
		dispatch(setOrderOftenWith(products));
		return products;
	}
}


export const action_fetch_get_ticket_list = (skip, headers) => {
	return async dispatch => {
		dispatch(setTicketListFinished(false))
		dispatch(setTicketListLoading(true))
		let skipArg = skip || 0, limitArg = 20
		const response = await fetchTicketList(skipArg, limitArg, headers)
		dispatch(setTicketListLoading(false))
		if(response?.code == 200){
			if(response?.result?.length < 1){
				dispatch(setTicketListFinished(true))
			} else {
				dispatch(setTicketList(response?.result))
				dispatch(setTicketListSkip(limitArg))
			}
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_add_ticket_list = (skip, headers) => {
	return async (dispatch) => {
		dispatch(setTicketListLoading(true))
		let skipArg = skip || 0, limitArg = 20
		const response = await fetchTicketList(skipArg, limitArg, headers)
		dispatch(setTicketListLoading(false))
		if(response?.code == 200){
			if(response?.result?.length < 1){
				dispatch(setTicketListFinished(true))
			} else {
				dispatch(addTicketList(response?.result))
				dispatch(setTicketListSkip(skipArg + limitArg))
			}
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_ticket_question_type = (headers) => {
	return async (dispatch ) =>{
		let response = await fetchMessageGetList("M1551", headers);
		if(response?.code === 200 && response?.result){
			dispatch(setTicketQuestionType(response.result));
			return response.result;
		}else{
			return null;
		}
	}
}

export const action_fetch_get_ticket_order = (skipParams, type, clear, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState();
		const { ticketOrder } = pageCustomer || {}
		const { data, skip, loading, finished, selectId } = ticketOrder
		dispatch(action_fetch_ticket_order_status_change(''))
		dispatch(setTicketOrderFinished(false))
		dispatch(setTicketOrder([]))
		dispatch(setTicketOrderLoading(true))
		let skipArg = skipParams || 0, limitArg = 20
		let postApi = ''
		if(type == 'all'){
			postApi = fetchOrderListAll
		} else if(type == 'unpaid'){
			postApi = fetchOrderListUnpaid
		} else if(type == 'paid'){
			postApi = fetchOrderListPaid
		} else if(type == 'confirmed'){
			postApi = fetchOrderListConfirmed
		} else if(type == 'shipped'){
			postApi = fetchOrderListShipped
		} else if(type == 'canceled'){
			postApi = fetchOrderListCanceled
		} else if(type == 'processing'){
			postApi = fetchOrderListProcessing
		} else {
			postApi = fetchOrderListAll
		}
		const response = await postApi(skipArg, headers)
		dispatch(setTicketOrderLoading(false))
		if(response?.code == 200){
			if(!response?.result || response?.result?.length < 1){
				dispatch(setTicketOrderFinished(true))
			} else {
				let list = [...response?.result]
				list.forEach((l => {
					// if(l?.id == selectId){
					// 	l.selected = true
					// } else {
						l.selected = false
					// }
				}))
				dispatch(setTicketOrder([...list]))
				dispatch(setTicketOrderSkip(limitArg))
			}
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_add_ticket_order = (skipParams, type, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState();
		const { ticketOrder } = pageCustomer || {}
		const { data, skip, loading, finished, selectId } = ticketOrder

		dispatch(setTicketOrderLoading(true))
		let skipArg = skipParams || 0, limitArg = 20
		let postApi = ''
		if(type == 'all'){
			postApi = fetchOrderListAll
		} else if(type == 'unpaid'){
			postApi = fetchOrderListUnpaid
		} else if(type == 'paid'){
			postApi = fetchOrderListPaid
		} else if(type == 'confirmed'){
			postApi = fetchOrderListConfirmed
		} else if(type == 'shipped'){
			postApi = fetchOrderListShipped
		} else if(type == 'canceled'){
			postApi = fetchOrderListCanceled
		} else {
			postApi = fetchOrderListAll
		}
		const response = await postApi(skipArg, headers)
		dispatch(setTicketOrderLoading(false))
		if(response?.code == 200){
			if(!response?.result || response?.result?.length < 1){
				dispatch(setTicketOrderFinished(true))
			} else {
				let list = [...response?.result]
				list.forEach((l => {
					if(l?.id == selectId){
						l.selected = true
					} else {
						l.selected = false
					}
				}))
				dispatch(addTicketOrder([...list]))
				dispatch(setTicketOrderSkip(skipArg + limitArg))
			}
			return response?.result
		} else {
			return null
		}
	}
}

export const action_fetch_ticket_order_status_change = (id, headers) => {
	return async (dispatch, getState) => {
		const { pageCustomer } = getState();
		const { ticketOrder } = pageCustomer || {}
		const { data, skip, loading, finished, selectId } = ticketOrder

		let list = [...data]
		list.forEach(l => {
			if(l.id == id && !l.selected){
				l.selected = true
			} else {
				l.selected = false
			}
		})
		if(id != selectId){
			dispatch(setTicketSelectId(id))
		} else {
			dispatch(setTicketSelectId(null))
		}
		dispatch(setTicketOrder([...list]))
	}
}

export const action_fetch_ticket_order_clear = (headers) => {
	return async dispatch => {
		dispatch(setTicketOrder([]))
	}
}

export const action_fetch_ticket_questions = (headers) => {
	return async (dispatch, getState) => {
		const response = await fetchTicketQuestions(headers)
		if(response?.code == 200 && response?.result?.length > 0){
			dispatch(setTicketQuestions(response.result))
		}
	}
}

export const action_fetch_ticket_questions_tree = (headers) => {
	return async (dispatch, getState) => {
		const response = await fetchTicketQuestionsTree(headers)
		if(response?.code == 200 && response?.result?.length > 0){
			dispatch(setTicketQuestionsTree(response.result))
		}
	}
}