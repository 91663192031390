import { fetchSearchList, fetchSearchSorters, fetchSearchFilter } from '../../../api'


export const __Default_Search_Limit__ = 12
export const __Default_Search_Limit_For_DeskTop__ = 20


export const SEARCH_LIST_ADD = 'SEARCH_LIST_ADD'
export const SEARCH_LIST_SET = 'SEARCH_LIST_SET'
export const SEARCH_LIST_SKIP = 'SEARCH_LIST_SKIP'
export const SEARCH_LIST_FINISHED = 'SEARCH_LIST_FINISHED'
export const SEARCH_LIST_LOADING = 'SEARCH_LIST_LOADING'

export const SEARCH_FILTER_SET = 'SEARCH_FILTER_SET'
export const SEARCH_SORTERS_SET = 'SEARCH_SORTERS_SET'

export const SEARCH_FILTER_DATA = 'SEARCH_FILTER_DATA'

export const SEARCH_LIST_TOTAL = 'SEARCH_LIST_TOTAL';

export const setSearchFilterData = payload => ({
	type: SEARCH_FILTER_DATA,
	payload
})

export const setSearchFilter = payload => ({
	type: SEARCH_FILTER_SET,
	payload
})

export const setSearchSorters = payload => ({
	type: SEARCH_SORTERS_SET,
	payload
})


export const setSearchList = payload => ({
	type: SEARCH_LIST_SET,
	payload
})

export const addSearchList = payload => ({
	type: SEARCH_LIST_ADD,
	payload
})

export const setSearchSkip = payload => ({
	type: SEARCH_LIST_SKIP,
	payload
})

export const setSearchFinished = payload => ({
	type: SEARCH_LIST_FINISHED,
	payload
})

export const setSearchListLoading = payload => ({
	type: SEARCH_LIST_LOADING,
	payload
})

export const setSearchListTotal = payload =>({
	type: SEARCH_LIST_TOTAL,
	payload
}) 

export const action_add_search_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSearchListLoading(true))
		const limit = params?.limit || __Default_Search_Limit__
		const response = await fetchSearchList(data, {...params, limit}, headers)
		dispatch(setSearchListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addSearchList(products))
			dispatch(setSearchSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setSearchFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_search_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSearchFinished(false))
		dispatch(setSearchListLoading(true))
		dispatch(setSearchFilterData(data))

		const limit = params?.limit || __Default_Search_Limit__

		const response = await fetchSearchList(data, {...params, skip: 0, limit}, headers)
		dispatch(setSearchListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setSearchList(products))
			dispatch(setSearchSkip(limit))
			dispatch(setSearchListTotal(response.total))
			if(!products || products?.length < 1){
				dispatch(setSearchFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_search_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchSearchFilter(params, headers)
		if(response?.code === 200){
			dispatch(setSearchFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_search_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchSearchSorters(params, headers)
		if(response?.code === 200){
			dispatch(setSearchSorters(response.result))
			return response.result
		}else{
			return null
		}
	}
}