import React, { useCallback, useEffect, useRef, useState } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'

export default props => {

    useStyles(classes)
    const {downMenu} = props
    const timing = useRef(null)

    const [show, setShow] = useState(false)
    const [trigger, setTrigger] = useState(false)

    useEffect(() => {
        if(props.trigger > 0){
            setShow(true)
            setTrigger(true)
            timing.current = setTimeout(() => {
                setShow(false)
                setTrigger(false)
            }, 5000)
        }
    }, [props.trigger])

    const overHandle = useCallback(e => {
        setShow(true)
        setTrigger(false)
        clearTimeout(timing.current)
    }, [])
    const leaveHandle = useCallback(e => {
        setShow(false)
    }, [])

    return <div onMouseOver={overHandle} onMouseLeave={leaveHandle} className={classes.ToolIcon}>
        <div className={classes.Icon}>
            {props.children}
        </div>
        {
            show && downMenu && <div className={`${classes.DownMenu} ${trigger? classes.Trigger: ''}`} style={props.menuStyle}>
                {
                    downMenu
                }
            </div>
        }
    </div>
}