import React from 'react'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import {v4} from 'uuid'

const withAlert = Wrapped => {
    return props => {
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(document.getElementById('toast-container'))
        }
        return <Wrapped {...props}  onClose={onClose} uuid={v4()}/>
    }
}


export const Toast = withAlert(props => {

    const { message, uuid, onClose, options } = props


    useEffect(() => {
        setTimeout(() => {
            onClose()
        }, 5000)
    }, [])


    return <React.Fragment>
        <div className={`Toast ${options?.isDesktop?"DesktopToast":""}`} key={uuid}>
            <span dangerouslySetInnerHTML={{__html: message}}/>
        </div>
    </React.Fragment>
})


export default (message,options) => {
    ReactDOM.render(<Toast message={message} options={options}/>, document.getElementById('toast-container'))
}