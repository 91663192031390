export default {
    sort: 'Ordenar',//CM0768
    filter: 'Filtro',//CM0930
    loading: 'carregando',//CM0380
    finished: 'Finalizado',//
    home: 'Loja',//CM0141
    get: 'Get',//
    currency: 'Moeda',//CM0345
    language: 'Idioma',//CM0655
    order_tracking: 'Rastreio do pedido',//CM0825
    shipping_info: 'Informações de envio',//CM0837
    return_policy: 'Política de devolução',//CM0105
    privarcy_policy: 'Termos da política de privacidade e segurança',//CM0835
    get_app: 'Baixe o aplicativo {app}',//
    color: 'Cor',//CM0143
    size: 'Tamanho',//CM0142
    add_to_bag: 'Adicione ao carrinho',//
    clear: 'limpar',//CM0855 
    apply: 'Aplicar',//CM0545_1 
    search: 'procurar',//
    i_shipping_for: 'Estou procurando...',//CM0112
    cancel: 'Cancelar',//CM0765
    hot_search: 'Pesquisas populares',//CM0705
    items: 'Itens',//CM1032
    view_more: 'mostrar mais', //CM0153
    sold_out: 'Esgotado',//CM0366
    view_all: 'Mostrar Tudo',//CM0797
    thanks_subscribe: 'Obrigado por sua inscrição',//CM0956
    thanks_enjoy: 'Confira seu e-mail para ver o nome de usuário e senha.',//CM0957
    subscribe_title: 'Se inscreva para ofertas exclusivas!',//
    subscribe: 'Se inscreva',//CM0091
    smufcme:'Quero receber e-mails da {brand}Você pode cancelar sua inscrição a qualquer momento.',//CM0967
    c_info: 'Informações de {brand}',//CM0830
    about_us: 'Sobre nós',//CM0095
    affiliate: 'Política de Afiliados',//CM0823
    blogger_program: 'Planos para blogueiros',//CM0824
    help_support: 'Ajuda e suporte',//CM0831
    shipping_policy: 'Política de frete',//CM0106
    faq: 'Perguntas frequentes',//CM0099
    order_tracking: 'Rastreio do pedido',//CM0825
    coupons: 'Cupons',//CM0188
    bonus_points: 'Pontos de bônus',//CM1035
    customer_care: 'Atendimento ao cliente',//CM0832
    contact_us: 'Fale conosco',//CM0097
    return_policy: 'Política de devolução',//CM0105
    out_of_stock: 'Produtos em falta',//CM0621
    wholesale_policy: 'Política de atacado',//CM0108
    drop_shipping_policy: 'Política de envio doméstico',//CM0109
    i_p_rights: 'Direitos de propriedade intelectual',
    privacy_policy: 'Termos da política de privacidade e segurança',//CM0835
    term_notice: 'Aviso de termos e condições',//CM0836
    category: 'Categoria', //CM1019
    new_in: 'Novo',//CM0001
    bag: 'carrinho',//CM1021
    account: 'Conta',
    flash_sale: 'Promoções relâmpago',//CM0772
    ends_in: 'Acaba em', //CM1009
    buy_now: 'compre agora', //CM0665
    free_gift: 'Presentes gratis',
    multi_add: 'Desculpe, você só pode escolher um item como presente gratuito, se quiser adicionar outro item, ele substituirá o presente gratuito que você já adicionou.',
    learn_more: 'Saber mais',
    email: 'E-mail',
    recommend: 'Recomendar',
    confirm: 'confirmar',
    get_the_look:"pegar o look", //CM1187
    previous:"anterior", //CM1188
    next:"próximo", //CM1189
    beauty:"Beleza", //CM0322
    lingerie:"Lingerie", //CM0135
    shop_the_look:"Compre o look",
    good_to_know:"bom saber", //CM1131
    time_left:"Tempo restante", //CM0363
    limited_titme:"Tempo limitado", //CM0907
    flash_sale:"Promoção relâmpago", //CM0451
    new:"NOVIDADES", //CM0456
    more:"Mais",
    qty:"Quantidade", //CM0200
    reviews:"comentários", //CM0370
    recommend:"Recomendar", //CM1034
    you_can_match_with:"Você pode combinar com", //CM1012
    related_searches:"Pesquisas relacionadas", //CM1000
    too_small:"Muito pequeno", //CM0373
    ture_to_size:"Serviu bem", //CM0372
    to_large:"Muito grande", //CM0371
    translare_to:"Traduzir para", //CM1128
    translate:"traduzir", //CM1127
    overall_fit:"Ajuste geral",
    share_the_product_to:"Compartilhe o produto com",
    description:"Descrição", //CM1130
    ingredients:"Ingredientes", //CM1132
    estimated_time:"Tempo Estimado de Chegada", //CM1031
    model_stature:"Estatura Modelo", //CM0367
    model_wear:"Model Wear", //CM1015
    height:"Altura", //CM0971
    bust:"Busto", //CM0172
    waist:"Cintura", //CM0173
    hips:"Quadril", //CM0174
    shipping_information:"Informações de envio", //CM0149
    sizing_help:"Guia de tamanhos", //CM1014
    product_details:"Detalhes do produto", //CM1013
    you_may_also_likes:"VOCÊ TAMBÉM PODE GOSTAR DE", //CM0154
    if_the_item_comes_back:"Se o item voltar ao estoque em 30 dias, enviaremos um e-mail.", //CM1136
    you_haveArrival_reminder:"Você já se inscreveu para o lembrete de chegada", //CM1125
    sold_out_notification:"O item está esgotado, clique em notificar para notificação de reabastecimento", //CM1126
    un_subscribe:"Cancelar subscrição", //CM1123
    notify_me:"Me Notifique", //CM1124
    add_to_cart:"Adicionar ao carrinho", //CM0145
    product_measurements:"Medidas do produto", //CM1183
    view_size_information:"Veja mais informações de tamanho", //CM1129
    beauty_not_supported:"Devolução e troca de mercadorias não são suportadas.", //CM1134
    easy_return:"DEVOLUÇÃO FÁCIL", //CM1011
    find_simlar:"Encontrar semelhante", //CM1135
    babies:"Bebês",
    babies_look:"Look de bebês",
    shipping_method:"Forma de envio", //CM0623
    ship_current_address:"Este item não pode ser enviado para o endereço atual", //CM1184
    show:"Mostrar",
    index_subscribe_agree:"Ao se inscrever, você concorda com nossos",
    privacy_cookie:"Privacidade e cookies",
    terms_conditions:"termos e Condições",
    and:"e",
    register:"Registrar", //CM1204
    us_address_deliverd:"Estima-se que o {value} seja entregue em",
    us_address:"endereço dos Estados",
    enter_email_address:"Digite seu endereço de e-mail",
    mystery_offer:"OFERTA MISTÉRIO",
    get_mystery_offer:"Não quero minha oferta misteriosa",
    has_subscribe_to:"O Email tem sido utilizado por outros. Por favor, especifique outro Email.",
    no_more_data:"Sem mais dados", //CM0381
    add:"adicionar",
    shopping_for:"Estou procurando", //CM0112
    buy_now: 'compre agora', //CM0665
    visual_search:"Pesquisa Visual",
    upload_image_search_photo:"Tire uma foto ou carregue uma imagem para encontrar itens semelhantes.",
    recommend:"Recomendar", //CM1034
    new_arrivals:"Novas chegadas", //CM0340
    price:"Preço", //CM0946
    take_a_photo:"Tire uma foto",
    upload_a_image:"Faça upload de uma imagem",
    photo_upload_image:"Agora você pode pesquisar o item que deseja, tirando uma foto ou fazendo upload de uma imagem",
    not_match_fund:"Correspondente não encontrado",
    please_try_clearer_image:"Tente uma imagem mais clara ou coloque o produto que deseja pesquisar no meio da imagem",
    try_text_to_search:"Experimente o texto para pesquisar o item que deseja",
    try_text_search:"Experimente a Pesquisa de Texto",
    try_again_with_image:"Tente novamente com imagem",
    or:"ou", //CM0547
    low_price:"Preço baixo",
    high_price:"Preço Alto",
    register_success_message:"Verifique em sua caixa de entrada o {coupon} e a recompensa de {point}! Enviamos um e-mail de confirmação para {email}. O link é válido por apenas 72 horas.", //CM1111
    register_success_message_phone: "Verifique em sua caixa de entrada o {coupon} e a recompensa de {point}!",
    sign_in:"Entrar", //CM0354
    or_join_with:"Ou Junte-se a", //CM1236
    captcha_not_empty:"O CAPTCHA não pode estar vazio", //google
    forget_password:"Esqueci a senha", //CM0458
    email_address:"Endereço de e-mail", //CM0272
    password:"Senha", //CM0432
    six_characters_min:"6 caracteres no mínimo", //CM1100
    twenty_characters_max:"20 caracteres no máximo", //CM1101
    no_emojis:"Sem emojis", //CM1102
    belong_get_points_500:"500 pontos pertencerão a você imediatamente", //CM1178
    finshed_register_shop:"Conclua o registro e ganhe pontos de recompensa para fazer compras", //CM1179
    register_privacy_policy:"Eu concordo com o {term} e {privacy}", // CM1116 CM0462
    term_of_services:"Termos de serviço", //CM0103
    privacy_policy_only:"Política de privacidade", //CM0104
    create_account:"Criar conta", //CM0491
    coupon:"Cupom", //CM0543
    points:"Pontos", //CM1202
    welcome_to:"Bem-vindo ao", //CM1107
    registration_success:"Registro com sucesso", //CM1108
    reset_password:"Redefinir senha", //CM0443
    please_send_email_auto_matically:"Por favor, insira o endereço de e-mail que você registrou conosco. Um e-mail de recuperação de senha será enviado a você automaticamente.", //CM1089
    send_email:"Enviar e-mail", //CM1090
    link_rest_password_to:"O link para redefinir a senha foi enviado para", //CM1091
    create_new_password_link_easy:"Para criar sua nova senha, basta clicar no link do e-mail que lhe enviamos - fácil", //CM1092
    check_agin_enter_email_send:"Não recebeu? Verifique o seu lixo eletrônico ou clique no link abaixo e enviaremos um novo para você.", //CM1093
    still_online_help:"Se você ainda não conseguir encontrar, clique em \"Ajuda Online\" para obter ajuda", //CM1094
    three_hours_expires:"Aviso: o link expira em três horas", //CM1095
    resend_email:"Reenviar email", //CM1115
    advanced_messenger:"Serviço de mensagens avançado", //CM0948
    discount_deals_weekly:"Você receberá códigos de desconto e promoções especiais toda semana", //CM0950
    response_order_infomation:"Você pode receber respostas automáticas sobre as informações do pedido", //CM0951
    online_custoer_service:"Você pode ter atendimento ao cliente on-line", //CM0952
    continue:"Continuar", //CM0953
    password_incorrect:"Senha incorreta! Esqueceu sua senha?", //CM1097
    try_again:"Tente novamente", //CM1099
    emaul_no_register_now:"Esta conta de e-mail não está registrada, deseja registrar-se agora", //CM1103
    no_thanks:"NÃO, OBRIGADO", //CM1104
    register_has_login_now:"Esta conta de e-mail foi registrada, deseja fazer o login agora?", //CM1105
    log_in_now:"Entrar agora", //CM1106
    view_on_app:"Ver no aplicativo", //google
    today_no_change:"Você ficou sem chances hoje, vá às compras!", //CM1140
    go_shopping:"ir às compras", //CM1141
    player_again:"jogar novamente", //CM1142
    congratulations:"Parabéns", //CM0940
    please_try_again:"Ops, tente novamente", //CM1145
    go:"VAI", //CM1180
    my_prize:"Meus prêmios", //CM1081
    rules:"Regras", //CM0777
    download_app:"Baixe o APP", //CM1082
    expire_in:"Expira em", //CM1250
    please_select_size:"Por favor escolha Tamanho", // google
    ok:"Ok",// google
    my_prizes_rules:"Meus prêmios e regras", //CM1192
    you_have:"Você tem", //CM1038
    changes:"Chances", //CM1039
    customer_services_prizes:"Faça uma captura de tela e {value} para entrar em contato com o atendimento ao cliente para receber seu prêmio", //google
    click_here:"clique aqui", //CM1064
    first_lottery_login:"Faça login primeiro para jogar", // google
    count_items: "{total} itens",
    today_no_change_for_app:"Você ficou sem chances hoje. Ganhe uma oportunidade extra no {name} APP!",
    email_entered_try_again:"O e-mail que introduziu é inválido. Por favor, verifique o seu e-mail e tente novamente.",
    you_are_registerd:"Já está registado",
    subscribe_to_get_lottery_change:"*A oferta só pode ser resgatada por novos subscritores. Ao inscrever-se, concorda em receber e-mails de marketing recorrentes da {name} no endereço de e-mail utilizado ao inscrever-se.",
    invalid_password:"senha inválida",
    lucky_draw:"SORTE\nDESENHAR",
    lucky_draw_nobreak:"SORTE DESENHAR",
    fill_in_password:"Preencha a palavra-chave",
    subtext: "Gostaríamos de lhe enviar notificações para as últimas notícias e atualizações",//CM1008
    no_thanks: "Não, Obrigado",//CM1117
    allow: "Permitir", //CM1118
    draw_now:"Desenhar\nAgora",
    take_simlar_item_image:"Tire uma foto ou faça upload de uma imagem para encontrar itens semelhantes.",
    camera:"CÂMERA",
    images:"IMAGENS",
    shop_similar:"Compre semelhante",
    privacy_request_center: "Centro de solicitação de Privacidade",
    valid_code_sent: "O código de verificação foi enviado, por favor vá ao seu e-mail para verificar o código de verificação",
    sending: 'enviando',
    send: 'Enviar',
    submit_successfully: 'Submetido com sucesso',
    thanck_request: 'Obrigado pelo seu pedido. Um representante irá rever o seu pedido e contactá-lo no endereço de email que forneceu.',
    country_is_required: 'Por favor, seleccione o seu país/região.',
    name_is_required: 'Por favor, introduza o seu nome completo.',
    account_is_required: 'O e-mail que introduziu é inválido.',
    account_is_invalid: 'O e-mail que introduziu é inválido.',
    code_is_required: 'Por favor, introduza o código de verificação.',
    description_is_required: 'Por favor, descreva o seu problema em pormenor.',
    country: 'País/Região',
    place_holder_country: 'Por favor, escolha o seu País/Região.',
    full_name: 'Nome completo',
    place_holder_fullname: 'O nome completo deve conter 2-100 dígitos.',
    your_account: 'A sua conta {webname}',
    place_holder_account: 'Precisaremos que forneça o endereço de email associado à sua conta a fim de encontrar os dados pessoais que temos armazenados no nosso sistema.',
    verification_code: 'Código de verificação',
    detailed_description: 'Descrição detalhada',
    place_holder_description: 'Por favor, introduza uma descrição detalhada.',
    i_agree_to_the_privacy_policy: 'Concordo que a {webname} recolha e utilize os dados que submeto acima de acordo com a {policy} para processar o meu pedido.',
    submit: 'Submit',
    next_step: 'PASSO SEGUINTE',
    privacy_policy: 'Política de Privacidade & Cookie',
    verify_email_comfirm:"Por favor certifique-se de que o e-mail que introduziu {value} está correcto, se precisar de continuar o registo, por favor clique em confirmar.",
    how_to_pay:"Como pagar",
    share_to:"Compartilhe com ",
    copy_link:"Copy o Link",
    copy_success:"Cópia bem-sucedida",
    get_draw_chance:"ter 1 oportunidade extra",
    support_pre_sale_title: 'Antes da venda', //CM0860
    support_pre_sale: 'Se tiver alguma dúvida antes de fazer uma compra, converse com nossos operadores on-line para mais informações.',//CM0861
    support_after_sale_title: 'Pós-venda',//CM0862
    support_after_sale: 'Se precisar de ajuda com algum problema pós-venda, envie um ticket.',//CM0863
    online_help: 'Ajuda on-line',//CM0915
    submit_ticket: 'Enviar um ticket',//CM0864
    search_for: 'Estou procurando...',//CM0112
    clear_all: 'Limpar tudo',//CM0931
    empty_cart_description: 'Seu carrinho está vazio',//CM0521
    you_selected: 'Você selecionou {color}',//CM0475
    uploading_photo: 'Pesquise por fazer upload de uma foto',//CM1298
    upload_photo: 'Enviar foto',//CM0257
    price_low_to_high: 'Preço menor para o maior',//CM0845
    price_high_to_low: 'Preço maior para o menor',//CM0846
    shop_similar:'Compre o Semelhante',//CM1304
    uploading: 'Enviando',
    empty_filter_page: 'Ops! Não encontramos o que você estava procurando. Tente usar menos filtros.',//CM0955
    recently_search: 'Buscas Recentes', //CM1017
    my_account: 'Minha Conta', //CM0296
    my_orders: 'Meus pedidos', //CM0479
    my_message: 'Minha Mensagem',//CM1119
    my_coupons: 'Meus Cupons',//CM1120
    my_points: 'Meus Pontos', //CM1121
    view_bag: 'Ver o carrinho',//CM0183
    sign_out: 'Sair',//CM0254
    total: 'Total',//CM0180
    check_it_in_coupons: 'Verifique-o em [Os Meus Cupões]',
    got_it: 'Entendido',
    congratulations_get: 'Parabéns por receber {money}!',
    wait: 'Espere',
    limit_coupon_unused: 'Ainda tem cupão(s) limitado(s) não utilizado(s)',
    limit_24_time: '24 HORAS SOMENTE',
    return_to_site: 'Regressar ao site',
    get_offer:"Obter oferta",
    enter_email_to_reveal_offer:'insira seu e-mail para revelar sua oferta especial', //CM1256
    buy:"Compre", //CM0242
    simply_special_price:"Basta escanear o código QR para instalar e se preparar com o PREÇO ESPECIAL!", //CM1209
    model:"Modelo", // CM0970
    size_guide:"Guia de tamanhos", // CM0156
    weight:"Peso",
    customer_reviews:"Comentários de clientes", // CM1018
    size_chart:"Tabela de tamanhos", //CM0476
    support:"Suporte", //CM0094
    payment_method:"Formas de pagamento", //CM0389
    website_rights_reserved:"{name} é uma marca registrada de {domain}. Todos os direitos reservados.", //CM0834
    follow_us:"siga-nos", //CM0093
    shipping_time:"Tempo de entrega", //CM0377
    costs:"Custos", //CM0965
    shipping_to:"Enviar para", //CM0972
    receiving_time:"Prazo para receber", //CM0375
    precessing_time:"Tempo de processamento", //CM0376
    products: 'Produtos',
    promotions: 'Promoções',
    go_to_bag: "Vai para o carrinho",
    total_peice_price:"Total {total} preço | {price}",
    reset_qty:"Redefinir Qtd",
    best_sellers:"Mais vendidos",
    best_sellers_in:"Mais vendidos em",
    product_unavailable:'Produto indisponível no momento',
    save:"Salvar", //CM0212
    set:"definir",
    buy_together:"Compre junto",
    add_successful:"Adicionar com Sucesso",
    collect:"coletar",
    collect_all:"coletar todos",
    free_cash: 'Distribuição de dinheiro grátis!',
    win_100: '100 % Taxa de GANHOS!',
    ann_c_1: 'Cada Usuário Registrado tem 1 chance por dia e 1 chance extra após as compras!',
    ann_c_2: '*A chance diária será atualizada às 00:00 UTC, de 17 a 23 de agosto.',
    counpon_has_sent: '{coupon} foi enviado para sua carteira.',
    expire_content: 'Expira em 24h! Agora aplique na compra! <br/> *Não pode ser usado para deduzir as taxas de frete.',
    lucky_winner: 'VENCEDOR DE SORTE',
    view_prizes:"Veja os prêmios",
    continue_shopping: 'Continuar comprando',
    go_wallet: 'Ir para a Carteira',
    open:'Abrir',
    shopping: 'Comprando',
    check: 'Checar',
    day1:'1º dia',
    day2:'2º dia',
    day3:'3º dia',
    day4:'4º dia',
    day5:'5º dia',
    day6:'6º dia',
    day7:'7º dia',
    specialGift: 'Presente Especial',
    specialGifts: 'Presentes Especiais',
    today: 'Hoje',
    checkInSuccess: 'Check-in com sucesso',
    checkDays: 'Você fez check-in por {day} dias.',
    checkDay: 'Você fez check-in por {day} dia.',
    checkRules: 'Regras de check-in de aniversário',
    openGift: 'Toque para abrir o Presente Especial!',
    openNotificationTip: 'Não podemos enviar notificações. Por favor, vá para as configurações para abri-lo.',
    checkDaysLeft: 'Você fez check-in por {daysHave} dias consecutivos. Faça o check-in por mais dias para obter mais surpresas.',
    remindMe: 'Lembre-me',
    downloadNow: 'Baixe agora',
    alreadyChecked: 'Você já fez check-in hoje',
    checkedNow: '50.000+ PRESENTES, APRESSE-SE!!!',
    checkInNow: 'Faça o check-in agora',
    gift: 'Presente',
    v_shop:"V-Loja",
    comments:"Comentários",
    chart_ask_question:"Converse ou faça perguntas",
    tap_mystery_gift: 'Toque para abrir o Presente Misterioso!',
    mystery_gift: 'Presente misterioso',
    addtohometext: 'Adicionar {site} à página inicial',
    inches:'Polegadas',
    centimeters: 'centímetro',
    sizeconversation:'Conversa do Tamanho',
    size: 'Tamanho',
    bust:"Busto",
    waist:"cintura",
    hip: "quadril",
    length:"Comprimento",
    size_help: "Guia de tamanhos",
    fit_type:"Tipo de ajuste",
    stretch: "Esticar",
    product_measurements:"Medidas do produto",
    body_measurements:"Medidas do Corpo",
    bust_size:"Tamanho do busto",
    waist_size:"Tamanho da cintura",
    hip_size:"Tamanho do Quadril",
    your_bust:"Seu busto",
    your_waist:"Sua cintura",
    your_hips:"Seu quadril",
    measurements: 'Sugerimos fortemente que você tire suas medidas e as use como referência ao selecionar o tamanho de um item. Meça a si mesmo seguindo o guia abaixo.',
    bust_note: "Meça sob seus braços ao redor da parte mais larga do seu busto. Certifique-se de manter a fita nivelada!",
    waist_note: "Certifique-se de que a fita métrica se encaixa confortavelmente enquanto você mede em torno da parte mais estreita de sua cintura natural.",
    hip_note: "Fique com os pés juntos e meça em torno da parte mais larga dos quadril.",
    length_note: "Para medir o comprimento do pé, sente-se em uma cadeira e coloque o pé sobre um pedaço de papel. Trace o pé segurando um lápis verticalmente e contornando o pé. Usando uma régua, meça a distância da parte inferior do calcanhar até a ponta do dedo mais longo. Registre o resultado em polegadas e centímetros e use essas medidas para encontrar seu tamanho no gráfico.",
    product_measurements_message:"* Esses dados foram obtidos a partir da medição manual do produto, pode estar errado em 1-2 CM.",
    measure_product_size:"Como Medir o Tamanho do Produto?",
    body_measurements_message:"* Dependendo do seu tipo de corpo e hábitos de vestir, os tamanhos acima são apenas para referência.",
    measure_body_size:"Como medir seu corpo?",
    measure_body_size_bust:"Meça a circunferência sobre a parte mais larga do seu peito.",
    measure_body_size_waist:"Meça sua cintura no lugar mais magro.",
    measure_body_size_hip:"Meça a parte mais larga de seus quadril.",
    size_fit:"Tamanho & Ajuste",
    height_waist:"Altura/Peso",
    measure:"Medidas",
    model_wear:"Vestir do Modelo",
    one_size: 'Um tamanho',
    code:"código",
    policy:"política",
    after_pay_faq: "Pós-pagamento FAQ",
    klnara_policy_message:"Compre agora, pague depois com Klarna!",
    zip:"Zip",
    vip:"Vip",
    delivery:"Entrega",
    about_my_wallet:"Sobre minha carteira",
    system_update: 'Atualização do sistema',
    system_update_back: 'Para melhor atendê-lo, estamos atualizando nossos sistemas. Nós voltaremos logo!',
    about_early_bird:"Sobre o EarlyBird",
    clear_pay:"Clearpay",
    sold_percent: "{percent}% Vendido",
    purchase_limit: "Limite de compra: {qty} quantidade",
    agree_login_policy_message:"Antes da próxima etapa, confira se você aceitou os Termos de uso e a política de privacidade.", //CM0856
    register_with:"Cadastre-se no", //CM0463
    enjoy_services:"para aproveitar serviços personalizados,", //CM0465
    including:"incluindo", //CM0466
    online_order_status:"Status do pedido on-line", //CM0469
    exclusive_emails:"E-mails exclusivos", // CM0470
    wishlist:"Lista de desejos", // CM0344
    checkout_preferences:"Preferências de pagamento", // CM0471
    sign_In_Facebook:"Entrar com o Facebook", // CM1233
    the_counry_not_support_message:"O país/região atual não suporta login/registo de número de telefone, por favor, use o e-mail para fazer o login/registo.",
    enter_the_password:"Digite a senha",
    enter_the_code_number:"Digite o número de código",
    enter_phone_number:"Digite seu número de telefone",
    incorrect_phone_number:"Número de telefone incorreto",
    password_change_success:"Senha alterada com sucesso",
    confirm_password:"Confirmar senha",
    verification_code_error_message:"Desculpe, o código de verificação inserido é incorreto ou inválido. ",
    phone_register_has_already:"O número de telefone celular já foi registrado, assine agora?",
    phone_number_no_register_now:"Este número de telefone não está registrado, você quer se registrar agora?",
    location:"Localização",
    telephone_number:"Número de telefone",
    resend:"Enviar novamente",
    use_verification_code:"Use o código de verificação para fazer o login",
    sin_in_via_password:"Use a senha para fazer login",
    verifycation_code_success:"Código de verificação enviado com sucesso",
    confirm_your_phone_number_change:"Confirme seu número de telefone e digite o código correto para mudar a senha.",
    inconststent_password:"Senhas inconsistentes",
    register_via_telephone_number:"Cadastre-se por telefone",
    register_via_email_address:"Cadastre-se por e-mail",
    signin_via_telephone_number:"Faça o login por número de telefone",
    signin_via_email_address:"Sign in por e-mail address",
    almost_sold_out: "Quase esgotado",
    telephone: 'Telefone',
    forget_your_password: 'Esqueceu sua senha?',
    choose_your_location: 'Favor selecionar sua localização',
    installment_forms: 'Formulários de Parcelamento',
    interest_free: 'Sem juros',
    staging_rates: 'Taxas de preparo variam de acordo com os bancos',
    accpect: 'Aceitar',
    sing_receive_discount_now:"Faça agora o login para usufruir dos seguintes descontos",
    received_following_discount:"Você recebeu os seguintes cupons",
    received_vip_exclusive_discount:"Você recebeu descontos exclusivos para VIP",
    pick_my_gift:"Escolher meu presente",
    choose_add_your_cart:"Por favor, escolha o seu presente favorito para adicionar ao seu carrinho.",
    reselect_gift_your_bag:"Você pode selecionar novamente o presente em seu carrinho.",
    check_out: 'IR AO CARRINHO',
    gift_to_your_bag: 'O Conjunto de Natal foi colocado em seu carrinho, você pode usar a qualquer momento antes de 6 de dezembro.',
    foot_length: 'Comprimento do pé',
    network_error:'Erro de rede',
    subscription:"Assinatura", //CM0089
    invalid_email: 'O e-mail que você digitou é inválido. Verifique seu e-mail e tente novamente.',

    size_conversion: 'Conversão de tamanho',
    international_size_reference: 'A comparação de tamanho internacional é apenas para referência, o tamanho específico ainda é baseado no tamanho real das roupas.',
    country_size: 'Tamanho {country}',
    new_register_privacy_policy:"Ao acessar sua conta, você concorda com nossa Política de Privacidade e de Serviço", // CM1116 CM0462
    incorrect_email:"E-mail Incorreto",
    enter_code_number:"Digite o número de código",
    enter_the_telephone_number_or_email:"Favor inserir Telefone ou e-mail",
    enter_the_verification_code:"Digite o código de verificação",
    send_verification_code:"Um código de verificação é enviado para {value}",

    back: 'Voltar',
    free_shipping: 'Envio Grátis',
    all: 'Tudo',
    reset: 'Redefinir',
    done: 'Feito',
    price_range: 'Faixa de preço({currency})',
    price_range_only: 'Faixa de preço',
    repick: 'Repicar',
    pick: 'Escolher',
    sure_delete_item: 'Tem certeza de que deseja excluir este item?',
    sure_delete_items: 'Tem certeza de que deseja excluir este item(s)?',
    recommend_add_item: 'Itens adicionais recomendados',
    free_gifts: 'Presentes Grátis',

    available: 'Disponível',
    not: 'Não',
    select_anthoer_paymentmethod: 'Por favor, selecione outra forma de pagamento',
    sign_to_sync_bag: 'Assine para sincronizar sua sacola de compras',
    ship_to_different_address: 'Enviar para endereço de entrega diferente',
    address_book: 'Livro de endereços',
    first_name: 'Primeiro Nome',
    last_name: 'Sobrenome',
    phone_number: 'Número de telefone',
    address_line_1: 'Endereço',
    address_line_2: 'Unidade',
    address_line_1_placeholder: 'Nome da rua e número da rua, nome da empresa.',
    number: 'Número',
    neighborhood: 'Bairro',
    city:'Cidade',
    state: 'Estado',
    zip_code: 'Código-postal',
    enter_it_manually: 'Insira manualmente',
    optional: 'Opcional',
    find_simillar: 'Encontre semelhante',
    ships_to_country: 'Envios para: {country}',
    shopping_bag: 'carrinho de Compras',
    secure_payment: 'Pagamento seguro',
    shopping_bag_empty: 'Sua sacola de compras está vazia!',
    sign_in_to_view_bag: 'faça login para ver seu carrinho e começar comprando',
    shop_now: 'Compre agora',
    sign_in_register: 'Entrar/REGISTRE-SE',
    secure_checkout: 'Pagamento seguro',
    create_account_more_discount: 'Crie uma nova conta para obter mais desconto',
    continue_to_checkout: 'CONTINUAR PARA PAGAR',
    checkout_guest_with_email: 'Faça o pagamento como convidado com seu e-mail',
    guest_checkout: 'Comprar como convidado',
    checkout: 'PAGA',
    move_to_wishlist: 'Botar na Lista de desejos',
    delete: 'Deletar',
    move_all: 'Mover tudo',
    you_like_fill_it: 'você pode gostar de preenchê-lo com',
    add_to_wish_list_confirm: 'Tem certeza de que está movendo os produto(s) da sacola de compras para a lista de desejos?',
    apply_coupon: 'Aplicar cupom',
    ivalid_items_title: 'Esses itens estão temporariamente fora de estoque.',
    count_items: '{total} Itens',
    add_shipping_insurance: 'Adicionar seguro de envio',
    place_order: 'Fazer uma encomenda',
    apply_points: 'Aplicar pontos',
    max_available_count: 'Máximo disponível {count}',
    apply_cash: 'Aplicar dinheiro',
    wallet: 'Carteira',
    order_confirmation: 'confirmação do pedido',
    shipping_address: 'Endereço de envio',
    switch_payment_method: 'Mudar forma de pagamento',
    change_card_information: 'Alterar informações do cartão',
    process_payment: 'Estamos processando seu pagamento',
    do_not_leave_refresh: 'Por favor, não saia ou atualize',
    price_per_installment: '{price}/mês',
    order_summary: 'Resumo da Compra',
    shipping_insurance: 'Seguro de envio',
    order_number: 'Nr.de pedido',
    item_total: 'Total dos itens',
    shipping_price: 'Taxa de envio',
    order_total: 'Total da encomenda',

    required: 'Necessário',
    invalid_email_address: '{email} não é um e-mail válido',
    first_name_reg_default: 'O primeiro nome deve conter de 1 a 40 caracteres numéricos não puros',
	last_name_reg_default: 'O sobrenome deve conter de 1 a 40 caracteres numéricos não puros',
    phone_reg_10_11: 'Insira pelo menos 10-11 números.',
	phone_reg_ae: 'Os números de telefone devem começar com 50/52/54/55/56/58/2/3/4/6/7/9, seguidos por 7 dígitos.',
	phone_reg_sa: 'Os números de telefone devem começar com 50/53/55/51/58/59/54/56/57/11/12/13/14/16/17/811, seguido por 7 dígitos.',
	phone_reg_default: 'Formato de número de telefone incorreto (somente números, sem formatação)',
    please_enter_5_digits: 'Por favor, insira 5 dígitos ou 5 dígitos com um número de 4 dígitos (por exemplo, 20001 ou 20001-0000)',
	wrong_zip_code: 'CEP errado! Por favor, ofereça-nos uma ou duas letras e um e dois algarismos com um espaço final com um algarismo e duas letras. (por exemplo, TN37 7HL,CW8 3AD)',
	wrong_address_br: 'O formato do endereço está incorreto, preencha o formulário de [01310-000]',
    not_vaild_cpf: '{value} não é um CPF válido',

    credit_debit_card:'Cartão de Crédito / Débito',
    billing_address: 'Endereço de Cobrança',
	select_a_card: 'Selecione um cartão',
	use_new_card: 'Usar um novo cartão',
    waiting_for_payment:"Aguardando o pagamento",
	view:"mostrar",

    auto_search_address:'BUSCADOR DE ENDEREÇO: Pesquisar por código postal, rua ou endereço',
    cant_find_address: "Não consegue encontrar o endereço?",
    select_pay_method: 'Selecione uma forma de pagamento!',
    only_left: 'Apenas {count} restam',
    pay_now: 'pague agora',
    card_number: 'Número do Cartão',
    expiration_date: 'Data de Validade',

    confirm_change_address: 'Alguns itens enviados de lojas dos EUA não estarão disponíveis para pagamento. Tem certeza de que deseja trocar de endereço?',
    edit: 'Editar',
    add_new_address: 'Adicionar um novo Endereço',
    details: 'Detalhes',

    // Me
    login_or_register:"Entrar / Registrar",
    introduce_to_others:"Apresente-se aos outros",
    sure_delete_items:"Tem certeza de que deseja excluir o(s) item(ns)?",
    guest_user:"Usuário convidado",
    set_password:"Definir Senha",
    temporay_account_set_password:"Esta é sua conta provisória. Define uma senha para se registrar e obter mais desconto!",
    addFavorites:'Adicione seus favoritos à sua lista de desejos agora!',









    // Me Setting
    sure_delete_address: 'Você tem certeza de apagar este endereço',  //google
    change_successful: 'Mudança bem sucedida!',   //google
    change_failed: 'Mudança falhada!',  //google
    save_successful: 'Salvar o sucesso!',  //google
    save_failed: 'Salve Failed!',  //google
    change_email: 'Alterar e-mail',  //google
    your_contact_email: 'Seu endereço de contato / assinatura:',  //google
    send_confirm_email: 'Envie um e-mail de confirmação',
    your_new_account: 'Novo Endereço de Email',
    my_payment_options:'Minha opção de pagamento',
    change_password: 'Alterar senha',  // changePassword
    about: 'Cerca de',
    sure_delete_card: 'Tem certeza de que deseja remover este cartão de crédito', // remove_credit_card
    current_password:"Senha atual",
    new_password:"Insira a nova senha",
    must_new_password:"Confirme a Senha. Deve ser igual à nova senha.",
    confirm_pwd: 'Confirme a Senha',
    old_new_password:"As senhas antigas e novas não podem ser iguais",
    expired_in:"Expirado em",
    view_rules:"Ver Regras",
    vip_rewards:"Recompansa {name} VIP",
    level_up_unlock:"subir de nível para desbloquear",
    view_rewards:"Ver Recompensas",
    points_redeem_success:'Resgate com sucesso!',
    faqs:"FAQS",
    your_vip_level_to:"O seu nível VIP foi elevado até",
    your_new_rewards:"vai ver as suas novas recompensas!",
    points_history:"Histórico de pontos",
    expriring_soon:'Que expirarão em breve',
    how_to_get_points:'Como ganhar pontos?',
    buy_and_earn:'Compre e ganhe',
    shop_now:'Compre agora',
    review:"Comentários",
    suggestion:"Sugestão",
    refer_a_friend:'Indicar um amigo',
    survey:"Pesquisa",
    more_ways:'Mais maneiras',
    hot_use_points:'Como usar os pontos?',
    redeem_coupon:'Use um cupom',
    apply_points:'Aplicar pontos para a compra',
    all:'Tudo',
    recived:'Ganhado',
    used:'Usado',
    expired:'Expirado',
    wishlist:'Lista de desejos',
    delete:'Excluir',
    unused_coupon: 'Cupons não utilizados', // unused_coupons
    expired_coupon: 'Cupons Expirados', // expired_coupons
    expired_coupon_remove: 'Os cupões expirados serão retirados do bolso do cupão após 7 dias.', // expired_coupoons_message
    no:'NÃO',
    yes:'SIM',
    bust_size:'Tamanho do busto',
    bra_size:'Tamanho do sutiã',
    what_your_preference:'Qual é a sua preferência?',
    large:'Grande',
    small:'Pequeno',
    my_measurements:'Minhas Medidas',
    preference_cannot_empty: "Minhas preferências não podem estar todas vazias",  // my_preference_error_message
    my_preference:'Minha preferência',
    who_you_buy_clothes_for:'Para quem você costuma comprar roupas?',   // who_do_you_ususlly
    which_catehories_you_favor:'Quais são suas categorias favoritas?', // whitch_are_your_favorite
    which_style_you_favor: 'Quais são seus estilos favoritos?', // whitch_are_your_styles
    bio: 'Bio',
    name: 'Nome',
    first_name: 'Primeiro nome', // firstName
    last_name: 'Sobrenome', // lastName
    nick_name: 'Apelido',
    change_profile_picture:'Alterar a foto do perfil',
    edit_profile: 'Editar perfil', // editProfile
    empty_here: "Está vazio aqui", // google
    ticket:'Bilhete',
    activities:'Atividades',
    promo:'Promoção',
    others:'Outros',
    news:'Notícias',
    orders:'Pedidos',
    message:'Mensagem',
    about_web: 'Cerca de {website}',
    setting: 'Definições',  // settings
    wallet_credit: 'Crédito de carteira',
    only_apply_for: 'Aplicável apenas para Compras {website}', // only_applicable
    wallet_history: 'História da carteira',
    earned: 'Ganhado',
    wallet: 'Carteira',
    expired_on: 'Expirado em: {date}',  // expied_on
    order: 'Pedido', // order_wallet
    get_more_points:"Obtenha mais pontos",
    share_friend_points_shop:"Voc\u00EA obter\u00E1 at\u00E9 <span class=\"font-red\">500 pontos</span> , e seu amigo receber\u00E1 at\u00E9 <span class=\"font-red\">1000 pontos</span> para fazer compras!", // CM1158
    for_limited_time_only:"Por tempo limitado!", // CM1159
    how_does_it_works:"Como isso funciona?", // CM1161
    share_the_link:"Compartilhe o link", // CM1162
    reward:"Recompensa", //CM0630_1
    copy_fail: 'cópia falhou! tente novamente', // google
    get_more_rewards:"Quer receber mais recompensas?", //CM1164
    friends_invited:"Amigos convidados", // CM1167
    points_earned:"Pontos ganhos", // CM1168
    share_with:"COMPARTILHAR COM", // CM1170
    share_friend_max_10:"O link pode ser compartilhado com {value} amigos ao mesmo tempo", //CM1166
    email_title:"T\u00EDtulo do Email", //CM1155
    open_to_points_reward:"Aberto para receber recompensas de pontos misteriosos", //CM1242
    email_notes:"Notas de Email", //CM1156
    share_textarea_description:"{name} tem tantos itens obrigat\u00F3rios! Registre-se agora para pr\u00EAmios de pontos para comprar em {name}. {value}. N\u00E3o perca esta oportunidade!", // CM1243
    share_via_email:"Compartilhar via e-mail", // CM1151
    share_extra_game_play:"Clique no link e me consiga uma jogada extra", //google
    share_draw_luck_gifts:"{name} Black Friday Lucky Draw. Jogue e ganhe iPhone 13, {name} favoritos de beleza, Pontos de Bônus, Cupons e muito mais.",  //google
    success:"Sucesso", //CM0558
    order_no: 'Nr. do Pedido', // orderNo
    item:"Item",
    track:'Faixa',
    pay_now:'PAGAR', // paynow
    remaining:'Tempo de Pagamento Restante',
    repurchase:'Recompra',
    to_review:'Para revisar',
    add_success:'Adicionar sucesso',
    add_failed:'Falha ao adicionar',
    unpaid:'Não pago',
    processing:'Processamento',
    shipped:'enviado',
    returns:'Devolução',
    history_orders_message:'Clique aqui para verificar seus pedidos anteriores.',
    order_details:'Detalhes do pedido',
    your_package_is_packed:'Seu pacote está sendo embalado',
    it_normally_process_your_order:'Normalmente leva de 3 a 7 dias úteis para processarmos seu pedido.',
    your_package_signed_for:'Seu pacote foi assinado para.',
    if_you_cosutomer_services:'Se você tiver alguma dúvida',
    payment_date: 'Data de pagamento',  // paymentDate
    related_order_no: 'Pedido relacionado nº', // relatedOrderNo
    shipping_price: 'Frete', // shippingPrice
    order_total: 'Total da Pedido', // orderTotal
    insurance:"Seguro de envio", // shippingInsurance
    shipping_address:'Endereço de Envio',
    billing_address:'Endereço de cobrança',
    shipping_method:'Forma de envio',
    delivery_time:'Tempo de entrega',
    return:'Retornar',
    often_bought_with:'COMPRADO COM MUITAS VEZES',
    cancel_order: 'Cancelar Pedido', // cancelOrder
    return_label:"Etiqueta de devolução", // returnlabel
    confirm_cancel:'Confirmar Cancelar',
    continue_to_pay:'Continuar pagando',
    you_sure_cancel:'Tem certeza que deseja cancelar o pagamento? Se o seu pedido não for pago em',
    it_will_cancel:'será cancelado',
    select_reason:'Selecione um motivo',
    reason_submit_success_message:"Enviado com sucesso! Você gostaria de adicionar o produto ao seu saco de compras novamente?", // google
    no_comment_order:'Você tem pedidos não revisados, vá e compartilhe sua experiência para ganhar mais pontos!',
    you_have_no_orders: "Você ainda não tem pedidos, vá escolher seus produtos favoritos!", // youHaveNoOrders
    go_shopping: 'Ir às compras', // goShopping
    default: 'Padrão',
    do_not_have_enough_points: 'Você não tem pontos suficientes para resgatar este cupom.', // points_not_enough_text
    use_points_redeem_coupon: 'Deseja usar {points} pontos para resgatar este cupom?', // points_check_redeem_text
    redeem:'Resgatar', // points_redeem
    redeemed: 'Resgatado',
    use_points_to_redeem:'Troque seus pontos por mais cupons!', // use_points_redeem_coupon
    upload_image:'Enviar Imagem',
    image_formate:'Máximo de 3 fotos, apenas JPEG, GIF ou PNG.', // maxinum_three_photos
    screen_freeze:'Congelamento de tela',
    app_crush:'Esmagamento de App',
    black_screen:'Tela preta',
    device_reboot: 'Reinicialização do dispositivo', // device_rebot
    running_slowly:'Correndo devagar',
    other_error:'Outro erro',
    update_success:'Atualizado com sucesso',
    question_type:'Tipo de pergunta',
    time:'Tempo',
    suggestion_placeholder:'Desculpe o transtorno, vamos consertar o problema o mais rápido possível ...', // sorry_inconvenience
    points: 'Pontos',
    please_check_rules: 'Por favor, verifique a página de regras para regras de pontos de texto e imagem',
    rating:"Rating", // google
    very_satisfied:'Muito satisfeito',
    satisfied:'Satisfeito',
    normal:'Normal',
    bad:'Ruim',
    poor:'Muito Mau',
    order_review_placeholder:'Ganhe {point} pontos para comentários sobre {wordNum} caracteres... *', // google
    earn_more:'Ganhe mais {points} pontos por comentários com fotos',
    my_size_infomation:'Minha informação de tamanho (opcional)',
    earn_more_for_size:'Ganhe mais {pontos} pontos para preencher todos os tamanhos',
    comments_not_empty:"Os comentários não podem estar vazios!", // google
    order_confirmed:"Pedido confirmado", // order_confirm
    order_detail_view:'Você pode ver os detalhes do pedido para verificar este pedido.',
    order_confirm_success:'Pedido confirmado com sucesso!',
    view_order:'Ver pedido',
    order_confirmed_enter_message: 'O(s) item(s) ainda está(ão) em trânsito, você tem certeza que quer clicar em confirmar para recebê-lo?', //google
    also_awaiting_review:'Estes pedidos também estão aguardando avaliação',
    shop_the_reviewd_items:'Compre os itens altamente avaliados',
    add_to_bag_success:'Adicionar ao carrinho com sucesso!',
    logisticsInfo:'Informação Logística',
    tracknum:'Numero de rastreio',
    logisticsCompany:'Empresa de logística',
    trackingDetailInfo:'Informações detalhadas de rastreamento',
    currentStatus:'Status atual',
    cancel_return:'Cancelar Devolução',
    edit_return_receipt:'Editar recibo de devolução',
    return_receipt:'Recibo de Devolução',
    return_record:'Registro de retorno',
    return_canceled:'Devolução cancelada',
    total_refund:'Reembolso total',
    are_you_want_cancel_return:'Tem certeza que deseja cancelar a devolução?',
    upload_receipt:'Upload recibo',
    a_pooto_package_and_tracking_number:'Uma foto do recibo da transportadora indicando o peso da embalagem e o número de rastreamento.',
    courier_company:'Companhia de Courier ',
    select_courier_company:'Por favor seleccione a Empresa de Courier ', // please_select_courier_company
    fillin_courier_cpmpany:'Preencha por favor a sua empresa de Courier rápido',
    not_be_empty:'não pode estar vazio',
    check_order:'Você pode verificar os detalhes do seu pedido para ver o progresso de sua devolução.',
    return_logistics:'Logística de Devolução',
    find_similar:'Encontrar o Semelhante',
    history_orders:'Pedidos de História',
    dont_want_rate: 'Tem certeza que não quer avaliar este pedido? Você receberá ',

    help_you: 'Como posso ajudá-lo?',
    message_us: 'Mensagem para nós',
    tickets: 'Bilhetes',
    search_holder: 'Produtos, entrega, pagamento...',
    search_results: 'Procurar Resultados',
    search_count: '{count} resultados para "{key}"',
    section_articles: 'Artigos nesta seção',
    status_replied: 'Respondido',
    status_unreplied: 'Não respondido',
    status_resolved: 'Resolvido',
    last_message: 'Última mensagem',
    image: 'Imagem',
    ticket_id: 'Ticket ID',
    subject_size_color: 'Tamanho / preferência de cor',
    subject_address: 'Alterar endereço de entrega',
    subject_shipping: 'Status de envio ou consulta ETA',
    subject_wrong: 'Item danificado ou errado recebido',
    subject_shippingmethod: 'Atualizar o método de envio',
    subject_return: 'Devolução ou troca',
    subject_refund: 'Solicitar reembolso / cancelar pedido',
    payment_time: 'Tempo de pagamento',
    response_time: 'Tempo de resposta esperado: 1 dia útil',
    select_order: 'Por favor, selecione o seu pedido',
    order_status_confirmed: 'Confirmado',
    order_status_canceled: 'Cancelado',
    order_status_pendding: 'Pendente',
    order_status_paid: 'Pago',
    order_status_partially_refunded: 'Parcialmente reembolsado',
    order_status_refunded: 'reembolsado',
    order_status_held: 'Mantido',
    rate: 'Avaliação',
    unsatisfied: 'Insatisfeito',
    you_can_rate: 'Você pode comentar o atendimento ao cliente aqui',
    please_rate: 'Por favor avalie meu serviço',
    click:'Para todas as perguntas e respostas, clique em nosso',
    page:"página",
    find_nothing:"Não conseguiu encontrar a resposta que procura?",
    order_processing: "Processamento de Pedido",
    tracking:"Rastreamento Logístico",
    return_tag:"Devolução e reembolso",
    product_tag:"Produto e estoque",
    payment_tag:"Pagamento e promoções",
    account_isssues:"Problemas de conta",
    ticket_time: 'SOMENTE para problema pós-venda (Cancelar / Editar pedido / Status de envio / Devolução / Troca etc.)',
    online_time: 'SOMENTE para problemas de pré-venda. As vendas online não têm acesso às informações do pedido. ',
    result_for: "resultados para \"<strong>{result}</strong>\"",
    TicketTime: 'SOMENTE para problema pós-venda （Cancelar / Editar pedido / Status de envio / Devolução / Troca etc.)',
    OnlineTime: 'SOMENTE para problemas de pré-venda. As vendas online não têm acesso às informações do pedido. ',
    resultFor: "resultados para \"<strong>{result}</strong>\"",
    question:"Tem mais perguntas?",
    article_helpful:"Este artigo foi útil?",
    related:"Artigos Relacionados",
    noTicket:"no Ticket",
    textarea_placeholder:"Digite uma mensagem aqui ...",
    response_time:"Tempo de resposta esperado: Dentro de 24h",
    select_tip: 'Selecione seu tipo de pergunta',
    status_wating: 'Esperando por respostas',
    submit_tips: "Os motivos abaixo são opcionais. Você pode clicar no “X” se não quiser escolher nenhum deles.",
    description_ph: 'Descreva o seu problema o máximo possível para que o atendimento ao cliente possa responder mais rápido…',
    upload_image: 'Enviar Imagem',
    rate:'Avalie meu serviço',
    rate_info: "Gostaríamos muito de saber o que você acha do nosso atendimento ao cliente para nos ajudar a atendê-lo melhor.",
    rate_text_placeholder: 'Você pode comentar o atendimento ao cliente aqui.',
    status_wating_apply:'Aguardando por se inscrever',
    facebook_check: 'Se você verificar isso, enviaremos a você o status de rastreamento de logística e as informações do pedido no Messenger.',
    return_the_oder: 'Devolver o pedido',
    popular_search: 'Buscas populares: Reembolso, Devolução, Envio',
    vip_service: 'serviço VIP',
    use_following_coupons: 'Você pode usar os seguintes cupons nos itens do seu carrinho de compras, por favor, use-os na página do pedido.',
    cancel_info: 'Você tem certeza de que quer cancelar o pagamento? Se seu pedido não for pago até as {countdown}, será cancelado.',
	continue_to_pay: 'Continuar pagando',
	confirm_cancel: 'Confirmar Cancelar',
    protectAccount: 'Os detalhes da sua conta estão totalmente protegidos e não serão revelados a terceiros por nenhuma razão.',
    available: 'Disponível',
    coupons_tip: "Cupons/Recompensas podem ser usados na próxima etapa",
    invalid_address: 'Formato de endereço incorreto',
    copied_successfully:"Copiado com sucesso", // CM1244
    share_the_code:"Compartilhe o c\u00F3digo", // CM1165

    out_of_stock_policy:"Política de Fora de Estoque",
    check_mail_box:"Um link de verificação foi enviado para seu endereço de e-mail, verifique sua caixa de correio.",
    success_will_send_latest:"Com sucesso! Enviaremos nossa última newsletter para seu novo endereço de e-mail.",
    upload_file_size_limit: 'O tamanho do arquivo deve ser inferior a 2 MB',

    order_processing_shipping_date: 'Geralmente levamos de 3 a 7 dias úteis para processar seu pedido.', // shippingDate
    my_order:"Meus pedidos",
    my_services:"Meu serviço",

    set_password_more_discount:"Defina sua senha para obter mais desconto!",   //google
    time_must_current:"A data de nascimento deve ser menor que a hora atual!",

    recently_viewed: "Visto recentemente", 
    you_not_recently_view:"Você não viu nada.", 

    website_trends:"Tendências {website}",

    verify_check_order: "Verificar pedido",
    shipping_cost: "custo de envio",// shippingCost
    register_and_get: 'Cadastre-se no {siteName} para rastrear seu pedido e obter mais desconto!',
    please_input_password_first:'Por favor, insira a senha primeiro',
    check_order_msg: 'Agora, você pode verificar seu pedido em sua conta. Marque “Meu pedido” em “Eu” para rastrear seu pedido.',
    total_count_pages: "total de {count} páginas",
    saved: "Economia",
    use_max_coupon: "Não se esqueça de usar o cupom de desconto de {coupon}!",
    up_to_saving: "até {coupon} de economia",
    
    please_select_order_first: 'Selecione um pedido primeiro!',

    exprct_to_save: "Espere economizar",
    max_save: "Economia",

    step_1_address_content: "Para ver os itens enviados para um local diferente, altere seu endereço de entrega.",
    next_step: "Próxima Etapa",
    step_2_select_content: "Clique aqui para selecionar os itens que você precisa para CHECKOUT.",
    got_it: "Entendi",
    step_2_checkout_content: "Aplique um código de cupom e/ou pontos {site} na próxima etapa.",
    shipping_fee: "Taxa de envio",
    more_products: "Mais produtos",
    change: 'mudar',

    bottomLengthNote: "Meça do cós até a abertura da perna ou bainha.",
    inseam: "Costura interna",
    inseamNote: "Meça o comprimento da costura da virilha até a parte inferior da perna.",
    yourBustNote:"Meça a circunferência na parte mais larga do seu busto.",
    yourUnderBust: "Seu busto",
    underBustNote: "Meça a circunferência sobre a linha inferior do seu busto.",
    swimBustNote: "Meça a circunferência na parte mais larga do seu peito.",
    swimUnderBustNote:"Meça em linha reta ao longo da faixa sob o busto de ponta a ponta.",
    swimWaistNote: "Meça sua cintura no ponto mais fino.",
    swimHipNote: "Meça a parte mais larga de seus quadris.",
    switchTo: "Mudar para",

    underBust: 'sob o busto',
    bustDifference: "Busto - Diferença abaixo do busto",
    expires_soon: "Expira em breve",
    sign_in_check_coupon:"Faça o login para verificar mais cupons",

    footWidth: 'Largura do pé',
    footLengthNote: 'Meça o comprimento máximo do seu pé.',
    footWidthNote: 'Meça a largura máxima do seu pé.',

    wholesale: "Atacado", // CM0828

    pre_wholesale: "PRE-Atacado",
    after_wholesale: "AFTER-Atacado",

    apply_before_expires: "Aplique antes que expire!",
    gift_has_been_cart: "O presente foi adicionado ao seu carrinho!",
    mystery_gifts_giveaway:"presentes misteriosos e grande sorteio",

    got_extra_chance_to_win: "Você tem uma oferta VIP exclusiva e uma EXTRA CHANCE para ganhar um grande prêmio!",
    collect_and_draw: 'Acepta & Sortea Ahora',

    how_rate_website: "Como você avaliaria {website}?",
	please_rate_by_stars: "Avalie {website} por estrelas: 1 a 5",
    close: "Fechar",


    purchase_protection: "Pagamento protegido",
    efficient_logistics: "Logística eficiente",
    customer_service: "Atendimento ao cliente",
    privacy_protection: "Privacidade protegida",
    proceed_to_checkout: "Fazer o check-out",

    enjoy_discount_add_items: "Aproveite descontos apenas se adicionar itens desta lista ao seu carrinho.",
    add_on_items: "Itens adicionais",

    with_shipping_price: 'com custo de envio',
    modify: 'modificar',
	please_sure_address_correct: 'Por favor certifique-se de que o endereço que preenche está correcto',
    login_to_receive: "Login para Receber",

    bottoms: 'PARTE DE BAIXO',
    coat: "CASACO",
    lingerie: 'LINGERIE',
    tops: 'PARTE DE CIMA',

    addOnItemMsg: 'Desconto adicional',
    extraDiscount: 'Desconto Extra',
    cheapThanAdd: 'Mais barato que um extra',

    can_find_your_size:"Não consegue encontrar seu tamanho?",
    tell_us_size: "Informe-nos seu tamanho",
    thanks_your_feedback: "Obrigado por seus comentários.",
    submit_successful: "Enviar com sucesso", 
    we_found_similar_size: "Também encontramos itens semelhantes para você em tamanho",
    we_optimize_based_feedback:"Otimizaremos a faixa de tamanho com base em seus comentários.",
    similar_item: "Itens semelhantes",
    not_find_anything_similar_search: "Lamentamos não ter encontrado nada semelhante ao que você estava procurando.",
    
    smaller_than: "Menor que",
    larger_than: "Maior que",
    spin_to_win_for_mom: 'Girar para ganhar, mãe!',

    not_your_vibe_there_more: 'Não é adequado? Há mais!',
    picks_for_you: 'Escolhas para você',
    add_to_home_screen:"Adicionar {name} à tela inicial",

    collect_share: 'Coletar e compartilhar',
    link_expired: 'Desculpe, este link expirou',
    link_valid_for_7_days: 'Foi válido por apenas 7 dias após ser compartilhado.',
    share_coupon_expired: 'Desculpe, o cupom compartilhado expirou',
    reach_coupon_limit: 'Desculpe, você atingiu o limite de reivindicações de cupom',

    your_app_is_outdated: 'Sua versão do aplicativo está desatualizada.',
    user_time_limit: 'Desculpe, você já recebeu o cupom. Não pode ser reclamado novamente.',

    share: "compartilhar",
    collect_now: "Coletar agora",
    gift_card_receive: 'Um cartão presente exclusivo foi adicionado ao seu pacote de cupons',
    get_gift_card_from_share: "Você receberá um cartão presente exclusivo compartilhado por seus amigos",
    limited_discount: 'Desconto Limitado',
    enjoy_free_shipping: 'Aproveite Frete Grátis',

    set_the_password: "Definir a senha",
    send_subscribe_message: "Clique para aceitar mensagens promocionais da {website} via WhatsApp ou messenger!  Você pode cancelar a assinatura a qualquer momento.",
    enter_whatsapp_number: "Digite seu número de WhatsApp",
    send_to_whatsapp: "Enviar para o WhatsApp",
    please_select_location: "Selecione sua localização",
    subscribe_success: "Sucesso na assinatura!",
    subscribe_filed: "Assinatura fracassada!",
    whatsapp_number: "Número do WhatsApp",
    receive_offers_message: "Gostaria de receber ofertas e notícias exclusivas por SMS.",

    status:'Status',
    wish_list_filter_empty: 'Nenhum resultado encontrado. Considere tentar opções diferentes.',
    wish_list_nothing: 'Atualmente você não tem nada salvo. Marque um item com um \'coração\' para começar sua lista de desejos.',
    click_to_draw: 'Clique para desenhar',

    wrong_zip_code_us: 'O CEP deve ter 5 dígitos ou 5 dígitos seguidos de um número de 4 dígitos, por exemplo 20001 ou 20001-0000.',
    wrong_zip_code_uk: 'O CEP deve conter de 6 a 8 letras, dígitos e espaços no formato M2 5BQ, CW8 3AD, TN37 7HL.',
    wrong_zip_code_br: 'O CEP deve conter 8 dígitos separados por um hífen, no seguinte formato: 12345-123.',
    wrong_zip_code_mx: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_de: 'O CEP deve ter pelo menos 5 dígitos. Exemplo: 12345.',
    wrong_zip_code_fr: 'O CEP deve conter 5 dígitos, por exemplo 12345.',
    wrong_zip_code_es: 'O CEP deve conter 5 dígitos. O primeiro dígito não pode ser 6, 7, 8 ou 9.',
    wrong_zip_code_it: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_at: 'O CEP deve ter 4 dígitos. Exemplo: 1234.',
    wrong_zip_code_be: 'O CEP deve ter 4 dígitos. Exemplo: 1234.',
    wrong_zip_code_au: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_bg: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_ca: 'O formato do CEP deve ser: letras + números + letras + espaços + números + letras + números, por exemplo A1B 2C3.',
    wrong_zip_code_ch: 'O CEP deve ter 4 dígitos. Exemplo: 1234.',
    wrong_zip_code_cy: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_cz: 'O CEP deve ter 5 dígitos e 1 espaço no formato 123 45.',
    wrong_zip_code_ee: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_fi: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_gr: 'O CEP deve ter 5 dígitos e 1 espaço no formato 123 45.',
    wrong_zip_code_hr: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_ie: 'O CEP deve conter 7 letras, dígitos e espaços no formato A65 F4E2, T45 D868, T12 FPK5.',
    wrong_zip_code_lt: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_lu: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_lv: 'O CEP deve ser um número de 4 dígitos, por exemplo LV-1234.',
    wrong_zip_code_nl: 'O CEP deve consistir em 4 dígitos seguidos de um espaço e terminar com 2 letras, por exemplo 2561 DV.',
    wrong_zip_code_no: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_nz: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_pl: 'O CEP deve ter 5 dígitos e um hífen no formato 12-345.',
    wrong_zip_code_pt: 'O CEP deve conter 7 dígitos separados por um hífen, no seguinte formato: 1234-123.',
    wrong_zip_code_ro: 'O CEP deve ser um número de 6 dígitos, por exemplo 123456.',
    wrong_zip_code_se: 'O CEP deve ser um número de 5 dígitos, por exemplo 12345.',
    wrong_zip_code_dk: 'O CEP deve ser um número de 4 dígitos, por exemplo 1234.',
    wrong_zip_code_us: 'CEP: deve ter 5 dígitos ou 5 dígitos seguidos de um número de 4 dígitos, ex. 20001 ou 20001-0000',
    wrong_zip_code_uk: 'CEP: deve conter 6-8 letras, dígitos e espaços no formato M2 5BQ, CW8 3AD, TN37 7HL',
    wrong_zip_code_br: 'CEP: deve conter 8 dígitos separados por um hífen, no seguinte formato: 12345-123',
    wrong_zip_code_mx: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_de: 'CEP: deve ter pelo menos 5 dígitos. Exemplo: 12345',
    wrong_zip_code_fr: 'CEP: deve conter 5 dígitos, ex. 12345',
    wrong_zip_code_es: 'CEP: deve ter 5 dígitos. O primeiro número não pode ser 6, 7, 8 ou 9',
    wrong_zip_code_it: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_at: 'O CEP deve consistir em 4 dígitos. Exemplo: 1234',
    wrong_zip_code_be: 'O CEP deve consistir em 4 dígitos. Exemplo: 1234',
    wrong_zip_code_au: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_bg: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_ca: 'Formato de CEP: letras + números + letras + espaços + números + letras + números, ex. A1B 2C3',
    wrong_zip_code_ch: 'O CEP deve consistir em 4 dígitos. Exemplo: 1234',
    wrong_zip_code_cy: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_cz: 'CEP: deve ter 5 dígitos e 1 espaço, no formato 123 45',
    wrong_zip_code_ee: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_fi: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_gr: 'CEP: deve ter 5 dígitos e 1 espaço, no formato 123 45',
    wrong_zip_code_hr: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_ie: 'CEP: deve conter 7 letras, dígitos e espaços no formato A65 F4E2, T45 D868, T12 FPK5',
    wrong_zip_code_lt: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_lu: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_lv: 'CEP: deve ser um número de 4 dígitos, ex. LV-1234',
    wrong_zip_code_nl: 'CEP: deve ter 4 dígitos seguidos de um espaço e 2 letras, ex. 2561 DV',
    wrong_zip_code_no: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_nz: 'CEP: deve ser um número de 4 dígitos, ex. 1234',
    wrong_zip_code_pl: 'CEP: deve ter 5 dígitos e "-" no formato 12-345',
    wrong_zip_code_pt: 'O código postal deve conter 7 dígitos separados por um hífen, no seguinte formato: 1234-123',
    wrong_zip_code_ro: 'CEP: deve ser um número de 6 dígitos, ex. 123456',
    wrong_zip_code_se: 'CEP: deve ser um número de 5 dígitos, ex. 12345',
    wrong_zip_code_da: 'CEP: deve ser um número de 4 dígitos, ex. 1234',

    systemEmailNotice: 'Aviso: Por favor, atualize o seu e-mail para receber notificações importantes de nós. Clique para atualizar agora.',
    wishListEmptySignIn: 'Lista de desejos vazia. Faça login para visualizar sua lista de desejos.',
    country_code: "Código do país",
    select_country_code: "Por favor, selecione o código do país correto",

    local_policy_pay_tax: "De acordo com a política local, os clientes deverão pagar imposto sobre operações de câmbio ao escolher este pagamento.",
    returning: "Devolução",
    select_to_be_returned: "Por favor, selecione o(s) item(ns) a ser(em) devolvido(s)",
    cannot_be_returned: "Este tipo de item não pode ser devolvido",
    are_being_returned: "O(s) item(ns) está(ão) sendo devolvido(s)",
    please_select_the_items: "Por favor, selecione o(s) item(ns)",
    select_reason_all_be_returned: "Por favor, selecione o motivo pelo qual todos os itens devem ser devolvidos",
    please_select_the_reason: "Por favor, selecione o motivo",
    please_submit_further_details: "Por favor, envie mais detalhes",
    descrive_problem_customer_service_respond: "Descreva seu problema o máximo possível para que o serviço ao cliente possa responder mais rapidamente",
    require_support: "requerer suporte",

    download: "Baixar",
    download_all: "Baixar tudo",
    download_products_images: "Baixar imagens do produto",

    drop_shipping: "Envio direto",
    limit_time_only:"POR TEMPO LIMITADO",
    ruler_min: 'A régua está no mínimo',
    ruler_max: 'A régua está no máximo',
    swiper_to_add: 'Deslize para adicionar suas informações corporais',
    save_your_body_mea: 'Vamos salvar suas medidas corporais para garantir um ajuste perfeito em suas compras futuras.',
    mea_perf: 'Preferência',

    safe_payment_options: "Opções de pagamento seguras",

    influencer_outfit: "Roupa de influenciador",

    sign_in_paypal:"Entrar com o PayPal",
    ends_today: 'termina hoje',

    selected: 'selecionado',
    addon_reach_limit: 'Você atingiu o limite para itens adicionais selecionados.Clique em "Selecionado" para desfazer sua seleção.',

    phone_number_or_email_address: "Número de telefone ou endereço de e-mail",
    reset_password_via_email: "Redefinir senha via endereço de e-mail",
    reset_password_via_phone: "Redefinir senha via número de telefone",
    welcome_back: "Bem-vindo de volta",
    please_sign_in_account: "Por favor, faça login com sua conta.",
    please_create_your_account: "Por favor, crie sua conta.",
    code: 'Código',

    sign_in_with:"Entrar com o {mode}",

    incorrect_email_address:"Endereço de e-mail incorreto",


    privacy_protection: 'Proteção da privacidade',
    security_privacy: 'Segurança e privacidade',
    register_success: "Você se registrou com sucesso",

    type: 'tipo',
    spin_to_win: 'Gire para Ganhar',
    you_run_out_of_chance: "Você esgotou suas chances",
    get_all_in_app: 'obter tudo no aplicativo',
    app_new_user_gift: 'Presente de App Novo',
    ends_in: 'termina em',

    cvv_tip: "Para um pagamento bem-sucedido, por favor, insira o Número de Verificação do Cartão de Crédito (CVV). Você pode encontrar o número CVV na parte de trás do seu cartão de crédito.",

    promotion_details: "Detalhes da promoção",
    estimated_price: "Preço estimado",
    buy_now_enjoy: "Compre agora para aproveitar os seguintes benefícios",
    discount_price: "Preço com desconto",
    original_price: "Preço original",
    estimated: "Estimado",
    discount_shown_estimated: "Os descontos mostrados são estimados e estão sujeitos às regras do cupom. O preço final pode variar.",
    promotions: "Promoções",
    get_coupon: "Obter cupom",
    best_coupon: "Melhor cupom",

    app_exclusive: 'Exclusivo',
    unsubscribe_message: 'Reassine com {email} na {website} e ganhe cupons.',
    subscribe_and_get: 'Reassine e ganhe cupons',

    quick_ship: 'Entrega rápida',
	provide_faster_delivery_service: 'irá fornecer um serviço de entrega mais rápido',
	between: 'entre',
	eligible_for_quick_ship: 'são elegíveis para Entrega Rápida',

    consent_cookie_dsc: 'Utilizamos cookies e outras tecnologias de rastreamento para melhorar sua experiência de navegação em nosso site, mostrar conteúdo personalizado e anúncios direcionados, analisar o tráfego de nosso site e entender de onde vêm nossos visitantes.',
    consent_btn_settings: 'Configurações de cookies',
    consent_btn_accept: 'Aceitar',
    consent_btn_decline: 'Recusar',
    consent_about: 'Sobre cookies',
    consent_setting_1_title: 'Cookies Necessários',
    consent_setting_1_status: 'Sempre ativos',
    consent_setting_1_dsc: 'Esses cookies são essenciais para o funcionamento adequado do site. Eles permitem funções básicas como navegação em páginas e acesso a áreas seguras do site. O site não pode funcionar corretamente sem esses cookies e eles não podem ser desativados.',
    consent_setting_2_title: 'Cookies Analíticos',
    consent_setting_2_dsc: 'Os cookies analíticos fornecem informações sobre como este site é usado. Isso melhora a experiência do usuário. Os dados coletados são agregados e anonimizados.',
    consent_setting_3_title: 'Cookies de Publicidade',
    consent_setting_3_dsc: 'Os cookies de publicidade fornecem informações sobre interações do usuário com o conteúdo da ChicMe. Isso nos ajuda a entender melhor a eficácia do conteúdo de nossos e-mails e de nosso site.',
    consent_btn_accept_all: 'Aceitar tudo',
    consent_btn_save_settings: 'Salvar configurações',
}  