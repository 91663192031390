import {
	CUSTOMER_SET,
	CUSTOMER_ADDRESS_LIST,
	CUSTOMER_CREDIT_CARD,
	CUSTOMER_MESSAGE,
	CUSTOMER_NOTIFICATION_NO_READ,
	CUSTOMER_NOTIFICATION_SET,
	CUSTOMER_NOTIFICATION_SET_LOADING,
	CUSTOMER_NOTIFICATION_SET_FINISHED,
	CUSTOMER_NOTIFICATION_SET_SKIP,
	CUSTOMER_SET_COUPON_LIST,
	CUSTOMER_SET_EXPIRED_COUPON,
	CUSTOMER_SET_WALLET,
	CUSTOMER_SET_WALLET_HISTORY,
	CUSTOMER_SET_WALLET_HISTORY_SKIP,
	CUSTOMER_SET_WALLET_HISTORY_LOADING,
	CUSTOMER_SET_WALLET_HISTORY_FINISHED,
	CUSTOMER_POINTS_HISTORY_ALL_DATA,
	CUSTOMER_POINTS_HISTORY_RECIVED_DATA,
	CUSTOMER_POINTS_HISTORY_USED_DATA,
	CUSTOMER_POINTS_HISTORY_EXPIRED_DATA,
	CUSTOMER_SET_SHOW_REDEEM_COUPON,
	CUSTOMER_SET_REDEEM_COUPON_LIST,
	CUSTOMER_WISHLIST_DATA, 
	CUSTOMER_POINTS_GUIDE, 
	CUSTOMER_ORDER_LIST_ALL, 
	CUSTOMER_ORDER_LIST_UNPAID, 
	CUSTOMER_ORDER_LIST_PROCESSING, 
	CUSTOMER_ORDER_LIST_SHIPPED, 
	CUSTOMER_ORDER_LIST_CONFIRMED, 
	CUSTOMER_ORDER_LIST_RETURN, 
	CUSTOMER_ORDER_DETAIL, 
	CUSTOMER_ORDER_RETURN_DETAIL_EMPTY, 
	CUSTOMER_ORDER_RETURN_DETAIL, 
	CUSTOMER_ORDER_REVIEW, 
	CUSTOMER_ORDER_OFTEN_BOUGHT_WITH, 
	CUSTOMER_SUCCESS_REMINDER, 
	CUSTOMER_SET_LOADING,
	CUSTOMER_GET_TICKET_LIST,
	CUSTOMER_ADD_TICKET_LIST,
	CUSTOMER_TICKET_LIST_LOADING,
	CUSTOMER_TICKET_LIST_FINISHED,
	CUSTOMER_TICKET_LIST_SKIP,
	CUSTOMER_TICKET_SET_QUESTION_TYPE,
	CUSTOMER_GET_TICKET_ORDER,
	CUSTOMER_ADD_TICKET_ORDER,
	CUSTOMER_TICKET_ORDER_LOADING,
	CUSTOMER_TICKET_ORDER_FINISHED,
	CUSTOMER_TICKET_ORDER_SKIP,
	CUSTOMER_TICKET_ORDER_SELECT_ID,
	CUSTOMER_TICKET_QUESTIONS,
	CUSTOMER_TICKET_QUESTIONS_TREE,
	CUSTOMER_WISH_LIST_FILTER,
	CUSTOMER_WISH_LIST_COUNT,
	CUSTOMER_WISH_LIST_INIT,
	CUSTOMER_WISH_LIST_APPEND,
	CUSTOMER_WISH_LIST_UNSHIFT,
	CUSTOMER_WISH_LIST_LOADING,
	CUSTOMER_WISH_LIST_FINISHED,
	CUSTOMER_WISH_LIST_FILTER_COUNT,
	CUSTOMER_WISH_LIST_LAST_TOKEN
} from '../../actions/pages/customer'

export const initialState = {
	customer: null,
	addressList: null,
	creditCards: null,
	message: {},
	notificationNoRead: {
		activity: 0,
		promo: 0,
		others: 0,
		ticket: 0,
		news: 0,
		orders: 0,
	},
	notifications: {
		activity: null,
		promo: null,
		others: null,
		ticket: null,
		news: null,
		orders: null,
	},
	notificationLoading:{
		activity: false,
		promo: false,
		others: false,
		ticket: false,
		news: false,
		orders: false,
	},
	notificationFinished:{
		activity: false,
		promo: false,
		others: false,
		ticket: false,
		news: false,
		orders: false,
	},
	notificationSkip:{
		activity: 0,
		promo: 0,
		others: 0,
		ticket: 0,
		news: 0,
		orders: 0,
	},
	coupons:{
		unused: null,
		expired: null,
	},
	showRedeemCoupon: false,
	redeemCouponList: null,
	expiredCoupon:{
		skip: 0,
		loading: false,
		finished: false
	},
	wallet: null,
	walletHistory:{
		all: null,
		earned: null,
		used: null,
		expired: null,
	},
	walletHistorySkip:{
		all: 0,
		earned: 0,
		used: 0,
		expired: 0,
	},
	walletHistoryLoading:{
		all: false,
		earned: false,
		used: false,
		expired: false,
	},
	walletHistoryFinished:{
		all: false,
		earned: false,
		used: false,
		expired: false,
	},
	pointsHistoryAll:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	pointsHistoryRecived:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	pointsHistoryUsed:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	pointsHistoryExpired:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	pointsGuide:null,
	orderListAll:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderListUnpaid:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderListProcessing:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderListShipped:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderListConfirmed:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderListReturn:{
		data:[],
		skip:0,
		loading:false,
		finished:false
	},
	orderDetail:null,
	orderDetailEmpty: false,
	orderReview:null,
	orderOftenList:null,
	successReminder:null,
	loading:false,
	orderReturnDetail:null,
	ticketList:{
		data:[],
		skip:0,
		loading: false,
		finished: false
	},
	ticketQuestionType: null,
	ticketOrder:{
		data:[],
		skip:0,
		loading: false,
		finished: false,
		selectId: null,
	},
	ticketQuestions:null,
	ticketQuestionsTree: null,
	wishListFilter: null,
	wishListFilterResult: null,
	wishListCount: 0,
	wishListFilterCount: null,
	wishListLoading: false,
	wishListFinished: false,
	wishListLastToken: null,
}

export default (state = initialState, action) => {
	switch (action.type){
		case CUSTOMER_SET:
			return {...state, customer: action.payload}
		case CUSTOMER_ADDRESS_LIST:
			return {...state, addressList: action.payload}
		case CUSTOMER_CREDIT_CARD:
			return {...state, creditCards: action.payload}
		case CUSTOMER_MESSAGE:
			return {...state, message: action.payload}
		case CUSTOMER_NOTIFICATION_NO_READ:
			return {...state, notificationNoRead: action.payload}
		case CUSTOMER_NOTIFICATION_SET:
			return {...state, notifications: {...state.notifications, ...action.payload}}
		case CUSTOMER_NOTIFICATION_SET_LOADING:
			return {...state, notificationLoading: {...state.notificationLoading, ...action.payload}}
		case CUSTOMER_NOTIFICATION_SET_FINISHED:
			return {...state, notificationFinished: {...state.notificationFinished, ...action.payload}}
		case CUSTOMER_NOTIFICATION_SET_SKIP:
			return {...state, notificationSkip: {...state.notificationSkip, ...action.payload}}
		case CUSTOMER_SET_COUPON_LIST:
			return {...state, coupons: {...state.coupons, ...action.payload}}
		case CUSTOMER_SET_SHOW_REDEEM_COUPON:
			return {...state, showRedeemCoupon: action.payload}
		case CUSTOMER_SET_REDEEM_COUPON_LIST:
			return {...state, redeemCouponList: [...action.payload]}
		case CUSTOMER_SET_EXPIRED_COUPON:
			return {...state, expiredCoupon: {...action.payload}}
		case CUSTOMER_SET_WALLET:
			return {...state, wallet: action.payload}
		case CUSTOMER_SET_WALLET_HISTORY:
			return {...state, walletHistory: {...state.walletHistory, ...action.payload}}
		case CUSTOMER_SET_WALLET_HISTORY_SKIP:
			return {...state, walletHistorySkip: {...state.walletHistorySkip, ...action.payload}}
		case CUSTOMER_SET_WALLET_HISTORY_LOADING:
			return {...state, walletHistoryLoading: {...state.walletHistoryLoading, ...action.payload}}	
		case CUSTOMER_SET_WALLET_HISTORY_FINISHED:
			return {...state, walletHistoryFinished: {...state.walletHistoryFinished, ...action.payload}}	
		case CUSTOMER_POINTS_HISTORY_ALL_DATA:
			return {...state, pointsHistoryAll: {...state.pointsHistoryAll, ...action.payload}}
		case CUSTOMER_POINTS_HISTORY_RECIVED_DATA:
			return {...state, pointsHistoryRecived: {...state.pointsHistoryRecived, ...action.payload}}
		case CUSTOMER_POINTS_HISTORY_USED_DATA:
			return {...state, pointsHistoryUsed: {...state.pointsHistoryUsed, ...action.payload}}
		case CUSTOMER_POINTS_HISTORY_EXPIRED_DATA:
			return {...state, pointsHistoryExpired: {...state.pointsHistoryExpired, ...action.payload}}
		case CUSTOMER_POINTS_GUIDE:
			return {...state, pointsGuide: action.payload}
		case CUSTOMER_ORDER_LIST_ALL:
			return {...state, orderListAll: {...state.orderListAll, ...action.payload}}
		case CUSTOMER_ORDER_LIST_UNPAID:
			return {...state, orderListUnpaid: {...state.orderListUnpaid, ...action.payload}}
		case CUSTOMER_ORDER_LIST_PROCESSING:
			return {...state, orderListProcessing: {...state.orderListProcessing, ...action.payload}}
		case CUSTOMER_ORDER_LIST_SHIPPED:
			return {...state, orderListShipped: {...state.orderListShipped, ...action.payload}}
		case CUSTOMER_ORDER_LIST_CONFIRMED:
			return {...state, orderListConfirmed: {...state.orderListConfirmed, ...action.payload}}
		case CUSTOMER_ORDER_LIST_RETURN:
			return {...state, orderListReturn: {...state.orderListReturn, ...action.payload}}
		case CUSTOMER_ORDER_DETAIL:
			return { ...state, orderDetail:action.payload }
		case CUSTOMER_ORDER_RETURN_DETAIL_EMPTY:
			return { ...state, orderDetailEmpty: action.payload }
		case CUSTOMER_ORDER_RETURN_DETAIL:
			return { ...state, orderReturnDetail: action.payload }
		case CUSTOMER_ORDER_REVIEW:
			return { ...state, orderReview:action.payload }
		case CUSTOMER_ORDER_OFTEN_BOUGHT_WITH:
			return { ...state, orderOftenList: action.payload }
		case CUSTOMER_SUCCESS_REMINDER:
			return { ...state, successReminder: action.payload }
		case CUSTOMER_SET_LOADING:
			return { ...state, loading: action.payload }
		case CUSTOMER_GET_TICKET_LIST:
			return {...state, ticketList: {...state.ticketList, data: action.payload}}
		case CUSTOMER_ADD_TICKET_LIST:
			return {...state, ticketList: {...state.ticketList, data:[...state.ticketList.data, ...action.payload]}}
		case CUSTOMER_TICKET_LIST_LOADING:
			return {...state, ticketList: {...state.ticketList, loading: action.payload}}
		case CUSTOMER_TICKET_LIST_FINISHED:
			return {...state, ticketList: {...state.ticketList, finished: action.payload}}
		case CUSTOMER_TICKET_LIST_SKIP:
			return {...state, ticketList: {...state.ticketList, skip: action.payload}}
		case CUSTOMER_TICKET_SET_QUESTION_TYPE:
			return {...state, ticketQuestionType: action.payload}
		case CUSTOMER_GET_TICKET_ORDER:
			return {...state, ticketOrder: {...state.ticketOrder, data: action.payload}}
		case CUSTOMER_ADD_TICKET_ORDER:
			return {...state, ticketOrder: {...state.ticketOrder, data:[...state.ticketOrder.data, ...action.payload]}}
		case CUSTOMER_TICKET_ORDER_LOADING:
			return {...state, ticketOrder: {...state.ticketOrder, loading: action.payload}}
		case CUSTOMER_TICKET_ORDER_FINISHED:
			return {...state, ticketOrder: {...state.ticketOrder, finished: action.payload}}
		case CUSTOMER_TICKET_ORDER_SKIP:
			return {...state, ticketOrder: {...state.ticketOrder, skip: action.payload}}
		case CUSTOMER_TICKET_ORDER_SELECT_ID:
			return {...state, ticketOrder: {...state.ticketOrder, selectId: action.payload}}
		case CUSTOMER_TICKET_QUESTIONS:
			return {...state, ticketQuestions: action.payload}
		case CUSTOMER_TICKET_QUESTIONS_TREE:
			return {...state, ticketQuestionsTree: action.payload}
		case CUSTOMER_WISH_LIST_LAST_TOKEN:
			return {...state, wishListLastToken: action.payload}
		case CUSTOMER_WISH_LIST_FILTER:
			return {...state, wishListFilter: action.payload}
		case CUSTOMER_WISH_LIST_COUNT:
			return {...state, wishListCount: action.payload}
		case CUSTOMER_WISH_LIST_FILTER_COUNT:
			return {...state, wishListFilterCount: action.payload}
		case CUSTOMER_WISH_LIST_INIT:
			return {...state, wishListFilterResult: action.payload}
		case CUSTOMER_WISH_LIST_APPEND:
			return {...state, wishListFilterResult: [...(state.wishListFilterResult || []), ...(action.payload || [])]}
		case CUSTOMER_WISH_LIST_UNSHIFT:
			return {...state, wishListFilterResult: [action.payload, ...(state.wishListFilterResult || [])]}
		case CUSTOMER_WISH_LIST_LOADING:
			return {...state, wishListLoading: action.payload}
		case CUSTOMER_WISH_LIST_FINISHED:
			return {...state, wishListFinished: action.payload}
		default:
			return state
	}
}