

const addToCart = data => {
    try {
        const { amount, currency, name, productId } = data
        
        window.uetq1 = window.uetq1 || [];
        window.uetq1.push('event', 'add_to_cart', { 'ecomm_prodid': productId, 'ecomm_pagetype': 'cart'});

        window.uetq2 = window.uetq2 || [];
        window.uetq2.push('event', 'add_to_cart', { 'ecomm_prodid': productId, 'ecomm_pagetype': 'cart'});

    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {

}

const pageView = data => {
    try {
        const {
            productId,
        } = data

        window.uetq1 = window.uetq1 || []
		window.uetq1.push('event', 'view_item', { 'ecomm_prodid': productId, 'ecomm_pagetype': 'product'})

        window.uetq2 = window.uetq2 || []
		window.uetq2.push('event', 'view_item', { 'ecomm_prodid': productId, 'ecomm_pagetype': 'product'})

    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        const {
            paymentTotalWithUSD,
            orderItems,
        } = data


        const item_ids = orderItems?.map(item => item.productId)

        window.uetq1 = window.uetq1 || [];
        window.uetq1.push({'gv': paymentTotalWithUSD?.amount});
        window.uetq1.push('event', 'purchase', {'gv': paymentTotalWithUSD?.amount, 'ecomm_prodid': item_ids, 'ecomm_pagetype': 'purchase'});


        window.uetq2 = window.uetq2 || [];
        window.uetq2.push({'gv': paymentTotalWithUSD?.amount});
        window.uetq2.push('event', 'purchase', {'gv': paymentTotalWithUSD?.amount, 'ecomm_prodid': item_ids, 'ecomm_pagetype': 'purchase'});


    } catch (e) {
        console.warn(e)
    }
}



export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}