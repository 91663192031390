import React from 'react'
import './style.scss'
import {CSSTransition} from 'react-transition-group'

export default props => {
	return <CSSTransition
		mountOnEnter={true}
		unmountOnExit
		{...props}
		in={props.in}
		timeout={200}
		className={props.className}
		classNames="DisplayTransition">
		{
			props.children
		}
	</CSSTransition>
}