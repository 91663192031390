const addToCart = data => {

}

const addToWishList = data => {

}

const pageView = data => {

}

const purchase = data => {
    try {
        if(window.pptag) {
            window.pptag.send('purchase')
        }
    } catch (e) {
        console.warn(e)
    }
}



export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}