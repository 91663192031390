export const unitPrice = price => {
	if(!price) return ''
	if(price.currency === 'EUR'){
		return `${price.amount}${price.unit}`
	}

	if(price.currency === 'BRL'){
		return `${price.unit}${String(price.amount||'')?.replace?.('.',',')}`
	}

	return `${price.unit}${price.amount}`
}

export const isPromotion = product => {
	if(!product) return
	return product.promotion && product.promotion.enabled && product.promotion.promotionPrice
}

export const isAddOnProduct = (product, fromCart) => {
	if(!product) return
	// 有redeemPrice才是换购产品，但是购物车里的换购产品用realPrice
	return product.redeemPrice ?
		!fromCart ? product.redeemPrice : product.realPrice :
		null
}

export const isFlashSales = product => {
	if(!product) return
	return product.promotion && product.promotion.type === '9' && product.promotion.enabled && product.promotion.promotionPrice
}

export const getLowerPrice = (product) => {
	if(!product) return
	const _is_promotion = isPromotion(product)
	if(_is_promotion){
		return product.promotion.promotionPrice
	}else{
		return product.price
	}
}

export const getGiftPrice = product => {
	// 凑单页礼物列表用giftPrice 购物车其他地方用realPrice
	if(!product) return
	return product.giftPrice
}

export const getDelPrice = (product) => {
	if(!product) return
	const _is_promotion = isPromotion(product)
	if(_is_promotion){
		return product.msrp || product.price
	}else{
		return product.msrp
	}
}

export const getCartLowerPrice = (item) => {
	if(!item)return
	return item.realPrice
}

export const getCartDelPrice = (item) => {
	if(!item)return
	return item.itemPrice
}

export const getAddOnLowerPrice = (product) => {
	if(!product) return
	return product.redeemPrice || product.price
}

export const getAddOnDelPrice = (product) => {
	if(!product) return
	return product.msrp || product.price
}


export const getLowerPriceWithVariant = variant => {
	return variant?.promotionPrice || variant?.price
}

export const getDelPriceWithVariant = variant => {
	if(!variant) return
	const _is_promotion = !!variant.promotionPrice
	if(_is_promotion){
		return variant.msrp || variant.price
	}else{
		return variant.msrp
	}
}

export const getProductUrl = (id, name, color, suffix, lang) => {
	let urlName = '-'
	if(name){
		urlName = name.replace('&', ' ').replace(/\s+/g,' ').replace(new RegExp(/[\s|\%|\?|\&|#]/g),'-').replace(new RegExp(/"/g),'').replace(new RegExp(/'/g),'').replace(/\//g, '') || '-'
	}
	if(color){
		return `${(lang && lang != 'en')?`/${lang}`:''}/product/${urlName.toLowerCase()}/${id}.html?color=${color}${suffix?('&'+suffix):''}`
	}else{
		return `${(lang && lang != 'en')?`/${lang}`:''}/product/${urlName.toLowerCase()}/${id}.html${suffix?('?'+suffix):''}`
	}
}

export const getColor = color => {
	return color?.replace(/\s|'/g, '').toLowerCase()
}

