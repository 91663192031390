// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2i9crJ2-uenqjDQn3hGjc2{height:100%;padding:0 45px 0px 51px}.Ioq1LU39yhsJUYHRwT9U3{font-family:Roboto-Medium;font-size:18px;color:#000000}.W9CHQmphwtonIT_s5TsQ_{font-family:Roboto;font-size:12px;color:#999999;margin-top:10px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/size-chart2/components/main/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,WAAY,CACZ,uBAAwB,CAE3B,uBAGG,yBAA0B,CAC1B,cAAe,CACf,aAAc,CACjB,uBAGG,kBAAmB,CACnB,cAAe,CACf,aAAc,CACd,eAAgB","sourcesContent":[".SizeChartBox{\n    height: 100%;\n    padding: 0 45px 0px 51px;\n    // overflow-y: scroll;\n}\n\n.Title{\n    font-family: Roboto-Medium;\n    font-size: 18px;\n    color: #000000;\n}\n\n.message{\n    font-family: Roboto;\n    font-size: 12px;\n    color: #999999;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SizeChartBox": "_2i9crJ2-uenqjDQn3hGjc2",
	"Title": "Ioq1LU39yhsJUYHRwT9U3",
	"message": "W9CHQmphwtonIT_s5TsQ_"
};
module.exports = ___CSS_LOADER_EXPORT___;
