import useStyles from "isomorphic-style-loader/useStyles";
import React, { useEffect, useMemo } from "react";
import classes from './style.module.scss'
import SizeChartMain from './components/main'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ReactDOM from 'react-dom'
import { SET_SIZE_CHART } from "../../../../../store/actions/global";
import DisplayTransition from "../../../components/transitions/display-transition";
import Mask from "../../../components/mask";
import { useSizeChartNew } from "../../../../hooks/sizechartnew";
import SizeChartHead from "./components/main/size-chart-head";
import Cookies from "js-cookie";


const SizeChartInit = props => {
    const {sizechartData} = props || {}
    const { data, show} = sizechartData || {}
    const { products, taglia, productSizeData, countryCode, selectedProduct} = data || {}

    const countryCodeCache = useMemo(() => {
        return countryCode || Cookies.get("sizeDefaultCountry")
    }, [countryCode, Cookies.get("sizeDefaultCountry")])

    // 产品类别 1鞋子 2衣服 3bra
    const formateSizeData = useSizeChartNew(productSizeData, taglia, '', products, countryCodeCache, selectedProduct)

    return formateSizeData?.hasSizeChartData ?
           <SizeChartMain
                {...props}
                show={show}
                formateSizeData={formateSizeData}
                productTaglia={taglia}
                productType={formateSizeData?.productType}
                countryCode={countryCodeCache}
                /> :
           <React.Fragment></React.Fragment>
}

const Wrapped = props => {
    useStyles(classes)
    const dispatch = useDispatch()

    const closeSizeChart = () => {
        dispatch({
            type: SET_SIZE_CHART,
            payload: {
                show: false,
                data: null
            }
        })
    }

    return <React.Fragment>
        <DisplayTransition in={props.show} className={classes.SizeChartContainer}>
            <div>
                <div className={classes.SizeChartBox}>
                    <SizeChartHead closeSizeChart={closeSizeChart} />
                    <div className={classes.Bd}>
                        <SizeChartInit {...props} closeSizeChart={closeSizeChart} />
                    </div>
                </div>
            </div>
        </DisplayTransition>
        {props.show && <Mask onClick={() => closeSizeChart()} />}
    </React.Fragment>
}


const SizeChartWrapped = props => {
    const global = useSelector(state => state.global)

    if (typeof window === 'undefined') {
        return null
    }

    const { sizechart } = global || {}
    const { show } = sizechart || {}

    return ReactDOM.createPortal(
        <Wrapped {...props} show={show} sizechartData={sizechart} />,
        document.getElementById('root')
    )
}

export default SizeChartWrapped

/**
 * 网站弹出式sizechart
   接受product，taglia, countryCode(仅app)数据
   // 处理数据
    * 使用示例
   const dispatch = useDispatch()
   dispatch({
       type: SET_SIZE_CHART,
       payload:{
           show: true,
           data: {
               products, // 需要传入products 可以从useProduct抛出的productVO种获取
               taglia, // 产品detail最外层
               productSizeData, // 产品detail最外层
               countryCode
           }
       }
   })
*/