// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2a2B76a53GXJFdFu3bCBkA{display:flex;position:relative;align-items:center;background-color:#fff}._2a2B76a53GXJFdFu3bCBkA .xHMlh1B-DiCeuohBo8r62{color:#222}._2a2B76a53GXJFdFu3bCBkA select{width:80px;height:33px;line-height:33px;cursor:pointer;border:1px solid #dbdbdb;text-align:center;list-style:none;background-color:#fff;padding-left:11px;outline:none;box-shadow:none;font-size:13px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/size-chart2/components/drop-down/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,iBAAkB,CAClB,kBAAmB,CACnB,qBAAsB,CAJ1B,gDAOQ,UAAW,CAPnB,gCAWQ,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,cAAe,CACf,wBAAyB,CACzB,iBAAkB,CAClB,eAAgB,CAChB,qBAAsB,CACtB,iBAAkB,CAClB,YAAa,CACb,eAAgB,CAChB,cAAe","sourcesContent":[".SelectM{\n    display: flex;\n    position: relative;\n    align-items: center;\n    background-color: #fff;\n\n    .Value{\n        color: #222;\n    }\n\n    select{\n        width: 80px;\n        height: 33px;\n        line-height: 33px;\n        cursor: pointer;\n        border: 1px solid #dbdbdb;\n        text-align: center;\n        list-style: none;\n        background-color: #fff;\n        padding-left: 11px;\n        outline: none;\n        box-shadow: none;\n        font-size: 13px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectM": "_2a2B76a53GXJFdFu3bCBkA",
	"Value": "xHMlh1B-DiCeuohBo8r62"
};
module.exports = ___CSS_LOADER_EXPORT___;
