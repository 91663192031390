import { fetchCollectionList, getCountryMessage } from '../../../api'


export const __Default_LIST_VIDEO_SALES_Limit__ = 12

export const LIST_VIDEO_SALES_SET = 'LIST_VIDEO_SALES_SET'
export const LIST_VIDEO_SALES_LIST_ADD = 'LIST_VIDEO_SALES_LIST_ADD'
export const LIST_VIDEO_SALES_LIST_SET = 'LIST_VIDEO_SALES_LIST_SET'
export const LIST_VIDEO_SALES_LIST_SKIP = 'LIST_VIDEO_SALES_LIST_SKIP'
export const LIST_VIDEO_SALES_LIST_FINISHED = 'LIST_VIDEO_SALES_LIST_FINISHED'
export const LIST_VIDEO_SALES_LIST_LOADING = 'LIST_VIDEO_SALES_LIST_LOADING'

export const LIST_VIDEO_SALES_MESSAGE_CONFIG = 'LIST_VIDEO_SALES_MESSAGE_CONFIG'

export const setMessageConfig = payload => ({
    type: LIST_VIDEO_SALES_MESSAGE_CONFIG,
    payload
})


export const setSales = payload => ({
	type: LIST_VIDEO_SALES_SET,
	payload
})

export const setSalesList = payload => ({
	type: LIST_VIDEO_SALES_LIST_SET,
	payload
})

export const addCollectionList = payload => ({
	type: LIST_VIDEO_SALES_LIST_ADD,
	payload
})

export const setSalesSkip = payload => ({
	type: LIST_VIDEO_SALES_LIST_SKIP,
	payload
})

export const setSalesFinished = payload => ({
	type: LIST_VIDEO_SALES_LIST_FINISHED,
	payload
})

export const setSalesListLoading = payload => ({
	type: LIST_VIDEO_SALES_LIST_LOADING,
	payload
})

export const action_add_list_video_sales_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSalesListLoading(true))
		const response = await fetchCollectionList(data, {...params, limit: __Default_LIST_VIDEO_SALES_Limit__}, headers)
		dispatch(setSalesListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addCollectionList(products))
			dispatch(setSalesSkip(params.skip + __Default_LIST_VIDEO_SALES_Limit__))

			if(!products || products?.length < 1){
				dispatch(setSalesFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_list_video_sales_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSalesFinished(false))
		dispatch(setSalesListLoading(true))
		const response = await fetchCollectionList(data, {...params, skip: 0, limit: __Default_LIST_VIDEO_SALES_Limit__}, headers)
		dispatch(setSalesListLoading(false))

		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setSalesList(products))
			dispatch(setSalesSkip(__Default_LIST_VIDEO_SALES_Limit__))
			if(!products || products?.length < 1){
				dispatch(setSalesFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}


export const action_fetch_message_config = (code, headers) => {
    return async dispatch => {
		const response = await getCountryMessage(code, headers)
		if(response?.code === 200){
			dispatch(setMessageConfig(response?.result))
			return response.result
		}else{
			return null
		}
	}
}