import {
	TRENDING_LIST_ADD,
	TRENDING_LIST_SET,
	TRENDING_LIST_FINISHED,
	TRENDING_LIST_SKIP,
	TRENDING_LIST_LOADING,
	TRENDING_FILTER_SET,
	TRENDING_SORTERS_SET,
	TRENDING_TOTAL,
	TRENDING_FILTER_DATA,
} from '../../actions/pages/trending'

const initialState = {
	list: [],
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	total: 0,
	filterData: null
}

export default (state = initialState, action) => {
	switch (action.type){
		case TRENDING_LIST_SET:
			return {...state, list: action.payload}
		case TRENDING_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case TRENDING_LIST_SKIP:
			return {...state, skip: action.payload}
		case TRENDING_LIST_FINISHED:
			return {...state, finished: action.payload}
		case TRENDING_LIST_LOADING:
			return {...state, loading: action.payload}
		case TRENDING_FILTER_SET:
			return {...state, filterVo: action.payload}
		case TRENDING_SORTERS_SET:
			return {...state, sorters: action.payload}
		case TRENDING_TOTAL:
			return {...state, total: action.payload}
		case TRENDING_FILTER_DATA:
			return {...state, filterData: action.payload}	
		default:
			return state
	}
}