import React, { useCallback, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import Cache from '../../../../../../utils/cache'
import { Iconfont } from '../../../../../components/icon/iconfont'
import withSourceClick from '../../../../../hocs/source-only-click'
import { getSensorsUrl } from '../../../../../utils/sensor'

const RecentlyWord = withSourceClick(props =>{
    const { innerRef, word, handleDelete } = props;

    const handleClick = useCallback((event, hot) => {
        let href;
        if (hot.href) {
            href = hot.href
        } else {
            href = `/i/search/result?key=${encodeURIComponent(hot.value)}`
        }

        const { currentTarget } = event;
		window.location.href = getSensorsUrl(href, {
			resourcepage_title: currentTarget.getAttribute("data-title"),
			resource_type: currentTarget.getAttribute("data-type"),
            resource_position: currentTarget.getAttribute("data-position"),
			resource_content: currentTarget.getAttribute("data-content"),
		})
    }, [])

    return <li 
        className={classes.Word} 
        onClick={e => handleClick(e, word)} 
        ref={innerRef}
        data-title={"search"}
        data-position={"1"}
        data-type={"18"}
        data-content={word?.label}
    >
        <span className={classes.WordSpan}>{word?.label}</span>
        <Iconfont onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleDelete(word)
        }} className={classes.DeleteIcon}>&#xe7c9;</Iconfont>
    </li>
})

export default props => {
    useStyles(classes)

    const [recentlyWords, setRecentlyWords] = useState(Cache.get('recently-words') || [])

    const handleDelete = w => {
        const filteredWords = recentlyWords?.filter(r => r.value !== w.value)
        setRecentlyWords(filteredWords)
        Cache.set('recently-words', filteredWords, 30 * 24 * 60 * 60)
    }

    const handleDeleteAll = () => {
        setRecentlyWords([])
        Cache.remove('recently-words')
    }

    return recentlyWords?.length > 0 ? <div className={classes.RecentlyWordsContainer}>
        <div className={classes.Hd}>
            <span className={classes.Title}><FormattedMessage id="recently_search" defaultMessage="Recently Search" /></span>
            <Iconfont onClick={handleDeleteAll} className={classes.RemoveAll}>&#xe629;</Iconfont>
        </div>
        <ul className={classes.WordList}>
            {
                recentlyWords?.map?.(word => <RecentlyWord key={word?.value} word={word} handleDelete={handleDelete} />)
            }
        </ul>
    </div> : <React.Fragment />

}