export const getTrimAndLower = (str) => {
    if (!str)
        return;
    return str.replace(/\s/g, '').toLowerCase();
}

export const getNoDash = (str) => {
    if (!str)
        return;
    return str.replace(/-/g, '');
}

export const getPhoneNumber = (str, phoneArea) => {
    if (!str)
        return;
    return `${phoneArea||''}${str}`.replace(/\D+/g, '');    
}