import useStyles from "isomorphic-style-loader/useStyles"
import React from "react"
import classes from './style.module.scss'

const Dropdown = props => {
	useStyles(classes)
	const { data, value } = props

	return <div className={`${classes.SelectM} ${props.className}`}>
		<select onChange={(e)=>props?.onChange(e)} value={value || data?.[0]['value']}>
			{
				data?.map((item, index) => <option value={item.value}>
					{ item.label }
				</option>)
			}
		</select>
	</div>
}

export default Dropdown