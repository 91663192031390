import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { useSelector, useDispatch } from 'react-redux'
import { setSizeDefaultCountry } from '../../../../../store/actions/global'
import Cookies from 'js-cookie'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'

export default props => {
    useStyles(classes)

    const dispatch = useDispatch()
    const { sizeChart2 } = props
    const global = useSelector(state => state.global)
    const intl = useIntl()

    const countries = sizeChart2?.countryAndSizesList?.[0]?.map(c => c.country)
    const selectedSizeCountry = countries?.find(c => c === global?.sizeDefaultCountry) || sizeChart2?.defaultCountry

    // useEffect(() => {
    //     if(selectedSizeCountry != global?.sizeDefaultCountry){
    //         Cookies.set('sizeDefaultCountry', selectedSizeCountry, { path: '/', expires: 30 })
    //         dispatch(setSizeDefaultCountry(selectedSizeCountry))
    //     }
    // }, [])

    return <select className={`${classes.Select} ${props?.className ? props.className : ''}`} onChange={e => {
        let country = e.target.value
        Cookies.set('sizeDefaultCountry', country, { path: '/', expires: 30 })
        dispatch(setSizeDefaultCountry(country))
    }} value={selectedSizeCountry}>
        {
            countries?.map(country => <option key={country} value={country}>
                {intl.formatMessage({id: 'country_size', defaultMessage: `${country} Size`},{country: country})}
            </option>)
        }
    </select>
}