import useStyles from 'isomorphic-style-loader/useStyles';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Iconfont } from '../../../../../../../components/icon/iconfont';
import DropDownContent from '../../drop-down-content';
import classes from './style.module.scss';
import { bathingHTML, bottomHTML, bottomOldHTML, braHTML, coatHTML, dressHTML, highheelsHTML, jumpsuitsHTML, onepieceHTML, shoesHTML, swimBraHTML, topHTML, underwearHTML } from './mock';

const HTMDetail = (props) => {
    useStyles(classes)
    const {type, intl, productMeasurementData} = props

    const list = {
        "jumpsuits":['waist','hip'],
        "dress":['bust','waist','hip'],
        "shoes":['length'],
        "top":['bust','waist','hip'],
        "underwear":['bust','waist','hip'],
        "bathing":['bust', 'hip'],
        "bottom":['waist','hip'],
        "coat":['bust','waist','hip'],
        "onepiece":['bust','waist','hip'],
        "highheels":['waist','hip'],
    }

    const contentDetail = (type) => {
        let content = ''
        switch(type){
            case 'waist':
                content = intl.formatMessage({id:"waist_note"})
                break
            case 'hip':
                content = intl.formatMessage({id:"hip_note"})
                break
            case 'bust':
                content = intl.formatMessage({id:"bust_note"})
                break
            case 'length':
                content = intl.formatMessage({id:"length_note"})
                break
            case 'bottomLength':
                content = intl.formatMessage({id:"bottomLengthNote"})
                break
            case 'inseam':
                content = intl.formatMessage({id:"inseamNote"})
                break
            case 'yourBust':
                content = intl.formatMessage({id:"yourBustNote"})
                break
            case 'underBust':
                content = intl.formatMessage({id:"underBustNote"})
                break
            case 'swimBust':
                content = intl.formatMessage({id:"swimBustNote"})
                break
            case 'swimUnderBust':
                content = intl.formatMessage({id:"swimUnderBustNote"})
                break
            case 'swimWaist':
                content = intl.formatMessage({id:"swimWaistNote"})
                break
            case 'swimHip':
                content = intl.formatMessage({id:"swimHipNote"})
                break
            case 'footLength':
                content = intl.formatMessage({id:"footLengthNote"})
                break
            case 'footWidth':
                content = intl.formatMessage({id:"footWidthNote"})
                break
            default:
                content = intl.formatMessage({id:"waist_note"})
        }
        return content
    }

    const TitleDetail = (type) => {
        let content = ''
        switch(type){
            case 'waist':
                content = intl.formatMessage({id:"waist"})
                break
            case 'hip':
                content = intl.formatMessage({id:"hip"})
                break
            case 'bust':
                content = intl.formatMessage({id:"bust"})
                break
            case 'length':
                content = intl.formatMessage({id:"length"})
                break
            case 'bottomLength':
                content = intl.formatMessage({id:"length"})
                break
            case 'inseam':
                content = intl.formatMessage({id:"inseam"})
                break
            case 'yourBust':
                content = intl.formatMessage({id:"your_bust"})
                break
            case 'underBust':
                content = intl.formatMessage({id:"yourUnderBust"})
                break
            case 'swimBust':
                content = intl.formatMessage({id:"bust"})
                break
            case 'swimUnderBust':
                content = intl.formatMessage({id:"yourUnderBust"})
                break
            case 'swimWaist':
                content = intl.formatMessage({id:"waist"})
                break
            case 'swimHip':
                content = intl.formatMessage({id:"hip"})
                break
            case 'footLength':
                content = intl.formatMessage({id:"foot_length"})
                break
            case 'footWidth':
                content = intl.formatMessage({id:"footWidth"})
                break
            default:
                content = intl.formatMessage({id:"waist"})
        }
        return content
    }

    return <div className={classes.HTMDetail}>
        {
            productMeasurementData?.[type].map((l,i) => (
                <div className={classes.HTMDetailItem} key={i}>
                    <div className={classes.HTMDetailItemTitle}>
                        <span>{i + 1}.</span>
                        <span style={{textTransform:"capitalize"}}>{TitleDetail(l)}</span>
                    </div>
                    <div className={classes.HTMDetailItemContent}>
                        {contentDetail(l)}
                    </div>
                </div>
            ))
        }
    </div>
}

let time = 1
const SizeChartProductMeasurement = props => {
    useStyles(classes)
    const {showProductSizeM, setShowProductSizeM, productTaglia, productType, imgSrc, intl, productMeasurementData} = props || {}

    const productHTML = <div className={classes.HTMContent}>
        {
            productType != 1 &&
            <div className={classes.HTMContentText}>
                <FormattedMessage id="measurements"/>
            </div>
        }
        <div className={classes.HTMStreamContent}>
            {
                productTaglia == 'shoes' || productTaglia == 'swimwear' || productTaglia == 'lingerie' ?
                <React.Fragment>
                    <div className={classes.NewPositionBox}>
                        <HTMDetail type={productTaglia} intl={intl} productMeasurementData={productMeasurementData}/>
                        <img src={imgSrc} alt="" />
                    </div>
                </React.Fragment>:
                <React.Fragment>
                    <img src={imgSrc} alt="" />
                    <HTMDetail type={productTaglia} intl={intl} productMeasurementData={productMeasurementData}/>
                </React.Fragment>
            }
        </div>
    </div>

    return <React.Fragment>
        <div className={classes.HowToMeasureBox}>
            <div className={classes.HTMTitle} onClick={() => setShowProductSizeM(!showProductSizeM)}>
                <FormattedMessage id="measure_product_size"/>
                <Iconfont className={`${classes.HTMShow} ${showProductSizeM && classes.HTMClose}`} >&#xe692;</Iconfont>
            </div>
            <DropDownContent show={showProductSizeM && productTaglia}>
                {productHTML}
            </DropDownContent>
        </div>
    </React.Fragment>
}


export default SizeChartProductMeasurement