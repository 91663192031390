import {
	CATEGORY_SET,
	CATEGORY_LIST_ADD,
	CATEGORY_LIST_SET,
	CATEGORY_LIST_FINISHED,
	CATEGORY_LIST_SKIP,
	CATEGORY_LIST_LOADING,
	CATEGORY_FILTER_SET,
	CATEGORY_SORTERS_SET,
	CATEGORY_TOTAL,
	CATEGORY_FILTER_DATA,
	CATEGORY_SET_PARENT_NODES,
} from '../../actions/pages/category'

const initialState = {
	list: [],
	total: 0,
	category: null,
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	filterData: null,
	parentNodes: null,
}

export default (state = initialState, action) => {
	switch (action.type){
		case CATEGORY_SET:
			return {...state, category: action.payload}
		case CATEGORY_LIST_SET:
			return {...state, list: action.payload}
		case CATEGORY_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case CATEGORY_LIST_SKIP:
			return {...state, skip: action.payload}
		case CATEGORY_LIST_FINISHED:
			return {...state, finished: action.payload}
		case CATEGORY_LIST_LOADING:
			return {...state, loading: action.payload}
		case CATEGORY_FILTER_SET:
			return {...state, filterVo: action.payload}
		case CATEGORY_SORTERS_SET:
			return {...state, sorters: action.payload}
		case CATEGORY_TOTAL:
			return {...state, total: action.payload}	
		case CATEGORY_FILTER_DATA:
			return {...state, filterData: action.payload }
		case CATEGORY_SET_PARENT_NODES:
			return {...state, parentNodes: action.payload }	
		default:
			return state
	}
}