import {combineReducers} from 'redux'
import GlobalReducer from './global'
import UIReducer from './ui'
import PageCollection from './pages/collection'
import PageCategory from './pages/category'
import PageSearch from './pages/search'
import PageHome from './pages/home'
import PageLanding from './pages/landing'
import PageSimilar from './pages/similar'
import PageProduct from './pages/product'
import PageSimpleLanding from './pages/simple-landing'
import PageGifts from './pages/gifts'
import PageListCollections from './pages/list-collections'
import PageSales from './pages/list-sales'
import PageVideoSales from './pages/list-video-sales'
import PageExclusive from './pages/exclusive'
import PageWelcome from './pages/welcome'
import PageLive from './pages/live'
import PageSimpleList from './pages/simple-list'
import PageCLog from './pages/c-log'
import PageNewArrivals from './pages/new-arrivals'
import PageSizeChart from './pages/size-chart'
import PagePolicy from './pages/policy'
import PageCart from './pages/cart'
import PageCustomer from './pages/customer'
import PageTrending from './pages/trending'

export default combineReducers({
	global: GlobalReducer,
	ui: UIReducer,
	pageCollection: PageCollection,
	pageCategory: PageCategory,
	pageSearch: PageSearch,
	pageHome: PageHome,
	pageLanding: PageLanding,
	pageSimilar: PageSimilar,
	pageProduct: PageProduct,
	pageSimpleLanding: PageSimpleLanding,
	pageGifts: PageGifts,
	pageListCollections: PageListCollections,
	pageSales: PageSales,
	pageVideoSales: PageVideoSales,
	pageExclusive: PageExclusive,
	pageWelcome: PageWelcome,
	pageLive: PageLive,
	pageSimpleList: PageSimpleList,
	pageCLog: PageCLog,
	pageNewArrivals: PageNewArrivals,
	pageSizeChart: PageSizeChart,
	pagePolicy: PagePolicy,
	pageCart: PageCart,
	pageCustomer: PageCustomer,
	pageTrending: PageTrending,
})