import { getCountryMessage, fetchABTesting, fetchBanner, getCountryAppMessage, fetchCollectionList, fetchConfig } from '../../../api'

export const LANDING_INIT = 'LANDING_INIT'
export const LANDING_CURRENT_PAGE = 'LANDING_INIT_PAGE'
export const LANDING_AB_TEST = 'LANDING_AB_TEST'

export const LANDING_WEB2APP_LOTTERY_MESSAGE = "LANDING_WEB2APP_LOTTERY_MESSAGE"
export const LANDING_WEB2APP_LIST_MESSAGE = "LANDING_WEB2APP_LIST_MESSAGE"
export const LANDING_WEB2APP_LIST_PRODUCTS = "LANDING_WEB2APP_LIST_PRODUCTS"
export const LANDING_WEB2APP_APPEND_LIST_PRODUCTS = "LANDING_WEB2APP_APPEND_LIST_PRODUCTS"
export const LANDING_WEB2APP_LIST_PRODUCTS_SKIP = "LANDING_WEB2APP_LIST_PRODUCTS_SKIP"
export const LANDING_WEB2APP_LIST_PRODUCTS_LIMIT = "LANDING_WEB2APP_LIST_PRODUCTS_LIMIT"
export const LANDING_WEB2APP_LIST_PRODUCTS_FINISHED = "LANDING_WEB2APP_LIST_PRODUCTS_FINISHED"
export const LANDING_WEB2APP_LIST_PRODUCTS_LOADING = "LANDING_WEB2APP_LIST_PRODUCTS_LOADING"
export const LANDING_WEB2APP_IMAGE_PAGE_MESSAGE = "LANDING_WEB2APP_IMAGE_PAGE_MESSAGE"

export const setLandingPages = payload => ({
    type: LANDING_INIT,
    payload
})

export const setCurrentPage = payload => ({
    type: LANDING_CURRENT_PAGE,
    payload
})

export const setLandingAbTest = payload => ({
    type: LANDING_AB_TEST,
    payload
})

export const setWeb2AppLotteryMessage = payload => ({
    type: LANDING_WEB2APP_LOTTERY_MESSAGE,
    payload
})

export const setWeb2AppListMessage = payload => ({
    type: LANDING_WEB2APP_LIST_MESSAGE,
    payload
})

export const setWeb2AppListProducts = payload => ({
    type: LANDING_WEB2APP_LIST_PRODUCTS,
    payload
})

export const appendWeb2AppListProducts = payload => ({
    type: LANDING_WEB2APP_APPEND_LIST_PRODUCTS,
    payload
})
export const setWeb2AppListProductsSkip = payload => ({
    type: LANDING_WEB2APP_LIST_PRODUCTS_SKIP,
    payload
})
export const setWeb2AppListProductsLimit = payload => ({
    type: LANDING_WEB2APP_LIST_PRODUCTS_LIMIT,
    payload
})
export const setWeb2AppListProductsFinished = payload => ({
    type: LANDING_WEB2APP_LIST_PRODUCTS_FINISHED,
    payload
})

export const setWeb2AppListProductsLoading = payload => ({
    type: LANDING_WEB2APP_LIST_PRODUCTS_LOADING,
    payload
})

export const setWeb2AppImagePageMessage = payload => ({
    type: LANDING_WEB2APP_IMAGE_PAGE_MESSAGE,
    payload
})

export const action_fetch_ab = headers => {
    return async dispatch => {
        const response = await fetchABTesting('0315-W', headers).catch(e => ({result: "A"}))
        if(response?.code === 200){
            dispatch(setLandingAbTest(response.result))
        }
        return response?.result
    }
}

export const action_fetch_landing = (website,previewCode, headers) => {
	return async dispatch => {
        let pages

		const response = await getCountryMessage(previewCode || 'M1581', headers)

		if(response?.code === 200){
            const { result } = response
            const websites = result?.websites || []

            const currentWebsite = (websites?.find(w => w.id === website) || websites[0])?.id

            dispatch(setCurrentPage(currentWebsite))


            const promises = websites?.map(website => getCountryMessage(website.code, headers).then(data => ({
                ...website,
                page: data?.result
            })).catch(data => {
                console.error(data)
                return {
                    ...website
                }
            }))

            if(promises && promises.length > 0){
                pages = await Promise.all(promises)
                const currentPage = pages?.find?.(p => p.id === currentWebsite)?.page
                const currentPageModules = currentPage?.modules?.filter(m => m.show)?.sort((m1, m2) => m1.sort - m2.sort)
                const bannerModule = currentPageModules?.find(m => m.type === 'banner')
                if(bannerModule){
                    const items = await fetchBanner(currentWebsite, headers).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    bannerModule.data = items
                }

                dispatch(setLandingPages(pages))
            }
            
			return pages
		}else{
			return null
		}
	}
}

export const action_fetch_web2app_lottery_message = (headers) => {
    return async dispatch => {
        const response = await fetchConfig('0573-W', headers)
        if(response?.code === 200){
            dispatch(setWeb2AppLotteryMessage(response?.result))
        }
        return response?.result
    }
}

export const action_fetch_web2app_list_product = (headers) => {
    return async dispatch => {
        dispatch(setWeb2AppListProductsFinished(false))
        dispatch(setWeb2AppListProducts([]))
        dispatch(setWeb2AppListProductsSkip(0))
        dispatch(setWeb2AppListProductsLimit(20))
        const messageRes = await getCountryAppMessage("M1834", headers)
        if(messageRes?.code == 200){
            dispatch(setWeb2AppListMessage(messageRes?.result))
            const productsRes = await fetchCollectionList({collectionId: messageRes?.result?.collectionId}, {skip:0, limit: 20},headers)
            if(productsRes?.code == 200){
                if(productsRes?.result?.length < 0){
                    dispatch(setWeb2AppListProductsFinished(true))
                    dispatch(setWeb2AppListProductsSkip(0))
                    dispatch(setWeb2AppListProducts([]))
                } else {
                    dispatch(setWeb2AppListProductsSkip(20))
                    dispatch(setWeb2AppListProducts(productsRes?.result))
                }
            }
        }
        return null
    }
}

export const action_append_web2app_list_product = (headers) => {
    return async (dispatch, getState) => {
        const { pageLanding } = getState()
        const { listMessage, listLoading, listSkip, listLimit, listFinished } = pageLanding?.web2App || {}

        if(!listFinished && !listLoading){
            dispatch(setWeb2AppListProductsLoading(true))
            const productsRes = await fetchCollectionList({collectionId: listMessage?.collectionId}, {skip:listSkip, limit: listLimit},headers)
            dispatch(setWeb2AppListProductsLoading(false))
            if(productsRes?.code == 200){
                if(productsRes?.result?.length < 0){
                    dispatch(setWeb2AppListProductsFinished(true))
                    dispatch(setWeb2AppListProductsSkip(0))
                } else {
                    dispatch(setWeb2AppListProductsSkip(listSkip + 20))
                    dispatch(appendWeb2AppListProducts(productsRes?.result))
                }
            }
        }
        return null
    }
}

export const action_fetch_web2app_image_page_message = (headers) => {
    return async dispatch => {
        const response = await getCountryAppMessage("M1842", headers)
        if(response?.code === 200){
            dispatch(setWeb2AppImagePageMessage(response?.result))
        }
        return response?.result
    }
}