import {
	SET_SIZE_CHART,
	SET_SHOW_SIZE_CHART,
    SET_APP_PRODUCT_DETAIL,
    SET_APP_PRODUCT_TAGLIA
} from '../../actions/pages/size-chart'

const initialState = {
	sizechart: null,
	showSizeChart: false,
    appProductDetail: null,
    appProductTaglia: null,
}

export default (state = initialState, action) => {
	switch (action.type){
		case SET_SIZE_CHART:
			return {...state, sizechart: action.payload}	
		case SET_SHOW_SIZE_CHART:
			return {...state, showSizeChart: action.payload}
        case SET_APP_PRODUCT_DETAIL:
            return {...state, appProductDetail: action.payload}
        case SET_APP_PRODUCT_TAGLIA:
            return {...state, appProductTaglia: action.payload}
		default:
			return state
	}
}
