import ads from '../../../../configs/ads.json'

const addToCart = data => {

}

const addToWishList = data => {

}

const pageView = data => {

}

const purchase = data => {
    try {
        const {
            paymentTotalWithUSD,
        } = data

        window.dotq1 = window.dotq1 || [];
        window.dotq1.push({
            'projectId': '10000',
            'properties': {
                'pixelId': ads.yahoo?.[0]?.id,
                'qstrings': {
                    'et': 'custom',
                    'gv': paymentTotalWithUSD?.amount,
                }
            }
        });

        window.dotq2 = window.dotq2 || [];
        window.dotq2.push({
            'projectId': '10000',
            'properties': {
                'pixelId': ads.yahoo?.[1]?.id,
                'qstrings': {
                    'et': 'custom',
                    'gv': paymentTotalWithUSD?.amount,
                }
            }
        });


        window.dotq3 = window.dotq3 || [];
        window.dotq3.push({
            'projectId': '10000',
            'properties': {
                'pixelId': ads.yahoo?.[2]?.id,
                'qstrings': {
                    'et': 'custom',
                    'gv': paymentTotalWithUSD?.amount,
                }
            }
        });


    } catch (e) {
        console.warn(e)
    }
}



export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}