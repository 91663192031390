import {SIMPLE_LANDING_INIT} from '../../actions/pages/simple-landing'

const initialState = {
	simpleData: null
}

export default (state = initialState, action) => {
	switch(action.type){
		case SIMPLE_LANDING_INIT:
			return {...state, simpleData: action.payload}
		default:
			return state
	}
}