
const PAGE_EARLY_BIRD = '/i/early-bird'
const PAGE_FLASH = '/i/flash/products'
const PAGE_NEW = '/i/new-arrivals'
const PAGE_TRENDING = '/i/trending-now'

const addParam = (param, url) => {
	if(url?.indexOf('?')>=0){
		return `${url}&${param.key}=${param.value}`
	}
	return `${url}?${param.key}=${param.value}`
}

const addParamStr = (paramStr, url) => {
	if(url?.indexOf('?')>=0){
		return `${url}&${paramStr}`
	}
	return `${url}?${paramStr}`
}

const addUtms = (params, url) => {
	try {
		if(!params || params?.length < 1){
			return url
		}
		const str = params?.map?.(param => `${param.key}=${param.value}`)?.join?.('&')
		if(url?.indexOf('?')>=0){
			return `${url}&${str}`
		}
		return `${url}?${str}`
	} catch (error) {
		return url
	}
	
}


export const getDeepLinkType = deepLink => {
	if (!deepLink) return 'unknown'
	const { type } = deepLink
	let deepLinkType
	switch (type) {
		case 1:
			deepLinkType = 'collection'
			break
		case 2:
			deepLinkType = 'product'
			break
		case 15:
			deepLinkType = 'category'
			break
		default:
			deepLinkType = 'unknown'
			break
	}
	return deepLinkType
}

export const getAppLink = deepLink => {
	if (!deepLink) return '#'

	if (typeof deepLink === 'string') {
		try {
			deepLink = JSON.parse(deepLink)
		} catch (e) {
			return '#'
		}
	}


	const { type, params } = deepLink
	let _url
	switch (type) {
		case 1:
			_url = 'chic-me://chic.me/collection?collectionId=' + params[0]
			break
		case 15:
			_url = 'chic-me://chic.me/category?categoryId=' + params[0]
			break
		case 16:
			_url = addParam({key:'isApp', value: 1}, params[0])
			break
		case 13:
			_url = 'chic-me://chic.me/newarrival'
			break
		case 12:
			_url = 'chic-me://chic.me/flashDeal'	
			break
		case 14:
			_url = 'chic-me://chic.me/trendingselling'
			break
		case 38:
			_url = `chic-me://chic.me/promotionCollection?id=${params[0]}`
			break	
		// 40 为 clog图片的  41为 clog视频的  42 clog直播
		default:
			_url = '#'
	}

	if(params?.[2]){
		_url = addParamStr(params?.[2], _url)
	}
	return _url
}

export default (deepLink, deviceType = 'msite') => {
	if (!deepLink) return '#'

	if (typeof deepLink === 'string') {
		try {
			deepLink = JSON.parse(deepLink)
		} catch (e) {
			return '#'
		}
	}


	const { type, params } = deepLink
	let _url
	switch (type) {
		case 1:
			_url = `/collection/products/${params[0]}.html`
			break
		case 2:
			_url = `/product/details/${params[0]}.html`
			break
		case 3:
			if(deviceType == 'pc')_url = '/me/m/coupons'
			if(deviceType == 'msite')_url = '/user/coupons'
			break
		case 7:
			if(deviceType == 'pc')_url = '/me/m/credits'
			if(deviceType == 'msite')_url = '/user/credits'
			break
		case 8:
			if(deviceType == 'pc')_url = `/me/m/order/detail/${params[0]}`
			if(deviceType == 'msite')_url = `/user/order/detail/${params[0]}`
			break
		case 9:
			if(deviceType == 'pc')_url = `/me/m/faq/support-ticket/${params[0]}`
			if(deviceType == 'msite')_url = `/user/faq/support-ticket/${params[0]}`
			break
		case 10:
			_url = '/i/lottery'
			break
		case 11:
			_url = PAGE_EARLY_BIRD
			break
		case 12:
			_url = PAGE_FLASH
			break
		case 13:
			_url = PAGE_NEW
			break
		case 14:
			_url = PAGE_TRENDING
			break
		case 15:
			_url = `/category/products/${params[0]}.html`
			break
		case 16:
			_url = params[0]
			break
		case 17:
			_url = '/cart'
			break
		case 21:
			_url = `/w-site/anon/search?searchValue=${params[0]}${params[1] ? `&searchTitle=${encodeURIComponent(params[1])}` : ""}`;
			break;
		case 22:
			_url = `/me/m/order/detail/${params[0]}`;
			break;
		case 36:
			_url = '/user/vip'
			break
		case 38:
			_url = `/collection/promotion/${params[0]}.html?ref=${params[2]?.indexOf?.('isGifts=1')>=0 ? `gifts&${params[2]}`: 'promotion' }`
			break
		case 46:
			_url = `/i/menu?categoryName=${encodeURIComponent(params[0])}`;
			break;
		default:
			_url = '#'
	}

	if(params?.[2] && type !== 38){
		_url = addParamStr( params?.[2], _url)
	}

	const utms = []

	if(deepLink.utmSource){
		utms.push({key: 'utm_source', value: deepLink.utmSource})
	}
	if(deepLink.utmCampaign){
		utms.push({key: 'utm_campaign', value: deepLink.utmCampaign})
	}
	if(deepLink.utmMedium){
		utms.push({key: 'utm_medium', value: deepLink.utmMedium})
	}
	if(deepLink.utmTerm){
		utms.push({key: 'utm_term', value: deepLink.utmTerm})
	}
	if(deepLink.utmContent){
		utms.push({key: 'utm_content', value: deepLink.utmContent})
	}
	if(deepLink.utmSku){
		utms.push({key: 'utm_sku', value: deepLink.utmSku})
	}

	return addUtms(utms, _url)
}



