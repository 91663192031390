import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveWishListProduct as apiAddToWishList, removeWishListProduct as apiRemoveFromWishList } from '../../../../../../api'
import GeekoEvents from '../../../../../ads/events'
import { action_fetch_wish_list, setWishList } from '../../../../../../store/actions/global'

export default Wrapped => {
    return props => {
        const { productId, product_type, saved } = props
        const dispatch = useDispatch()
        const global = useSelector((state) => state.global)
        const { wishlist } = global
        const hasExistInWishList = wishlist?.some(pid => pid === productId) || saved

        useEffect(() => {
            if(saved){
                dispatch(setWishList([...(wishlist || []), productId]))
            }
        }, [saved])

        const addToWishList = async e => {
            e?.preventDefault?.()
            e?.stopPropagation?.()
            if (hasExistInWishList || saved) {
                dispatch(setWishList(wishlist?.filter(w => w !== productId)))
                await apiRemoveFromWishList(productId)
            } else {
                dispatch(setWishList([...(wishlist || []), productId]))
                const response = await apiAddToWishList(productId)
                if (response?.code === 300) {
                    window.location.href = `/i/login?redirectUrl=${window.location.href}`
                } else if(response?.code != 200) {
                    if(response?.result){
                        alert(response?.result)
                    } else {
                        console.error(response)
                    }
                }
                GeekoEvents.addToWishList({
                    productId,
                    product_type
                })
            }
            // dispatch(action_fetch_wish_list())
        }

        return <Wrapped {...props} addToWishList={addToWishList} hasExistInWishList={hasExistInWishList}/>
    }
}