import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

export default props => {
    useStyles(classes)
    const { isDesktop } = props;
    return <div className={`${classes.Spinner} ${isDesktop ? classes.Desktop : ""}`}>
        <div className={classes.Bar1}></div>
        <div className={classes.Bar2}></div>
        <div className={classes.Bar3}></div>
        <div className={classes.Bar4}></div>
        <div className={classes.Bar5}></div>
        <div className={classes.Bar6}></div>
        <div className={classes.Bar7}></div>
        <div className={classes.Bar8}></div>
        <div className={classes.Bar9}></div>
        <div className={classes.Bar10}></div>
        <div className={classes.Bar11}></div>
        <div className={classes.Bar12}></div>
    </div>
}