

const addToCart = data => {
    try {
        const {
            variantId,
            productId
        } = data
        window.rtbhEvents = window.rtbhEvents || []
        window.rtbhEvents.push({ eventType: 'basketadd', offerId: productId })
    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        const {
            variantId,
            productId
        } = data
        window.rtbhEvents = window.rtbhEvents || []
        window.rtbhEvents.push({ eventType: 'wishlist', offerId: productId });
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
   
}

const purchase = data => {
    try {
        const {
            transactionId,
            paymentTotalWithUSD,
            orderItems,
        } = data

        const item_ids = orderItems?.map(item => item.productId)

        window.rtbhEvents = window.rtbhEvents || []
        window.rtbhEvents.push({ eventType: 'conversion', conversionClass: 'order', conversionSubClass: 'purchase', conversionId: transactionId, conversionValue: paymentTotalWithUSD?.amount, offerIds: item_ids })
    
    } catch (e) {
        console.warn(e)
    }
}



export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}