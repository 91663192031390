import { useEffect, useState, useRef, useCallback } from "react";

export function useScroll() {

    const [direction, setDirection] = useState(null)

    const beforeScrollTop = useRef(null)

    const timeRef = useRef()


    const scrollHandle = useCallback(event => {
        const afterScrollTop = window.scrollY, delta = afterScrollTop - beforeScrollTop.current
        setDirection(delta > 0 && afterScrollTop > 100 ? 'up' : 'down')
        beforeScrollTop.current = afterScrollTop
        clearTimeout(timeRef.current)
        timeRef.current = setTimeout(() => {
            setDirection(null)
        }, 2000)
    }, [])

    useEffect(() => {
        beforeScrollTop.current = window.scrollY
        window.addEventListener('scroll', scrollHandle)
        return () => {
            window.removeEventListener('scroll', scrollHandle)
        }
    }, [])

    return direction
}