import React, { useState, useRef, useEffect, useMemo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import classes from './style.module.scss'
import deep from '../../../../../../utils/deeplink'
import { getDelPrice, getLowerPrice, isFlashSales, unitPrice } from '../../../../../../utils/product'
import useStyles from 'isomorphic-style-loader/useStyles'
import withSourceScroll from '../../../../../hocs/source-scoll'
import { FullSwiper } from '../../../../../pages/moduleable/components/swiper'
import { getSensorsUrl } from '../../../../../utils/sensor'
import _ from 'lodash'
import CountDown from '../../../../../components/count-down'

const clickAHandle = (e, sensors) => {
    e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}

export const SmartType2 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard
    const url = deep(deepLink)

    return <a href={url} ref={innerRef}
        data-position={position + 1}
        data-type="1"
        data-content={enTitle}
        data-title="list"
        onClick={e => clickAHandle(e, {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: enTitle,
            resource_position: position + 1,
        })}
        style={{
            textDecoration: 'none',
            color: '#222'
        }}
    >
        <div
            className={classes.SmartCard2}
            style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
            <div className={classes.Bd}>
                <FullSwiper
                    items={products}
                    options={{
                        pagination: {
                            clickable: true,
                            bulletClass: classes.Bullet,
                            bulletActiveClass: classes.BulletActive
                        },
                        loop: true,
                        autoplay: {
                            disableOnInteraction: false,
                            delay: 2000,
                        }
                    }}
                    render={(product, index) => <div>
                        <div className={classes.Image} key={product.id + index}>
                            <img src={`${IMAGE_GEEKO_LTD}/medium/${product?.pcMainImage}`} />
                        </div>
                    </div>}
                >
                </FullSwiper>
            </div>

            <div className={classes.Hd}>
                <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
            </div>
        </div>

    </a>
})

export const SmartType1 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard
    const url = deep(deepLink)


    return <a href={url} ref={innerRef}
        data-position={position + 1}
        data-type="1"
        data-content={enTitle}
        data-title="list"
        onClick={e => clickAHandle(e, {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: enTitle,
            resource_position: position + 1,
        })}
        style={{
            textDecoration: 'none',
            color: '#222'
        }}
    >
        <div
            className={classes.SmartCard}
            style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
            <div className={classes.Hd}>
                <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
            </div>
            <div className={classes.Bd}>
                {
                    products?.slice(0,4)?.map(product => <div className={classes.InnerProduct}>
                        <div className={classes.Image} key={product.id}>
                            <LazyLoadImage src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />
                        </div>
                        <div className={classes.Price}>
                            {unitPrice(getLowerPrice(product))}
                        </div>
                    </div>)
                }
            </div>
        </div>

    </a>
})




const FlashImage = props => {
    useStyles(classes)
    const { products, position, innerRef, entered } = props
    const [selectedProduct, setSelectedProduct] = useState(products?.[0])
    const opacityIndex = useRef(-2)
    const timing = useRef()
    const timeout = useRef()

    useEffect(() => {

        timeout.current = setTimeout(() => {
            timing.current = setInterval(() => {
                if (opacityIndex.current < products?.length - 2) {
                    opacityIndex.current = opacityIndex.current + 1
                    if (opacityIndex.current >= -1) {
                        setSelectedProduct(products[opacityIndex.current + 1])
                    }
                } else {
                    opacityIndex.current = -2
                }
            }, 2000)
        }, position * 100)
        

        return () => {
            clearInterval(timing.current)
            clearInterval(timeout.current)
        }
    }, [])



    return <div className={classes.InnerProduct}>
        <div className={classes.Image}>
            {
                products?.map((product, index) => <img className={opacityIndex.current >= index ? classes.Transparent : ''} key={`${product?.id}-${index}`} style={{ zIndex: products?.length - index }} src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />)
            }
        </div>
        <div className={classes.Price}>
            {unitPrice(getLowerPrice(selectedProduct))}
        </div>
    </div>
}

export const SmartType3 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard
    const url = deep(deepLink)

    const groupedProducts = _.reduce(products, function (result, p, index) {
        result[index % 4].push(p)
        return result
    }, [[], [], [], []])


    return <a href={url} ref={innerRef}
        data-position={position + 1}
        data-type="1"
        data-content={enTitle}
        data-title="list"
        onClick={e => clickAHandle(e, {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: enTitle,
            resource_position: position + 1,
        })}
        style={{
            textDecoration: 'none',
            color: '#222'
        }}
    >
        <div
            className={classes.SmartCard}
            style={{
                backgroundImage: `url(${backgroundImage})`
            }}>
            <div className={classes.Hd}>
                <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
            </div>
            <div className={classes.Bd}>
                {
                    groupedProducts?.map((ps, index) => <FlashImage position={index} key={index} products={ps} />)
                }
            </div>
        </div>

    </a>
})

const SmartType6Product = props => {
    useStyles(classes)
    const {product} = props
    const [lowerPrice, delPrice, isFlash] = [
        getLowerPrice(product),
        getDelPrice(product),
        isFlashSales(product)
    ]

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    return <div className={`${classes.Image} ${classes.Type6Image}`}>
        <LazyLoadImage src={`${IMAGE_GEEKO_LTD}/small/${product?.pcMainImage}`} />
        {
            off > 0 && <span className={classes.FlashTip}>
                <span className={classes.Number}>-{off}%</span>
            </span>
        }
    </div>
}

export const SmartType6 = withSourceScroll(props => {
    useStyles(classes)
    const { smartCard, innerRef, position } = props
    const { products, title, titleColor, backgroundImage, deepLink, enTitle } = smartCard
    const url = deep(deepLink)

    const offset = useMemo(() => {
        if(products?.length <= 0)return 0
        let product1 = products?.[0]
        let num = product1?.promotion?.endTime - product1?.promotion?.serverTime
        return num > 0 ? num: 0
    }, [products])

    const productsPages = useMemo(() => {
        let output = []
        const chunkArray = (arr, chunkSize) => {
            for(let i = 0; i < arr.length; i += chunkSize){
                output.push(arr.slice(i, i + chunkSize));
            }
        }
        chunkArray(products, 4)
        return output
    }, [products])

    return <a href={url} ref={innerRef}
        data-position={position + 1}
        data-type="1"
        data-content={enTitle}
        data-title="list"
        onClick={e => clickAHandle(e, {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: enTitle,
            resource_position: position + 1,
        })}
        style={{
            textDecoration: 'none',
            color: '#222'
        }}
    >
        <div
            className={`${classes.SmartCard2} ${classes.SmartCard6}`}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: '100% 100%'
            }}>
            <div className={classes.Hd}>
                <span style={{color: titleColor || '#222'}} dangerouslySetInnerHTML={{__html:title}}></span>
            </div>
            <div className={classes.CountDownBox}>
                <CountDown offset={offset} showHour showLabel/>
            </div>
            <div className={classes.Bd}>
                <FullSwiper
                    style={{height: '100%'}}
                    items={productsPages}
                    options={{
                        loop: true,
                        autoplay: {
                            disableOnInteraction: false,
                            delay: 5000,
                        }
                    }}
                    render={(productList, index) => <div className={classes.Type6Content} key={index}>
                        {
                            productList?.map((p) => {
                                return <SmartType6Product product={p} key={p?.id}/>
                            })
                        }
                    </div>}
                >
                </FullSwiper>
            </div>
        </div>

    </a>
})

export default props => {
    const { smartCard } = props;

    return <React.Fragment>
        {
            smartCard?.style === '1' && <SmartType1 {...props} />
        }

        {
            smartCard?.style === '2' && <SmartType2 {...props} />
        }

        {
            smartCard?.style === '3' && <SmartType3 {...props} />
        }

        {
            smartCard?.style === '6' && <SmartType6 {...props} />
        }
    </React.Fragment>
}