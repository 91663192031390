import { getCountryMessage, fetchABTesting, fetchBanner } from '../../../api'

export const HOME_INIT = 'HOME_INIT'
export const HOME_CURRENT_PAGE = 'HOME_INIT_PAGE'
export const HOME_AB_TEST = 'HOME_AB_TEST'
export const HOME_TOOGLE_SERVICE = 'HOME_TOOGLE_SERVICE';

export const setHomePages = payload => ({
    type: HOME_INIT,
    payload
})

export const setCurrentPage = payload => ({
    type: HOME_CURRENT_PAGE,
    payload
})

export const setHomeAbTest = payload => ({
    type: HOME_AB_TEST,
    payload
})

export const setHomeToogleService = payload =>({
    type: HOME_TOOGLE_SERVICE,
    payload
})

export const action_fetch_ab = headers => {
    return async dispatch => {
        const response = await fetchABTesting('0315-W', headers).catch(e => ({result: "A"}))
        if(response?.code === 200){
            dispatch(setHomeAbTest(response.result))
        }
        return response?.result
    }
}

export const action_fetch_home = (website, previewCode, headers) => {
	return async dispatch => {
        let pages

		const response = await getCountryMessage(previewCode || 'M1327', headers)

		if(response?.code === 200){
            const { result } = response
            const websites = result?.websites || [
                {
                    id: "1",
                    name: "Women",
                    refId: "Home-Women",
                    code: "M1316"
                }
            ]

            const currentWebsite = (websites?.find(w => w.id === website) || websites[0])?.id

            dispatch(setCurrentPage(currentWebsite))


            const promises = websites?.map(website => getCountryMessage(website.code, headers).then(data => ({
                ...website,
                page: data?.result
            })).catch(data => {
                console.error(data)
                return {
                    ...website
                }
            }))

            if(promises && promises.length > 0){
                pages = await Promise.all(promises)
                const currentPage = pages?.find?.(p => p.id === currentWebsite)?.page
                const currentPageModules = currentPage?.modules?.filter(m => m.show)?.sort((m1, m2) => m1.sort - m2.sort)
                const bannerModule = currentPageModules?.find(m => m.type === 'banner')
                if(bannerModule){
                    const items = await fetchBanner(currentWebsite, headers).then(data => data.result).catch(e => {
                        console.error(e)
                        return []
                    })
                    bannerModule.data = items
                }

                dispatch(setHomePages(pages))
            }
            
			return pages
		}else{
			return null
		}
	}
}