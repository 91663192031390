import {WELCOME_INIT} from '../../actions/pages/welcome'

const initialState = {
	simpleData: null
}

export default (state = initialState, action) => {
	switch(action.type){
		case WELCOME_INIT:
			return {...state, simpleData: action.payload}
		default:
			return state
	}
}