// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2qNZ-Zg7WdKX9O8pWjNhK8{display:flex;flex-wrap:wrap;margin-left:-10px;margin-right:-10px}._2qNZ-Zg7WdKX9O8pWjNhK8>*{width:20%;padding:20px 10px}@media screen and (max-width: 1200px){._2qNZ-Zg7WdKX9O8pWjNhK8>*{width:25%}}\n", "",{"version":3,"sources":["webpack://src/client/desktop/pages/listing/components/list/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,cAAe,CACf,iBAAkB,CAClB,kBAAmB,CAJvB,2BAMQ,SAAU,CACV,iBAAkB,CACrB,sCARL,2BAYY,SAAU,CACb","sourcesContent":[".Listing{\n    display: flex;\n    flex-wrap: wrap;\n    margin-left: -10px;\n    margin-right: -10px;\n    & > *{\n        width: 20%;\n        padding: 20px 10px;\n    }\n\n    @media screen and (max-width: 1200px) {\n        & > *{\n            width: 25%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Listing": "_2qNZ-Zg7WdKX9O8pWjNhK8"
};
module.exports = ___CSS_LOADER_EXPORT___;
