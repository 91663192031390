import React, { useCallback } from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { action_logout } from '../../../../../../../store/actions/global'


export default props => {

    useStyles(classes)
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const { user, countNotification } = global

    const loginHandle = useCallback(evt => {
        window.location.href = `/i/login?redirectUrl=${encodeURIComponent(window.location.href)}`
    }, [])

    const accountHandle = useCallback(evt => {
        window.location.href = `/me/m`
    }, [])

    const ordersHandle = useCallback(evt => {
        window.location.href = '/me/m/order'
    }, [])

    const messageHandle = useCallback(evt => {
        window.location.href = '/me/m/notification'
    }, [])

    const couponHandle = useCallback(evt => {
        window.location.href = '/me/m/coupons'
    }, [])

    const pointHandle = useCallback(evt => {
        window.location.href = '/me/m/credits'
    }, [])

    const logoutHandle = useCallback(evt => {
        dispatch(action_logout()).then(response =>{
            // console.log('response', response)
            window.location.reload();
        })
    }, [])

    return <ul className={classes.Ops}>
        {
            (!user || !user.id) ? <li className={classes.Op} onClick={loginHandle}>
                <FormattedMessage id="sign_in" defaultMessage="Sign In"/> / <FormattedMessage id="register" defaultMessage="Register"/>
            </li>: <li className={classes.Op} onClick={accountHandle}>
                <FormattedMessage id="my_account" defaultMessage="My Account"/>
            </li>
        }

        <li className={classes.Op} onClick={ordersHandle}>
            <FormattedMessage id="my_orders" defaultMessage="My Orders"/>
        </li>

        <li className={classes.Op} onClick={messageHandle}>
            <FormattedMessage id="my_message" defaultMessage="My Message"/>
            {
                countNotification > 0 && <span className={classes.Tip}>{countNotification}</span>
            }
        </li>

        <li className={classes.Op} onClick={couponHandle}>
            <FormattedMessage id="my_coupons" defaultMessage="My Coupons"/>
        </li>

        <li className={classes.Op} onClick={pointHandle}>
            <FormattedMessage id="my_points" defaultMessage="My Points"/>
        </li>

        {
            user && user.id && <li onClick={logoutHandle} className={`${classes.Op} ${classes.SignOut}`}>
                <FormattedMessage id="sign_out" defaultMessage="Sign Out"/>
            </li>
        }

    </ul>
}