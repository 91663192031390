import Chain from '../../../utils/chain'
import Coupon from './coupon'
import Notification from './notification'

/**
 * {
 *     type-1  bodycon+标题+文本
 *     type-2  bodycon+标题+文本+小图片
 *     type-3  bodycon+标题+文本+按钮
 * 
 * 
 *     type, bodycon, title, body, buttons, image
 * 
 *     button:{
 *          text, deepLink, successMsg
 *     }
 * }
 */

const CouponAdapter = new Chain(Coupon)
const NotificationAdapter = new Chain(Notification)

CouponAdapter.setNext(NotificationAdapter)

export default CouponAdapter

