import React from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { FormattedMessage } from 'react-intl'
import ReactDOM from 'react-dom'
import Mask from '../../../../../../components/mask'


const ErrorAlert = props => {
    useStyles(classes)
    return <div className={classes.Error}>
        <div className={classes.Hd}>
            <FormattedMessage id="not_match_fund" defaultMessage="Not Match found"/>
        </div>
        <div className={classes.Bd}>
            <div><FormattedMessage id="please_try_clearer_image" defaultMessage="Please try a clearer image, or put the product you want to search for in the middle of the image"/></div>
            <div><FormattedMessage id="or" defaultMessage="Or"/></div>
            <div><FormattedMessage id="try_text_to_search" defaultMessage="Try text to search for the item you want"/></div>
        </div>
        <div className={classes.Fd}>
            <button className={classes.Button} onClick={props?.handleNo}><FormattedMessage id="try_text_search" defaultMessage="Try Text Search"/></button>
            <button style={{marginTop: 10}} onClick={props?.handleYes} className={`${classes.Button} ${classes.Active}`}><FormattedMessage id="try_again_with_image" defaultMessage="Try Again With Image"/></button>
        </div>
    </div>
}

export default props => {

	if (typeof window === 'undefined')
		return null

	return ReactDOM.createPortal(
		<React.Fragment>
            <ErrorAlert {...props} />
            <Mask style={{zIndex: 20}}/>
        </React.Fragment>,
		document.getElementById('root')
	)
}