import React, { useEffect, useState, useMemo } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Iconfont } from '../../../../../../../components/icon/iconfont'
import { FormattedMessage, useIntl } from 'react-intl'
import { Products } from '../../../../../../pages/listing/components/list'
import { fetchSearchImageSort } from '../../../../../../../../api'
import SimilarRecommend from "../recommend/index";

const CategorySelection = props => {
    useStyles(classes)
    const { category, onSelect, selected } = props
    return <span onClick={e => onSelect(category)} className={`${classes.Selection} ${selected? classes.Selected:''}`}>
        <span className={classes.Check} />
        <span className={classes.Label}>{category?.title}</span>
    </span>
}

export default props => {
    useStyles(classes)

    const intl = useIntl()

    const { image, result, onUpload, productId, sensors } = props
    const [selectedCategory, setSelectedCategory] = useState(result?.[0])
    const [sortedProducts, setSortedProducts] = useState(result?.[0]?.products)
    const [originProducts, setOriginProducts] = useState(result?.[0]?.products)
    const [sort, setSort] = useState(0)
    const [closed, setClosed] = useState(false)

    useEffect(() => {
        setSelectedCategory(result?.[0])
        setSortedProducts(result?.[0]?.products)
        setOriginProducts(result?.[0]?.products)
        setSort(0)
    }, [result])

    const handleCategory = c => {
        setSelectedCategory({
            ...c,
            products: sortedProducts?.filter(p => c?.products?.some(p1 => p1.id === p.id))
        })
    }

    const handleSort = async e => {
        const { value } = e.target
        setSort(value)

        if(value === 0 || value === '0'){
            setSortedProducts(originProducts)
            setSelectedCategory({ ...selectedCategory, products: originProducts?.filter(p => selectedCategory?.products?.some(p1 => p1.id === p.id)) })
        }else{
            const response = await fetchSearchImageSort({
                sort: value,
                ids: sortedProducts?.map(p => p.id)?.join(',')
            })
    
            if (response.code === 200) {
                setSortedProducts(response?.result)
                setSelectedCategory({ ...selectedCategory, products: response?.result?.filter(p => selectedCategory?.products?.some(p1 => p1.id === p.id)) })
            } else {
                response?.result && alert(response.result)
            }
        }

        
    }

    const isSizeSelector = useMemo(() =>{
		return productId && !result?.length;
	}, [productId, result]);

    const sensorsData = useMemo(() =>{
        return sensors || {
            resourcepage_title: 'search',
            resource_position: '1',
            resource_type: '19',
            resource_content: "Image"
        };
    }, [sensors]);

    return <div className={classes.Container}>
        <div className={classes.Hd}>
            <span className={classes.Title}>
                <FormattedMessage id="shop_similar" defaultMessage="Shop Similar" />
            </span>
            <Iconfont onClick={evt => props?.onClose()} className={classes.Close}>&#xe7c9;</Iconfont>
        </div>
        <div className={classes.Bd}>
            <div className={classes.Aside}>
                <div className={classes.UploadedImage} style={{
                    backgroundImage: `url(${image})`
                }}>
                    <div className={classes.Canmera}>
                        <Iconfont className={classes.Icon}>&#xe706;</Iconfont>
                        <input onChange={onUpload} type="file"/>
                    </div>
                </div>


                {
                    !isSizeSelector && <div className={`${classes.Filter} ${closed ? classes.Closed: ''}`}>
                        <div className={classes.Hd} onClick={e=> setClosed(!closed)}>
                            <FormattedMessage id="category" defaultMessage="Category" />
                        </div>
                        <div className={classes.Bd}>
                            <ul className={classes.Selections}>
                                {
                                    result?.map((c, index) => <li key={`${c.title}-${index}`}>
                                        <CategorySelection selected={c.title === selectedCategory?.title} category={c} onSelect={handleCategory} />
                                    </li>)
                                }
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className={classes.Main}>
                {
                    isSizeSelector ? <div className={classes.ScrollContainer}>
                        <SimilarRecommend 
                            productId={productId} 
                            sensors={sensorsData} 
                        />
                    </div> : <React.Fragment>
                        <div className={classes.Hd}>
                            <div className={classes.Title}>
                                <FormattedMessage id="count_items" values={{
                                    total: selectedCategory?.products?.length
                                }} />
                            </div>
                            
                            <div className={classes.Select}>
                                <label>
                                    <FormattedMessage id="sort" defaultMessage="Sort" />
                                </label>
                                <select value={sort} onChange={handleSort}>

                                    <option value={0}>
                                        {intl.formatMessage({
                                            id: 'recommend',
                                            defaultMessage: 'Recommend'
                                        })}
                                    </option>
                                    <option value={1}>
                                        {intl.formatMessage({
                                            id: 'new_arrivals',
                                            defaultMessage: 'New Arrivals'
                                        })}
                                    </option>
                                    <option value={2}>
                                        {intl.formatMessage({
                                            id: 'price_low_to_high',
                                            defaultMessage: 'Price Low To High'
                                        })}
                                    </option>
                                    <option value={3}>
                                        {intl.formatMessage({
                                            id: 'price_high_to_low',
                                            defaultMessage: 'Price High To Low'
                                        })}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div className={classes.Bd}>
                            <Products 
                                className={classes.Products} 
                                products={selectedCategory?.products} 
                                column="virtualsearch" 
                                sensors={sensorsData}
                            />
                        </div>
                    </React.Fragment>
                }
                
            </div>
        </div>
    </div>
}