// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1cilDTUUnLIdhkF1ajMpa9{height:25px;max-width:600px}._3qy8B2wCXec8rhGgBbSNfS{height:25px;display:flex;align-items:center;overflow:hidden;font-size:12px}.e3KDE0MlTyfjp3uxT1uIO{text-decoration:none;font-size:12px;color:#222}.e3KDE0MlTyfjp3uxT1uIO:hover{text-decoration:underline}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/components/tip-swiper/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,WAAY,CACZ,eAAgB,CACnB,yBAGG,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,cAAe,CAClB,uBAGG,oBAAqB,CACrB,cAAe,CACf,UAAW,CAHf,6BAKQ,yBAA0B","sourcesContent":[".Swiper{\n    height: 25px;\n    max-width: 600px;\n}\n\n.SwiperSlide{\n    height: 25px;\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n    font-size: 12px;\n}\n\n.TipLink{\n    text-decoration: none;\n    font-size: 12px;\n    color: #222;\n    &:hover{\n        text-decoration: underline;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Swiper": "_1cilDTUUnLIdhkF1ajMpa9",
	"SwiperSlide": "_3qy8B2wCXec8rhGgBbSNfS",
	"TipLink": "e3KDE0MlTyfjp3uxT1uIO"
};
module.exports = ___CSS_LOADER_EXPORT___;
