import {
	SET_MENU,
	SET_HEADER_TIP,
	SET_CART_NUMBER, SET_DOWNLOAD, SET_CONFIG, SET_HOT_WORDS,
	SET_WISH_LIST,
	SET_CURRENCIES,
	SET_CURRENCY,
	SET_LANGUAGE,
	SET_CURRENT_USER,
	SET_RAFFLE,
	SET_SHOW_RAFFLE,
	SET_NOTIFICATION_NUMBER,
	SET_CART,
	SET_WEBSITES,
	SET_WEBSITE,
	SET_IS_BOT,
	SET_IS_APP,
	SET_REDIRECT_URL,
	SET_CURRENT_SEARCH,
	SET_SEARCH_IMAGE_RESULT,
	SET_COUNTRIES,
	SET_IS_FACEBOOK,
	SET_AB_FOR_IMAGE_SEARCH,
	SET_CART_INITED,
	SET_CART_TRIGGER,
	SET_EDITING_PRODUCT,
	SET_DOWNLOAD_MESSAGE,
	NOTIFICATION_EVENT_DATA,
	SET_COUNTRY,
	SET_LIST_TOP_BANNER,
	SET_SIZE_CHART,
	SET_TIME_RANGS,
	SET_FIRST_ORDER,
	SET_PROMOTIONAL,
	SET_WEBSITE_INFO,
	SET_SHOW_SIZE_CHART,
	SET_SIZE_DEFAULT_COUNTRY,
	SET_FLOATING_MARK_DATA, 
	SET_DEFERRED_PROMPT_INFORM,
	SET_SHOW_FIXED_ICON,
	SET_MEET_FREE_SHIPPING,
	SET_MEET_FREE_SHIPPING_MSG,
	SET_PAY_IMAGE_MESSAGES,
	SET_LIST_TYPE,
	SET_HEADER_EXTRA_TIP,
	SET_SERVERTIME, 
	SET_SHOW_BOTTOM_BANNER,
	SET_RANDOM,
} from '../actions/global'

const initialState = {
	tip: null,
	countCart: 0,
	countNotification: 0,
	download: null,
	config: {},
	menus: [],
	hotWords: [],
	wishlist: [],
	currencies: [],
	currency: 'USD',
	currencyObject: {unit: '$', name: 'USD'},
	languages: [
		{label: 'English', value: 'en'},
		{label: 'Español', value: 'es'},
		{label: 'Deutsch', value: 'de'},
		{label: 'Français', value: 'fr'},
		{label: 'Português', value: 'pt'},
		{label: 'Svenska', value: 'sv'},
		{label: 'Dansk', value: 'da'},
		{label: 'Norsk', value: 'nb'},
		{label: 'Íslenska', value: 'is'},
		{label: 'Suomalainen', value: 'fi'},
	],
	language: 'en',
	user: null,
	raffle: null,
	showRaffle: false,
	cart: null,
	websites: null,
	website: '1',
	websiteInfo: null,
	isBot: false,
	isApp: false,
	redirectUrl: '/',
	currentSearch: '',
	imageSearch:{
		url:null,
		result:[],
		show:false,
		searchType:null,
		sensors:null,
		productId: null
	},
	countries: [],
	isFacebook: false,
	abTestForImageSearch: 'A',
	cartInited: false,
	cartTrigger: 0,
	editingProduct: null,
	downloadMessage: null,
	notificationData: null,
	country: null,
	listTopBanner: null,
	sizechart: null,
	showSizeChart: false,
	timeRanges: null,
	isFirstOrder: true,
	promotionalActivity:null,
	appVersion: null,
	deviceType: null,
	sizeDefaultCountry: null,
	floatingMarkData: null,
	deferredPromptInform: false,
	hideFixedIcon: false,
	meetFreeShipping: false,
	meetFreeShippingMsg: null,
	payImageMessages: null,
	abTest: 'A',
	extraHeaderTip: null,
	serverTime: 0,
	showBottomBanner: false,
	random: null,
}




export default (state = initialState, action) => {
	switch (action.type){
		case SET_CART:
			return {...state, cart: action.payload, cartInited: true}
		case SET_HEADER_TIP:
			return {...state, tip: action.payload}
		case SET_DOWNLOAD:
			return {...state, download: action.payload}
		case SET_CONFIG:
			return {...state, config: action.payload}
		case SET_MENU:
			return {...state, menus: action.payload}
		case SET_HOT_WORDS:
			return {...state, hotWords: action.payload}
		case SET_WISH_LIST:
			return {...state, wishlist: action.payload}
		case SET_CART_NUMBER:
			return {...state, countCart: action.payload}
		case SET_MEET_FREE_SHIPPING:
			return {...state, meetFreeShipping: action.payload}
		case SET_MEET_FREE_SHIPPING_MSG:
			return {...state, meetFreeShippingMsg: action.payload}
		case SET_NOTIFICATION_NUMBER:
			return {...state, countNotification: action.payload}
		case SET_CURRENCIES:
			return {...state, currencies: action.payload}
		case SET_CURRENCY:
			return {...state, currency: action.payload?.name, currencyObject: action.payload}
		case SET_LANGUAGE:
			return {...state, language: action.payload}
		case SET_CURRENT_USER:
			return {...state, user: action.payload}
		case SET_RAFFLE:
			return {...state, raffle: action.payload}	
		case SET_SHOW_RAFFLE:
			return {...state, showRaffle: action.payload}
		case SET_WEBSITES:
			return {...state, websites: action.payload}	
		case SET_WEBSITE:
			return {...state, website: action.payload || '1'}	
		case SET_WEBSITE_INFO:
			return {...state, websiteInfo: action.payload}	
		case SET_IS_BOT:
			return {...state, isBot: action.payload}	
		case SET_IS_FACEBOOK:
			return {...state, isFacebook: action.payload}		
		case SET_IS_APP:
			return {...state, isApp: action.payload}	
		case SET_REDIRECT_URL:
			return {...state, redirectUrl: action.payload}
		case SET_CURRENT_SEARCH:
			return {...state, currentSearch: action.payload}	
		case SET_SEARCH_IMAGE_RESULT:
			return {...state,imageSearch:action.payload}
		case SET_COUNTRIES:
			return {...state,countries:action.payload}	
		case SET_AB_FOR_IMAGE_SEARCH:
			return {...state, abTestForImageSearch: action.payload}	
		case SET_CART_INITED:
			return {...state, cartInited: action.payload}	
		case SET_CART_TRIGGER:
			return {...state, cartTrigger: (state.cartTrigger || 0) + 1}	
		case SET_EDITING_PRODUCT:
			return {...state, editingProduct: action.payload}
		case SET_DOWNLOAD_MESSAGE:
			return {...state, downloadMessage: action.payload}
		case NOTIFICATION_EVENT_DATA:
			return {...state, notificationData: action.payload}
		case SET_COUNTRY:
			return {...state, country: action.payload}	
		case SET_LIST_TOP_BANNER:
			return {...state, listTopBanner: action.payload}	
		case SET_SIZE_CHART:
			return {...state, sizechart: action.payload}	
		case SET_SHOW_SIZE_CHART:
			return {...state, showSizeChart: action.payload}
		case SET_TIME_RANGS:
			return {...state, timeRanges: action.payload}	
		case SET_FIRST_ORDER:
			return {...state, isFirstOrder: action.payload}	
		case SET_PROMOTIONAL:
			return {...state, promotionalActivity: action.payload}
		case SET_SIZE_DEFAULT_COUNTRY:
			return {...state, sizeDefaultCountry: action.payload}
		case SET_FLOATING_MARK_DATA:
			return { ...state, floatingMarkData: action.payload } 
		case SET_DEFERRED_PROMPT_INFORM:
			return { ...state, deferredPromptInform: action.payload }

		case SET_SHOW_FIXED_ICON:
			return { ...state, hideFixedIcon: action.payload }

		case SET_PAY_IMAGE_MESSAGES:
			return { ...state, payImageMessages: action.payload }	

		case SET_LIST_TYPE:
			return { ...state, listType: action.payload }

		case SET_HEADER_EXTRA_TIP:
			return { ...state, extraHeaderTip: action.payload }
		case SET_SERVERTIME:
			return { ...state, serverTime: action.payload }
		case SET_SHOW_BOTTOM_BANNER:
			return { ...state, showBottomBanner: action.payload }
		case SET_RANDOM:
			return { ...state, random: action.payload }	
		default:
			return state
	}
}
