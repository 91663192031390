// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2Chhrnv7Q7Ph2ZRai52WYw{padding:5px 0}._2Chhrnv7Q7Ph2ZRai52WYw ._2tL6TYl5qZ_bg7opzlzjMk{height:30px;line-height:30px;color:#666;cursor:pointer;padding-left:12px;padding-right:12px}._2Chhrnv7Q7Ph2ZRai52WYw ._2tL6TYl5qZ_bg7opzlzjMk:hover{background-color:#efefef;color:#222}._2Chhrnv7Q7Ph2ZRai52WYw ._2tL6TYl5qZ_bg7opzlzjMk._2gfMHSQLrD2L9plVO-1sUN{color:#222}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/components/language/style.module.scss"],"names":[],"mappings":"AAAA,yBAgBI,aAAc,CAhBlB,kDAEQ,WAAY,CACZ,gBAAiB,CACjB,UAAW,CACX,cAAe,CAQf,iBAAkB,CAClB,kBAAmB,CAd3B,wDAOY,wBAAyB,CACzB,UAAW,CARvB,0EAWY,UAAW","sourcesContent":[".Languages{\n    .Language{\n        height: 30px;\n        line-height: 30px;\n        color: #666;\n        cursor: pointer;\n        &:hover{\n            background-color: #efefef;\n            color: #222;\n        }\n        &.Selected{\n            color: #222;\n        }\n        padding-left: 12px;\n        padding-right: 12px;\n    }\n    padding: 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Languages": "_2Chhrnv7Q7Ph2ZRai52WYw",
	"Language": "_2tL6TYl5qZ_bg7opzlzjMk",
	"Selected": "_2gfMHSQLrD2L9plVO-1sUN"
};
module.exports = ___CSS_LOADER_EXPORT___;
