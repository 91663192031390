import {
	EXCLUSIVE_LIST_ADD,
	EXCLUSIVE_LIST_SET,
	EXCLUSIVE_LIST_FINISHED,
	EXCLUSIVE_LIST_SKIP,
	EXCLUSIVE_LIST_LOADING,
	EXCLUSIVE_FILTER_SET,
	EXCLUSIVE_SORTERS_SET,
	EXCLUSIVE_TOTAL,
	EXCLUSIVE_FILTER_DATA,
} from '../../actions/pages/exclusive'

const initialState = {
	list: [],
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	total: 0,
	filterData: null
}

export default (state = initialState, action) => {
	switch (action.type){
		case EXCLUSIVE_LIST_SET:
			return {...state, list: action.payload}
		case EXCLUSIVE_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case EXCLUSIVE_LIST_SKIP:
			return {...state, skip: action.payload}
		case EXCLUSIVE_LIST_FINISHED:
			return {...state, finished: action.payload}
		case EXCLUSIVE_LIST_LOADING:
			return {...state, loading: action.payload}
		case EXCLUSIVE_FILTER_SET:
			return {...state, filterVo: action.payload}
		case EXCLUSIVE_SORTERS_SET:
			return {...state, sorters: action.payload}
		case EXCLUSIVE_TOTAL:
			return {...state, total: action.payload}	
		case EXCLUSIVE_FILTER_DATA:
			return {...state, filterData: action.payload}	
		default:
			return state
	}
}