import {
    getClogTitles,
    getClogsByTitle,
	getSimilarClogs,
	getCLogBanner
} from '../../../api'

const __List_Limit__ = 10


//首页
export const CLOG_SET_HOME_TITLES = 'CLOG_SET_HOME_TITLES'
export const CLOG_SET_HOME_BANNER = 'CLOG_SET_HOME_BANNER';
export const CLOG_SET_HOME_LIST_ADD = 'CLOG_SET_HOME_LIST_ADD'
export const CLOG_SET_HOME_LIST_SET = 'CLOG_SET_HOME_LIST_SET'
export const CLOG_SET_HOME_LIST_SKIP = 'CLOG_SET_HOME_LIST_SKIP'
export const CLOG_SET_HOME_LIST_FINISHED = 'CLOG_SET_HOME_LIST_FINISHED'
export const CLOG_SET_HOME_LIST_LOADING = 'CLOG_SET_HOME_LIST_LOADING'


//  详情页列表[图片]
export const CLOG_SET_IMAGE_LIST_ADD = 'CLOG_SET_IMAGE_LIST_ADD'
export const CLOG_SET_IMAGE_LIST_SET = 'CLOG_SET_IMAGE_LIST_SET'
export const CLOG_SET_IMAGE_LIST_SKIP = 'CLOG_SET_IMAGE_LIST_SKIP'
export const CLOG_SET_IMAGE_LIST_FINISHED = 'CLOG_SET_IMAGE_LIST_FINISHED'
export const CLOG_SET_IMAGE_LIST_LOADING = 'CLOG_SET_IMAGE_LIST_LOADING'


//  详情页列表[视频]
export const CLOG_SET_VIDEO_LIST_ADD = 'CLOG_SET_VIDEO_LIST_ADD'
export const CLOG_SET_VIDEO_LIST_SET = 'CLOG_SET_VIDEO_LIST_SET'
export const CLOG_SET_VIDEO_LIST_SKIP = 'CLOG_SET_VIDEO_LIST_SKIP'
export const CLOG_SET_VIDEO_LIST_FINISHED = 'CLOG_SET_VIDEO_LIST_FINISHED'
export const CLOG_SET_VIDEO_LIST_LOADING = 'CLOG_SET_VIDEO_LIST_LOADING'


export const CLOG_TOGGLE_LIKED = 'CLOG_TOGGLE_LIKED'
export const CLOG_TOOGLE_COMMENT = 'CLOG_TOOGLE_COMMENT';


//hashtag list
export const CLOG_SET_TAG_LIST_ADD = 'CLOG_SET_TAG_LIST_ADD'
export const CLOG_SET_TAG_LIST_SET = 'CLOG_SET_TAG_LIST_SET'
export const CLOG_SET_TAG_LIST_SKIP = 'CLOG_SET_TAG_LIST_SKIP'
export const CLOG_SET_TAG_LIST_FINISHED = 'CLOG_SET_TAG_LIST_FINISHED'
export const CLOG_SET_TAG_LIST_LOADING = 'CLOG_SET_TAG_LIST_LOADING'

export const toggleLiked = payload => ({
	type: CLOG_TOGGLE_LIKED,
	payload
})

export const toggleComment = payload =>({
	type: CLOG_TOOGLE_COMMENT,
	payload
})

export const setHomeTitles = payload => ({
    type: CLOG_SET_HOME_TITLES,
    payload
})

export const setHomeBanner = payload => ({
    type: CLOG_SET_HOME_BANNER,
    payload
})

export const setHomeClogs = payload => ({
    type: CLOG_SET_HOME_LIST_SET,
    payload
})

export const addHomeClogs = payload => ({
    type: CLOG_SET_HOME_LIST_ADD,
    payload
})

export const setHomeSkip = payload => ({
    type: CLOG_SET_HOME_LIST_SKIP,
    payload
})

export const setHomeFinished = payload => ({
    type: CLOG_SET_HOME_LIST_FINISHED,
    payload
})

export const setHomeLoading = payload => ({
    type: CLOG_SET_HOME_LIST_LOADING,
    payload
})




//image list
export const setImageClogs = payload => ({
    type: CLOG_SET_IMAGE_LIST_SET,
    payload
})

export const addImageClogs = payload => ({
    type: CLOG_SET_IMAGE_LIST_ADD,
    payload
})

export const setImageSkip = payload => ({
    type: CLOG_SET_IMAGE_LIST_SKIP,
    payload
})

export const setImageFinished = payload => ({
    type: CLOG_SET_IMAGE_LIST_FINISHED,
    payload
})

export const setImageLoading = payload => ({
    type: CLOG_SET_IMAGE_LIST_LOADING,
    payload
})


//video list
export const setVideoClogs = payload => ({
    type: CLOG_SET_VIDEO_LIST_SET,
    payload
})

export const addVideoClogs = payload => ({
    type: CLOG_SET_VIDEO_LIST_ADD,
    payload
})

export const setVideoSkip = payload => ({
    type: CLOG_SET_VIDEO_LIST_SKIP,
    payload
})

export const setVideoFinished = payload => ({
    type: CLOG_SET_VIDEO_LIST_FINISHED,
    payload
})

export const setVideoLoading = payload => ({
    type: CLOG_SET_VIDEO_LIST_LOADING,
    payload
})


//tag list
export const setTagClogs = payload => ({
    type: CLOG_SET_TAG_LIST_SET,
    payload
})

export const addTagClogs = payload => ({
    type: CLOG_SET_TAG_LIST_ADD,
    payload
})

export const setTagSkip = payload => ({
    type: CLOG_SET_TAG_LIST_SKIP,
    payload
})

export const setTagFinished = payload => ({
    type: CLOG_SET_TAG_LIST_FINISHED,
    payload
})

export const setTagLoading = payload => ({
    type: CLOG_SET_TAG_LIST_LOADING,
    payload
})




export const action_fetch_home_titles = headers => {
    return async dispatch => {
        const response = await getClogTitles(headers)
        if (response?.code === 200) {
            dispatch(setHomeTitles(response.result))
            return response.result
        } else {
            return null
        }
    }
}

export const action_fetch_home_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setHomeLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getClogsByTitle({...params, limit, skip: 0}, headers)
		dispatch(setHomeLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(setHomeClogs(clogs))
			dispatch(setHomeSkip(limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setHomeFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}


export const action_add_home_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setHomeLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getClogsByTitle({...params, limit}, headers)
		dispatch(setHomeLoading(false))
		if(response?.code === 200){

            const clogs = response?.result

			dispatch(addHomeClogs(clogs))
			dispatch(setHomeSkip(params.skip + limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setHomeFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}

//IMAGE LIST
export const action_fetch_image_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setImageLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getSimilarClogs({...params, limit, skip: 0}, headers)
		dispatch(setImageLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(setImageClogs(clogs))
			dispatch(setImageSkip(limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setImageFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}


export const action_add_image_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setImageLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getSimilarClogs({...params, limit}, headers)
		dispatch(setImageLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(addImageClogs(clogs))
			dispatch(setImageSkip(params.skip + limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setImageFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}


//VIDEO LIST
export const action_fetch_video_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setVideoLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getSimilarClogs({...params, limit, skip: 0}, headers)
		dispatch(setVideoLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(setVideoClogs(clogs))
			dispatch(setVideoSkip(limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setVideoFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}


export const action_add_video_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setVideoLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getSimilarClogs({...params, limit}, headers)
		dispatch(setVideoLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(addVideoClogs(clogs))
			dispatch(setVideoSkip(params.skip + limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setVideoFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}

export const action_fetch_home_banner = headers => {
    return async dispatch => {
        const response = await getCLogBanner(headers)
        if (response?.code === 200) {
            dispatch(setHomeBanner(response.result))
            return response.result
        } else {
            return null
        }
    }
}



export const action_fetch_tag_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setTagLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getClogsByTitle({...params, limit, skip: 0}, headers)
		dispatch(setTagLoading(false))
		if(response?.code === 200){
            const clogs = response?.result
			dispatch(setTagClogs(clogs))
			dispatch(setTagSkip(limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setTagFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}

export const action_add_tag_clogs = (params, headers) => {
    return async dispatch => {
		dispatch(setTagLoading(true))
		const limit = params?.limit || __List_Limit__
		const response = await getClogsByTitle({...params, limit}, headers)
		dispatch(setTagLoading(false))
		if(response?.code === 200){

            const clogs = response?.result

			dispatch(addTagClogs(clogs))
			dispatch(setTagSkip(params.skip + limit))

			if(!clogs || clogs?.length < 1){
				dispatch(setTagFinished(true))
			}
			return clogs
		}else{
			return null
		}
	}
}