import useStyles from "isomorphic-style-loader/useStyles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Iconfont } from "../../../../../../../components/icon/iconfont";
import classes from './style.module.scss';

const SizeChartHead = props => {
    useStyles(classes)
    const { closeSizeChart } = props

    return <div className={classes.SizeChartHead}>
            <Iconfont className={classes.SizeChartClose} onClick={() => closeSizeChart()}>&#xe69a;</Iconfont>
            <span className={classes.SizeChartTitle}><FormattedMessage id="size_chart"/></span>
    </div>
}

export default SizeChartHead