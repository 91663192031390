// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WnzNgCwDiytuS6Gy2vbxh{padding:12px;display:flex;flex-wrap:wrap;width:460px;justify-content:space-around;align-items:center}.WnzNgCwDiytuS6Gy2vbxh>li{width:20%;padding:6px 0}.WnzNgCwDiytuS6Gy2vbxh ._1mUoxyKi_4ECjy35Gcsm3W{display:flex;align-items:center;cursor:pointer;text-align:center}.WnzNgCwDiytuS6Gy2vbxh ._1mUoxyKi_4ECjy35Gcsm3W ._3B4iWEnIzh1IPfh1IGf15z{width:30px;height:16px;display:inline-block;vertical-align:middle;background-size:100% auto;border:1px solid #e6e6e6;background-position:center;background-repeat:no-repeat}.WnzNgCwDiytuS6Gy2vbxh ._1mUoxyKi_4ECjy35Gcsm3W ._2BKXwYMYU1buFCGEuX_dj9{text-transform:uppercase}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/components/currency/style.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAa,CACb,YAAa,CACb,cAAe,CACf,WAAY,CACZ,4BAA6B,CAC7B,kBAAmB,CANvB,0BAQQ,SAAU,CACV,aAAc,CATtB,gDAYQ,YAAa,CACb,kBAAmB,CACnB,cAAe,CACf,iBAAkB,CAf1B,yEAiBY,UAAW,CACX,WAAY,CACZ,oBAAqB,CACrB,qBAAsB,CACtB,yBAA0B,CAC1B,wBAAyB,CACzB,0BAA2B,CAC3B,2BAA4B,CAxBxC,yEA2BY,wBAAyB","sourcesContent":[".Currencies{\n    padding: 12px;\n    display: flex;\n    flex-wrap: wrap;\n    width: 460px;\n    justify-content: space-around;\n    align-items: center;\n    & > li{\n        width: 20%;\n        padding: 6px 0;\n    }\n    .Currency{\n        display: flex;\n        align-items: center;\n        cursor: pointer;\n        text-align: center;\n        .Image{\n            width: 30px;\n            height: 16px;\n            display: inline-block;\n            vertical-align: middle;\n            background-size: 100% auto;\n            border: 1px solid #e6e6e6;\n            background-position: center;\n            background-repeat: no-repeat;\n        }\n        .Label{\n            text-transform: uppercase;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Currencies": "WnzNgCwDiytuS6Gy2vbxh",
	"Currency": "_1mUoxyKi_4ECjy35Gcsm3W",
	"Image": "_3B4iWEnIzh1IPfh1IGf15z",
	"Label": "_2BKXwYMYU1buFCGEuX_dj9"
};
module.exports = ___CSS_LOADER_EXPORT___;
