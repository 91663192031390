import React from 'react'
import ReactDOM from 'react-dom'

import BlackMask from './mask'

const withAlert = Wrapped => {
    return props => {
        const onClose = () => {
            ReactDOM.unmountComponentAtNode(document.getElementById('alert-container'))
        }

        return <Wrapped {...props}  onClose={onClose}/>
    }
}


export const NormalConfirm = withAlert(props => {

    const {title, message, options, uuid, onClose} = props


    const handleClick = event => {
        onClose()
        options?.onClose?.()
    }

    const handleConfirm = event => {
        onClose()
        options?.onConfirm?.()
    }

    return <React.Fragment>
        <BlackMask onClick={handleClick} />
        <div className={`NormalAlert`} id={uuid}>

            {
                title && <div className="Title" style={{fontSize: 16, textAlign: 'center', fontFamily:'Roboto-Medium', marginBottom: 10}}>
                    {
                        title
                    }
                </div>
            }
            
            <div className="Msg">
                {
                    message
                }
            </div>
            <div className="ButtonBox" style={{flexWrap: 'wrap'}}>
                <button style={{width: '100%', textTransform: 'uppercase'}} className="CancelButton" onClick={handleClick}>{options?.cancelText || 'Cancel'}</button>
                <button style={{width: '100%', textTransform: 'uppercase', marginTop: 12, marginLeft: 0}} className="OkButton" onClick={handleConfirm}>{options?.confirmText || 'Confirm'}</button>
            </div>
        </div>
    </React.Fragment>
})


export default (title, message, options) => {
    ReactDOM.render(<NormalConfirm title={title} message={message} options={options}/>, document.getElementById('alert-container'))
}