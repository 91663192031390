import {
	LIST_SALES_LIST_ADD,
	LIST_SALES_LIST_SET,
	LIST_SALES_LIST_FINISHED,
	LIST_SALES_LIST_SKIP,
	LIST_SALES_LIST_LOADING,
	LIST_SALES_MESSAGE_CONFIG
} from '../../actions/pages/list-sales'

const initialState = {
	list: [],
	collection: null,
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	messageConfig: null
}

export default (state = initialState, action) => {
	switch (action.type){
		case LIST_SALES_MESSAGE_CONFIG:
			return {...state, messageConfig: action.payload}
		case LIST_SALES_LIST_SET:
			return {...state, list: action.payload}
		case LIST_SALES_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case LIST_SALES_LIST_SKIP:
			return {...state, skip: action.payload}
		case LIST_SALES_LIST_FINISHED:
			return {...state, finished: action.payload}
		case LIST_SALES_LIST_LOADING:
			return {...state, loading: action.payload}
		default:
			return state
	}
}