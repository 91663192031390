import React, { useCallback } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import deeplink from '../../../../../../utils/deeplink'
import { getCollectionUrl } from '../../../../../../utils/url'
import withSourceScroll from '../../../../../hocs/source-scoll'
import { getSensorsUrl } from '../../../../../utils/sensor'

const clickAHandle = (e, sensors) => {
    e.preventDefault()
    const href = e.currentTarget?.href
    window.location.href = getSensorsUrl(href, sensors)
}


export default withSourceScroll(props => {
    useStyles(classes)
    const { collection, innerRef, position } = props
    const href = collection?.deepLink ? deeplink(collection?.deepLink, "pc") : getCollectionUrl(collection?.id, collection?.name)

    return <a className={classes.CollectionCard} href={href}
        ref={innerRef}
        data-position={position + 1}
        data-type="1"
        data-title="list"
        data-content={collection?.enName || collection?.name}
        onClick={e => clickAHandle(e, {
            resourcepage_title: 'list',
            resource_type: '1',
            resource_content: collection?.enName || collection?.name,
            resource_position: position+1,
        })}
    >
        <div className={classes.Image}>
            <LazyLoadImage src={`${IMAGE_GEEKO_LTD}${collection.mainImage}`} />
        </div>
    </a>
})