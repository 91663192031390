import React, { useCallback, useRef, useState } from 'react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { unitPrice, getLowerPrice, getDelPrice, isFlashSales, getProductUrl } from '../../../../../../utils/product'
import { useLanguage, useWebp } from '../../../../../hooks/common'
import withItemScroll from '../../../../../hocs/item-scroll'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { IMAGE_GEEKO_LTD } from '../../../../../../constants/index'
import { FormattedMessage } from 'react-intl'
import CartIcon from '../cart/cart-icon'
import { Iconfont } from '../../../../../components/icon/iconfont'
import { getSensorsUrl } from '../../../../../utils/sensor'



export const ViewMoreProductItem = props => {

    useStyles(classes)
    const { sensors, item } = props

    const clickHandle = e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
        })
    }


    return <figure className={`${classes.NormalItem}`}>
        <a
            href={item?.href}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            onClick={clickHandle}
        >
            <div className={classes.Image}>
                <div className={classes.ViewMore}>View <br /> More {'>'}</div>
            </div>
        </a>
        <figcaption>
        </figcaption>
    </figure>
}


export const EmptyItem = props => {
    useStyles(classes)
    return <figure className={classes.NormalItem}>
        <div>
            <div className={classes.Image}>
                <img alt="empty" src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" />
            </div>
        </div>
        <figcaption>
            <div className={classes.Name}>-</div>
            <div className={classes.Prices}>
                <span className={classes.Price}>{'-'}</span>
            </div>
        </figcaption>
    </figure>
}

const Processes = props => {
    useStyles(classes)
    const { rate, isFinished } = props
    const percent = Math.min(Math.round(rate * 100), 100)
    const isAmost = percent >= 90

    return <div className={classes.Processes}>
        <div className={classes.Actived} style={{ width: `${percent}%` }} />
        <span className={classes.Text}>
            {
                isFinished ? <FormattedMessage id="sold_out" defaultMessage="Sold Out" /> : (isAmost ? <FormattedMessage id="almost_sold_out" defaultMessage="Almost Sold Out" /> : <FormattedMessage id="sold_percent" values={{
                    percent
                }} />)
            }
        </span>
    </div>
}

export default withItemScroll(props => {
    useStyles(classes)

    const { product, position, column, sensors, product_type, innerRef, filter, isTypeFlash } = props

    // product.promotion = {
    //     ...(product.promotion || {}),
    //     type: '9',
    //     // salesQty: Math.round(Math.random() * 10),
    //     totalQty: 12,
    //     salesQty: 12
    // }

    const language = useLanguage()

    const { differentColorStyleVariants } = product

    const [selectedColor, setSelectedColor] = useState(differentColorStyleVariants?.find(v => v.productId === product.id))
    const [hovered, setHovered] = useState(false)

    const isWebp = useWebp() && selectedColor?.status === '1'

    const [lowerPrice, delPrice, isFlash] = [
        getLowerPrice(product),
        getDelPrice(product),
        isFlashSales(product)
    ]


    const isFinished = isFlash && product.promotion?.totalQty <= product.promotion?.salesQty

    let off = 0
    if (delPrice) {
        off = Math.round((Number(delPrice.amount) - Number(lowerPrice.amount)) * 100 / Number(delPrice.amount))
    }

    const url = getProductUrl(selectedColor?.productId || product?.id, product?.name, selectedColor?.color, '', language)

    const promotionType = product?.promotion?.type || ''

    const priceClass = isFlash ? classes.FlashPrice : (off > 0 ? classes.PromotionPrice : classes.Price)

    const imageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.mainImage || product?.pcMainImage}`
    const secondaryImageUrl = `${IMAGE_GEEKO_LTD}${isWebp ? '/webp' : ''}/medium/${selectedColor?.pcMainImage2 || product?.pcMainImage2}`


    const handleProductClick = e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.resourcepage_title,
            resource_type: sensors?.resource_type,
            resource_content: sensors?.resource_content,
            resource_position: sensors?.resource_position,
            product_position: position + 1,
            filter,
        })
    }


    return <figure className={`${classes.NormalItem} ${position === 0 ? classes.FirstImage : ''}`}>
        <a
            href={url} ref={innerRef}
            product-id={product?.id}
            data-product-list-source
            data-promotion={promotionType}
            data-layer={product?.layerName}
            data-tracking={product.trackingRecords}
            data-product-source={product.dataSource}
            data-geeko-experiment={product.geekoExperimentId}
            data-geeko-id={product.geekoRequsestId}
            data-request-id={product.aliRequestId}
            data-experiment-id={product.aliExperimentId}
            data-column={column}
            data-product-position={position + 1}
            data-position={sensors?.resource_position}
            data-type={sensors?.resource_type}
            data-content={sensors?.resource_content}
            data-title={sensors?.resourcepage_title}
            data-filter={filter}
            data-product-type={product_type}
            onClick={handleProductClick}
        >

            <div onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                className={classes.Image}>

                {
                    position === 0 ? <img src={hovered && secondaryImageUrl ? secondaryImageUrl : imageUrl} alt={product?.name} /> : <LazyLoadImage src={hovered ? secondaryImageUrl : imageUrl} alt={product?.name} />
                }

                {
                    off > 0 && <span className={classes.FlashTip}>
                        <span className={classes.Number}>-{off}%</span>
                    </span>
                }

                {/* {
                    product.isNew && !isFlash && <span className={classes.New}><FormattedMessage id="new" defaultMessage="New" /></span>
                }

                {
                    !product.isNew && !isFlash && off > 0 && <span className={classes.OffTip}>{`-${off}%`}</span>
                } */}

                {
                    isFinished && <div className={classes.Finished}>
                        <FormattedMessage id="finished" defaultMessage={'Finished'} />
                    </div>
                }



            </div>
        </a>
        <figcaption>
            <div className={classes.Name}>{product.name}</div>
            <div className={classes.Prices}>
                <span className={priceClass}>{unitPrice(lowerPrice)}</span>
                {
                    delPrice && <del className={classes.Del}>{unitPrice(delPrice)}</del>
                }
            </div>
            <div className={classes.FlashProcess}>

                {
                    isFlash && product.promotion.totalQty > 0 ? <Processes isFinished={isFinished} rate={product.promotion.salesQty / product.promotion.totalQty} />: <div/>
                }

                <CartIcon
                    render={() => {
                        return <Iconfont className={classes.CartIcon}>&#xe6a8;</Iconfont>
                    }}
                    sensors={
                        {
                            ...sensors,
                            layerName: product?.layerName,
                            promotionType,
                            trackingRecords: product?.trackingRecords,
                            dataSource: product?.dataSource,
                            geekoExperimentId: product?.geekoExperimentId,
                            geekoRequsestId: product?.geekoRequsestId,
                            aliRequestId: product?.aliRequestId,
                            aliExperimentId: product?.aliExperimentId,
                            product_position: position + 1,
                        }
                    } product_type={product_type} productId={selectedColor?.productId || product.id} color={selectedColor ? selectedColor.color : product?.variants?.[0]?.color} />
            </div>
        </figcaption>
    </figure>
})