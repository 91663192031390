import React from 'react'
import _ from 'lodash'
import classes from './style.module.scss'
import withStyles from 'isomorphic-style-loader/withStyles'
import ErrorBinder from '../../../../../error-wrapper'


export const withList = (Wrapped, Pagination) => {
    return withStyles(classes)(class extends React.Component {
        constructor(props) {
            super(props)
        }
        render() {
            return <div>
                <Wrapped {...this.props} />
                <ErrorBinder>
                    {
                        this.props.pagination && <div className={classes.Pagination}>
                            <Pagination pagination={this.props.pagination}/>
                        </div>
                    }
                </ErrorBinder>
            </div>
        }
    })
}