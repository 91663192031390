export const TOGGLE_UI_MENU = 'TOGGLE_UI_MENU'
export const TOGGLE_UI_SEARCH = 'TOGGLE_UI_SEARCH'
export const TOGGLE_UI_LOTTERY = 'TOGGLE_UI_LOTTERY'
export const TOGGLE_UI_LOGIN = 'TOGGLE_UI_LOGIN';
export const TOOGLE_UI_DIRECTION = 'TOOGLE_UI_DIRECTION'
export const TOOGLE_UI_REQUEST_PRIVACY = 'TOOGLE_UI_REQUEST_PRIVACY'
export const TOOGLE_SUCCESS_WINDOW = 'TOOGLE_SUCCESS_WINDOW'
export const TOOGLE_UI_ALERT = 'TOOGLE_UI_ALERT'
export const TOOGLE_UI_CONFIRM = 'TOOGLE_UI_CONFIRM'
export const TOOGLE_UI_POPUP_SLIDE_IMAGES = "TOOGLE_UI_POPUP_SLIDE_IMAGES";
export const TOOGLE_UI_OPLOADING = "TOOGLE_UI_OPLOADING";

export const setOPLoading = payload =>({
    type: TOOGLE_UI_OPLOADING,
    payload
})
