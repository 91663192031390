import React, {useEffect, createRef, useLayoutEffect} from 'react'

export default Wrapped => {

    const handleClick = event => {
        const {
            currentTarget
        } = event


        const pdetai_product_id = window.pdetai_product_id
        const column = currentTarget.getAttribute('data-column')

        const ali_request_id = currentTarget.getAttribute("data-request-id")
        const geeko_request_id = currentTarget.getAttribute("data-geeko-id")
        const geeko_experiment_id = currentTarget.getAttribute("data-geeko-experiment")
        const ali_experiment_id = currentTarget.getAttribute("data-experiment-id")
        const data_source = currentTarget.getAttribute("data-product-source")

        const data_layer_name = currentTarget.getAttribute("data-layer")
        const data_tracking_records = currentTarget.getAttribute("data-tracking")
        const promotion_type = currentTarget.getAttribute("data-promotion")

        const product_id = currentTarget.getAttribute("product-id")
        const product_position = currentTarget.getAttribute("data-product-position")

        const product_type = currentTarget.getAttribute("data-product-type")




        const resourcepage_title = currentTarget.getAttribute("data-title")
        const resource_position = currentTarget.getAttribute("data-position")
        const resource_type = currentTarget.getAttribute("data-type")
        const resource_content = currentTarget.getAttribute("data-content")
        const filter = currentTarget.getAttribute("data-filter")
        const price_tag = currentTarget.getAttribute("data-price-tag")
        const price = currentTarget.getAttribute("data-price")
        const title = currentTarget.getAttribute("data-title")
        const is_top = currentTarget.getAttribute("data-top-flag");
        const related_sku = currentTarget.getAttribute("data-parent-sku");
        const view_pattern = currentTarget.getAttribute("data-view-pattern");
        

        if(window.GeekoSensors){
            window.GeekoSensors.Track('ProductListClick', {
                product_id,
                product_position,
                pdetai_product_id,
                column,
                resourcepage_title,
                resource_position,
                resource_type,
                resource_content,
                filter,
                ali_request_id,
                geeko_request_id,
                ali_experiment_id,
                data_source,
                geeko_experiment_id,
                data_layer_name,
                data_tracking_records,
                promotion_type,
                product_type,
                price_tag,
                price,
                title,
                is_top: !!is_top,
                related_sku,
                view_pattern
            })
        }

    }


    return props => {
        const dom = createRef()
        useEffect(() => {
            window?.productListObserver?.observe(dom.current)
            dom?.current?.addEventListener?.('click', handleClick)

            return () => {
                if(dom?.current){
                    dom?.current?.removeEventListener?.('click', handleClick)
                    window?.productListObserver?.unobserve(dom.current)
                }
            }
        }, [])
        return <Wrapped innerRef={dom} {...props}/>
    }
}