import {HOME_INIT, HOME_CURRENT_PAGE, HOME_AB_TEST, HOME_TOOGLE_SERVICE} from '../../actions/pages/home'

const initialState = {
	pages: [],
	currentPage: '1',
	abTest: 'A',
	toogleService: false
}

export default (state = initialState, action) => {
	switch(action.type){
		case HOME_INIT:
			return {...state, pages: action.payload}
		case HOME_CURRENT_PAGE:
			return {...state, currentPage: action.payload}
		case HOME_AB_TEST:
			return {...state, abTest: action.payload}	
		case HOME_TOOGLE_SERVICE:
			return { ...state, toogleService: action.payload }
		default:
			return state
	}
}