import useStyles from "isomorphic-style-loader/useStyles"
import React from "react"
import classes from './style.module.scss'

const RadioSelect = (props) => {
    useStyles(classes)
    const {data, value, onChange } = props 

    return <div className={`${classes.radioSelectBox} ${props.className || ''}`}>
        {
            data?.map((ele)=>(
                <span className={classes.selectItemM} key={ele.id}>
                    <span className={value == ele.value ? classes.selected : classes.noSelected} onClick={()=>onChange(ele.value)}>{ ele.label }</span>
                </span>
            ))
        }
    </div>
}

export default RadioSelect