// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3f5JGH1mBpaIAbo1jqOIKv{background-color:rgba(0,0,0,0.4);position:fixed;width:100%;height:100%;top:0;left:0;cursor:pointer;z-index:10}\n", "",{"version":3,"sources":["webpack://src/client/desktop/components/mask/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,gCAAmC,CACnC,cAAe,CACf,UAAW,CACX,WAAY,CACZ,KAAM,CACN,MAAO,CACP,cAAe,CACf,UAAW","sourcesContent":[".Mask{\n    background-color: rgba(0, 0, 0, .4);\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    top: 0;\n    left: 0;\n    cursor: pointer;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Mask": "_3f5JGH1mBpaIAbo1jqOIKv"
};
module.exports = ___CSS_LOADER_EXPORT___;
