// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2_9-bfJuCjvwdBHR3h89sX{display:flex;flex-wrap:wrap;margin:12px -5px -5px -5px}._2_9-bfJuCjvwdBHR3h89sX>li{margin:5px}._2_9-bfJuCjvwdBHR3h89sX ._2NnkQjOW2ukkdeqr9ZXNvl{cursor:pointer;display:inline-flex;align-items:center;padding:4px 12px;background-color:#f5f5f5}._2_9-bfJuCjvwdBHR3h89sX ._2NnkQjOW2ukkdeqr9ZXNvl ._3zF-o6uiDbNp6vV2dg3lqY{width:16px;margin-right:4px}._27zxhKHx4TOUCq8hZwh4A-{padding-left:12px;padding-right:12px}._27zxhKHx4TOUCq8hZwh4A->.__Ux9ZAAOcONXIQD0Htzl{font-size:16px;font-family:Roboto-Medium}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/search/hot-words/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,cAAe,CACf,0BAA2B,CAH/B,4BAKQ,UAAW,CALnB,kDAQQ,cAAe,CACf,mBAAoB,CACpB,kBAAmB,CACnB,gBAAiB,CACjB,wBAAyB,CAZjC,2EAcY,UAAW,CACX,gBAAiB,CACpB,yBAKL,iBAAkB,CAClB,kBAAmB,CAFvB,gDAIQ,cAAe,CACf,yBAA0B","sourcesContent":[".WordList{\n    display: flex;\n    flex-wrap: wrap;\n    margin: 12px -5px -5px -5px;\n    & > li{\n        margin: 5px;\n    }\n    .Word{\n        cursor: pointer;\n        display: inline-flex;\n        align-items: center;\n        padding: 4px 12px;\n        background-color: #f5f5f5;\n        .HotIcon{\n            width: 16px;\n            margin-right: 4px;\n        }\n    }\n}\n\n.HotWordsContainer{\n    padding-left: 12px;\n    padding-right: 12px;\n    & > .Hd{\n        font-size: 16px;\n        font-family: Roboto-Medium;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WordList": "_2_9-bfJuCjvwdBHR3h89sX",
	"Word": "_2NnkQjOW2ukkdeqr9ZXNvl",
	"HotIcon": "_3zF-o6uiDbNp6vV2dg3lqY",
	"HotWordsContainer": "_27zxhKHx4TOUCq8hZwh4A-",
	"Hd": "__Ux9ZAAOcONXIQD0Htzl"
};
module.exports = ___CSS_LOADER_EXPORT___;
