// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3HMQ_Md2dSf031c1yZW1H9._1jagDA9-DuAFhkx2xfu80s{height:45%}._3HMQ_Md2dSf031c1yZW1H9 img{display:block;width:100%}\n", "",{"version":3,"sources":["webpack://src/client/pages/moduleable/components/swiper/style.module.scss"],"names":[],"mappings":"AAEA,iDAEI,UAAW,CAFf,6BAKM,aAAc,CACd,UAAW","sourcesContent":["@import \"../../../../base.scss\";\n\n.FullSwiperSlide{\n  &.Half{\n    height: 45%;\n  }\n    img{\n      display: block;\n      width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FullSwiperSlide": "_3HMQ_Md2dSf031c1yZW1H9",
	"Half": "_1jagDA9-DuAFhkx2xfu80s"
};
module.exports = ___CSS_LOADER_EXPORT___;
