import {
	fetchHeaderTip,
	fetchMenu,
	getCountryMessage,
	getBaseConfig,
	getHotWords,
	fetchWishList,
	fetchCountCart,
	fetchCurrencies,
	sendCurrency,
	fetchCurrentUser,
	fetchCountNotification,
	fetchCurrency,
	fetchCart,
	fetchCountries,
	fetchABTesting,
	logout,
	getLatestNotification,
	getCouponNotification,
	fetchCountry,
	fetchTimeRanges,
	getFirstOrder,
	getActivityPromotional,
	fetchGetMessage, 
	fetchFixedLuckDraw,
	fetchCartIconInfo,
	fetchConfig
} from '../../api'

import NotificationAdapters from '../../client/notifications/adapters'

export const SET_HEADER_TIP = 'SET_HEADER_TIP'
export const SET_MENU = 'SET_MENU'
export const SET_CART_NUMBER = 'SET_CART_NUMBER'
export const SET_NOTIFICATION_NUMBER = 'SET_NOTIFICATION_NUMBER'
export const SET_DOWNLOAD = 'SET_DOWNLOAD'
export const SET_CONFIG = 'SET_CONFIG'
export const SET_HOT_WORDS = 'SET_HOT_WORDS'
export const SET_WISH_LIST = 'SET_WISH_LIST'
export const SET_CURRENCIES = 'SET_CURRENCIES'
export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_RAFFLE = 'SET_RAFFLE'
export const SET_SHOW_RAFFLE = 'SET_SHOW_RAFFLE'
export const SET_CART = 'SET_CART'
export const SET_WEBSITES = 'SET_WEBSITES'
export const SET_WEBSITE = 'SET_WEBSITE'
export const SET_IS_BOT = 'SET_IS_BOT'
export const SET_IS_APP = 'SET_IS_APP'
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL'
export const SET_CURRENT_SEARCH = 'SET_CURRENT_SEARCH'
export const SET_SEARCH_IMAGE_RESULT = 'SET_SEARCH_IMAGE_RESULT';
export const SET_COUNTRIES = 'SET_COUNTRIES'
export const SET_IS_FACEBOOK = 'SET_IS_FACEBOOK'
export const SET_AB_FOR_IMAGE_SEARCH = 'SET_AB_FOR_IMAGE_SEARCH'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_LIST_TOP_BANNER = 'SET_LIST_TOP_BANNER'
export const SET_TIME_RANGS = 'SET_TIME_RANGS'
export const SET_FIRST_ORDER = 'SET_FIRST_ORDER'
export const SET_WEBSITE_INFO = 'SET_WEBSITE_INFO'



export const SET_PROMOTIONAL = "SET_PROMOTIONAL";
export const SET_CART_INITED = 'SET_CART_INITED'
export const SET_CART_TRIGGER = 'SET_CART_TRIGGER'
export const SET_EDITING_PRODUCT = 'SET_EDITING_PRODUCT'
export const SET_DOWNLOAD_MESSAGE = 'SET_DOWNLOAD_MESSAGE'
export const NOTIFICATION_EVENT_DATA = 'NOTIFICATION_EVENT_DATA'
export const SET_SIZE_CHART = 'SET_SIZE_CHART'
export const SET_SHOW_SIZE_CHART = 'SET_SHOW_SIZE_CHART'
export const SET_SIZE_DEFAULT_COUNTRY = 'SET_SIZE_DEFAULT_COUNTRY'

export const SET_FLOATING_MARK_DATA = "SET_FLOATING_MARK_DATA";

export const SET_DEFERRED_PROMPT_INFORM = 'SET_DEFERRED_PROMPT_INFORM';

export const SET_SHOW_FIXED_ICON = "SET_SHOW_FIXED_ICON";
export const SET_MEET_FREE_SHIPPING = "SET_MEET_FREE_SHIPPING";
export const SET_MEET_FREE_SHIPPING_MSG = 'SET_MEET_FREE_SHIPPING_MSG';
export const SET_PAY_IMAGE_MESSAGES = 'SET_PAY_IMAGE_MESSAGES'
export const SET_AB_TEST = 'SET_AB_TEST'
export const SET_LIST_TYPE = 'SET_LIST_TYPE'
export const SET_HEADER_EXTRA_TIP = 'SET_HEADER_EXTRA_TIP'
export const SET_SERVERTIME = 'SET_SERVERTIME';

export const SET_SHOW_BOTTOM_BANNER = 'SET_SHOW_BOTTOM_BANNER';
export const SET_RANDOM = 'SET_RANDOM'

export const setSizeDefaultCountry = payload => ({
	type: SET_SIZE_DEFAULT_COUNTRY,
	payload
})

export const setWebsiteInfo = payload => ({
	type: SET_WEBSITE_INFO,
	payload
})

export const setFirstOrder = payload => ({
	type: SET_FIRST_ORDER,
	payload
})

export const setTimeRanges = payload => ({
	type: SET_TIME_RANGS,
	payload
})

export const setListTopBanner = payload => ({
	type: SET_LIST_TOP_BANNER,
	payload
})


export const setAbTestForImageSearch = payload => ({
	type: SET_AB_FOR_IMAGE_SEARCH,
	payload
})

export const setFacebook = payload => ({
	type: SET_IS_FACEBOOK,
	payload
})

export const setNotificationEventData = payload => ({
	type: NOTIFICATION_EVENT_DATA,
	payload
})

export const setDownloadMessage = payload => ({
	type: SET_DOWNLOAD_MESSAGE,
	payload
})

export const setEditingProduct = payload => ({
	type: SET_EDITING_PRODUCT,
	payload
})

export const setCartTrigger = () => ({
	type: SET_CART_TRIGGER
})

export const setCountries = payload => ({
	type: SET_COUNTRIES,
	payload
})

export const setCurrentSearch = payload => ({
	type: SET_CURRENT_SEARCH,
	payload
})
export const setRedirectUrl = payload => ({
	type: SET_REDIRECT_URL,
	payload
})

export const setApp = payload => ({
	type: SET_IS_APP,
	payload
})

export const setBot = payload => ({
	type: SET_IS_BOT,
	payload
})

export const setWebsite = payload => ({
	type: SET_WEBSITE,
	payload
})

export const setWebsites = payload => ({
	type: SET_WEBSITES,
	payload
})

export const setShowRaffle = payload => ({
	type: SET_SHOW_RAFFLE,
	payload
})

export const setRaffle = payload => ({
	type: SET_RAFFLE,
	payload
})

export const setCurrentUser = payload => ({
	type: SET_CURRENT_USER,
	payload
})

export const setLanguage = payload => ({
	type: SET_LANGUAGE,
	payload
})

export const setCurrency = payload => ({
	type: SET_CURRENCY,
	payload
})

export const setCurrencies = payload => ({
	type: SET_CURRENCIES,
	payload
})

export const setWishList = payload => ({
	type: SET_WISH_LIST,
	payload
})

export const setHeaderTip = payload => ({
	type: SET_HEADER_TIP,
	payload
})

export const setExtraHeaderTip = payload => ({
	type: SET_HEADER_EXTRA_TIP,
	payload
})

export const setMenu = payload => ({
	type: SET_MENU,
	payload
})

export const setDownload = payload => ({
	type: SET_DOWNLOAD,
	payload
})

export const setConfig = payload => ({
	type: SET_CONFIG,
	payload
})

export const setHotWords = payload => ({
	type: SET_HOT_WORDS,
	payload
})

export const setCountCart = payload => ({
	type: SET_CART_NUMBER,
	payload
})

export const setMeetFreeShipping = payload => ({
	type: SET_MEET_FREE_SHIPPING,
	payload
})

export const setMeetFreeShippingMsg = payload => ({
	type: SET_MEET_FREE_SHIPPING_MSG,
	payload
})

export const setCountNotification = payload => ({
	type: SET_NOTIFICATION_NUMBER,
	payload
})

export const setCart = payload => ({
	type: SET_CART,
	payload
})

export const setCountry = payload => ({
	type: SET_COUNTRY,
	payload
})

export const setSizeChart = payload => ({
	type: SET_SIZE_CHART,
	payload
})

export const setPromotional = payload => ({
	type: SET_PROMOTIONAL,
	payload
})

export const setShowSizeChart = payload => ({
	type: SET_SHOW_SIZE_CHART,
	payload
})

export const setFloatingMarkData = payload => ({
    type: SET_FLOATING_MARK_DATA,
    payload
})

export const setDeferredPromptInform = payload =>({
	type: SET_DEFERRED_PROMPT_INFORM,
	payload
})

export const setHideFixedIcon = payload => ({
	type: SET_SHOW_FIXED_ICON,
	payload
})

export const setPayImageMessages = payload => ({
	type: SET_PAY_IMAGE_MESSAGES,
	payload
})

export const setAbTest = payload => ({
	type: SET_AB_TEST,
	payload
})

export const setListType = payload => ({
	type: SET_LIST_TYPE,
	payload
})

export const setRadom = payload => ({
	type: SET_RANDOM,
	payload
})


export const action_fetch_size_data = headers => {
	return async dispatch => {
		const response = await fetchGetMessage('M1708',headers )
		if(response?.code === 200 && response?.result){
			const res = JSON.parse(response?.result?.message)
			// dispatch(setSizeChart(res))
			return res
		}else{
			return null
		}
	}
}

export const action_fetch_cart = headers => {
	return async dispatch => {
		const response = await fetchCart(headers)
		if(response?.code === 200){
			dispatch(setCart(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_cart_count = headers => {
	return async dispatch => {
		// const response = await fetchCountCart(headers)
		const response = await fetchCartIconInfo(headers)
		if(response?.code === 200){
			dispatch(setCountCart(response?.result?.shoppingCartProductCount))
			dispatch(setMeetFreeShipping(response?.result?.mode))
			dispatch(setMeetFreeShippingMsg(response?.result?.msg))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_notification_count = headers => {
	return async dispatch => {
		const response = await fetchCountNotification(headers)
		if(response?.code === 200){
			dispatch(setCountNotification(response.result))
			return response.result
		}else{
			dispatch(setCountNotification(0))
			return null
		}
	}
}


export const action_fetch_header_tip = headers => {
	return async dispatch => {
		const response = await fetchHeaderTip(headers)
		if(response?.code === 200){
			dispatch(setHeaderTip(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_menu = headers => {
	return async dispatch => {
		const response = await fetchMenu(headers)
		if(response?.code === 200){
			dispatch(setMenu(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_download = headers => {
	return async dispatch => {
		const response = await getCountryMessage('M1321', headers)
		if(response?.code === 200){
			dispatch(setDownload(response.result))
			return response.result
		}else{
			return []
		}
	}
}

export const action_fetch_hot_words = headers => {
	return async dispatch => {
		const response = await getHotWords(headers)
		if(response?.code === 200){
			dispatch(setHotWords(response.result))
			return response.result
		}else{
			return []
		}
	}
}


export const action_fetch_wish_list = headers => {
	return async dispatch => {
		const response = await fetchWishList(headers)
		if(response?.code === 200){
			const wishlist = response.result?.[0]?.productIds || []
			dispatch(setWishList(wishlist))
			return wishlist
		}else{
			dispatch(setWishList([]))
			return []
		}
	}
}

export const action_fetch_currencies = headers => {
	return async dispatch => {
		const response = await fetchCurrencies(headers)
		if(response?.code === 200){
			const currencies = response.result
			dispatch(setCurrencies(currencies))
			return currencies
		}else{
			return []
		}
	}
}


export const action_fetch_current_user = headers => {
	return async dispatch => {
		const response = await fetchCurrentUser(headers).catch(data => (null))
		if(response?.code === 200){
			const user = response.result
			dispatch(setCurrentUser(user))
			return user
		}else{
			return null
		}
	}
}



const fetchMobilePromises = async (headers, dispatch) => {
	const fetch1 = getCountryMessage('M1321', headers)
	const fetch2 = fetchHeaderTip(headers)
	const fetch3 = getBaseConfig(headers)
	const fetch4 = fetchCurrentUser(headers).catch(data => (null))
	const fetch5 = getFirstOrder(headers)

	const fetch6 = fetchABTesting('0347-W',headers)
	const fetch7 = fetchCountry(headers)
	const fetch8 = fetchCurrency(headers)
	const fetch9 = fetchGetMessage('M1142',headers)
	const fetch10 = getCountryMessage('M1814', headers)
	const fetch11 = fetchConfig('0592-W',headers)

	const promises = [fetch1, fetch2, fetch3, fetch4, fetch5, fetch6, fetch7, fetch8, fetch9, fetch10, fetch11]
	const values = await Promise.all(promises)


	if(values){
		// dispatch({
		// 	type: SET_SERVERTIME,
		// 	payload: values[0]?.serverTime
		// });

		if(values[0]){
			dispatch(setDownload(values[0].result))
		}
		if(values[1]){
			dispatch(setHeaderTip(values[1].result))
		}
		if(values[2]){
			dispatch(setConfig(values[2].result))
		}
		if(values[3]){
			dispatch(setCurrentUser(values[3].result))
		}

		if(values[4]){
			dispatch(setFirstOrder(values[4].result))
		}


		if(values[5]){
			dispatch(setAbTestForImageSearch(values[5].result))
		}

		if(values[6]){
			dispatch(setCountry(values[6].result))
		}

		if(values[7]){
			dispatch(setCurrency(values[7].result))
		}

		if(values[8]){
			try {
				dispatch(setPayImageMessages(JSON.parse(values[8]?.result?.message)?.result))
			} catch (error) {
				
			}
		}
		//todo
		if(values[9]){
			dispatch(setExtraHeaderTip(values[9].result))
		}

		if(values[10]){
			dispatch(setRadom(values[10]?.result))
		}
	}

	if(headers?.website){
		dispatch(setWebsite(headers?.website))
	}
	dispatch(setBot(headers?.isBot))
	return values
}


const fetchDesktopPromises = async (headers, dispatch) => {
	const fetch1 = getCountryMessage('M1321', headers)
	const fetch2 = fetchHeaderTip(headers)
	const fetch3 = getBaseConfig(headers)
	const fetch4 = fetchCurrentUser(headers).catch(data => (null))

	const fetch5 = getFirstOrder(headers)


	const fetch6 = fetchMenu(headers).catch(data => null)
	const fetch7 = getCountryMessage('M1327', headers)

	const fetch8 = fetchCountry(headers)
	const fetch9 = fetchCurrency(headers)
	const fetch10 = fetchTimeRanges(headers)
	const fetch11 = fetchGetMessage('M1142',headers)
	
	

	const promises = [fetch1, fetch2, fetch3, fetch4, fetch5, fetch6, fetch7, fetch8, fetch9, fetch10, fetch11]
	const values = await Promise.all(promises)


	if(values){
		// dispatch({
		// 	type: SET_SERVERTIME,
		// 	payload: values[0]?.serverTime
		// });

		if(values[0]){
			dispatch(setDownload(values[0].result))
		}
		if(values[1]){
			dispatch(setHeaderTip(values[1].result))
		}
		if(values[2]){
			dispatch(setConfig(values[2].result))
		}
		if(values[3]){
			dispatch(setCurrentUser(values[3].result))
		}


		if(values[4]){
			dispatch(setFirstOrder(values[4].result))
		}

		if(values[5]){
			dispatch(setMenu(values[5].result))
		}
		if(values[6]){
			const websites = values[6].result?.websites
			dispatch(setWebsites(websites))
			const website = websites?.find(w => w?.id === headers?.website) || websites?.[0]
			dispatch(setWebsiteInfo(website))
		}
		if(values[7]){
			dispatch(setCountry(values[7].result))
		}

		if(values[8]){
			dispatch(setCurrency(values[8].result))
		}

		if(values[9]){
			dispatch(setTimeRanges(values[9].result))
		}

		if(values[10]){
			try {
				dispatch(setPayImageMessages(JSON.parse(values[10]?.result?.message)?.result))
			} catch (error) {
				
			}
		}

		
	}

	dispatch(setBot(headers?.isBot))
	dispatch(setFacebook(headers?.isFacebook))
		
	if(headers?.website){
		dispatch(setWebsite(headers?.website))
	}
	dispatch(setBot(headers?.isBot))
	return values
}

export const action_fetch_init_data = headers => {
	return async dispatch => {
		const values = headers?.deviceType === 'pc' ? await fetchDesktopPromises(headers, dispatch) : await fetchMobilePromises(headers, dispatch)
		return values?.map?.(v  => v?.result)
	}
}




export const action_set_currency = (currency, headers) => {
	return async dispatch => {
		const response = await sendCurrency(currency, headers)
		if(response?.code === 200){
			dispatch(setCurrency(currency))
			return currency
		}else{
			return currency
		}
	}
}

export const action_fetch_currency = headers => {
	return async dispatch => {
		const response = await fetchCurrency(headers)
		if(response?.code === 200){
			const currency = response?.result
			dispatch(setCurrency(currency))
			return currency?.name
		}
	}
}

export const action_fetch_websites = headers => {
	return async dispatch => {
		const response = await getCountryMessage('M1327', headers)
		if(response?.code === 200){
			const websites = response?.result?.websites
			dispatch(setWebsites(websites))
			const website = websites?.find?.(w => w?.id === headers?.website) || websites?.[0]
			dispatch(setWebsiteInfo(website))
			return websites
		}
	}
}


export const action_fect_countries = headers => {
	return async dispatch => {
		const response = await fetchCountries(headers)
		if(response?.code === 200){
			const countries = response?.result
			dispatch(setCountries(countries))
			return countries
		}
	}
}

export const action_logout = headers => {
	return async dispatch => {
		const response = await logout(headers)
		if(response?.code === 200){
			dispatch(setCurrentUser(null))
			dispatch(action_fetch_cart_count(headers))
			dispatch(action_fetch_notification_count(headers))
		}
		return response;
	}
}

export const action_fetch_download_message = headers => {
	return async dispatch => {
		const response = await getCountryMessage('M1321', headers)
		if(response?.code === 200){
			dispatch(setDownloadMessage(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_notification = (type, headers) => {
	return async dispatch => {
		let response

		switch(type){
			case 'notification':
				response = await getLatestNotification(headers)
				break
			case 'coupon':
				response = await getCouponNotification(headers)
				break	
			default:
				response = null
		}


		if(response?.code === 200 && response?.result){
			const result = NotificationAdapters.excute({
				payload: response.result,
				type
			})
			dispatch(setNotificationEventData(result))
			return result
		}else{
			return null
		}
	}
}


export const action_fetch_list_top_banner = headers => {
	return async dispatch => {
		const response = await getCountryMessage('M1673', headers)
		if(response?.code === 200){
			dispatch(setListTopBanner(response.result))
			return response.result
		}
	}
}

export const action_fetch_time_ranges = headers => {
	return async dispatch => {
		const response = await fetchTimeRanges(headers)
		if(response?.code === 200){
			dispatch(setTimeRanges(response.result))
			return response.result
		}
	}
	
}

export const action_fetch_activity_promotional = (productId, activityId, headers) =>{
	return async dispatch =>{
		const response = await getActivityPromotional(activityId, productId, headers);
		if(response?.code === 200){
			dispatch(setPromotional(response.result))
			return response.result
		}
	}
}

export const action_fetch_floating_mark_data = (headers) => {
    return async dispatch => {
        const response = await fetchFixedLuckDraw();
        if(response?.code == 200 && response?.result){
			let responseData = { ...response.result, serverTime: response.serverTime };
            dispatch(setFloatingMarkData(responseData))
            return responseData;
        } else {
            dispatch(setFloatingMarkData(null))
            return null
        }
    }
}