import {
	COLLECTION_SET,
	COLLECTION_LIST_ADD,
	COLLECTION_LIST_SET,
	COLLECTION_LIST_FINISHED,
	COLLECTION_LIST_SKIP,
	COLLECTION_LIST_LOADING,
	COLLECTION_FILTER_SET,
	COLLECTION_SORTERS_SET,
	COLLECTION_TOTAL,
	MODAL_GFITS_ADD_GIFT_LIST,
    MODAL_GIFTS_SET_GIFT_SKIP,
    MODAL_GIFTS_SET_GIFT_LOADING,
    MODAL_GIFTS_SET_GIFT_FINISHED,
	MODAL_GIFTS_MODAL_DATA,
	SET_GIFTS_INFO_MESSAGE,
	COLLECTION_FILTER_DATA
} from '../../actions/pages/collection'

const initialState = {
	list: [],
	total: 0,
	collection: null,
	filterVo: null,
	sorters: [],
	skip: 0,
	finished: false,
	loading: false,
	modalGifts:{
        skip: 0,
        products: [],
        loading: false,
        finished: false
    },
	giftInfo:null,
	filterData: null,
	giftsModalData: {
		show: false,
		options: null
	}
}

export default (state = initialState, action) => {
	switch (action.type){
		case COLLECTION_SET:
			return {...state, collection: action.payload}
		case COLLECTION_LIST_SET:
			return {...state, list: action.payload}
		case COLLECTION_LIST_ADD:
			return {...state, list: [...(state.list||[]), ...(action.payload||[])]}
		case COLLECTION_LIST_SKIP:
			return {...state, skip: action.payload}
		case COLLECTION_LIST_FINISHED:
			return {...state, finished: action.payload}
		case COLLECTION_LIST_LOADING:
			return {...state, loading: action.payload}
		case COLLECTION_FILTER_SET:
			return {...state, filterVo: action.payload}
		case COLLECTION_SORTERS_SET:
			return {...state, sorters: action.payload}
		case COLLECTION_TOTAL:
			return {...state, total: action.payload}
		case MODAL_GFITS_ADD_GIFT_LIST:
			return { ...state, modalGifts: { ...state.modalGifts, products: [...(state.modalGifts?.products || []), ...action.payload] } }
		case MODAL_GIFTS_SET_GIFT_SKIP:
			return { ...state, modalGifts: { ...state.modalGifts, skip: action.payload } }
		case MODAL_GIFTS_SET_GIFT_LOADING:
			return { ...state, modalGifts: { ...state.modalGifts, loading: action.payload } }
		case MODAL_GIFTS_SET_GIFT_FINISHED:
			return { ...state, modalGifts: { ...state.modalGifts, finished: action.payload } }
		case SET_GIFTS_INFO_MESSAGE:
			return { ...state, giftInfo:action.payload }
		case COLLECTION_FILTER_DATA:
			return { ...state, filterData: action.payload }	
		case MODAL_GIFTS_MODAL_DATA:
			return { ...state, giftsModalData: action.payload }
		default:
			return state
	}
}