// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3jSBwwFxuAmBaeLVW0c6Gx{height:50px;line-height:50px;text-align:center}.z0UeutJSeLBskc0yAi5pJ,._1_0gYpIYQj-EL2DRpXZwzl{color:var(--light-color--, #999);font-size:12px}.PdLPkO40yO0U-r6wWoNke,._2hc4kyGkDJGL0pm5e05sxo{color:var(--light-color--, #999);font-size:12px;height:50px;line-height:50px;text-align:center}\n", "",{"version":3,"sources":["webpack://src/client/components/list/style.module.scss"],"names":[],"mappings":"AAEA,yBACI,WAAY,CACZ,gBAAiB,CACjB,iBAAkB,CACrB,gDAGG,gCAAiC,CACjC,cAAe,CAClB,gDAGG,gCAAiC,CACjC,cAAe,CACf,WAAY,CACZ,gBAAiB,CACjB,iBAAkB","sourcesContent":["@import \"../../base\";\n\n.LoadingWrapper{\n    height: 50px;\n    line-height: 50px;\n    text-align: center;\n}\n\n.Loading, .Finished{\n    color: var(--light-color--, #999);\n    font-size: 12px;\n}\n\n.ContainerLoading, .ContainerFinished{\n    color: var(--light-color--, #999);\n    font-size: 12px;\n    height: 50px;\n    line-height: 50px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LoadingWrapper": "_3jSBwwFxuAmBaeLVW0c6Gx",
	"Loading": "z0UeutJSeLBskc0yAi5pJ",
	"Finished": "_1_0gYpIYQj-EL2DRpXZwzl",
	"ContainerLoading": "PdLPkO40yO0U-r6wWoNke",
	"ContainerFinished": "_2hc4kyGkDJGL0pm5e05sxo"
};
module.exports = ___CSS_LOADER_EXPORT___;
