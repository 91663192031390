import { fetchSimilarList, fetchSimilarSorters, fetchSimilarFilter } from '../../../api'


export const __Default_Similar_Limit__ = 12


export const SIMILAR_LIST_ADD = 'SIMILAR_LIST_ADD'
export const SIMILAR_LIST_SET = 'SIMILAR_LIST_SET'
export const SIMILAR_LIST_SKIP = 'SIMILAR_LIST_SKIP'
export const SIMILAR_LIST_FINISHED = 'SIMILAR_LIST_FINISHED'
export const SIMILAR_LIST_LOADING = 'SIMILAR_LIST_LOADING'

export const SIMILAR_FILTER_SET = 'SIMILAR_FILTER_SET'
export const SIMILAR_SORTERS_SET = 'SIMILAR_SORTERS_SET'

export const setSimilarFilter = payload => ({
	type: SIMILAR_FILTER_SET,
	payload
})

export const setSimilarSorters = payload => ({
	type: SIMILAR_SORTERS_SET,
	payload
})


export const setSimilarList = payload => ({
	type: SIMILAR_LIST_SET,
	payload
})

export const addSimilarList = payload => ({
	type: SIMILAR_LIST_ADD,
	payload
})

export const setSimilarSkip = payload => ({
	type: SIMILAR_LIST_SKIP,
	payload
})

export const setSimilarFinished = payload => ({
	type: SIMILAR_LIST_FINISHED,
	payload
})

export const setSimilarListLoading = payload => ({
	type: SIMILAR_LIST_LOADING,
	payload
})

export const action_add_similar_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSimilarListLoading(true))
		const response = await fetchSimilarList(data, {...params, limit: __Default_Similar_Limit__}, headers)
		dispatch(setSimilarListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addSimilarList(products))
			dispatch(setSimilarSkip(params.skip + __Default_Similar_Limit__))

			if(!products || products?.length < 1){
				dispatch(setSimilarFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_similar_list = (data, params, headers) => {
	return async dispatch => {
		dispatch(setSimilarFinished(false))
		dispatch(setSimilarListLoading(true))
		const response = await fetchSimilarList(data, {...params, productId: data?.productId, skip: 0, limit: __Default_Similar_Limit__}, headers)
		dispatch(setSimilarListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setSimilarList(products))
			dispatch(setSimilarSkip(__Default_Similar_Limit__))
			if(!products || products?.length < 1){
				dispatch(setSimilarFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_similar_filter = (params, headers) => {
	return async dispatch => {
		const response = await fetchSimilarFilter(params, headers)
		if(response?.code === 200){
			dispatch(setSimilarFilter(response.result))
			return response.result
		}else{
			return null
		}
	}
}

export const action_fetch_similar_sorters = (params, headers) => {
	return async dispatch => {
		const response = await fetchSimilarSorters(params, headers)
		if(response?.code === 200){
			dispatch(setSimilarSorters(response.result))
			return response.result
		}else{
			return null
		}
	}
}