import React from 'react'

const __dayoffset = 1000 * 60 * 60 * 24, __houroffset = 1000 * 60 * 60, __minuteoffset = 1000 * 60
const getFullNumber = (number) => number >= 10 ? number : ('0' + number)

export default class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			lefting: 0
		}
	}

	getStructeDate(lefting) {
		const day = Math.floor(lefting / __dayoffset)
		const _leftday = lefting % __dayoffset
		let hour = this.props.showDay ? Math.floor(_leftday / __houroffset) : Math.floor(lefting / __houroffset)
		const _lefthour = lefting % __houroffset
		const minute = Math.floor(_lefthour / __minuteoffset)
		const second = Math.floor((lefting % __minuteoffset) / 1000)

		return {
			day: getFullNumber(day),
			hour: getFullNumber(hour),
			minute: getFullNumber(minute),
			second: getFullNumber(second)
		}
	}

	componentDidMount() {
		const { offset, callback } = this.props
		this.setState({
			lefting: offset
		})
		this.timerID = setInterval(() => {
			if (this.state.lefting - 1000 < 0) {
				callback && callback()
				clearInterval(this.timerID)
			} else {
				this.setState({
					lefting: this.state.lefting - 1000
				})
			}
		}, 1000)
	}

	componentWillUnmount() {
		clearInterval(this.timerID)
	}

	render() {
		const { day, hour, minute, second } = this.getStructeDate(this.state.lefting)
		const { showDay, showHour, showLabel, offset, numberStyle, labelStyle, dotStyle, numberClass, labelClass, dotClass } = this.props
		return <React.Fragment>
			{
				offset >= 1000 && <span {...this.props}>
					{showDay && day!=='00' && <React.Fragment><span className={numberClass} style={numberStyle} data-number>{day}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label><span>d</span></span>}<span className={dotClass}  style={dotStyle} data-dot>:</span></React.Fragment>}
					{showHour && <React.Fragment><span className={numberClass} style={numberStyle} data-number>{hour}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label><span>h</span></span>}<span className={dotClass}  data-dot style={dotStyle}>:</span></React.Fragment>}
					<span className={numberClass} style={numberStyle} data-number>{minute}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label><span>m</span></span>}<span className={dotClass} style={dotStyle} data-dot>:</span>
					<span className={numberClass} style={numberStyle} data-number>{second}</span>{showLabel && <span className={labelClass} style={labelStyle} data-label><span>s</span></span>}
				</span>
			}
		</React.Fragment>
	}
}


