import Cookies from 'js-cookie'
import { sendToFaceBook } from '../../../../api'
import Cache from '../../../../utils/cache'
import { getNoDash, getTrimAndLower, getPhoneNumber } from '../../utils/facebook-data'
import { v4 } from 'uuid'
import ads from '../../../../configs/ads.json'


const _send = (eventName, customData, transactionId, userData, shouldAddPurchase) => {


    const [fbp, fbc, wid] = [
        Cookies.get('_fbp'),
        Cookies.get('_fbc'),
        Cookies.get('wid')
    ]

    const eventId = transactionId || (eventName + '_' + new Date().getTime() + '_' + wid);
    const _userData = userData || window.userData

    sendToFaceBook({
        email: _userData?.email || window.email || Cache.get('shippingDetail')?.email || Cache.get('customerEmail'),
        eventId: eventId,
        eventName: eventName,
        fbp: fbp,
        fbc: fbc,
        customData,
        userData: _userData,
        shouldAddPurchase
    })
    //todo

    if (window.fbq) {
        if(!shouldAddPurchase){
            window.fbq('track', eventName, customData, { eventID: eventId, fbp: fbp, fbc: fbc })
        }else{
            window.fbq('trackSingle', ads?.facebook?.pixelId , eventName, customData, { eventID: eventId, fbp: fbp, fbc: fbc })
        }
    }
}


const addToCart = data => {
    try {

        if (!window.fbq)
            return


        const {
            productId,
            amount,
            currency,
            shouldAddPurchase
        } = data


        _send('AddToCart', {
            content_ids: [productId],
            content_type: 'product',
            value: amount,
            currency: currency
        })


        if(shouldAddPurchase){
            _send('Purchase', {
                value: amount,
                currency: currency,
                content_type: 'product',
                content_ids: [productId],
            }, `addcart_${v4()}`, null, shouldAddPurchase)
        }

    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        const {
            productId,
        } = data

        _send('AddToWishlist', {
            content_ids: [productId],
            content_type: 'product',
        })
    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    try {

        const {
            productId,
            amount,
            currency
        } = data


        _send('ViewContent', {
            content_type: 'product',
            content_ids: [productId],
            value: amount,
            currency: currency
        })
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        const {
            orderItems,
            orderTotal,
            transactionId,
            shippingDetail,
            id
        } = data

        const item_ids = orderItems?.map(item => item.productId)



        let userData
        if (shippingDetail) {
            const areaCodes = Cache.get('phone_area_codes') || []
            const areaCode = areaCodes.find(item => item.country === shippingDetail.country?.value)

            userData = {
                em: getTrimAndLower(shippingDetail.email || window.email),
                ph: getPhoneNumber(shippingDetail.phoneNumber, shippingDetail.phoneArea || areaCode?.areaCode),
                fn: getTrimAndLower(shippingDetail.firstName),
                ln: getTrimAndLower(shippingDetail.lastName),
                ct: getTrimAndLower(shippingDetail.city),
                st: getTrimAndLower(shippingDetail.state ? shippingDetail.state.value : ''),
                zp: getNoDash(getTrimAndLower(shippingDetail.zipCode)),
                country: getTrimAndLower(shippingDetail.country ? shippingDetail.country.value : '')
            }
        }

        _send('Purchase', {
            value: orderTotal?.amount,
            currency: orderTotal?.currency,
            content_type: 'product',
            content_ids: item_ids,
            order_id: id,
        }, transactionId, userData)

    } catch (e) {
        console.warn(e)
    }
}

const addPaymentInfo = data => {

    try {
        const { shippingDetail } = data

        let userData
        if (shippingDetail) {
            const areaCodes = Cache.get('phone_area_codes') || []
            const areaCode = areaCodes.find(item => item.country === shippingDetail.country?.value)
            userData = {
                em: getTrimAndLower(shippingDetail.email || window.email),
                ph: getPhoneNumber(shippingDetail.phoneNumber, shippingDetail.phoneArea || areaCode?.areaCode),
                fn: getTrimAndLower(shippingDetail.firstName),
                ln: getTrimAndLower(shippingDetail.lastName),
                ct: getTrimAndLower(shippingDetail.city),
                st: getTrimAndLower(shippingDetail.state ? shippingDetail.state.value : ''),
                zp: getNoDash(getTrimAndLower(shippingDetail.zipCode)),
                country: getTrimAndLower(shippingDetail.country ? shippingDetail.country.value : '')
            }
        }

        _send('AddPaymentInfo', null, null, userData)
    } catch (error) {
        console.warn(error)
    }


}

const register = data => {
    try {
        const { registration_method, status } = data
        _send('CompleteRegistration', { status, registration_method })
    } catch (error) {
        console.warn(error)
    }

}

const search = data => {
    try {
        const { search_string } = data
        _send('Search', { search_string })
    } catch (error) {
        console.warn(error)
    }
}

const initiateCheckout = data => {
    try {
        const { content_ids,  currency, num_items, value } = data
        _send('InitiateCheckout', { content_type: 'product', content_ids,  currency, num_items, value })
    } catch (error) {
        console.warn(error)
    }

}


export default {
    addToCart,
    addToWishList,
    pageView,
    purchase,
    addPaymentInfo,
    register,
    search,
    initiateCheckout
}