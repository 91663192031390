import React from 'react'
import useStyles from 'isomorphic-style-loader/useStyles'
import classes from './style.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { action_fetch_currencies, action_set_currency } from '../../../../../../../store/actions/global'
import { IMAGE_GEEKO_LTD } from '../../../../../../../constants'
import Cookies from 'js-cookie'

export default props => {
    useStyles(classes)
    const dispatch = useDispatch()
    const global = useSelector(state => state.global)
    const {currencies} = global

    if(!currencies || currencies.length < 1){
        dispatch(action_fetch_currencies())
    }

    const handleCurrency = async c => {
		await dispatch(action_set_currency(c.value))
		Cookies.set('currency', c.value, { path: '/', expires: 30 })
		window.location.reload()
    }

    return <ul className={classes.Currencies}>
        {
            currencies?.map(c => <li className={classes.Currency} key={c.value} onClick={ evt => handleCurrency(c) }>
                <span className={classes.Image} style={{
                    backgroundImage: `url(${IMAGE_GEEKO_LTD}/site/ninimour/flags/flag-${c.value}.png)`
                }} alt={c.label}/>
                <span className={classes.Label}>{c.value}</span>
            </li>)
        }
    </ul>
}