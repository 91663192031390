

const addToCart = data => {
    try {
        const {
            variantId,
            productId,
            amount,
            currency,
            name,
            quantity
        } = data
        if (window.pintrk) {
            window.pintrk('track', 'addtocart', {
                value: (Number(amount) * (quantity||1)).toFixed(2),
                currency: currency,
                line_items: [{
                    product_name: name,
                    product_id: productId,
                    product_variant_id: variantId,
                    product_price: amount,
                    product_quantity: quantity
                }]
            })
        }
    } catch (e) {
        console.warn(e)
    }
}

const addToWishList = data => {
    try {
        const {
            variantId,
            productId
        } = data

    } catch (e) {
        console.warn(e)
    }
}

const pageView = data => {
    
    try {
        const {
            productId
        } = data

        if(window.pintrk){
            window.pintrk('track', 'pagevisit',{
                promo_code: productId
            })
        }
        
    } catch (e) {
        console.warn(e)
    }
}

const purchase = data => {
    try {
        const {
            orderTotal,
            orderItems,
            transactionId,
        } = data

        if (window.pintrk) {
            window.pintrk('track', 'checkout', {
                value: orderTotal?.amount,
                currency: orderTotal?.currency,
                order_id: transactionId,
                line_items: orderItems.map(item => ({
                    product_name: item.productName,
                    product_id: item.productId,
                    product_variant_id: item.variantId,
                    product_price: item.price?.amount,
                    product_quantity: item.quantity
                }))
            })
        }

    } catch (e) {
        console.warn(e)
    }
    
}

export default {
    addToCart,
    addToWishList,
    pageView,
    purchase
}