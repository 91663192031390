import {
    GIFTS_SET_INIT_DATA,
    GFITS_ADD_COLLECTION_LIST,
    GIFTS_SET_COLLECTION_SKIP,
    GIFTS_SET_COLLECTION_LOADING,
    GIFTS_SET_COLLECTION_FINISHED,
    GFITS_ADD_GIFT_LIST,
    GIFTS_SET_GIFT_SKIP,
    GIFTS_SET_GIFT_LOADING,
    GIFTS_SET_GIFT_FINISHED
} from '../../actions/pages/gifts'

const initialState = {
    gift: {
        id: undefined,
        skip: 0,
        products: [],
        loading: false,
        finished: false
    },
    collection: {
        id: undefined,
        skip: 0,
        products: [],
        loading: false,
        finished: false
    }


}

export default (state = initialState, action) => {
    switch (action.type) {
        case GIFTS_SET_INIT_DATA:
            return { ...action.payload }
        case GFITS_ADD_COLLECTION_LIST:
            return { ...state, collection: { ...state.collection, products: [...(state.collection?.products || []), ...action.payload] } }
        case GIFTS_SET_COLLECTION_SKIP:
            return { ...state, collection: { ...state.collection, skip: action.payload } }
        case GIFTS_SET_COLLECTION_LOADING:
            return { ...state, collection: { ...state.collection, loading: action.payload } }
        case GIFTS_SET_COLLECTION_FINISHED:
            return { ...state, collection: { ...state.collection, finished: action.payload } }
        case GFITS_ADD_GIFT_LIST:
            return { ...state, gift: { ...state.gift, products: [...(state.gift?.products || []), ...action.payload] } }
        case GIFTS_SET_GIFT_SKIP:
            return { ...state, gift: { ...state.gift, skip: action.payload } }
        case GIFTS_SET_GIFT_LOADING:
            return { ...state, gift: { ...state.gift, loading: action.payload } }
        case GIFTS_SET_GIFT_FINISHED:
            return { ...state, gift: { ...state.gift, finished: action.payload } }
        default:
            return state
    }
}