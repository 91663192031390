import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Iconfont } from '../../../../../../../components/icon/iconfont';
import DropDownContent from '../../drop-down-content';
import classes from './style.module.scss';

const SizeChartMeasureBody = props => {
    useStyles(classes)
    const {showBodyM, setShowBodyM} = props || {}

    const bodyHTML = <div className={classes.HTMContent}>
                <div className={classes.HTMFlexContent}>
                    
                <div className={classes.HTMDetail}>
                        <div className={classes.HTMDetailItem}>
                            <div className={classes.HTMDetailItemTitle}>
                                <span>1</span>
                                <span><FormattedMessage id="bust"/></span>
                            </div>
                            <div className={classes.HTMDetailItemContent}>
                                <FormattedMessage id="measure_body_size_bust"/>
                            </div>
                        </div>
                        <div className={classes.HTMDetailItem}>
                            <div className={classes.HTMDetailItemTitle}>
                                <span>2</span>
                                <span><FormattedMessage id="waist"/></span>
                            </div>
                            <div className={classes.HTMDetailItemContent}>
                                <FormattedMessage id="measure_body_size_waist"/>
                            </div>
                        </div>
                        <div className={classes.HTMDetailItem}>
                            <div className={classes.HTMDetailItemTitle}>
                                <span>3</span>
                                <span><FormattedMessage id="hip"/></span>
                            </div>
                            <div className={classes.HTMDetailItemContent}>
                                <FormattedMessage id="measure_body_size_hip"/>
                            </div>
                        </div>
                    </div>
                    <div className={classes.imgBox}>
                        <img className={classes.bodyMImg} src="https://image.geeko.ltd/chicme/2021042901/bwh.png" alt="" />
                    </div>
                </div>
            </div>
    

    return <React.Fragment>
        <div className={classes.HowToMeasureBox}>
            <div className={classes.HTMTitle} onClick={() => setShowBodyM(!showBodyM)}>
                <FormattedMessage id="measure_body_size"/>
                <Iconfont className={`${classes.HTMShow} ${showBodyM && classes.HTMClose}`} >&#xe692;</Iconfont>
            </div>

            <DropDownContent show={showBodyM}>
                {bodyHTML}
            </DropDownContent>
        </div>
    </React.Fragment>
}


export default SizeChartMeasureBody