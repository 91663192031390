// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3NtuLKNSV-WRp94Prg7pJV{z-index:11;position:fixed;top:50%;left:50%;transform:translate(-50%, -60%)}._3NtuLKNSV-WRp94Prg7pJV ._1XnFTUr8c5NDxttVmpNqHx{position:fixed;z-index:400;width:720px;left:50%;top:50%;height:774px;background-color:#fff;border-radius:4px;transform:translate(-50%, -50%)}._3NtuLKNSV-WRp94Prg7pJV ._1XnFTUr8c5NDxttVmpNqHx .EV3pY69O29LuRgohjIWDX{height:calc(774px - 58px);overflow:hidden;overflow-y:scroll}._3NtuLKNSV-WRp94Prg7pJV ._1XnFTUr8c5NDxttVmpNqHx .EV3pY69O29LuRgohjIWDX::-webkit-scrollbar{width:6px;height:6px}._3NtuLKNSV-WRp94Prg7pJV ._1XnFTUr8c5NDxttVmpNqHx .EV3pY69O29LuRgohjIWDX::-webkit-scrollbar-thumb{background-color:#ccc;border-radius:10px}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/size-chart2/style.module.scss"],"names":[],"mappings":"AACA,yBACI,UAAW,CACX,cAAe,CACf,OAAQ,CACR,QAAS,CACT,+BAAgC,CALpC,kDAQQ,cAAe,CACf,WAAY,CACZ,WAAY,CACZ,QAAS,CACT,OAAQ,CACR,YAAa,CACb,qBAAsB,CACtB,iBAAkB,CAClB,+BAAgC,CAhBxC,yEAmBY,yBAA0B,CAC1B,eAAgB,CAChB,iBAAkB,CArB9B,4FAwBgB,SAAU,CACV,UAAW,CAzB3B,kGA4BgB,qBAAsB,CACtB,kBAAmB","sourcesContent":["\n.SizeChartContainer{\n    z-index: 11;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -60%);\n\n    .SizeChartBox{\n        position: fixed;\n        z-index: 400;\n        width: 720px;\n        left: 50%;\n        top: 50%;\n        height: 774px;\n        background-color: #fff;\n        border-radius: 4px;\n        transform: translate(-50%, -50%);\n\n        .Bd{\n            height: calc(774px - 58px);\n            overflow: hidden;\n            overflow-y: scroll;\n\n            &::-webkit-scrollbar{\n                width: 6px;\n                height: 6px;\n            }\n            &::-webkit-scrollbar-thumb{\n                background-color: #ccc;\n                border-radius: 10px;\n            }\n        }\n    }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SizeChartContainer": "_3NtuLKNSV-WRp94Prg7pJV",
	"SizeChartBox": "_1XnFTUr8c5NDxttVmpNqHx",
	"Bd": "EV3pY69O29LuRgohjIWDX"
};
module.exports = ___CSS_LOADER_EXPORT___;
