import { fetchConfig, fetchGetMessage, fetchStagingPrice, getCountryMessage } from "../../../api";

export const GET_GENERAL_MESSAGE = "GET_GENERAL_MESSAGE";
export const GET_GENERAL_COUNTRY_MESSAGE = "GET_GENERAL_COUNTRY_MESSAGE";
export const PRODUCT_SET_STAGING_INFO = 'PRODUCT_SET_STAGING_INFO';

export const setGeneralMessage = payload =>({
    type: GET_GENERAL_MESSAGE,
    payload
})

export const setGeneralCountryMessage = payload =>({
    type: GET_GENERAL_COUNTRY_MESSAGE,
    payload
})

export const setStagingInfo = payload => ({
	type: PRODUCT_SET_STAGING_INFO,
	payload
})

export const action_global_get_init_message = (code, name, headers) =>{
    return async (dispatch) =>{
        let response = await fetchGetMessage(code, headers)
        if(response?.code === 200 && response?.result?.message && response?.result?.enabled){
            let data = {};
            data[name] = response.result;
            dispatch(setGeneralMessage(data));
            return response.result;
        }else{
            return null;
        }
    }
}

export const action_global_get_init_country_message = (code, name, headers) =>{
    return async dispatch =>{
        let response = await getCountryMessage(code, headers);
        if(response?.code === 200 && response?.result){
            let data = {};
            data[name] = response.result;
            dispatch(setGeneralCountryMessage(data))
            return response.result;
        }else{
            return null;
        }
    }
}

export const action_test_message = (data) =>{
    return dispatch =>{
        dispatch(setGeneralCountryMessage(data))
    }
}

export const action_fetch_staging_info = (headers) => {
	return async dispatch => {
		const response = await fetchConfig('0488-W', headers)
		if(response?.code == 200){
			dispatch(setStagingInfo(response?.result))
		}
		return response?.result
	}
}