// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2gFwLyiMQlFDTVRVxq5jwU{position:relative;display:inline-block}._2gFwLyiMQlFDTVRVxq5jwU ._1ic7dgguzzBzgVxahbSWBe{height:100%;display:flex;align-items:center}._2gFwLyiMQlFDTVRVxq5jwU ._3uWNi84W_tUF-HqSREd60B{position:absolute;top:100%;background-color:#fff;z-index:10;border:1px solid #efefef}._2gFwLyiMQlFDTVRVxq5jwU ._3uWNi84W_tUF-HqSREd60B._17eJq7INnha2z7FCH0DSU0{animation:x1hMDxUffNdQuh4LsWWum 5s ease-in-out}._2gFwLyiMQlFDTVRVxq5jwU .iconfont{font-size:20px;color:#222;cursor:pointer}@keyframes x1hMDxUffNdQuh4LsWWum{0%{opacity:1}80%{opacity:1}100%{opacity:0}}\n", "",{"version":3,"sources":["webpack://src/client/desktop/layout/fragments/tools/components/tool-icon/style.module.scss"],"names":[],"mappings":"AAAA,yBACI,iBAAkB,CAClB,oBAAqB,CAFzB,kDAKQ,WAAY,CACZ,YAAa,CACb,kBAAmB,CAP3B,kDAWQ,iBAAkB,CAClB,QAAS,CACT,qBAAsB,CACtB,UAAW,CACX,wBAAyB,CAfjC,0EAkBY,8CAAkC,CAlB9C,mCAuBQ,cAAe,CACf,UAAW,CACX,cAAe,CAClB,iCAID,GACI,SAAU,CAGd,IACI,SAAU,CAGd,KACI,SAAU,CAAA","sourcesContent":[".ToolIcon{\n    position: relative;\n    display: inline-block;\n\n    .Icon{\n        height: 100%;\n        display: flex;\n        align-items: center;\n    }\n    \n    .DownMenu{\n        position: absolute;\n        top: 100%;\n        background-color: #fff;\n        z-index: 10;\n        border: 1px solid #efefef;\n\n        &.Trigger{\n            animation: disapear 5s ease-in-out;\n        }\n    }\n\n    :global(.iconfont){\n        font-size: 20px;\n        color: #222;\n        cursor: pointer;\n    }\n}\n\n@keyframes disapear {\n    0%{\n        opacity: 1;\n    }\n\n    80%{\n        opacity: 1;\n    }\n\n    100%{\n        opacity: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToolIcon": "_2gFwLyiMQlFDTVRVxq5jwU",
	"Icon": "_1ic7dgguzzBzgVxahbSWBe",
	"DownMenu": "_3uWNi84W_tUF-HqSREd60B",
	"Trigger": "_17eJq7INnha2z7FCH0DSU0",
	"disapear": "x1hMDxUffNdQuh4LsWWum"
};
module.exports = ___CSS_LOADER_EXPORT___;
