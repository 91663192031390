export const __Default_Simple_Limit__ = 12
export const __Default_Simple_Limit_For_Desktop__ = 30

export const SIMPLE_LIST_ADD = 'SIMPLE_LIST_ADD'
export const SIMPLE_LIST_SET = 'SIMPLE_LIST_SET'
export const SIMPLE_LIST_SKIP = 'SIMPLE_LIST_SKIP'
export const SIMPLE_LIST_FINISHED = 'SIMPLE_LIST_FINISHED'
export const SIMPLE_LIST_LOADING = 'SIMPLE_LIST_LOADING'


export const setSimpleList = payload => ({
	type: SIMPLE_LIST_SET,
	payload
})

export const addSimpleList = payload => ({
	type: SIMPLE_LIST_ADD,
	payload
})

export const setSimpleSkip = payload => ({
	type: SIMPLE_LIST_SKIP,
	payload
})

export const setSimpleFinished = payload => ({
	type: SIMPLE_LIST_FINISHED,
	payload
})

export const setSimpleListLoading = payload => ({
	type: SIMPLE_LIST_LOADING,
	payload
})

export const action_add_simple_list = (fetchFunc, params, headers) => {
	return async dispatch => {
		dispatch(setSimpleListLoading(true))
		const limit = params?.limit || __Default_Simple_Limit__
		const response = await fetchFunc({...params, limit}, headers)
		dispatch(setSimpleListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(addSimpleList(products))
			dispatch(setSimpleSkip(params.skip + limit))

			if(!products || products?.length < 1){
				dispatch(setSimpleFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}

export const action_fetch_simple_list = (fetchFunc, params, headers) => {
	return async dispatch => {
		dispatch(setSimpleFinished(false))
		dispatch(setSimpleListLoading(true))
		const limit = params?.limit || __Default_Simple_Limit__
		const response = await fetchFunc({...params, skip: 0, limit}, headers)
		dispatch(setSimpleListLoading(false))
		if(response?.code === 200){

			const products = response.result?.map(p => ({
				...p,
				geekoRequsestId: response.requestId,
				geekoExperimentId: response.experimentId,
				layerName: response.layerName,
				trackingRecords: response.trackingRecords
			}))


			dispatch(setSimpleList(products))
			dispatch(setSimpleSkip(limit))
			if(!products || products?.length < 1){
				dispatch(setSimpleFinished(true))
			}
			return products
		}else{
			return null
		}
	}
}