import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'

import SwiperCore, {
	Pagination,
	Autoplay
} from 'swiper';

SwiperCore.use([Pagination, Autoplay]);

export const FullSwiper = props => {
    useStyles(classes)
    const {items, options} = props

	return <Swiper
        {...options}
		style={props.style}
		className={props.className}
	>
		{
			items?.map?.((item, index) => <SwiperSlide className={`${classes.FullSwiperSlide} ${options?.slidesPerColumn == 2 ? classes.Half: ''} ${props?.slideClass}`} key={index}>
				{
					props.render(item, index)
				}
			</SwiperSlide>)
		}
	</Swiper>
}
